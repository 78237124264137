import { useState } from 'react'
import CloseIcon from '../../../components/accreditation-components/close-icon/closeIcon';
import GetHelp from "../../../components/get-help/getHelp";
import { TextField, Button } from '@material-ui/core'
import { THEME_COLOR } from '../../../constants/colors'

function AddOfferingStep2() {

    const [accountInfo, setAccountInfo] = useState({
        offeringText: '',
        offeringWatermark: ''
    })

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountInfo(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <section className="add-offering-step1-page">
            <div className="add-offering-step1">
                <CloseIcon />
                <div className="form-title-container">
                    <h3 className="form-title">
                        Add offering
                    </h3>
                    <span className="step">
                        Step 2 of 2
                    </span>
                </div>
                <form className="add-offering-step1-form" onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="offeringText" className="inputlabel-label">Offering text</label>
                    <TextField
                        value={accountInfo.offeringText}
                        onChange={inputHandler}
                        id="offeringText"
                        name="offeringText"
                        multiline
                        rows={4}
                        rowsMax={6}
                        className="form-field" />
                    <label htmlFor="offeringWatermark" className="inputlabel-label">Offering document watermark</label>
                    <TextField
                        value={accountInfo.offeringWatermark}
                        onChange={inputHandler}
                        id="offeringWatermark"
                        name="offeringWatermark"
                        multiline
                        rows={4}
                        rowsMax={6}
                        className="form-field" />

                    <Button
                        style={{ backgroundColor: THEME_COLOR }}
                        className="accreditation-next-button"
                    >
                        Next ➝{" "}
                    </Button>
                </form>
                <GetHelp />
            </div>
        </section>
    )
}

export default AddOfferingStep2;