import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Button } from "@material-ui/core";
import { NOT_ACCREDITATED } from "../../constants/achConstants";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetLink } from "../../redux/actions/achActions";

export default function AchMain() {
  const dispatch = useDispatch();
  let { url } = useRouteMatch();
  const achStatus = useSelector((state: any) => state.achReducer.accountStatus);
  const [method, setMethod] = React.useState("Plaid");
  const history = useHistory();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl className="options-container" component="fieldset">
      {achStatus === NOT_ACCREDITATED ? (
        <p>PLEASE PASS KYC AND ACCREDITATION</p>
      ) : (
        <>
          <div className="options">
            <FormLabel className="title">Please, choose the option</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={method}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Plaid"
                control={<Radio />}
                label="Create link with Plaid"
              />
              {!achStatus ? null : (
                <FormControlLabel
                  color="primary"
                  value="North"
                  control={<Radio color="primary" />}
                  label="Create link with North Capital/Solidblock"
                />
              )}
              {!achStatus ? (
                <FormControlLabel
                  value="Account"
                  control={<Radio color="primary" />}
                  label="Create with North Capital/Solidblock"
                />
              ) : null}
            </RadioGroup>
          </div>
          <Button
            className="next"
            onClick={() =>
              method === "Plaid"
                ? dispatch(getWidgetLink(history, url, achStatus))
                : method === "Account"
                ? history.push(`${url}/info-section`)
                : history.push(`${url}/input-section`)}
          />
        </>
      )}
                     
     {/* return (
         <FormControl className='options-container' component="fieldset" onClick={() => console.log(method)}>
            {achStatus === NOT_ACCREDITATED ? <p>PLEASE PASS KYC AND ACCREDITATION</p> :
                <React.Fragment>
                    <FormLabel component="legend">Please, choose the option</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={method} onChange={handleChange}>
                        <FormControlLabel value="Plaid" control={<Radio />} label="Create link with Plaid" />
                        {!achStatus ? null : <FormControlLabel value="North" control={<Radio />} label="Create link with North Capital/Solidblock" />}
                        {!achStatus ? <FormControlLabel value="Account" control={<Radio />} label="Use “Account test”" /> : null}
                    </RadioGroup>
                    <Button style={{ backgroundColor: THEME_COLOR }}
                        onClick={() => method === 'Plaid' ? dispatch(getWidgetLink(history, url, achStatus)) : method === 'Account' ? history.push(`${url}/info-section`) : history.push(`${url}/input-section`)}>
                        Confirm
                    </Button>
                </React.Fragment>
            }  */}
    </FormControl>
  );
}
