import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import ErrorBoundry from "../components/error-boundry/errorBoundry";
import Footer from "../components/footer/footer";
import Spinner from '../components/spinner/spinner';
import Headers from "../components/headers-components/headers";
import ScrollToTop from "./scrollToTop";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import MainAssetPage from "../pages/main-assets-pages/main-asset-page/mainAssetPage";
// import SignUpConfirmationPage from "../pages/auth-pages/signup-pages/signup-confirmation-page/signupConfirmationPage";
// import { AboutPage, EmailConfirm, RedfrogPage, DetoxPage } from "../pages";
// import { ApplyTokenizationPages } from "../pages";
import { CheckoutPages } from "../pages";
import SimpleSnackbar from "../components/exchange-componnets/snakBar";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SignUpRouter = lazy(
  () => import("../pages/auth-pages/signupRouter")
);

const LoginPage = lazy(
  () => import("../pages/auth-pages/login-page/loginPage")
);
const GoogleAuthReg = lazy(
  () => import("../pages/auth-pages/google-auth-reg/googleAuthReg")
);

const ProfilePages = lazy(
  () => import("../pages/profile-pages/profilePages")
);
const CoursePage = lazy(
  () => import("../pages/course-page")
);
//////////
// const ApplyTokenizationPages = lazy(
//   () => import("../pages/apply-tokenization-pages/applyTokenizationPages")
// );
const SignUpConfirmationPage = lazy(
  () => import("../pages/auth-pages/signup-pages/signup-confirmation-page/signupConfirmationPage")
);
// const AboutPage = lazy(
//   () => import("../pages/about-page/aboutPage")
// );

const RedfrogPage = lazy(
  () => import("../pages/main-assets-pages/redfrog-page/redfrogPage")
);
const DetoxPage = lazy(
  () => import("../pages/main-assets-pages/detox-page/detoxPage")
);
const LdbPage = lazy(
  () => import("../pages/main-assets-pages/ldb-page/ldbPage")
);
const AirPage = lazy(
  () => import("../pages/main-assets-pages/air-page/airPage")
);
const MainAssetPage = lazy(
  () => import("../pages/main-assets-pages/main-asset-page/mainAssetPage")
);
////////
// const CheckoutPage = lazy(
// () => import("../pages/main-assets-pages/checkout-page/checkoutPage")
// );
const MarketplacePage = lazy(
  () => import("../pages/marketplace-page/marketplacePage")
);
// const LandingPage = lazy(
//   () => import("../pages/landing-page/landingPage")
// );
const AccreditationPages = lazy(
  () => import("../pages/accreditation-pages/accreditationPages")
);
const KYCPages = lazy(() => import("../pages/KYC-pages/kycPages"));
const MyInvestmentsPage = lazy(
  () => import("../pages/my-investments-page/myInvestmentsPage")
);
const TransactionHistoryPage = lazy(
  () => import("../pages/transaction-history-page/transactionHistoryPage")
);
const PaymentPage = lazy(
  () => import("../pages/main-assets-pages/payment-page/paymentPage")
);
const ConfirmationPage = lazy(
  () => import("../pages/main-assets-pages/confirmation-page/confirmationPage")
);
const ForgotPasswordPages = lazy(
  () => import("../pages/auth-pages/forgot-password-pages/forgotPasswordPages")
);
const SocialAuth = lazy(
  () => import("../pages/auth-pages/social-authentication/socialAuth")
);
const IssuerPages = lazy(
  () => import("../pages/issuer-pages/issuerPages")
);
const ExchangePage = lazy(
  () => import("../pages/exchange-page/exchangePage")
);
const TokenizationForm = lazy(
  () => import("../pages/tokenization-form-pages/tokenization-form")
);
const TokenizationThanksForm = lazy(
  () => import("../pages/tokenization-form-pages/tokenization-thanks-form")
);
// const TokenizationPaymentSetup = lazy(
//   () => import("../pages/tokenization-form-pages/tokenization-payment-setup")
// );
// const TokenizationPaymentFinish = lazy(
//   () => import("../pages/tokenization-form-pages/tokenization-payment-finish")
// );
const ContactsPage = lazy(
  () => import("../pages/contacts-page/contacts")
);
const PartnersPage = lazy(
  () => import("../pages/contacts-page/partnersContacts")
);
const OnboardingPage = lazy(
  () => import("../pages/contacts-page/onboarding")
);
const DHBPage = lazy(
  () => import("../pages/dhb-page/index")
);
const BrokersPage = lazy(
  () => import("../pages/brokers-page/index")
);
const DHBThanksPage = lazy(
  () => import("../pages/dbh-thanks/index")
);
// const ApplyTokeniztionGetStarted = lazy(() => import("../pages/apply-tokenization-pages/apply-tokenization-get-started/applyTokenizationGetStarted"));
// const ApplyTokeniztionConfirmed = lazy(() => import("../pages/apply-tokenization-pages/apply-tokenization-confirmed/applyTokenizationConfirmed"));
const ApplyTokeniztionGate = lazy(() => import("../pages/apply-tokenization-pages/apply-tokenization-gate/applyTokenizationGate"));
// const HotelNftPage = lazy(() => import("../pages/hotelNft-page/index"));

export default function RootRouter() {
  const history = useHistory();

  const exchangeColor = useSelector(
    (store: any) => store.exchangeReducer.exchange
  );

  return (
    <main
      style={{
        position: "relative",
        background: `${exchangeColor && history.location.pathname === "/exchange"
          ? "rgba(0, 0, 0, 0.1)"
          : ""
          }`,
      }}
    >
      <BrowserRouter>
        <SimpleSnackbar />
        <ScrollToTop />
        <Route component={Headers} path="*" />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <NotAuthedPrivateRoute exact component={LoginPage} path="/login" />
            <Route component={SignUpRouter} path="/sign-up" />
            <NotAuthedPrivateRoute component={ForgotPasswordPages} path="/forgot-password"/>
            <Route exact component={SocialAuth} path="/social-auth" />
            <Route exact component={DHBPage} path="/DAH" />
            <Route exact component={DHBThanksPage} path="/DAH-thanks" />
            <Route exact component={GoogleAuthReg} path="/google-auth-reg" />
            <Route exact component={RedfrogPage} path="/asset-view/red-frog-beach-portfolio-panama" />
            <Route exact component={DetoxPage} path="/asset-view/detox-digital-coin" />
            <Route exact component={LdbPage} path="/asset-view/london-digital-bonds" />
            <Route exact component={AirPage} path="/asset-view/air-bc-premium" />
            <Route exact component={MainAssetPage} path="/asset-view/:id" />

            <AuthedPrivateRoute component={ProfilePages} path="/profile" />
            <AuthedPrivateRoute
              // exact
              component={PaymentPage}
              path="/payment-method"
            />
            <Route
              exact
              component={MarketplacePage}
              path="/marketplace"
            />
            <AuthedPrivateRoute
              component={AccreditationPages}
              path="/accreditation"
            />
            <AuthedPrivateRoute
              exact
              component={ConfirmationPage}
              path="/confirmation"
            />
            <AuthedPrivateRoute component={CheckoutPages} path="/checkout" />
            {/* <Route component={ApplyTokenizationPages} path="/tokenization" /> */}
            <AuthedPrivateRoute component={KYCPages} path="/kyc" />
            {/* <AuthedPrivateRoute component={CheckoutPage} path="/checkout/:id/:tokens?" /> */}
            <AuthedPrivateRoute
              exact
              component={MyInvestmentsPage}
              path="/my-assets"
            />
            <AuthedPrivateRoute
              exact
              component={TransactionHistoryPage}
              path="/transaction-history"
            />
            <AuthedPrivateRoute component={IssuerPages} path="/issuer" />
            <AuthedPrivateRoute component={ExchangePage} path="/exchange" />
            <Route exact component={ContactsPage} path="/contact" />
            <Route exact component={TokenizationForm} path="/tokenization-form" />
            <Route exact component={TokenizationThanksForm} path="/tokenization-thanks" />
            {/* <AuthedPrivateRoute component={TokenizationPaymentSetup} path="/tokenization-setup" />
            <AuthedPrivateRoute component={TokenizationPaymentFinish} path="/tokenization-finish" />
            <NotAuthedPrivateRouteTokenization
              component={ApplyTokeniztionGetStarted}
              path="/tokenization-get-started"
            /> */}
            <Redirect from="/tokenization" to="/tokenization-form" exact />
            {/* <AuthedPrivateRoute
              component={ApplyTokeniztionConfirmed}
              path="/tokenization-confirm"
            /> */}
            <Route exact component={ApplyTokeniztionGate} path="/tokenization-FAQ" />
            <Route exact component={CoursePage} path="/course" />
            {/* <Route exact path="/tokenize-real-estate.html" render={() => (window.location.href = "https://www.solidblock.co/real-estate-tokenization")} /> */}
            <Route component={OnboardingPage} path="/onboarding" />
            <Route component={PartnersPage} path="/partners" />
            <Route component={BrokersPage} path="/brokers" />
            <Route component={ErrorBoundry} path="/error" />
            <Route
              exact
              render={() => {
                window.location.assign(baseUrl + "/real-estate-tokenization/")
                return <></>
              }}
              path="/real-estate-tokenization"
            />
            <Route
              exact
              render={() => {
                window.location.assign(baseUrl + "/about/")
                return <></>
              }}
              path="/about"
            />
            <Route
              exact
              render={() => {
                window.location.assign(baseUrl + '/')
                return <></>
              }}
              path="/"
            />
            <Route
              exact
              component={SignUpConfirmationPage}
              path="/confirmation/email" 
            />
            <SitePromoRoute path="/erc" siteName="erc" />
            <SitePromoRoute path="/mountx" siteName="mountx" />
            <Redirect to={"/"} />
          </Switch>
        </Suspense>
        <Route component={Footer} path="*" />
      </BrowserRouter>
    </main>
  );
}

const SitePromoRoute = ({ path, siteName }: { path: string, siteName: string }) => {
  return <Route path={path}
    render={() => {
      if (!localStorage.getItem('token')) {
        localStorage.setItem('sitePromo', siteName)
        return <Redirect to={`/sign-up/auth?utm_source=${siteName}`} />
      }
      return <Redirect to={"/"} />
    }} />
}

export const AuthedPrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const NotAuthedPrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/sign-up/login-redirect",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const NotAuthedPrivateRouteTokenization = (props: any) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/tokenization/dashboard",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
