import { PaymentsReducer } from "../../../interfaces/assetsInterface";
import * as types from "../../constants/types";

const initialState: PaymentsReducer = {
  assetId: "",
  cryptoPayment: [],
  id: "",
  ncAchPayment: null,
  ncCheckPayment: null,
  ncWirePayment: null,
  paymentsLoading: false,
};
const paymentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ASSET_PAYMENTS_REQ:
      return {
        ...state,
        paymentsLoading: true,
      };
    case types.GET_ASSET_PAYMENTS_SUC:
      return {
        assetId: action.payload.assetId,
        cryptoPayment: action.payload.cryptoPayment,
        id: action.payload.id,
        ncAchPayment: action.payload.ncAchPayment,
        ncCheckPayment: action.payload.ncCheckPayment,
        ncWirePayment: action.payload.ncWirePayment,
        paymentsLoading: false,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
