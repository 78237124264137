const axios = require("axios");
const url = process.env.REACT_APP_API_KEY;
const postApi = (action: any) => {
  return axios
    .post(url + action[1], action[0], {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        "zaebalis": "1",
        Pragma: "no-cache",
        Expires: 0,
      },
    })
    .catch((res: any) => {
      throw res;
    })
    .then((res: any) => {
      return res;
    })
};

export { postApi };
