import * as type from "../../constants/types";
import { InnerInfo } from "../../../interfaces/myInvestymentsInterface";
import { AnyAction } from "redux";
const initialState = {
    investmentsList: [],
    historyList: [],
    purchasesHistory: {},
    credits: ''
};
const investmentsReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case type.GET_MY_INVESTMENTS:
            return {
                ...state,
                investmentsList: action.payload,
                credits: action.credits
            };
        case type.GET_MY_HISTORY:
            return {
                ...state,
                historyList: action.payload
            };
        case type.TOGGLE_HISTORY:
            let purchases = state.investmentsList.find(
                (el: InnerInfo) => el.id === action.payload
            );

            return {
                ...state,
                purchasesHistory: purchases,
            };
        default:
            return state;
    }
};

export default investmentsReducer;
