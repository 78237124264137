import { call, put, takeEvery } from "redux-saga/effects";
import { postApi } from "../shared/postApi";
import { postTokenApi } from "../shared/postTokenApi";
import { getTokenApi } from "../shared/getTokenApi";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import {
  ConfirmData,
  ThirdSignupStep,
} from "../../../interfaces/actions-interfaces/registration-interfaces";
import * as type from "../../constants/types";
import { putTokenApi } from "../shared/putTokenApi";
import mautic from 'mautic-tracking';
import { isEmailExists, showWarningSnackbar } from "../../actions/registartionAction";
import ReactPixel from 'react-facebook-pixel';
import { checkRole } from "../../actions/headerActions";

function* checkEmailSaga(action: ReturnType<typeof isEmailExists>
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "auth/email";
    const userData = yield call(postApi, [{ email: action.payload.firstStep.email }, endpoint]);
    if (!userData.data.isExist) {
      yield put({
        type: type.PUT_USER_EMAIL_PSW,
        payload: action.payload.firstStep
      })
      mautic.pageView({ email: action.payload.firstStep.email, tags: 'signup_step_1' });
      action.payload.history.push("/sign-up/info");
    }
  } catch (e) {
    yield put({
      type: type.GET_USER_REGISTRATION_FAILED,
      message: 'This email address already exists.',
    });
  }
}



// function* checkEmailSagaTok(action: {
//   type: string;
//   payload: any;
// }): TypedIterableIterator<any, any, number> {
//   try {
//     const endpoint = "auth/email";
//     const userData = yield call(postApi, [{ email: action.payload.firstStep.email }, endpoint]);
//     if (!userData.data.isExist) {
//       yield put({
//         type: type.PUT_USER_EMAIL_PSW,
//         payload: action.payload.firstStep
//       })
//       mautic.pageView({ email: action.payload.firstStep.email, tags: 'sponsor_signup_step_1' });
//       action.payload.history.push("/tokenization-email");
//     } else throw userData
//   } catch (e) {
//     yield put({
//       type: type.GET_USER_REGISTRATION_FAILED,
//       message: 'Email already exist',
//     });
//   }
// }

function* signupSaga(action: {
  type: string;
  payload: ThirdSignupStep;
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "auth/signUp";
    const userData = yield call(postApi, [action.payload.signupdata, endpoint]);
    yield put({ type: type.USER_SUGNUP, userdata: userData.data });
    localStorage.setItem("token", userData.data.token);
    localStorage.setItem("refreshToken", userData.data.refreshToken);
    localStorage.setItem("expiredTime", userData.data.expiredTime);
    ReactPixel.track('CompleteRegistration');
    action.payload.history.push("/marketplace");
    localStorage.removeItem("referralCode");
    localStorage.removeItem("sitePromo");
  } catch (e: any) {
    yield put({
      type: type.GET_USER_REGISTRATION_FAILED,
      message: e?.response?.data?.error,
    });
  }
}

function* signupSagaTok(action: {
  type: string;
  payload: ThirdSignupStep;
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "auth/signUp";
    const userData = yield call(postApi, [action.payload.signupdata, endpoint]);
    yield put({ type: type.USER_SUGNUP, userdata: userData.data });
    localStorage.setItem("token", userData.data.token);
    localStorage.setItem("refreshToken", userData.data.refreshToken);
    localStorage.setItem("expiredTime", userData.data.expiredTime);
    ReactPixel.track('CompleteRegistration');
    mautic.pageView({ email: action.payload.signupdata.email, firstname: action.payload.signupdata.firstName, country: action.payload.signupdata.country, phone: action.payload.signupdata.phoneNumber, tags: 'sponsor_signup_step_1, sponsor_signup_step_2' });
    action.payload.history.push("/tokenization-confirm");
    localStorage.removeItem("referralCode");
  } catch (e: any) {
    yield put({
      type: type.GET_USER_REGISTRATION_FAILED,
      message: typeof e.response.data?.error?.error === 'string'?e.response.data?.error?.error:'server Error',
    });
  }
}

function* resendCodeSaga(action: {
  type: string;
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "auth/resendCode?email=true ";
    const userData = yield call(postTokenApi, ['', endpoint]);
    if (userData.status >= 200 && userData.status < 300) {
      return;
    } else throw userData;
  } catch (e: any) {
    yield put({
      type: type.SNACKBAR_RESPONSE,
      mainMessage: "Something's gone wrong",
      secMessage: "Your payment ....",
      status: "warning",
    });
  }
}

function* confirmUserEmailSaga(action: {
  type: string;
  payload: ConfirmData;
}): TypedIterableIterator<any, any, number> {
  const code = action.payload.code
  const email = encodeURIComponent(action.payload.email)
  try {

    const confirmData = yield call(getTokenApi, `auth/confirmEmail?code=${code}&email=${email} `);
    if (confirmData.status >= 200 && confirmData.status < 300) {
      yield put({ type: type.CONFIRM_EMAIL_SUC, payload: confirmData.data })
      yield put(checkRole())
      action.payload.history.push("/sign-up/country-phone")
    } else throw confirmData

  }
  catch (e: any) {
    yield put(showWarningSnackbar(
      `Server Error`,
      e?.response?.data?.error || ''
    ))
    action.payload.history.push("/sign-up/verify-email")
  }
}

function* confirmEmailTokenizationUserSaga(action: {
  type: string;
  payload: ConfirmData;
}): TypedIterableIterator<any, any, number> {
  const code = action.payload.code
  const email = encodeURIComponent(action.payload.email)
  // const clientId = action.payload.clientId
  try {
    const confirmData = yield call(getTokenApi, `auth/confirmEmail?code=${code}&email=${email} `);
    if (confirmData.status >= 200 && confirmData.status < 300) {
      yield put({ type: type.CONFIRM_EMAIL_SUC, payload: confirmData.data })
      if (localStorage.getItem('formResult')){
        yield put({ type: type.SET_TOKENIZATION_PAID_STATUS_REQ, payload: localStorage.getItem("formResult") })
        yield put(checkRole())
        localStorage.removeItem("formResult");
        action.payload.history.push('/tokenization-setup')
      } else {
        action.payload.history.push('/tokenization/dashboard')
      }
    } else throw confirmData
  } catch (e: any) {
    yield put(showWarningSnackbar(
      `Server Error`,
      e?.response?.data?.error || ''
    ))
    action.payload.history.push('/tokenization/dashboard')
  }
}

function* resendPhoneCodeSaga(action: { type: string })
  : TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "auth/resendCode?phone=true";
    yield call(postTokenApi, ['', endpoint]);

  } catch (e) {

  }
}

function* confirmPhoneSaga(action: {
  type: string;
  payload: any;
}): TypedIterableIterator<any, any, number> {
  const code = action.payload.code
  // const email = action.payload.email
  // const clientId = action.payload.clientId
  try {
    const confirmData = yield call(getTokenApi, `auth/confirm?code=${code}&phone=true`);
    if (confirmData.status >= 200 && confirmData.status < 300) {
      yield put({ type: type.CHECK_IS_ADMIN_REQ })
      action.payload.history.push('/marketplace')
    } else throw confirmData
  } catch (e) {
    yield put({ type: type.CONFIRM_PHONE_FAIL })
  }
}

function* updateUserPhoneSaga(action: {
  type: string,
  payload: {
    phone: string,
    id: string
  }
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = 'user'
    const userDataRes = yield call(putTokenApi, [endpoint, action.payload])
    if (userDataRes.status >= 200 && userDataRes.status < 300) {
      yield put({ type: type.CHECK_IS_ADMIN_REQ })
    }
  } catch (e) {

  }
}



function* watchSignUpSaga() {
  yield takeEvery(type.CHECK_IF_EMAIL_EXISTS, checkEmailSaga);
  yield takeEvery(type.GET_USERS_AUTH, signupSaga);
  yield takeEvery(type.GET_USERS_AUTH_TOK, signupSagaTok);
  yield takeEvery(type.RESEND_CODE_REQ, resendCodeSaga);
  yield takeEvery(type.CONFIRM_EMAIL_REQ, confirmUserEmailSaga);
  yield takeEvery(type.CONFIRM_EMAIL_REQ_TOK, confirmEmailTokenizationUserSaga);
  yield takeEvery(type.RESEND_PHONE_CODE_REQ, resendPhoneCodeSaga);
  yield takeEvery(type.CONFIRM_PHONE_REQ, confirmPhoneSaga);
  yield takeEvery(type.UPDATE_USER_PHONE, updateUserPhoneSaga);
}

export default watchSignUpSaga;
