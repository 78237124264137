// import { Tooltip } from "@mui/material";
// import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/styles/makeStyles";
import { useState } from "react";
import Fade from '@mui/material/Fade';
import CopyIcon from "./../../Assets/images/checkout/copy.svg"
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles({
    tooltip: {
      backgroundColor: "#0EC22B",
      minWidth: '116px',
      border: "none",
      color: "white",
      fontSize: 12,
      textAlign: "center",
      lineHeight: '16px',
      fontFamily: "Montserrat",
      fontWeight: 700,
      borderRadius: 2,
    },
    arrow: {
      fontSize: 24,
      width: 24,
      borderTop: "none",
      "&::before": {
        backgroundColor: "#0EC22B",
      },
    },
});

const GreenTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0EC22B",
    minWidth: '116px',
    border: "none",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    lineHeight: '16px',
    fontFamily: "Montserrat",
    fontWeight: 700,
    borderRadius: 2,
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 24,
    width: 24,
    color:  "#0EC22B",
  },
}));

const ClipboardCopy = (props: {text: string}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(props.text);
        setIsCopied(true);
        setTimeout(() => {
          setOpen(false);
        }, 1500);
        setTimeout(() => {
          setIsCopied(false);
        }, 1750);
    }
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      if (!isCopied){
        setOpen(false);
      }
    };
  
    const handleOpen = () => {
      setOpen(true);
    };

    return (
        <GreenTooltip 
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
            }} 
            style={{marginLeft:"10px"}}
            title={isCopied ? "Copied!" : "Copy Text"}
            placement="top" 
            arrow 
            disableInteractive
            open={open} 
            onClose={handleClose} 
            onOpen={handleOpen}
            TransitionProps={{ timeout: 500 }}
            TransitionComponent={Fade}
        >
          <button className="copyIcon" onClick={handleCopyClick}>
            <img src={CopyIcon} alt="copy icon"/>
          </button>
        </GreenTooltip>
    );
};

export default ClipboardCopy;
