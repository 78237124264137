const axios = require("axios");

const accessToken = localStorage.getItem('token')
const refreshToken = localStorage.getItem('refreshToken')
const refreshTokenApi = () => axios.post(process.env.REACT_APP_API_KEY + 'auth/refreshToken', {
    accessToken: accessToken,
    refreshToken: refreshToken
}
).then((res: any) => {
    localStorage.setItem('token', res.data.token)
    localStorage.setItem('refreshToken', res.data.refreshToken)
    localStorage.setItem('expiredTime', res.data.expiredTime)
})
    .catch((err: any) => {
        if (err.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expiredTime");
            window.location.assign('/login')
        }
        throw err
    })

export { refreshTokenApi }