import classes from './style.module.scss'
import { useEffect } from "react";
import RaiseCapital from "../../Assets/images/tokenization/RaiseCapital.png";
import Security from "../../Assets/images/tokenization/security.png";
import mautic from 'mautic-tracking';


function BrokersPage() {

    useEffect(() => {
        mautic.pageView({});
    }, []);

    return <div className={classes.wrapper}>
        <div className={classes.content}>
            <header className={classes.header}>
                Brokers
            </header>
            <p>
                DIBS is a web3-enabled brokerage platform and a network of licensed brokers which work together via affiliation contracts to raise funds for deals as a group.
            </p>
            <p>
                Brokers, joining DIBS, gain access to new customers and deals as well as opportunities to earn commissions by partnering with other brokers. Brokers can also
                list their clients’ deals onto the platform and invite additional brokers to join and fundraise together thus benefiting from DIBS global network of fundraisers and syndicators.
            </p>
            <img src={RaiseCapital} alt="RaiseCapital" className={classes.img}/>
            <a
                href="https://forms.gle/NwNGDaA6KiNWsdiv8"
                className={classes.join}
                target="_blank"
                rel="noreferrer"
            >
                SIGN UP AS A BROKER
            </a>
            <p>
                <b>Compliance:</b><br />
                DIBS Capital facilitates brokerage transactions via Viant Capital, licensed broker dealer. In addition, DIBS uses a
                special purpose broker dealer partner to facilitate secondary market transactions for digital securities.
            </p>
            <p>
                <b>Broker Dealer Function:</b><br />
                The main activity of Viant in digital securities is to facilitate distribution on the network via being the broker of record
                enabling cooperation between the reps and brokers on the DIBS network and to enable commission fee participation, i.e. transaction fee.
            </p>
            <p>
                <b>Opportunity Brokers & Liquidity Providers:</b><br />
                <b>DIBS</b> and <b>Viant Capital</b> merged to establish <b>DIBS</b> (Distributed Investment Bank System), a distributed global brokerage
                for trading and underwriting of private security offerings and structured products.
            </p>
            <p>
                DIBS can be integrated with any platform selling securities to make a private offering compliant and to provide
                liquidity through our affiliate system of broker dealers and their investment networks.
            </p>
            <p>
                <b>Assets to be traded:</b><br />
                <ul>
                    <li>Real estate
                    </li>
                    <li>Private equity (late stage)
                    </li>
                    <li>Commodities
                    </li>
                    <li>Funds
                    </li>
                    <li>Structured products
                    </li>
                </ul>
            </p>
            <img src={Security} alt="Security" className={classes.img}/>
            <p>
                <b>How it works:</b><br />
                The combination of the broker dealer and a tokenization platform is powered by an affiliation
                engine for broker dealers and reps with automated waterfall commission split on the blockchain
            </p>
            <p>
                Any broker or reps will be able to create a digital securities layer for the offerings they promote
            </p>
            <p>
                Brokers will be able to lend/underwrite from the commissions account/wallet on the platform instantly and compliantly
            </p>
            <p>
                <b>Brokers and market makers can participate via:</b>
                <ul>
                    <li>Signing up to join existing raises
                    </li>
                    <li>List their raises on the wholesale listing and Invite other brokers to join their raises & split commissions automatically
                    </li>
                </ul>
            </p>
            <p>
                <b>Investing in DIBS:</b>
                <ul>
                    <li>Brokers can become early members of DIBS and gain early access to popular products, underwrite offerings & receive discounts on transaction fees by investing in DIBS.
                    </li>
                    <li>Liquidity Providers/Market Makers will gain various benefits such as exclusivity, early access, call options and preferred fees by investing in DIBS. Early members will
                        have reduced membership & trading fees. To receive information on benefits for liquidity providers please email <b>investment@dibscapital.com</b>.
                    </li>
                </ul>
            </p>
            <p>
                <b>Fractional underwriting by liquidity providers, brokers and market makers:</b><br />
                <ul>
                    <li>Any tokens issued on DIBS and any Structured Products, aggregated on DIBS and minted as a separate token on DIBS, can be underwritten by liquidity providers
                    </li>
                    <li>Early members of DIBS will gain certain levels of exclusivity and early access to those products
                    </li>
                    <li>Early members of DIBS will be able to purchase call options to buy products at set or discount prices
                    </li>
                    <li>Brokers/market makers can participate in the raise and/or underwrite
                    </li>
                    <li>Brokers/market makers can place funds in the system for the purpose of underwriting
                    </li>
                    <li>Commissions received via brokers can be used to lend funds (via CLA) to promising projects raising capital in anticipation of the raise, covering a part of the raise
                    </li>
                    <li>If the raise is successful, underwriters get their loan repaid with interest. If not, their CLA converts into tokens/shares in the project, which they can re-sell
                    </li>
                </ul>
            </p>
        </div>
    </div>
}

export default BrokersPage;