import { useState } from "react";
import makeStyles from '@material-ui/styles/makeStyles'

import { TradeModalProps } from "../../interfaces";
import FindBuyerSection from "./findBuyerSection";
import SendExchangeSection from "./sendExchangeSection";
import CloseMobileIcon from '../../Assets/images/close-btn-mobile.svg'
import { THEME_COLOR } from "../../constants/colors";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles({
    button: {
        '&:after': {
            backgroundColor: THEME_COLOR
        }
    }
})

const TradeModal = (props: TradeModalProps) => {
    const classes = useStyles();
    const [findBuyerIsActive, setFindBuyerIsActive] = useState(true);

    const findBuyerLinkHandler = () => {
        setFindBuyerIsActive(true);
    };

    const sendToExchangeLinkHandler = () => {
        setFindBuyerIsActive(false);
    };

    return (
        <Dialog open onClose={props.hideTradeModal} >
            <div className="trade-modal-container">
                {/* <div className='mobile-btn-container'> */}
                <button
                    onClick={props.hideTradeModal}
                    className="close-mobile-btn-icon"
                >
                    <img src={CloseMobileIcon} alt="Close" />
                </button>
                {/* </div> */}
                <div className="trade-modal-content-container">
                    <h2 className="trade-modal-title">Trade</h2>
                    <div className="trade-modal-text">
                        Freely enter and exit investments with SolidBlock’s token marketplace. This feature ensures high liquidity and offers investors access to a multitude of investments.
                    </div>
                    <div className="trade-modal-nav">
                        <button
                            onClick={findBuyerLinkHandler}
                            className={
                                findBuyerIsActive
                                    ? `trade-modal-link_active ${classes.button}`
                                    : "trade-modal-link"
                            }
                        >
                            OTC
                        </button>
                        <button
                            onClick={sendToExchangeLinkHandler}
                            className={
                                !findBuyerIsActive
                                    ? `trade-modal-link_active ${classes.button}`
                                    : "trade-modal-link"
                            }
                        >
                            Send to Exchange
                        </button>
                    </div>
                    {findBuyerIsActive ? (
                        <FindBuyerSection hideTradeModal={props.hideTradeModal} />
                    ) : (
                        <SendExchangeSection hideTradeModal={props.hideTradeModal} />
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default TradeModal;
