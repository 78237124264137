import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

const getApi = (endpoint: any) => {
  return axios
    .get(url + endpoint, {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((err: any) => {
      if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiredTime");
      }
      throw err
    });
};

export { getApi };
