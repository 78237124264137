import "./styles/index.scss";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRouter from "./routes";

import store from "./store";
import ErrorBoundry from "./components/error-boundry/errorBoundry";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEME_COLOR } from "./constants/colors";
const materialTheme = createTheme({
  palette: {
    primary: {
      //@ts-ignore
      main: THEME_COLOR,
    },
  }
});

export const history = createBrowserHistory();

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={materialTheme}>
        <ErrorBoundry>
          <Router history={history}>
            <AppRouter />
          </Router>
        </ErrorBoundry>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
