import * as types from "../constants/types";

export const applyDiscount = function (discount: string, id: string) {
  return {
    type: types.APPLY_DISCOUNT_REQ,
    discount,
    id,
  };
};

export const getPDF = (
  data: { name: string; value: string; }[]
) => {
  return {
      type: types.GET_WIRE_PDF,
      payload: {data},
  };
};

export const clearDiscount = function () {
  return {
    type: types.CLEAR_DISCOUNT,
  };
};

export const getCheckoutInfoReq = function (
  id: string,
  history: any,
  tokens: number
) {
  return {
    type: types.GET_CHECKOUT_INFO_REQ,
    payload: {
      id,
      history,
      tokens,
    },
  };
};
export const spentCreditsCounting = function (credits: number) {
  return {
    type: types.SPENT_CREDITS_COUNTING,
    credits,
  };
};

export const callbackAction = function (unitPrice: number, newPrice: number) {
  return {
    type: types.CALLBACK_ACTION,
    unitPrice,
    newPrice,
  };
};

export const changeDiscount = function (unitPrice: number, newPrice: number) {
  return {
    type: types.CHANGE_DISCOUNT,

    unitPrice,
    newPrice,
  };
};

export const changeCodeAction = function () {
  return {
    type: types.CHANGE_CODE,
  };
};

export const checkoutError = function () {
  return {
    type: types.CHECKOUT_ERROR,
  };
};

export const setCheckoutTotal = (tokens: number) => {
  return {
    type: types.SET_CHECKOUT_TOTAL,
    payload: tokens,
  };
};

export const getAssetPayments = (assetId: string) => {
  return {
      type: types.GET_ASSET_PAYMENTS_REQ,
      payload: assetId
  };
};

export const productsCheckout = (
  products: string[],
  subscription: string,
  tokenizationId: string,
  callBack:(url:string)=>void
) => ({
  type:types.CHECKOUT_PRODUCTS,
  payload:{
    products,
    subscription,
    tokenizationId,
    callBack,
  }
})

export const checkoutPurchase = (
  data: {
    "assetId": string,
    "transactionUnits": number,
    "discountId": string,
    "spendCredit": number
  },
  callBack:(url:string)=>void,
) => ({
  type: types.CHECKOUT_PURCHASE,
  payload: {...data,callBack },
})