import { call, put, takeEvery } from "redux-saga/effects";
import { getApi } from "../shared/getApi";
import { getTokenApi } from "../shared/getTokenApi";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import * as type from "../../constants/types";
import { postTokenApi } from "../shared/postTokenApi";

function* handleInfo(action: {
  type: string;
  id: string;
}): TypedIterableIterator<any, any, number> {
  try {
    yield put({ type: type.TOGGLE_LOADER });
    const mainInfoEndpoint = "asset/page/" + action.id;
    const mainInfoData = yield call(getApi, mainInfoEndpoint);
    yield put({
      type: type.GET_ASSET_MAIN_INFO,
      assetData: mainInfoData.data,
      id: action.id,
    });

    const locationEndpoint = "asset/page/location/" + action.id;
    const locationData = yield call(getApi, locationEndpoint);
    yield put({
      type: type.GET_LOCATION_INFO,
      assetData: locationData.data,
    });

    const foldersEndpoint = "asset/page/folder/" + action.id;
    const foldersData = yield call(getTokenApi, foldersEndpoint);
    yield put({ type: type.GET_FOLDERS, assetData: foldersData.data });

    const projectionEndpoint = "asset/page/projection/" + action.id;
    const projectionData = yield call(getApi, projectionEndpoint);
    yield put({
      type: type.GET_PROJECTION_INFO,
      assetData: projectionData.data,
    });

    const highlightsEndpoint = "asset/page/highlights/" + action.id;
    const highlightsData = yield call(getApi, highlightsEndpoint);
    yield put({
      type: type.GET_HIGHLIGHTS_INFO,
      assetData: highlightsData.data,
    });
    yield put({ type: type.TOGGLE_LOADER });
  } catch (e) {
    if (e instanceof Error) {
      yield put({ type: type.GET_USERS_FAILED, message: e.message });
    }
  }
}

function* subscribeUpcomingSaga(action: {
  type: string;
  payload: {
    email: string;
    assetId: string;
  };
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `asset/${action.payload.assetId}/interest`;
    const subscribeRes = yield call(postTokenApi, [
      { email: action.payload.email },
      endpoint,
    ]);
    if (subscribeRes.status >= 200 && subscribeRes.status < 300) {
      yield put({ type: type.SUBSCRIBE_UPCOMING_SUC });
    } else throw subscribeRes;
  } catch (e) {
    yield put({ type: type.SUBSCRIBE_UPCOMING_FAIL });
  }
}

function* getPresentationSaga(action: {
  type: string;
  payload: {
    email: string;
    assetId: string;
  };
}): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `asset/${action.payload.assetId}/interest`;
    const subscribeRes = yield call(postTokenApi, [
      { email: action.payload.email },
      endpoint,
    ]);

    if (subscribeRes.status >= 200 && subscribeRes.status < 300) {
      yield put({ type: type.GET_PRESENTATION_SUC });
    } else throw subscribeRes;
  } catch (e) {
    yield put({ type: type.GET_PRESENTATION_FAIL });
  }
}

function* watchAssetInfoSaga() {
  yield takeEvery(type.GET_ASSET_INFO_REQ, handleInfo);
  yield takeEvery(type.SUBSCRIBE_UPCOMING_REQ, subscribeUpcomingSaga);
  yield takeEvery(type.GET_PRESENTATION_REQ, getPresentationSaga);
}
export default watchAssetInfoSaga;
