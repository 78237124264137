import * as types from './../constants/types'

export const getUserImage = () => {
    return {
        type: types.GET_USER_IMAGE_REQ
    }
}

export const putHeaderImage = (action: any) => {
    return {
        type: types.PUT_HEADER_IMAGE
    }
}

export const checkRole = (callBack?:()=>void) => {
    return {
        type: types.CHECK_IS_ADMIN_REQ,
        payload:{callBack}
    }
}

export const openVerifyModal = () => {
    return {
        type: types.OPEN_VERIFY_MODAL
    }
}

export const closeVerifyModal = () => {
    return {
        type: types.CLOSE_VERIFY_MODAL
    }
}