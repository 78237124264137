import * as types from './../../constants/types'

const initialState = {
    selectedInfo: {
        accountRegistration: '',
        type: '',
        domesticYN: '',
        file: '',
        documentType: '',
        typeId: '',
        domesticYNId: ''
    },
    domesticYN: [],
    accountType: [],
    accreditationLoading: false,
    finalLoading: false,
    error: false,
    status: false,
    modalOpen: false,
    isAccredited: true,
    fileError: false
}

const accreditationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case (types.GET_ACCREDITATION_INFO_REQ):
            return {
                ...state,
                accreditationLoading: true
            }
        case (types.GET_DOMESTIC_SUC):
            return {
                ...state,
                domesticYN: action.payload
            }
        case (types.GET_ACCOUNT_TYPE_SUC):
            return {
                ...state,
                accountType: action.payload
            }
        case (types.GET_ACCREDITATION_INFO_SUC):
            return {
                ...state,
                accreditationLoading: false
            }
        case (types.SEND_ACCREDITATION_REQ):
            return {
                ...state,
                finalLoading: true
            }
        case (types.SEND_ACCREDITATION_SUC):
            return {
                ...state,
                finalLoading: false,
                status: true,
                modalOpen: true
            }
        case (types.SEND_ACCREDITATION_FAIL):
            return {
                ...state,
                finalLoading: false,
                fileError: true
            }
        case (types.SET_ACCREDITATION_STEP1):
            const { accountRegistration, type, domesticYN, domesticYNId, typeId } = action.payload;
            return {
                ...state,
                selectedInfo: {
                    ...state.selectedInfo,
                    accountRegistration: accountRegistration,
                    type: type,
                    domesticYN: domesticYN,
                    typeId: typeId,
                    domesticYNId: domesticYNId
                }
            }
        case (types.SET_ACCREDITATION_STEP2):
            return {
                ...state,
                selectedInfo: {
                    ...state.selectedInfo,
                    documentType: action.payload
                }
            }


        case (types.SET_ACCREDITATION_STEP3):
            return {
                ...state,
                selectedInfo: {
                    ...state.selectedInfo,
                    file: action.payload
                }
            }
        case types.CLOSE_ACCREDITATION_MODAL:
            return {
                ...state,
                modalOpen: false
            }
        case types.GET_IS_ACCREDITED_REQ:
            return {
                ...state
            }
        case types.GET_IS_ACCREDITED_SUC:
            return {
                ...state,
                isAccredited: action.payload
            }
        default:
            return state
    }
}

export default accreditationReducer