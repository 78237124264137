const RedfrogProjection = () => {
  return (
    <div className="financial-projection">
      <div className="title">Financial Projection</div>
      <div className="field-item">
        <div className="field">Targeted ROI</div>
        <div className="value">15%</div>
      </div>
      <div className="field-item">
        <div className="field">Asset Structure</div>
        <div className="value">Single Asset</div>
      </div>
      <div className="field-item">
        <div className="field">Equity Multiple</div>
        <div className="value">2</div>
      </div>
      <div className="field-item">
        <div className="field">Targeted IRR</div>
        <div className="value">13.61%</div>
      </div>
    </div>
  );
};

export default RedfrogProjection;
