import { useState } from "react";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

import BuyArrow from "./../../../../Assets/icons/buy.svg";
import Question from "./../../../../Assets/icons/question-tooltip.png";

function AssetTopRight(props: {
  toggleCheckout: () => void;
  checkout: boolean;
}) {
  const infoAdvanced = {
    totelRaise: "20,000,000",
    minimumInvestment: "5000",
    tokenName: "LDB",
    videos: [
      "https://www.youtube.com/watch?v=Rf9M9Ix9-r0&ab_channel=OWN",
      "https://youtu.be/KRcrv8uG7Y8"
    ],
  }

  const { toggleCheckout, checkout } = props;

  const assetInfo = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const timerField = (endDate: string, status: string) => {
    const endTime = Date.parse(endDate);
    const diff = +endTime - +new Date();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (status === "FUNDED" || days < 0) {
      return 0;
    } 
    return days;
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="asset-top-right-wrapper">
      <div className={`asset-top-right ${checkout ? "to-checkout" : ""}`}>
        <div className="raised">£{infoAdvanced.totelRaise}</div>
        {/* <div className="line">
          <div className="filled" style={{ width: `${progress}%` }}></div>
        </div> */}
        <div className="percentage">
          {/* <span className="green">0%</span> */}
          Total investment opportunity
        </div>
        <div className="info-field">
          <div className="value">£1</div>
          <div className="field">Price per digital share / {infoAdvanced.tokenName} token </div>
        </div>
        <div className="info-field">
          <div className="value">£{assetInfo.minAmount}</div>
          <div className="field">Minimum Investment</div>
        </div>
        {timerField(assetInfo.endDate, assetInfo.status)?
            <div className={`info-field last ${tooltipOpen ? "tooltiped" : ""}`}>
            <div className="value">{timerField(assetInfo.endDate, assetInfo.status)}</div>
            <div className="field">
              Days Left to Invest
              <img
                src={Question}
                alt=""
                className="question"
                onMouseOver={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
              />
              <div className="tooltip-wrapper">
                <div className="days-left-tooltip">
                  {timerField(assetInfo.endDate, assetInfo.status)} days left to invest in current round. Round ends Dec 31,
                  2021
                </div>
              </div>
            </div>
          </div>
        :null}
        
        <div className="buy" onClick={toggleCheckout}>
          Buy Digital Shares
          <img src={BuyArrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AssetTopRight;
