import { New_KYC_StateInterface, kycRequest, kycSecondStepState, KYC_AccreditationState } from '../../../interfaces/newSignUpInterfaces'
import { kycSetStep,  newSignUpAccreditationSetDocumentTypeAC } from '../../actions/newSignUpActions'
import * as types from './../../constants/types'


const initialState: New_KYC_StateInterface = {
    loading: false,
    step: 'location',
    firstStep: {
        dateOfBirth: null,
        country: '',
        streetAddress: '',
        city: '',
        zipCode: '',
        occupation: '',
        isUSCitizen: false,
        state: '',
        socialSecurityNumber: '',
    },
    secondStep: {
        documents: [],
        fullNameOnId: '',
        documentType: '',
    },
    accreditation: {
        document: null,
        documentType: '',
    }
}

const newSignUpKYCReducer = (state = initialState, action: any): New_KYC_StateInterface => {
    let typedAction
    switch (action.type) {
        case types.NEW_SIGN_UP_KYC_STOP_LOADING:
            return {
                ...state,
                loading: false
            }
        case types.NEW_SIGN_UP_KYC_START_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.NEW_SIGN_UP_KYC_GET_RESP:
            typedAction = action as { payload: kycRequest }
            return {
                ...state,
                firstStep: { ...typedAction.payload },
                loading: false
            }
        case types.NEW_SIGN_UP_KYC_SET_STEP:
            typedAction = action as ReturnType<typeof kycSetStep>
            return {
                ...state,
                step: typedAction.payload
            }
        case types.NEW_SIGN_UP_GET_KYC_SECOND_STEP_RESP:
            typedAction = action as { payload: kycSecondStepState }
            return {
                ...state,
                secondStep: typedAction.payload
            }
        case types.NEW_SIGN_UP_KYC_FILE_UPLOAD_RESP:
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    documents: [...state.secondStep.documents, action.payload]
                }
            }
        case types.NEW_SIGN_UP_KYC_FILE_DELETE_RESP:
            typedAction = action as { payload: { id: string } }
            const documentIdToDel = typedAction.payload.id
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    documents: [
                        ...state.secondStep.documents
                            .filter(a => a.documentId !== documentIdToDel)
                    ]
                }
            }
        case types.NEW_SIGN_UP_ACCREDITATION_GET_FILES_RESP:
            typedAction = action as { payload: KYC_AccreditationState  }
            return {
                ...state,
                loading:false,
                accreditation:typedAction.payload
            }
        case types.NEW_SIGN_UP_ACCREDITATION_POST_FILES_RESP:    
            return {
                ...state,
                accreditation:{
                    ...state.accreditation,
                    document:{...action.payload}
                }
            }
        case types.NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_RESP:     
            return {
                ...state,
                accreditation:{
                    ...state.accreditation,
                    document:null,
                }
            }
        case types.NEW_SIGN_UP_ACCREDITATION_SET_DOCUMENT_TYPE:
            typedAction = action as ReturnType<typeof newSignUpAccreditationSetDocumentTypeAC>
            return {
                ...state,
                accreditation:{
                    ...state.accreditation,
                    documentType:typedAction.payload.docType
                }
            }

        default: return state
    }
}

export default newSignUpKYCReducer;