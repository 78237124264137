import { all } from "redux-saga/effects";
import signupSaga from "./registrationSaga/signupSaga";
import signinSaga from "./registrationSaga/signinSaga";
import reqEmailSaga from "./registrationSaga/requestEmailSaga";
import changePasswordSaga from "./profile-sagas/changePasswordSaga";
import getUserDataSaga from "./profile-sagas/getUserDataSaga";
import changeUserDataSaga from "./profile-sagas/changeUserDataSaga";
import watchCheckAccountSaga from "./ach-sagas/achSagas";
import {
    watchChangeUserImage,
    watchDeleteUserImageSaga,
} from "./profile-sagas/changeUserImageSaga";
import { watchMarketplaceSaga } from "./marketplace-sagas/marketplaceSaga";
import watchAssetInfoSaga from "./asset-sagas/asset-info-saga";
import watchSendKYCInformationSaga from "./KYC-sagas/KYCSaga";
import WatchAccreditationSaga from "./accreditation-sagas/accreditationSaga";
import watchHeaderSaga from "./header-sagas/headerSaga";
import convertingCurrencySaga from "./payment-sagas/cryptoSagas";
import watchContactsSaga from "./contacts-saga/contactsSaga";
import watchCheckoutSaga from "./checkout-saga/checkoutSaga";
import {
    myInvestmetsSaga,
    postInvestmetsSaga,
} from "./my-investments-sagas/myInvestmentsSagas";
import watchGetUserReferralsSaga from "./profile-sagas/getUserRefferrals";
import watchGetUserDocumentsSaga from "./profile-sagas/userDocumetsSaga";
import watchExchangeSaga from "./exchange-sagas/getAllOffers";
import watchSendATsteps from "./apply-tokenization-saga/ATSaga";
import watchNewSignUpSaga from "./registrationSaga/newSignUpSaga";

export default function* rootSaga() {
    yield all([
        watchNewSignUpSaga(),
        signinSaga(),
        watchContactsSaga(),
        signupSaga(),
        reqEmailSaga(),
        changePasswordSaga(),
        getUserDataSaga(),
        changeUserDataSaga(),
        watchChangeUserImage(),
        watchDeleteUserImageSaga(),
        watchMarketplaceSaga(),
        watchAssetInfoSaga(),
        watchSendKYCInformationSaga(),
        WatchAccreditationSaga(),
        watchHeaderSaga(),
        watchExchangeSaga(),
        watchCheckAccountSaga(),
        convertingCurrencySaga(),
        myInvestmetsSaga(),
        postInvestmetsSaga(),
        watchGetUserReferralsSaga(),
        watchCheckoutSaga(),
        watchGetUserDocumentsSaga(),
        watchSendATsteps(),
    ]);
}
