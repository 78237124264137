import { useState } from "react";
import { useSelector } from "react-redux";
import { MONTH_NAMES } from "../../../../constants/myInvestmentsConstants";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

import BuyArrow from "./../../../../Assets/icons/buy.svg";
import Question from "./../../../../Assets/icons/question-tooltip.png";
// import { useSelector } from 'react-redux';
// import { AssetDataInterface } from '../../../../interfaces/assetsInterface';

function AssetTopRight(props: {
  toggleCheckout: () => void;
  checkout: boolean;
}) {
  const infoAdvanced = {
    title: "Detox Digital Coin",
    location: "United States",
    description: "Detox Digital Coin has identified for acquisition the first in a series of an additional 20 behavioral health centers (BHCs) in prime US locations earmarked for renting to qualified substance use disorder and recovery facilitators.",
    totelRaise: "2,449,616",
    minimumInvestment: "5000",
    tokenName: "DDC",
    videos: [
      "https://www.youtube.com/watch?v=Rf9M9Ix9-r0&ab_channel=OWN",
      "https://youtu.be/KRcrv8uG7Y8"
    ],
    highlightItems: [
      { highlight: "Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years." },
      { highlight: "Industry revenues will climb to almost $99.4 billion by 2028." },
      { highlight: "Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs." },
      { highlight: "The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year." },
      { highlight: "BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments." },
      { highlight: "Facilities identified, renovated and leased prior to acquisition." },
      { highlight: "“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent." },
      { highlight: "Anticipated 5-year Annualized Return: 12%." },
    ],
    investedDetail: [
      { field: "Revenue type", value: "NNN Lease" },
      { field: "Capital Stack", value: "Equity" },
      { field: "Property Type", value: "Medical Facility" },
    ],
    projectionDetail: [
      { field: "Targeted ROI", value: "12%" },
      { field: "Capital Structure", value: "35% Equity; 65% Debt" },
      { field: "Equity Multiple", value: "1.62" },
      { field: "Targeted IRR", value: "15%" },
    ],
    content: {
      offer: `<div>SolidBlock has teamed up with Holdun Family Office to create the Detox Digital Coin, a real estate investment opportunity to grow detox and behavioral health centers across the US.<br/><br/>
      The offering addresses a rapidly expanding market due to the exploding behavioral health crisis which includes drug and alcohol addiction and mental health wellbeing.<br/><br/>
      Detox Digital Coin uses blockchain technology to tokenize and raise capital. With a minimum investment of $250 USD, investors receive tokenized shares in the property, which are represented as Detox Digital Coins. These coins will be deposited into the investor’s account or digital wallet.<br/><br/>
      Each new acquisition will be listed as a separate and distinct investment opportunity. The goal is to acquire up to 20 additional facilities.
      </div>`,
    }
  }

  const { toggleCheckout, checkout } = props;

  const assetInfo = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
  const endDateParse = new Date(assetInfo.endDate);
  const endMounth = endDateParse.getMonth();
  const endDay = endDateParse.getDay();
  const endYear = endDateParse.getFullYear();

  const timerField = (endDate: string, status: string) => {
    const endTime = Date.parse(endDate);
    const diff = +endTime - +new Date();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (status === "FUNDED" || days < 0) {
      return 0;
    }
    return days;
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="asset-top-right-wrapper">
      <div className={`asset-top-right ${checkout ? "to-checkout" : ""}`}>
        <div className="raised">${infoAdvanced.totelRaise}</div>
        {/* <div className="line">
          <div className="filled" style={{ width: `${progress}%` }}></div>
        </div> */}
        <div className="percentage">
          {/* <span className="green">0%</span> */}
          Total raise for the first in a series of acquisitions
        </div>
        <div className="info-field">
          <div className="value">$1</div>
          <div className="field">Price per digital share / {infoAdvanced.tokenName} token </div>
        </div>
        <div className="info-field">
          <div className="value">${assetInfo.minAmount}</div>
          <div className="field">Minimum Investment</div>
        </div>
        {timerField(assetInfo.endDate, assetInfo.status) ?
          <div className={`info-field last ${tooltipOpen ? "tooltiped" : ""}`}>
            <div className="value">{timerField(assetInfo.endDate, assetInfo.status)}</div>
            <div className="field">
              Days Left to Invest
              <img
                src={Question}
                alt=""
                className="question"
                onMouseOver={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
              />
              <div className="tooltip-wrapper">
                <div className="days-left-tooltip">
                  {timerField(assetInfo.endDate, assetInfo.status)} days left to invest in current round. Round ends {endDay}{' '}
                  {MONTH_NAMES[endMounth]} {endYear}
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="buy" onClick={toggleCheckout}>
          Buy Digital Shares
          <img src={BuyArrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AssetTopRight;
