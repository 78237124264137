import { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import MobileNavDrawer from "../../mobile-nav-drawer/mobileNavDrawer";
import { useDispatch, useSelector } from 'react-redux'
import { UserImageState } from './../../../../interfaces/headerInterface'
import { getUserImage } from './../../../../redux/actions/headerActions'

import { LogoDark } from "../../../../constants/logo";

import { LANDING_CATEGORIES_AUTHED } from "../../../../constants/nav";
import DefaultImage from "../../../../Assets/images/default-avatar.svg";

const MobileAccountNav = () => {
    const [navShow, setNavShow] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch()
    const userData = useSelector((state: UserImageState) => state.headerReducer)

    useEffect(() => {
        dispatch(getUserImage())
    }, [dispatch])

    const userImageUrl = userData.userImage || DefaultImage

    return (
        <div className="account-nav-mobile">
            <img
                onClick={() => history.push("/")}
                className="account-nav-logo"
                src={LogoDark}
                alt="solidblock"
            />
            <div className="menu-container">
                <div className="user-photo-container">
                    <img
                        className="user-photo"
                        alt="user"
                        src={userImageUrl}
                        onClick={() => history.push("/profile/main-information")}
                    />
                </div>
                <IconButton
                    aria-label="Open drawer"
                    edge="start"
                    onClick={() => setNavShow(true)}
                >
                    <MenuIcon fontSize="medium" />
                </IconButton>
            </div>
            <MobileNavDrawer
                navCategories={LANDING_CATEGORIES_AUTHED}
                navShow={navShow}
                setNavShow={setNavShow}
            />
        </div>
    );
};

export default MobileAccountNav;
