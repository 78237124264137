import { useEffect } from 'react';
import DesktopAccountNav from "./desktop-account-nav/desktopAccountNav";
import MobileAccountNav from "./mobile-account-nav/mobileAccountNav";
import { useDispatch } from 'react-redux';
import { checkRole } from './../../../redux/actions/headerActions';

function HeadersComponent() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkRole())
    }, [dispatch])

    return (
        <>
            <DesktopAccountNav />
            <MobileAccountNav />
        </>
    );
}

export default HeadersComponent;
