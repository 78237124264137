import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { iDocumentItem, FolderItem, FoldersListInterface } from "../../../../interfaces/assetsInterface";
import DocIcon from "./../../../../Assets/icons/document.svg";
import Lock from "./../../../../Assets/icons/lock.svg";

function AssetDocuments() {

  const [documents, setDocuments] = useState<iDocumentItem[] | undefined>([]);

  const documentsList = useSelector(
    (state: FoldersListInterface) => state.foldersList
  );

  const openDoc = (link: string | null) => {
    if (!link) return;
    window.open(link, "_blank");
  };

  useEffect(() => {
    const documentsFolder = documentsList.documents.find((folder: FolderItem) => folder.name === 'documents')
    setDocuments(documentsFolder?.documents)
  }, [documentsList])

  if (!documents) return null

  return (
    <div className="asset-documents">
      <div className="title">Documents</div>
      {
        documents.map((doc: iDocumentItem) => (
          <div
            key={doc.id}
            className={`document ${doc.link ? "" : "disabled"}`}
            onClick={() => openDoc(doc.link)}
            // onClick={() => window.open(doc.link, '_blank')}
          >
            {doc.link?(<img src={DocIcon} alt="" />):(<img src={Lock} alt="" />)}
            <span>{doc.name}</span>
          </div>
        ))
      }
    </div>
  );
}

export default AssetDocuments;
