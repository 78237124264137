import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addComma } from "../../constants/addCommaFunction";
import { 
  CheckoutInterface, 
} from "../../interfaces/assetsInterface";

function CheckoutStripe() {

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  return (
    <div className="checkout-wire">
      <div className="title">
        Success!
      </div>
      <div className="descr">
        Your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} token allocation should appear in your SolidBlock account any moment now.
      </div>
      <div className="divider"></div>
      <div className="title">
        What to expect next:
      </div>
      <div className="descr">
        <ul>
          <li>You will receive an email from SolidBlock confirming the payment.</li>
          <li>This allocation will remain in reserved status until the project is funded. </li>
          <li>Once the project has been funded, your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} tokens will officially be distributed to your account.</li>
        </ul>
      </div>
      <div className="descr">
        View your <Link to="/my-assets" className="link">Assets Page</Link> or <Link to="/transaction-history" className="link">Transaction History</Link> to see the status of your order.
      </div>
    </div>
  );
}

export default CheckoutStripe;
