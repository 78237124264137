import * as types from "../constants/types";
import H from "history";
import { ACCREDITATION_DOCUMENT_TYPE, companyInformationIterface, kycRequest, KYC_DOCUMENTS_TYPE, signUpRequest } from "../../interfaces/newSignUpInterfaces";

export const signUpAC = function (prop: {
    request: signUpRequest,
    existEmailCb: (b: boolean) => void,
    existGogleEmailCb: (b: boolean) => void,
    history: H.History
    entityName?: string
}) {
    return {
        type: types.NEW_SIGN_UP_REQUEST,
        payload: prop,
    };
};

export const kycSetStep = (step: 'location' | 'documents') => ({
    type: types.NEW_SIGN_UP_KYC_SET_STEP,
    payload: step
})
export const kycGetAC = function () {
    return {
        type: types.NEW_SIGN_UP_KYC_GET_REQ,
    };
};

export const kycSendAC = function (prop: {
    request: kycRequest,
}) {
    return {
        type: types.NEW_SIGN_UP_KYC_POST,
        payload: prop,
    };
};

export const getKycFiles = () => ({
    type: types.NEW_SIGN_UP_GET_KYC_SECOND_STEP_REQ,
})

export const kycSendFileAC = (
    formData: FormData,
    progressCB: (data: { loaded: number, total: number }) => void,
) => (
    {
        type: types.NEW_SIGN_UP_KYC_FILE_UPLOAD_REQ,
        payload: {
            formData,
            progressCB,
        },
    }
)
export const kycFileDeleteAC = (id: string) => ({
    type: types.NEW_SIGN_UP_KYC_FILE_DELETE_REQ,
    payload: { id }
})

export const sendKycSecondStepAC = (fullNameOnId: string, documentType: KYC_DOCUMENTS_TYPE, history: H.History) => ({
    type: types.NEW_SIGN_UP_POST_KYC_SECOND_STEP_REQ,
    payload: { fullNameOnId, documentType, history }
})

export const newSignUpAccreditationSkipAC = (history: H.History) => ({
    type: types.NEW_SIGN_UP_ACCREDITATION_SKIP_REQ,
    payload: { history },
})
export const newSignUpAccreditationGetFiles = () => ({
    type: types.NEW_SIGN_UP_ACCREDITATION_GET_FILES_REQ
})


export const newSignUpAccreditationSendFileAC = (
    formData: FormData,
    progressCB: (data: { loaded: number, total: number }) => void,
) => (
    {
        type: types.NEW_SIGN_UP_ACCREDITATION_POST_FILES_REQ,
        payload: {
            formData,
            progressCB,
        },
    }
)

export const newSignUpAccreditationDeleteFileAC = (id: string) => ({
    type: types.NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_REQ,
    payload: { id }
})

export const newSignUpAccreditationSetDocumentTypeAC = (docType: ACCREDITATION_DOCUMENT_TYPE | '') => ({
    type: types.NEW_SIGN_UP_ACCREDITATION_SET_DOCUMENT_TYPE,
    payload: { docType }
})

export const newSignUpAccreditationSubmitAC = (history: H.History) => ({
    type: types.NEW_SIGN_UP_ACCREDITATION_SUBMIT,
    payload: { history }
})

export const newSignUpCompanyInformationSubmitAC = (history: H.History, companyInfo: companyInformationIterface) => ({
    type: types.NEW_SIGN_UP_COMPANY_INFO_SUBMIT_REQ,
    payload: {
        companyInfo,
        history,
    }
})

export const newSignUpSetMobilePhoneAC = (history: H.History, phoneNumber: string, enableButtonCb: () => void) => ({
    type: types.NEW_SIGN_UP_MOBILE_SET_UP_REQ,
    payload: {
        history,
        phoneNumber,
        enableButtonCb,
    }
})

export const newSignUpSetMobilePhoneFromOauthAC = (
    formData: { firstName: string, lastName: string, phoneNumber: string, accountType: string },
    history: H.History,
    enableButtonCb: () => void
) => ({
    type: types.NEW_SIGN_UP_MOBILE_SET_UP_OAUTH_REQ,
    payload: {
        history,
        formData,
        enableButtonCb,
    }
})

export const newSignUpResendTFACodeAC = (doneCB: () => void) => ({
    type: types.NEW_SIGN_UP_MOBILE_RESEND_CODE_REQ,
    payload: { doneCB }
})

export const newSignUpConfirmMobilePhoneAC =
    (
        request: {
            code: string,
            shouldRemember: boolean,
        },
        history: H.History,
        submitFailCb: () => void
    ) => ({
        type: types.NEW_SIGN_UP_MOBILE_CONFIRM_UP_REQ,
        payload: {
            history,
            request,
            submitFailCb,
        }
    })