import CircularProgress from '@material-ui/core/CircularProgress';
import { THEME_COLOR } from '../../constants/colors';

function Spinner(props:{color?:string}) {
    return (
        <div className="spinner-wrapper">
            <CircularProgress className="loading-spinner" style={{color: props.color|| THEME_COLOR}}/>
        </div>
    )
}

export default Spinner;