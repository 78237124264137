import Button from "../../../reusable/button";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import { THEME_COLOR } from "../../../../constants/colors";
import { ISO_DATE } from "./../../../../constants/regex";
import { ChatBubble } from "@material-ui/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import GeoIcon from "../../../../Assets/images/geo.svg";
import  OpenDeckIcon from './../../../../Assets/icons/open-deck.svg'
import Divider from "../../../../Assets/images/divider.svg";
import { CURRENCY_SYMBOLS } from "../../../../constants/myInvestmentsConstants";
import { addComma } from "../../../../constants/addCommaFunction";
import { FoldersListInterface } from "../../../../interfaces/assetsInterface";
import moment from "moment";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import AssetTZeroIcon from "../../../../Assets/icons/asset-t-zero.svg";
import { useHistory } from "react-router-dom";

import SwiperCore, { Navigation, Pagination } from "swiper/core";

SwiperCore.use([Navigation, Pagination]);

const AssetInfo = ({ buyDigitalButtonHandler,funded}:
    {
        handleModal: () => void,
        buyDigitalButtonHandler: () => void,
        funded:boolean,
    }) => {
    const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
    const history = useHistory()
    const foldersList = useSelector((state: FoldersListInterface) => state.foldersList);

    return (
        <div className="asset-info-photo">
            <div className="asset-info-container">
                <div className="higher-info">
                    <h2 className="asset-title">{info.title} <span>(${info.alias})</span></h2>
                    <div className="geo">
                        <img src={GeoIcon} alt=" " />
                        <p>{info.location}</p>
                    </div>
                    <p>{info.description} </p>

                    <img alt="" className="divider-img" src={Divider} />
                    <div>
                        {Object.entries(info.assetFields).map((field, index) => {
                            let fieldValue;
                            ISO_DATE.test(field[1].value)
                                ? (fieldValue = moment(field[1].value).format("DD-MMM-YYYY"))
                                : (fieldValue = field[1].value);

                            return (
                                <div key={index} className={index % 2 === 0 ? "optionalField" : "optionalField2"}>
                                    <div className="inner-optional">
                                        <p className="field-value">
                                            {typeof fieldValue === "number"
                                                ? CURRENCY_SYMBOLS[info.currency] + addComma(String(fieldValue))
                                                : fieldValue}
                                        </p>
                                        <p className="field-name">{field[1].customField}</p>
                                    </div>
                                </div>
                            );
                        })}
                        {funded?
                            <Button
                            text={"Funded"}
                            className="buyButton funded"
                            onClick={()=>{}}
                            img={null}
                        />
                        :
                        <Button
                            text={info.alias === 'ASPD' ? "Buy on" : "Buy Digital Shares"}
                            className="buyButton"
                            onClick={buyDigitalButtonHandler}
                            img={info.alias === 'ASPD' ? AssetTZeroIcon : null}
                        />}
                        
                                            <div className="green-text">
                        <div onClick={()=>{history.push('/contact')}} className="green-label" style={{ color: THEME_COLOR }}>
                            <ChatBubble style={{ fill: THEME_COLOR, width: 16 }} />
                            <p>Questions? Chat with us</p>
                        </div>
                        <div className="green-label" style={{ color: THEME_COLOR }}>
                            <img 
                            src={OpenDeckIcon} 
                            style={{ fill: THEME_COLOR, width: 15, marginRight: 10 }}
                            alt="" />
                            <a  href={foldersList.documents.find((el: any) => el.name === "Open Deck")?.documents[0].link}
                                style={{ color: THEME_COLOR }}
                                download={info.title}>
                                Download full PPT deck
                            </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="main-asset-image">
                <Swiper navigation={true} observer = {true} observeParents={true} pagination={{ clickable: true }}>
                    {info.images?.map((el, id) => (
                        <SwiperSlide key={el + id}>
                            <img alt="" src={el} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default AssetInfo;