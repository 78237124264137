import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import AssetFullAccess from "../../../components/assets/redfrod/asset-full-access/assetFullAccess";
import RedfrogContent from "../../../components/assets/redfrod/redfrog-content/redfrogContent";
import AssetDocuments from "../../../components/assets/asset-main-view/asset-documents/assetDocuments";
import AssetHighlights from "../../../components/assets/asset-main-view/asset-highlights/assetHighlights";
import InvestmentDetails from "../../../components/assets/asset-main-view/investment-details/investmentDetails";
import Spinner from '../../../components/spinner/spinner';
import AssetMap from "../../../components/assets/asset-main-view/asset-map/assetMap";
import InvestorPerks from "../../../components/assets/asset-main-view/investor-perks/investorPerks";
import RedfrogInfo from "../../../components/assets/redfrod/redfrog-info/redfrogInfo";
import RedfrogProjection from "../../../components/assets/redfrod/redfrog-projection/redfrogProjection";
import { AssetDataInterface } from "../../../interfaces/assetsInterface";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { getMainAssetInfo } from "../../../redux/actions/assetsAction";
import { RED_FROG_ID } from "../../../constants/assetsId";
import mautic from 'mautic-tracking';

function RedfrogPage() {
  const dispatch = useDispatch();
  const { id } = useParams<MainAssetPageParams>();
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  useEffect(() => {
    if (!info.alias || info.id !== RED_FROG_ID)
      dispatch(getMainAssetInfo(`${RED_FROG_ID}`));
  }, [dispatch, id, info.alias, info.id]);

  useEffect(() => {
    mautic.pageView({});
  }, []);


  if(info.isLoading) return <Spinner />

  return (
    <div className="redfrog-page">
      <div className="asset-top">
        <div className="container">
          <RedfrogInfo />
        </div>
      </div>
      <div className="container">
        <div className="content">
          <div className="left">
            <AssetHighlights />
            <RedfrogContent />
            <AssetMap />
            <div className="mobile-blocks">
              <div className="divider"></div>
              <InvestorPerks />
              {/* <AssetDocuments /> */}
            </div>
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
          <div className="right">
            <InvestmentDetails />
            {/* {localStorage.getItem("token") || localStorage.getItem('gotRedfrogPresentation') ? ( */}
            {localStorage.getItem("token")? (
              <>
                <RedfrogProjection />
                <AssetDocuments />
              </>
            ) : (
              <AssetFullAccess />
            )}
            <InvestorPerks />
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedfrogPage;
