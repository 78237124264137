import * as type from "../../constants/types";

const initialState = {
    accountFullName: "",
    accountNickName: "",
    routingNumber: "",
    accountNumber: "",
    accountType: "",
    errorMessage: "",
};
const createAccountReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_EXTERNAL_ACCOUNT_INFO:
            return {
                ...state,
                accountFullName: action.payload.AccountName,
                accountNickName: action.payload.AccountNickName,
                accountNumber: action.payload.AccountNumber,
                routingNumber: action.payload.AccountRoutingNumber,
            };
        case type.CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                errorMessage: action.message,
            };
        default:
            return state;
    }
};

export default createAccountReducer;
