import * as types from "./../../constants/types";
import { GetAccreditationInfo } from "./../../../interfaces/accreditationInterface";
import { getTokenApi } from "./../shared/getTokenApi";
import { postTokenApi } from "./../shared/postTokenApi";
import { call, put, takeEvery } from "redux-saga/effects";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";

function* getAccreditationInfoSaga(
    action: GetAccreditationInfo
): TypedIterableIterator<any, any, number> {
    try {
        const domesticYNEndpoint = "accreditation/domestic";
        const accountTypeEndpoint = "accreditation/account-type";
        const domesticYN = yield call(getTokenApi, domesticYNEndpoint);
        yield put({ type: types.GET_DOMESTIC_SUC, payload: domesticYN.data });

        const accountType = yield call(getTokenApi, accountTypeEndpoint);

        yield put({
            type: types.GET_ACCOUNT_TYPE_SUC,
            payload: accountType.data,
        });

        yield put({ type: types.GET_ACCREDITATION_INFO_SUC });
    } catch (e) {}
}

function* sendVerificationSaga(
    action: any
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "accreditation";
        const accreditationRes = yield call(postTokenApi, [
            action.payload.accreditationFormData,
            endpoint,
        ]);
        if (accreditationRes.status >= 200 && accreditationRes.status < 300) {
            yield put({
                type: types.SEND_ACCREDITATION_SUC,
                payload: accreditationRes.data,
            });
            if (accreditationRes.data.status) {
                action.payload.history.push("/marketplace");
            }
        } else throw accreditationRes;
    } catch (e) {
        yield put({ type: types.SEND_ACCREDITATION_FAIL });
    }
}

function* getIsAccreditedSaga(
    action: any
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "accreditation/check";
        const isAccredited = yield call(getTokenApi, endpoint);

        if (isAccredited.status >= 200 && isAccredited.status < 300) {
            yield put({
                type: types.GET_IS_ACCREDITED_SUC,
                payload: isAccredited.data.accreditationPassed,
            });
        } else throw isAccredited;
    } catch (e) {}
}

function* WatchAccreditationSaga() {
    yield takeEvery(types.GET_ACCREDITATION_INFO_REQ, getAccreditationInfoSaga);
    yield takeEvery(types.SEND_ACCREDITATION_REQ, sendVerificationSaga);
    yield takeEvery(types.GET_IS_ACCREDITED_REQ, getIsAccreditedSaga);
}

export default WatchAccreditationSaga;
