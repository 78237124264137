import { IconButton, Button } from "@material-ui/core";
import Drawer from '@mui/material/Drawer';
import { NavLink, useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DrawerButton from "./drawer-button/drawerButton";

import { Logo } from "../../../constants/logo";
import LogOut from "../../../Assets/icons/logout-icon.svg";
import { MobileNavDrawerProps, NavCategory } from "../../../interfaces/navInterfaces";

const MobileNavDrawer = ({
    navCategories,
    navShow,
    setNavShow,
    authed
}: MobileNavDrawerProps) => {
    const history = useHistory();

    const logOut = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiredTime");
        history.push("/login");
    };

    const renderDrawerButton = () => {
        return localStorage.getItem("token") ? (
            renderDrawerLogOut()
        ) : (
            <>
                <Button
                    className="login-button"
                    onClick={() => history.push("/login")}
                >
                    Log In
                                </Button>
                <DrawerButton setNavShow={setNavShow} />
            </>
        );
    };

    const renderDrawerLogOut = () => {
        return (
            <div className="mobile-nav-drawer-logout-item" onClick={logOut}>
                <img src={LogOut} alt="" className="mobile-nav-drawer-logout" />
                Log out
            </div>
        );
    };

    return (
        <Drawer open={navShow} onClose={() => setNavShow(false)}>
            <div className="mobile-nav-drawer-header">
                <img
                    onClick={() => history.push("/")}
                    src={Logo}
                    className="mobile-nav-drawer-logo"
                    alt="solidblock"
                />
                <IconButton
                    onClick={() => setNavShow(false)}
                    className="account-nav-mobile-close-icon"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className="mobile-nav-drawer-title">MENU</div>
            <div className="mobile-nav-drawer-list">
                {authed ? navCategories.map((category: NavCategory, index: number) => (
                    <NavLink
                        key={index}
                        to={category.path}
                        onClick={() => setNavShow(false)}
                        className="mobile-nav-drawer-link"
                        activeClassName="mobile-nav-drawer-link-active"
                        exact
                    >
                        {category.title}

                    </NavLink>
                )) : navCategories.map((category: NavCategory, index: number) => (
                    <a key={index} 
                    className="mobile-nav-drawer-link" 
                    onClick={() => setNavShow(false)} 
                    href={category.path}>{category.title}</a>
                ))}
                <a className="mobile-nav-drawer-link"  href="https://blog.dibscapital.com/">BLOG</a>
            </div>
            <div className="mobile-nav-drawer-txt">
                Bringing real estate online. Streamlined access. Data-driven
                investments. Liquidity, powered by the blockchain.
            </div>
            {renderDrawerButton()}
        </Drawer>
    );
};

export default MobileNavDrawer;
