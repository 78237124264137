import React, { useEffect, useState } from "react";
import { AssetInterface } from "../../interfaces/marketplaceInterface";

const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

const PaymentStatus = ({ tooltip, asset }: { tooltip: string, asset: AssetInterface | undefined | null }) => {
    const [tooltipShowMobile, setShowMobile] = React.useState<boolean>(false);
    const showHide = () => {
      if (tooltipShowMobile){
        setShowMobile(false)
      } else {
        setShowMobile(true)
      }
    }

    const idVerification = (
        <div>
            <span className="tooltipTitle">
                ID Verification Pending
            </span>
            <p className="tooltipText">
                Your ID verification has not yet been submited or approved.
            </p>
        </div>
    );

    const Cancelled = (
        <div>
            <span className="tooltipTitle">
                Payment Cancelled
            </span>
            <p className="tooltipText">
                Your payment was cancelled. 
            </p>
        </div>
    );

    const Payment = (
        <div>
            <span className="tooltipTitle">
                Payment Pending
            </span>
            <p className="tooltipText">
                Your payment has not yet been cleared. 
            </p>
        </div>
    );

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (asset){
            setProgress(Math.round((asset.tokenSold / asset.maxAmount) * 100));
        }
    }, [asset?.tokenSold, asset?.maxAmount, asset]);

    const Reserved = (
        <div>
            <span className="tooltipTitle">
                Reserved
            </span>
            <p className="tooltipText">
                Your allocation will remain in reserved status until the project is funded. 
            </p>
            <div className="line">
                <div
                className="raised"
                style={{
                    width: `${progress > 5 ? progress : 5}%`,
                }}
                ></div>
            </div>
            <div className="amount">
                {progress}% funded
            </div>
        </div>
    );

    const renderStatus = () => {
        if (tooltip === "CANCELED") {
            return Cancelled;
        }
        if (tooltip === "CREATED") {
            return Payment;
        }
        if (tooltip.includes("PENDING")) {
            return idVerification;
        }
        if (tooltip === "SETTLED") {
            return Reserved;
        }
    };

    return (
      <>
        <div className="at-step-one__container__column__title">
          {supportsTouch ? 
            <div className={`${tooltip} status`} onClick={showHide}>
            </div>
              : 
            <div className={`${tooltip} status`} onMouseEnter={() => setShowMobile(true)} onMouseLeave={() => setShowMobile(false)}>
            </div>
        }
        </div>
        <div 
          className={`status-tooltip ${tooltip} ${tooltipShowMobile ? "active" : "closed"}`}
        >
          {renderStatus()}
        </div>
      </>
    );
  }

  export default PaymentStatus;