import axios from "axios";

const PROD_DISCOUNT = `${process.env.REACT_APP_API_KEY}asset`

function fetchMarketplaceStories() {

    return axios.get(`${PROD_DISCOUNT}/stories`)
        .then(res => res)
        .catch(err => err)
}

function fetchCategoryStories(category: any) {

    return axios.get(`${PROD_DISCOUNT}/stories/category?category=${category[0]}`)
        .then(res => res)
        .catch(err => err)
}

export { fetchMarketplaceStories,fetchCategoryStories}