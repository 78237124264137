import * as types from "../constants/types";

export const getAssetsList = function (visibility?: string) {
  return {
    type: types.GET_ASSETS_LIST_REQ,
    payload: visibility,
  };
};

export const getMainAssetInfo = function (id: string) {
  return {
    type: types.GET_ASSET_INFO_REQ,
    id: id,
  };
};

export const getAssetLocation = function (id: string) {
  return {
    type: types.GET_ASSET_LOCATION_REQ,
    id: id,
  };
};
export const setSliderVal = function (
  sliderValue: number | number[],
  inputValue: number | number[]
) {
  return {
    type: types.SLIDER_VALUE,
    sliderValue: sliderValue,
    inputValue: inputValue,
  };
};

export const subscribeUpcoming = function (data: {
  email: string;
  assetId: string;
}) {
  return {
    type: types.SUBSCRIBE_UPCOMING_REQ,
    payload: data,
  };
};

export const getPresentation = function (data: {
  email: string;
  assetId: string;
}) {
  return {
    type: types.GET_PRESENTATION_REQ,
    payload: data,
  };
};

export const disableAllowPresentation = function (){
    return {
        type: types.GET_PRESENTATION_FAIL
    }
}
