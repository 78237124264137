import { useDispatch, useSelector } from "react-redux";
import { getMainAssetInfo } from "../../../redux/actions/assetsAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import { AssetDataInterface } from "../../../interfaces/assetsInterface";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { DETOX_ID } from "../../../constants/assetsId";

import AssetMap from "../../../components/assets/asset-main-view/asset-map/assetMap";
import AdvanceInfo from "../../../components/assets/asset-advansed-view/advance-info/advanceItemInfo"; 

import AdvanceProjection from "../../../components/assets/asset-advansed-view/advance-projection/advanceProjection";
import AdvanceContent from "../../../components/assets/asset-advansed-view/advance-content/advanceItemContent";
import AdvanceHighlights from "../../../components/assets/asset-advansed-view/advance-highlight/advanceHighlight";
import AdvanceInvestmentDetails from "../../../components/assets/asset-advansed-view/advance-investment-details/advanceInvestmentDetails";
import AdvanceInvestorPerks from "../../../components/assets/asset-advansed-view/advance-investor-perks/advance-investor-perks";
import AssetFullAccess from "../../../components/assets/asset-advansed-view/asset-full-access/assetFullAccess";
import AssetDocuments from "../../../components/assets/asset-main-view/asset-documents/assetDocuments";
import mautic from 'mautic-tracking';
import Spinner from "../../../components/spinner/spinner";

const DetoxPage = () => {

  const dispatch = useDispatch();
  const { id } = useParams<MainAssetPageParams>();
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
  useEffect(() => {
    if (!info.alias || info.id !== DETOX_ID)
      dispatch(getMainAssetInfo(`${DETOX_ID}`));
      
  }, [dispatch, id, info.alias, info.id]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  if(info.isLoading) return <Spinner /> 

  return (
    <div className="redfrog-page">
      <div className="asset-top">
        <div className="container">
          <AdvanceInfo />
        </div>
      </div>
      <div className="container">
        <div className="content">
          <div className="left">
            <AdvanceHighlights />
            <AdvanceContent />
            <AssetMap />
            <div className="mobile-blocks">
              <div className="divider"></div>
            </div>
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
          <div className="right">
            <AdvanceInvestmentDetails />
            {localStorage.getItem("token") ? (
              <>
                <AdvanceProjection />
                <AssetDocuments />
              </>
            ) : (
              <AssetFullAccess />
            )}
            <AdvanceInvestorPerks />
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
        </div>
      </div>
    </div>


    // <div className="asset-wrapper">
    //   {localStorage.getItem("token") ? <Banner /> : ""}
    //   {loader.loader ? (
    //     <Spinner />
    //   ) : (
    //     <div className="asset-container">
    //       <ComingSoonModal
    //         title={info.status}
    //         open={open}
    //         handleModal={handleModal}
    //         assetId={info.id}
    //       />
    //       <div className="info-containers">
    //         <AssetInfoIpad
    //           handleModal={handleModal}
    //           buyDigitalButtonHandler={buyDigitalButtonHandler}
    //         />
    //         <AssetInfo
    //           handleModal={handleModal}
    //           buyDigitalButtonHandler={buyDigitalButtonHandler}
    //         />
    //       </div>
    //       {/* <AssetStage /> */}
    //       <AssetGridComponent
    //         buyDigitalButtonHandler={buyDigitalButtonHandler}
    //       />
    //       {/* <AssetBidArea handleModal={handleModal} /> */}
    //       <VerifyModal />
    //     </div>
    //   )}
    // </div>
  );
};

export default DetoxPage;
