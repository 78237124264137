import * as types from "./../../constants/types";

const initialState = {
    assetsTokens: [],
};

const tokensInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.GET_TOKENS_INFO:
            return {
                ...state,
                assetsTokens: action.payload,
            };
        case types.CHECK_USER_TOKENS:

            return {
                ...state,
            };
        default:
            return state;
    }
};

export default tokensInfoReducer;
