import * as types from "./../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { getTokenApi } from "./../shared/getTokenApi";
import { postTokenApi } from "./../shared/postTokenApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* exchangeSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "token-offer/";
        const exchangeItems = yield call(getTokenApi, endpoint);
        if (exchangeItems.status >= 200 && exchangeItems.status < 300) {
            yield put({
                type: types.ALL_EXCAHNGE_OFFERS,
                payload: exchangeItems.data.offers,
                userId: exchangeItems.data.userId,
            });
        } else throw exchangeItems;
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}
function* tempItemsSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "asset/alias";
        const exchangeItems = yield call(getTokenApi, endpoint);
        yield put({ type: types.TEMP_ITEMS, payload: exchangeItems.data });
        const exchangeEndpoint = "user/assets/tokens";
        const mainExchangeItems = yield call(getTokenApi, exchangeEndpoint);
        yield put({
            type: types.MAIN_EXCHANGE_ITEMS,
            payload: mainExchangeItems.data.purchasedAssetTokens,
        });
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}
function* postOfferSaga(action: {
    type: string;
    tradeInfo: object;
}): TypedIterableIterator<any, any, number> {
    try {
        const endpoint2 = "token-offer/";
        const exchangeItems2 = yield call(getTokenApi, endpoint2);
        yield put({
            type: types.ALL_EXCAHNGE_OFFERS,
            payload: exchangeItems2.data.offers,
            userId: exchangeItems2.data.userId,
        });
        const endpoint3 = "purchase";
        const myInvestmentsData = yield call(getTokenApi, endpoint3);
        yield put({
            type: types.GET_MY_INVESTMENTS,
            payload: myInvestmentsData.data.assetPurchases,
            credits: myInvestmentsData.data.userCredit,
        });
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}
function* buyExchangedSaga(action: {
    type: string;
    id: string;
    cryptoId: string;
}): TypedIterableIterator<any, any, number> {
    const secMessage =
        action.cryptoId === null
            ? "Demo account"
            : "You dont't have enough funds";
    try {
        const endpoint = "token-offer/" + action.id;
        const exchangeItems = yield call(postTokenApi, [{}, endpoint]);
        if (exchangeItems.status >= 200 && exchangeItems.status < 300) {
            const endpoint2 = "token-offer/";
            const exchangeItems2 = yield call(getTokenApi, endpoint2);
            yield put({
                type: types.ALL_EXCAHNGE_OFFERS,
                payload: exchangeItems2.data.offers,
                userId: exchangeItems2.data.userId,
            });
            yield put({
                type: types.SNACKBAR_RESPONSE,
                mainMessage: "Your message has been delivered",
                secMessage: "Your payment ....",
                status: "success",
            });
        } else throw exchangeItems;
    } catch (e) {
        yield put({
            type: types.SNACKBAR_RESPONSE,
            mainMessage: "Something went wrong",
            secMessage: secMessage,
            status: "warning",
        });
    }
}
function* cancelOfferSaga(action: {
    type: string;
    id: string;
}): TypedIterableIterator<any, any, number> {
    try {
        const endpoint2 = "token-offer/";
        const exchangeItems2 = yield call(getTokenApi, endpoint2);
        yield put({
            type: types.ALL_EXCAHNGE_OFFERS,
            payload: exchangeItems2.data.offers,
            userId: exchangeItems2.data.userId,
        });
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}

function* getUserAssetsTokensSaga(action: {
    type: string;
}): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user/assets/tokens";
        const tokensInfo = yield call(getTokenApi, endpoint);
        yield put({
            type: types.GET_TOKENS_INFO,
            payload: tokensInfo.data.purchasedAssetTokens,
        });
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}

function* watchExchangeSaga() {
    yield takeEvery(types.GET_ALL_EXCHANGE_OFFERS, exchangeSaga);
    yield takeEvery(types.GET_TEMP_ITEMS, tempItemsSaga);
    yield takeEvery(types.POST_TRADE_OFFER, postOfferSaga);
    yield takeEvery(types.BUY_EXCHANGED_OFFER, buyExchangedSaga);
    yield takeEvery(types.CANCEL_OFFER, cancelOfferSaga);
    yield takeEvery(types.GET_USER_ASSETS_TOKENS, getUserAssetsTokensSaga);
}

export default watchExchangeSaga;
