import * as types from "./../../constants/types";

import { Marketplace } from "../../../interfaces/marketplaceInterface";

const initialState: Marketplace = {
    assetsList: [],
    storiesList: [],
    slugStories: [],
    assetsLoading: false,
    storiesLoading: false,
    assetsError: false,
    storiesError: false,
    openStories: false,
    activeStory: null,
    activeStoryIndex: 0,
    currentStory: 0,
    loader: false,
};

const marketplaceReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.GET_ASSETS_LIST_REQ:
            return {
                ...state,
                assetsLoading: true,
            };
        case types.GET_ASSETS_LIST_SUC:
            return {
                ...state,
                assetsList: action.payload,
                assetsLoading: false,
            };
        case types.GET_ASSETS_LIST_FAIL:
            return {
                ...state,
                assetsLoading: false,
                assetsError: true,
            };
        case types.GET_STORIES_LIST_REQ:
            return {
                ...state,
                storiesLoading: true,
            };
        case types.GET_STORIES_LIST_SUC:
            return {
                ...state,
                storiesList: action.payload,
                storiesLoading: false,
            };
        case types.GET_STORIES_LIST_FAIL:
            return {
                ...state,
                storiesLoading: false,
                storiesError: true,
            };
        case types.OPEN_STORIES_REQ:
            return {
                ...state,
                loader: true,
            };
        case types.OPEN_STORIES_SUC:
            return {
                ...state,
                loader: false,
                activeStory: action.payload.category,
                activeStoryIndex: action.payload.index,
                slugStories: action.payload.slugStories,
                openStories: true,
            };
        case types.NEXT_STORIE_REQ:
            return {
                ...state,
                loader: true,
                activeStoryIndex:
                    action.payload.clickValue !== null
                        ? action.payload.clickValue
                        : state.activeStoryIndex + 1,
            };
        case types.NEXT_STORIE_SUC:
            return {
                ...state,
                activeStory: state.storiesList[state.activeStoryIndex].category,
                slugStories: action.payload,
                loader: false,
            };
        case types.CLOSE_STORIES:
            return {
                ...state,
                openStories: false,
            };

        default:
            return state;
    }
};

export default marketplaceReducer;
