import { LoginAction } from "../../interfaces/actions-interfaces/registration-interfaces";
import { SignInTFAresp } from "../../interfaces/signInInterface";
import * as types from "../constants/types";
import { History } from "history";

export const signInFirstStepAC = function (loginData: LoginAction,) {
    return {
        type: types.GET_SIGNIN_REQ,
        payload: loginData,
    };
};

export const sigInSeconStepSetUpAC = (resp:SignInTFAresp)=>({
    type:types.SIGN_IN_FIRST_STEP_TFA_RESP,
    payload:resp
})
export const sigInPostCodeAC = (otp:string,shouldRemember:boolean,history:History,failCB:()=>void)=>({
    type:types.SIGN_IN_POST_TFA_CODE_REQ,
    payload:{otp,shouldRemember,history,failCB}
})

export const signInResendCodeAC = (cb:()=>void) => ({
    type:types.SIGN_IN_RESEND_TFA_RESP,
    payload: {cb}
})