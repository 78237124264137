import { useState } from "react";
import { 
  useDispatch,
  useSelector } from "react-redux";
import { validateEmail } from "../../../../constants/regex";
import {
  AssetDataInterface,
  FoldersListInterface,
} from "../../../../interfaces/assetsInterface";
import { getPresentation } from "../../../../redux/actions/assetsAction";
import PptIcon from "./../../../../Assets/images/ppt.png";

function AdvancePresentaion() {
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const documentsList = useSelector(
    (state: FoldersListInterface) => state.foldersList
  );

  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const presentationHandler = () => {
    setError("");
    if (
      !localStorage.getItem("token")
    ) {
      if (!email) {
        setError("Please, enter your email");
        return;
      }
      if (!validateEmail(email)) {
        setError("Invalid email");
        return;
      }
    }
    dispatch(
      getPresentation({
        assetId: info.id,
        email: email,
      })
    );
    const folder = documentsList.documents.find(
      (doc) => doc.name === "presentation"
    );
    if (folder && folder.documents.length) {
      const docLink = folder.documents[0].link;
      const a = document.createElement("a");
      a.href = docLink;
      a.download = 'true'
      a.target = 'blank'
      a.click();
    }
  };

  return (
    <div className="redfrog-presentation">
      <img
        src={PptIcon}
        alt=""
        className="ppt"
      />
      <div className="inner">
        <div className="title">Detox Digital Coin</div>
        <div className="subtitle">Full Investor Presentation</div>
        {localStorage.getItem("token") ? null : (
          <input
            className="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        )}
        <span className="enter" onClick={presentationHandler}>
          Download Now
        </span>
        <span className="input-error">{error}</span>
        {info.allowPresentation ? (
          <span className="downloaded">Thanks for downloading!</span>
        ) : null}
      </div>
    </div>
  );
}

export default AdvancePresentaion;
