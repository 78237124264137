import { useLocation } from "react-router";
import AccreditationHeader from "./accreditation-nav/accreditation-header/accreditationHeader";
import AccountNav from "./account-nav/accountNav";
import LandingNavMobile from "./landing-nav/landing-nav-mobile/landingNavMobile";

import {
  ACCREDITATION_PAGES,
  AUTHED_ROUTES,
  AUTH_MOBILE_CATEGORIES,
  LANDING_HEADER_PAGES,
  NON_HEADER_PAGES,
} from "../../constants/nav";
import NewLandingNav from "../headers-components/new-landing-nav/newLandingNav";

function HeadersComponent() {
  const { pathname } = useLocation();

  if (pathname.includes("/asset-view")) {
    return localStorage.getItem("token") ? <AccountNav /> : <NewLandingNav />;
  }
  if (pathname.includes("/tokenization")) {
    return localStorage.getItem("token") ? <AccountNav /> : <NewLandingNav />;
  }
  if (pathname === "/marketplace") {
    return localStorage.getItem("token") ? <AccountNav /> : <NewLandingNav />;
  }

  if (NON_HEADER_PAGES.includes(pathname)) {
    return null;
  }

  if (LANDING_HEADER_PAGES.includes(pathname)) {
    return <NewLandingNav />;
  }

  if (AUTH_MOBILE_CATEGORIES.includes(pathname)) {
    return <LandingNavMobile />;
  }

  if (AUTHED_ROUTES.includes(pathname)) {
    return <AccountNav />;
  }

  if (ACCREDITATION_PAGES.includes(pathname)) {
    return <AccreditationHeader />;
  }

  return localStorage.getItem("token") ? <AccountNav /> : <NewLandingNav />;
}

export default HeadersComponent;
