import './style.scss'
import Partner1 from "./../../../Assets/new-landing/partnters/1.png";
import Partner2 from "./../../../Assets/new-landing/partnters/2.png";
import Partner3 from "./../../../Assets/new-landing/partnters/3.png";
import Partner4 from "./../../../Assets/new-landing/partnters/4.png";
import Partner5 from "./../../../Assets/new-landing/partnters/5.png";
import Partner6 from "./../../../Assets/new-landing/partnters/6.png";
import Partner7 from "./../../../Assets/new-landing/partnters/7.png";
import Partner9 from "./../../../Assets/new-landing/partnters/9.png";
import Partner10 from "./../../../Assets/new-landing/partnters/10.png";
import Partner11 from "./../../../Assets/new-landing/partnters/11.png";
import Partner12 from "./../../../Assets/new-landing/partnters/12.svg";
import Partner13 from "./../../../Assets/new-landing/partnters/13.png";
import { useEffect } from "react";

function StrategicPartners() {
  useEffect(() => {
    const myId:string = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView({block: "center"});
    }
  }, [])
  return (
    <div className="strategic-partners" id="partners">
      <div className="container">
        <h2 className="title">Strategic Partners</h2>
        <div className="partners-list">
          <div className="partner">
            <img src={Partner1} alt="Partner1" loading="lazy" />
          </div>
          <div className="partner">
            <img src={Partner2} alt="" className="archax" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner3} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner4} alt="" loading="lazy"/>
          </div>
          <div className="partner" style={{marginTop:'-17px'}}>
            <img src={Partner10} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner5} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner6} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner7} alt="" loading="lazy"/>
          </div>
          <div className="partner" >
            <img src={Partner12} width={85} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner9} alt="" loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner11} alt="" width={150} loading="lazy"/>
          </div>
          <div className="partner">
            <img src={Partner13} alt="" width={150} loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrategicPartners;
