import * as types from "../constants/types";

export const checkExternalAccount = function (history: any, url: string) {
    return {
        type: types.CHECK_EXTERNAL_ACCOUNT,
        history,
        url,
    };
};
export const getWidgetLink = function (
    history: any,
    url: any,
    achStatus: string
) {
    return {
        type: types.GET_WIDGET_LINK,
        history,
        url,
        achStatus,
    };
};

export const createExternalAccount = function (
    info: object,
    history: any,
    achStatus: string
) {
    return {
        type: types.CREATE_EXTERNAL_ACCOUNT,
        info,
        history,
        achStatus,
    };
};

export const toggleLoader = function () {
    return {
        type: types.TOGGLE_LOADER,
    };
};
