import { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { THEME_COLOR } from "../../../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { UserImageState } from "./../../../../interfaces/headerInterface";
import { getUserImage } from "./../../../../redux/actions/headerActions";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import { useLocation } from "react-router";
import AccountNavCollapse from "./account-nav-collapse/accountNavCollapse";
// import secureIcon from "./../../../../Assets/icons/navSecure.svg";

import { LogoDark } from "../../../../constants/logo";
import LogoCube from "./../../../../Assets/new-landing/logo-cube.png";
import DefaultImage from "../../../../Assets/images/default-avatar.svg";
// import { CHECKOUT_PAGES } from "../../../../constants/nav";

const useStyles = makeStyles({
  link: {
    "&:hover": {
      color: `${THEME_COLOR} !important`,
    },
  },
});

const DeskTopNav = () => {
  const [open, setOpen] = useState(true);
  const [reminder, setReminder] = useState(false);
  const styles = useStyles();
  const history = useHistory();
  // const { pathname } = useLocation();
  const dispatch = useDispatch();

  const exchangeColor = useSelector(
    (store: any) => store.exchangeReducer.exchange
  );
  useEffect(() => {
    dispatch(getUserImage());
  }, [dispatch]);
  const userData = useSelector((state: UserImageState) => state.headerReducer);

  const userImageUrl = userData.userImage || DefaultImage;

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const expiredTime = localStorage.getItem("expiredTime");

  const adminLink = `${process.env.REACT_APP_ADMIN_LINK}/auth?token=${token}&refreshToken=${refreshToken}&expiredTime=${expiredTime}`;

  const adminLinkElement =
    userData.role > 5 ? (
      <a
        className="collapse-header-item"
        href={adminLink}
        style={{ textDecoration: "none" }}
      >
        <LockOutlinedIcon
          fill="#333333"
          style={{ width: 20, marginRight: 7 }}
        />
        Admin
      </a>
    ) : null;

  useEffect(() => {
    //remove fields accreditationSkipped ready , coz this fields should not affect on reminder
    let needReminder = Object.entries(userData.steps)
    .filter(a => a[0] !== 'accreditationSkipped' && a[0] !== 'ready' &&a[0] !== 'accreditation')
    .map(i => i[1]).includes(false)
    if(!userData.isEntity&&!userData.steps.accreditation&&!userData.steps.accreditationSkipped){
      needReminder = true
    }
    setReminder(needReminder);
  }, [userData.steps,userData.isEntity])


  return (
    <div
      onMouseLeave={() => setOpen(true)}
      style={{ background: `${exchangeColor && history.location.pathname === '/exchange' ? 'rgba(0,0,0,0)' : ''}` }}
      className="desktop-nav-container scrolled">
      <div className="desktop-nav">
        <Link className="logo-scrollable" to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={LogoCube} alt="" className="logo-cube" />
          <img src={LogoDark} alt="" className="logo-main" />
        </Link>
        <div className="desktop-nav-ava">
          {/* <NavLink
              className={styles.link + " desktop-nav-link"}
              activeStyle={{ color: THEME_COLOR }}
              to="/exchange"
          >
              Exchange
          </NavLink> */}
          {/* <NavLink
            className={styles.link + " desktop-nav-link"}
            activeStyle={{ color: "#8A00F6" }}
            to="/tokenization/dashboard"
          >
            Tokenization
          </NavLink> */}
          <NavLink
            className={styles.link + " desktop-nav-link"}
            activeStyle={{ color: THEME_COLOR }}
            to="/marketplace"
          >
            Marketplace
          </NavLink>
          <NavLink
            className={styles.link + " desktop-nav-link"}
            activeStyle={{ color: THEME_COLOR }}
            to="/my-assets"
          >
            My Assets
          </NavLink>
          <div className={`logout ${reminder ? 'reminder' : ''}`}>
            <div className="logout-icon-container">
              <img
                onMouseOverCapture={() => setOpen(false)}
                alt="logout-icon"
                src={userImageUrl}
                onClick={() => setOpen(!open)}
              ></img>
            </div>
            <AccountNavCollapse
              setOpen={setOpen}
              open={open}
              admin={adminLinkElement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeskTopNav;
