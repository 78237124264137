import * as type from "../../constants/types";
import { SignInStore } from "../../../interfaces/signInInterface";
import { LoginAction } from "../../../interfaces/actions-interfaces/registration-interfaces";
import { sigInSeconStepSetUpAC } from "../../actions/signInActions";

const initialState:SignInStore = {
    email:'',
    password:'',
    buttonDisabled:false,
    Session:'',
    phone:'',
    username:'',
};

const signInReducer = (state:SignInStore|undefined = initialState, action: any):SignInStore => {
    let typedAction
    switch (action.type) {
        case type.GET_SIGNIN_REQ:
            typedAction = action.payload as LoginAction
            return {
                ...state,
                buttonDisabled:true,
                email:typedAction.logindata.email,
                password:typedAction.logindata.password,
            }
        case type.SIGN_IN_FIRST_STEP_TFA_RESP:
            typedAction = action as ReturnType<typeof sigInSeconStepSetUpAC>
            return {
                ...state,
                Session:typedAction.payload.Session,
                phone:typedAction.payload.phone,
                username:typedAction.payload.username,
                buttonDisabled:false,
            }
        case type.SIGN_IN_ENABLE_BUTTON_SUBMIT:
            typedAction = action as ReturnType<typeof sigInSeconStepSetUpAC>
            return {
                ...state,
                buttonDisabled:false,
            }
        

        default:
            return state;
    }
};

export default signInReducer;
