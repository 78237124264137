import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

SwiperCore.use([Navigation]);

function AdvanceItemContent() {

  const assetInfo = useSelector(
    (state: AssetDataInterface) => state.mainAssetInfo
  );

  return (
    <div className="redfrog-content">
      <div className="image1">
        <img src={assetInfo.images[1]} alt="" />
      </div>
      <div className="title">The Summary</div>
      <div className="subtitle">{" "}</div>
      <div 
        className="text" 
      >
        {assetInfo.summary}
      </div>
      <div className="swiper-image">
        <Swiper loop navigation>
          {assetInfo.images.map((image,i) => (
            <SwiperSlide key={i}>
              <img src={image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default AdvanceItemContent;
