import { InputAdornment, TextField } from "@material-ui/core";
import SecureCheckout from "../../../components/new-checkout/secureCheckout";
import CheckoutStages from "../../../components/new-checkout/checkoutStages";
import BuyArrow from "./../../../Assets/icons/buy.svg";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { useEffect, useState } from "react";
import {
  applyDiscount,
  clearDiscount,
  getCheckoutInfoReq,
} from "../../../redux/actions/checkoutActions";
import { CheckoutInterface } from "../../../interfaces/assetsInterface";
import { numberWithCommasDots } from "../../../constants/numberWithCommasDots";
import mautic from 'mautic-tracking';
import { addComma } from "../../../constants/addCommaFunction"; 

function CheckoutOverview() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, tokens } = useParams<MainAssetPageParams>();

  useEffect(() => {
    if (!tokens || !id) history.push("/marketplace");
  }, [id, tokens, history]);

  useEffect(() => {
    dispatch(getCheckoutInfoReq(id, history, +tokens));
  }, [dispatch, history, id, tokens]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  // useEffect(() => {
  //   dispatch(setCheckoutTotal(+tokens));
  // }, [tokens, dispatch, checkoutInfo.unitPrice]);

  const [discountCode, setDiscountCode] = useState("");

  const discountInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(e.target.value);
  };

  const applyDiscountHandler = () => {
    if (discountCode.trim()) {
      dispatch(applyDiscount(discountCode, checkoutInfo.id));
    }
  };

  const discountErrorMessage = checkoutInfo.discountError ? (
    <span className="discount-error">Discount not found</span>
  ) : null;

  const discountSuccessMessage = checkoutInfo.discountActive ? (
    <span className="discount-success">Discount applied</span>
  ) : null;

  const _isLDB = checkoutInfo.alias === "LDB";

  useEffect(() => {
    dispatch(clearDiscount()); 
  }, [dispatch])

  return (
    <div className="checkout-overview">
      <SecureCheckout />
      <CheckoutStages />
      <div className="container">
        <div className="cart">
          <div className="head">
            <div className="asset">Asset</div>
            <div className="quantity">Quantity</div>
            <div className="price">Unit Price</div>
            <div className="subtotal">Subtotal</div>
          </div>
          <div className="card">
            <div className="asset">
              <div className="img">
                <img src={checkoutInfo.imagePath} alt="" />
              </div>
              <div className="info">
                <div className="title">{checkoutInfo.title}</div>
                <div className="tokens">{checkoutInfo.alias} tokens</div>
              </div>
            </div>
            <div className="quantity">
              <span className="label">Quantity</span>
              <span className="value">{tokens}</span>
            </div>
            <div className="price">
              <span className="label">Unit Price</span>
              <span className="value">{_isLDB ? (`£${numberWithCommasDots(checkoutInfo.unitPrice)}`) : (`$${numberWithCommasDots(checkoutInfo.unitPrice)}`)}</span> 
            </div>
            <div className="subtotal">
              <span className="label">Subtotal</span>
              {/* <span className="value">{_isLDB ? (`£${numberWithCommasDots(checkoutInfo.unitPrice * +tokens)}`) : (`$${numberWithCommasDots(checkoutInfo.unitPrice * +tokens)}`)}</span> */}
              <span className="value">{_isLDB ? (`£${addComma(String(checkoutInfo.unitPrice * +tokens))}`) : (`$${addComma(String(checkoutInfo.unitPrice * +tokens))}`)}</span>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="wrapper">
            <div className="discount">
              <div className="title">Discount Code</div>
              <TextField
                variant="outlined"
                className="discount-input"
                value={discountCode}
                onChange={discountInputHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="apply" onClick={applyDiscountHandler}>
                        Apply
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
              {discountErrorMessage}
              {discountSuccessMessage}
            </div>
            {/* <div className="credits">
              <div className="use">
                <CheckboxInput
                  disabled={false}
                  checked={false}
                  id="credits"
                  label=""
                  checkBoxHandler={() => {}}
                />
                <span className="label">Use Credits</span>
              </div>
              <div className="balance">
                You have <span className="amount">0 credits</span> on your
                balance.
              </div>
              <div className="earn">You will earn 0 credits on this order</div>
            </div> */}
            <div className="total">
              <div className="result">
                <div className="title">Total:</div>
                <div className="amount">
                  {_isLDB ? 
                    (
                      <>
                        {/* £{numberWithCommasDots(checkoutInfo.total)}  */}
                        £{addComma(String(checkoutInfo.total))}
                        <span className="currency">GBP</span>
                      </>
                    ) 
                      : 
                    (
                      <>
                        {/* ${numberWithCommasDots(checkoutInfo.total)} */}
                        ${addComma(String(checkoutInfo.total))}
                        <span className="currency">USD</span>
                      </>
                    )
                  }
                </div>
              </div>

              <div
                className="next"
                onClick={() => {
                  // dispatch(clearDiscount()); 
                  mautic.pageView({ tags: 'payment'});
                  history.push(`/checkout/payment/${id}/${tokens}`)
                }}
              >
                Next
                <img src={BuyArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutOverview;
