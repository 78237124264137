import * as types from './../constants/types'
import { SendVerification, SetAccreditationStep1 } from './../../interfaces/accreditationInterface'

export const getAccreditationInfo = function () {
    return {
        type: types.GET_ACCREDITATION_INFO_REQ
    }
}

export const sendVerification = function (object: SendVerification) {
    return {
        type: types.SEND_ACCREDITATION_REQ,
        payload: object
    }
}

export const setAccreditationStep1 = function (object: SetAccreditationStep1) {
    return {
        type: types.SET_ACCREDITATION_STEP1,
        payload: object
    }
}

export const setAccreditationStep2 = function (object: string) {
    return {
        type: types.SET_ACCREDITATION_STEP2,
        payload: object
    }
}

export const setAccreditationStep3 = function (object: Blob) {
    return {
        type: types.SET_ACCREDITATION_STEP3,
        payload: object
    }
}

export const closeAccreditationModal = function () {
    return {
        type: types.CLOSE_ACCREDITATION_MODAL
    }
}

export const checkisAccredited = function () {
    return {
        type: types.GET_IS_ACCREDITED_REQ
    }
}

