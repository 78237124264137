import { useState } from "react";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

import AssetCheckout from "./../asset-checkout/assetCheckout";
import AssetTopRight from "./../asset-top-right/assetTopRight";
import mountxImg from "./../../../../Assets/images/mountx.png";
import ercImg from '../../../../Assets/images/ercInvest.png'
import { UserImageState } from "../../../../interfaces/headerInterface";

function RedfrogInfo() {

  const [checkout, setCheckout] = useState(false);

  const userData = useSelector((state: UserImageState) => state.headerReducer);
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const toggleCheckout = () => {
    setCheckout(!checkout);
  };

  const [load, setLoaded] = useState(true);

  return (
    <div className="asset-info">
      <div className="asset-title">
        <div>
          <div className="title">Red Frog Beach Portfolio <span>(${info.alias})</span></div>
          <div className="location">Isla Bastimentos, Bocas del Toro, Panama</div>
          <div className="descr">
            We are expanding this environmentally sustainable luxury resort that
            is home to lush rain forests and pristine beaches.
          </div>
        </div>
        {userData.fromSite ==='erc'?
        <img src={ercImg} alt="erc" className="promo" />
        :
        ''
        }
        {userData.fromSite ==='mountx'?
        <img src={mountxImg} alt="mountx" className="promo" />
        :
        ''
        }
      </div>
      <div className="asset-info-main">
        <div className="left">
          <div className="asset-image">
            {load ? (
                <div className="loading"></div>
              ) : null}
              <iframe
                src={`https://www.youtube.com/embed/XfeUfoP-Mgc`}
                title="Red Frog Video"
                frameBorder="0"
                onLoad={() => {setLoaded(false)}}
              />
          </div>
          {/* <div className="tags">
            <div className="tag-item">Hospitality</div>
            <div className="tag-item">Debt</div>
          </div> */}
        </div>
        <div className="right">
          {/* <Fade in={!checkout}> */}
          {checkout ? null : (
            <AssetTopRight
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          )}
          {/* </Fade> */}

          {/* <Fade in={checkout}> */}
          {checkout ? (
            <AssetCheckout
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          ) : null}
          {/* </Fade>  */}
        </div>
      </div>
    </div>
  );
}

export default RedfrogInfo;
