import * as types from "./../../constants/types";

const initialState = {
    open: false,
    text: "",
    status: "",
    secMessage: "",
};

const snackbarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.SNACKBAR_RESPONSE:
            return {
                ...state,
                open: true,
                mainMessage: action.mainMessage,
                secMessage: action.secMessage,
                status: action.status,
            };
        case types.CLOSE_SNACKBAR:
            return {
                ...state,
                open: false,
                // mainMessage: "",
                // secMessage: "",
                // status: "",
            };

        default:
            return state;
    }
};

export default snackbarReducer;
