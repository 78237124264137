import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";

function AssetFullAccess() {
  const [email, setEmail] = useState("");

  const history = useHistory();

  return (
    <div className="asset-full-access">
      <div className="title">Log in for full access</div>
      <div className="descr">
        Once you log in, you'll have access to data about this project such as
        total returns, cumulative net cash flow, sales proceeds and Investors
        Presentation.
      </div>
      <div className="form">
        <input
          className="email"
          placeholder="Enter Your Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />

        <span
          className="enter"
          onClick={() => history.push(`/login?email=${email}&redirectTo=/asset-view/red-frog-beach-portfolio-panama`)}
        >
          Log In
        </span>
      </div>
    </div>
  );
}

export default AssetFullAccess;
