import * as types from './../../constants/types'

const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    country: null,
    isUSCitizen: false,
    isAccredited: false,
    imagePath: null,
    state: null,
    city: null,
    address: null,
    zipCode: null,
    phonr: null,
    loaded: false,
    imageLoaded: false,
    error: false,
    fromSite:'',
}

const mainInformationReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case types.LOGOUT:
        return {
            ...state,
            fromSite:'',
        }
        case types.GET_USER_DATA_REQ:
            return {
                ...state
            }
        case types.GET_USER_DATA_SUC:
            return {
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                country: action.payload.country,
                isUSCitizen: action.payload.isUSCitizen,
                isAccredited: action.payload.isAccredited,
                imagePath: action.payload.imagePath,
                state: action.payload.state,
                city: action.payload.city,
                address: action.payload.address,
                zipCode: action.payload.zipCode,
                phone: action.payload.phone,
                loaded: true,
                error: true,
                imageLoaded: true,
                fromSite:action.payload.fromSite,
            }
        case types.GET_USER_DATA_FAIL:
            return {
                ...state,
                loaded: true,
                error: true
            }
        case types.CHANGE_USER_DATA_REQ:
            return {
                ...state,
                loaded: false,
                imageLoaded: false
            }
        case types.CHANGE_USER_DATA_SUC:
            return {
                ...state,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                country: action.payload.country,
                isUSCitizen: action.payload.isUSCitizen,
                isAccredited: action.payload.isAccredited,
                state: action.payload.state,
                city: action.payload.city,
                address: action.payload.address,
                zipCode: action.payload.zipCode,
                loaded: true,
                error: false,
                imageLoaded: true,
                fromSite:action.payload.fromSite,
            }
        case types.CHANGE_USER_DATA_FAIL:
            return {
                ...state,
                loaded: true,
                error: true,
                imageLoaded: true
            }

        case types.CHANGE_USER_IMAGE_REQ:
            return {
                ...state,
                imageLoaded: false
            }
        case types.CHANGE_USER_IMAGE_SUC:
            return {
                ...state,
                imagePath: action.payload.imagePath,
                imageLoaded: true
            }
        case types.DELETE_USER_IMAGE_REQ:
            return {
                ...state,
                imageloaded: false
            }
        case types.DELETE_USER_IMAGE_SUC:
            return {
                ...state,
                imagePath: action.payload,
                imageLoaded: true
            }

        default: return state
    }
}

export default mainInformationReducer;