import * as type from "../../constants/types";
interface data {
    cryptoData: { [key: string]: number };
    cryptoValue: string;
    cryptoSymbol: string;
    cryptoPrices: any;
    loading: boolean;
}
const initialState: data = {
    cryptoData: {},
    cryptoValue: "",
    cryptoSymbol: '',
    cryptoPrices: {},
    loading: true,
};
const cryptoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.SET_CONVERTED_VALUE: //dont use
            return {
                ...state,
                cryptoData: action.payload,
                cryptoSymbol: 'BTC',
                cryptoValue: action.payload["BTC"] * action.value,
            };
        case type.SET_CONVERTED_PRICES:
            return {
                ...state,
                cryptoPrices: action.payload,
                loading: false,
            };
        case type.CONVERT_CURRENCY:
            return {
                ...state,
                cryptoSymbol: action.crypto,
                // cryptoValue: state.cryptoData[action.crypto] * action.total,
                cryptoValue: action.total / state.cryptoPrices[action.crypto]?.[action.currency],
            };
        default:
            return state;
    }
};

export default cryptoReducer;
