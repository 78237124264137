import React, { useState, useRef, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/styles/withStyles";
import { THEME_COLOR } from "../../../../constants/colors";

import Lock from "./../../../../Assets/icons/checkout-lock.svg";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addComma } from "../../../../constants/addCommaFunction";
import VerifyModal  from "../../../../components/modals/verify-modal/verifyModal"; 
import { openVerifyModal } from "./../../../../redux/actions/headerActions";
import { UserImageState } from "../../../../interfaces/headerInterface";
import mautic from 'mautic-tracking';

const CustomSlider = withStyles({
  thumb: {
    backgroundColor: THEME_COLOR,
    width: 30,
    height: 30,
    marginTop: "-9px",
    position: "relative",
    "&:after": {
      content: " ",
      backgroundColor: "#fff",
      borderRadius: "50%",
      width: 14,
      height: 14,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  track: {
    backgroundColor: THEME_COLOR,
    height: 13,
  },
  rail: {
    height: 13,
    backgroundColor: "#C4BFC1",
  },
})(Slider);

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FAEDED",
    color: "#000",
    maxWidth: 384,
    fontSize: 15,
    border: "1px solid #F36969",
    fontFamily: "Montserrat",
    fontWeight: 400,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: '6px 10px'
  },
  arrow: {
    fontSize: 24,
    width: 24,
    "&::before": {
      border: "1px solid #F36969",
      backgroundColor: "#FAEDED",
      boxSizing: "border-box"
    }
  }
})(Tooltip)

function AssetCheckout(props: { toggleCheckout: () => void, checkout: boolean }) {

  const { toggleCheckout } = props;

  const history = useHistory()
  const dispatch = useDispatch();

  const assetInfo = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
  const userData = useSelector((state: UserImageState) => state.headerReducer);

  const inputRef = useRef<HTMLInputElement>(null)

  const [minimumTooltip, setMinimumTooltip] = useState(false)

  const [sliderValue, setSliderValue] = useState(assetInfo.minAmount)

  const sliderHandler = (event: any, value: number | number[]) => {
    setSliderValue(Number(value))
  }

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof +e.target.value === "number"
      && !isNaN(+e.target.value)
      && +e.target.value < assetInfo.realMaxAmount) {
      setSliderValue(+e.target.value)
    }
  }

  useEffect(() => {
    if (sliderValue < assetInfo.minAmount) {
      setMinimumTooltip(true)
    } else setMinimumTooltip(false)
  }, [sliderValue, assetInfo.minAmount])

  const checkoutHandler = () => {
    if (sliderValue < assetInfo.minAmount){
      return;
    }
    if (userData.role === 2) {
      dispatch(openVerifyModal());
      return;
    }
    if (!localStorage.getItem('token')){
      sessionStorage.setItem('fromAsset',`/checkout/overview/${assetInfo.id}/${sliderValue}`);
      history.push(`/login?toCheckout=true&toAsset=${assetInfo.id}&tokens=${sliderValue}`)
      return
    }
    mautic.pageView({ tags: 'checkout'});
    history.push(`/checkout/overview/${assetInfo.id}/${sliderValue}`)
  }

  return (
    <div className="asset-checkout-wrapper">
      <div className="asset-checkout">
        <div className="top">
          <div className="title">How many digital shares are you buying?</div>
          <div className="tokens">
            <CustomTooltip
              arrow
              open={minimumTooltip}
              placement="top-start"
              title={
                <div className="minimum-tooltip">
                  Minimum Required {assetInfo.minAmount}
                </div>

              }>
              <input className={`amount ${minimumTooltip ? 'red' : ''}`} 
                value={sliderValue}
                ref={inputRef}
                onChange={inputHandler} />
            </CustomTooltip>
            <div className="type">frOGI Tokens</div>
          </div>
          <div className="slider">
            <CustomSlider
              step={5000}
              min={assetInfo.minAmount}
              max={assetInfo.realMaxAmount}
              value={sliderValue}
              onChange={sliderHandler} />
            <div className="remain">{addComma(String(assetInfo.realMaxAmount))} frOGI tokens remaining</div>
          </div>
        </div>
        <div className="bottom">
          <div className="total">
            Total:
            <span className="amount">${addComma(String(sliderValue))}</span>
          </div>
          <div className="checkout">
            <div className="back" onClick={toggleCheckout}>{"< "} Back</div>
            <div className="begin" onClick={checkoutHandler}>
              <img src={Lock} alt="" />
              Begin Checkout
            </div>
          </div>
        </div>
      </div>
      <VerifyModal />
    </div>
  );
}

export default AssetCheckout;
