import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CheckoutConfirmation } from '..';
import CheckoutOverview from './checkout-overview/checkoutOverview';
import CheckoutPayment from './checkout-payment/checkoutPayment';

function CheckoutPages() {
    return (
        <Switch>
            <Route component={CheckoutOverview} path="/checkout/overview/:id?/:tokens?" />    
            <Route component={CheckoutPayment} path="/checkout/payment/:id?/:tokens?" />    
            <Route component={CheckoutConfirmation} path="/checkout/confirmation/:id?/:tokens?" />    
        </Switch>
    )
}

export default CheckoutPages;