import { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/styles/makeStyles";
// import Tooltip from '@mui/material/Tooltip';

import Team1 from "./../../../Assets/new-landing/team/1.png";
import Team2 from "./../../../Assets/new-landing/team/2.png";
import Team3 from "./../../../Assets/new-landing/team/10.png";
import Team4 from "./../../../Assets/new-landing/team/4.png";
import Team5 from "./../../../Assets/new-landing/team/12.png";
import Team6 from "./../../../Assets/new-landing/team/6.png";
import Team7 from "./../../../Assets/new-landing/team/14.png";
import Team8 from "./../../../Assets/new-landing/team/15.png";
import Team9 from "./../../../Assets/new-landing/team/16.png";
import Team10 from "./../../../Assets/new-landing/team/17.png";
import Team12 from "./../../../Assets/new-landing/team/19.png";
import Team14 from "./../../../Assets/new-landing/team/gavi.png";
import Team15 from "./../../../Assets/new-landing/team/irena.jpg";
import Team16 from "./../../../Assets/new-landing/team/20.jpg";
import President from "./../../../Assets/new-landing/team/president.jpg";
import aboutImg from "./../../../Assets/new-landing/team/aboutImg.png";
import Tasha from "./../../../Assets/new-landing/team/Tasha.png";
import Dante from "./../../../Assets/new-landing/team/Dante.png";
import Lydia from "./../../../Assets/new-landing/team/Lydia.png";


const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    maxWidth: 384,
    fontSize: 15,
    border: "1px solid #0EC22B",
    fontFamily: "Montserrat",
    fontWeight: 400,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
  arrow: {
    fontSize: 24,
    width: 24,
    "&::before": {
      border: "1px solid #0EC22B",
      backgroundColor: "#fff",
      boxSizing: "border-box",
    },
  },
});

function AboutTeam() {
  const [bioOpen, setBioOpen] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    12: false,
    13:false,
    14: false,
    15:false,
    16: false,
    17: false,
    18: false,
    19: false,
  });
  const [bioOpen1, setBioOpen1] = useState(false);
  const [bioOpen2, setBioOpen2] = useState(false);
  const [bioOpen3, setBioOpen3] = useState(false);
  const [bioOpen4, setBioOpen4] = useState(false);
  const [bioOpen5, setBioOpen5] = useState(false);
  const [bioOpen6, setBioOpen6] = useState(false);
  const [bioOpen7, setBioOpen7] = useState(false);
  const [bioOpen8, setBioOpen8] = useState(false);
  const [bioOpen9, setBioOpen9] = useState(false);
  const [bioOpen10, setBioOpen10] = useState(false);
  const [bioOpen12, setBioOpen12] = useState(false);
  const [bioOpen13, setBioOpen13] = useState(false);
  const [bioOpen14, setBioOpen14] = useState(false);
  const [bioOpen15, setBioOpen15] = useState(false);
  const [bioOpen16, setBioOpen16] = useState(false);
  const [bioOpen17, setBioOpen17] = useState(false);
  const [bioOpen18, setBioOpen18] = useState(false);
  const [bioOpen19, setBioOpen19] = useState(false);

  const classes = useStyles();

  return (
    <div className="about-team">

      <div className="container">
        <div className="head">
          <img src={aboutImg} alt="real estate cryptocurrency coins" className="about-image" />
          <div className="about-container">
            <h1 className="title about">About SolidBlock Digital Platform </h1>
            <div className="title about about-sub" >
              Our platform transforms the world of real estate by bridging the gap between traditional markets and the decentralized economy.         </div>
            <div className="descr about">
              SolidBlock is an end-to-end platform that allows real estate developers and fund managers to leverage blockchain technology for peer-to-peer transactions that bypass traditional banking structure for secure, quick, and paperless trading.
            </div>
          </div>
        </div>
        <div className="line"></div>
        <h2 className="title">Executive Team</h2>
        <div className="descr" >
          Our comprehensive understanding of the development process and the <br/>
          benefits of a digital marketplace is unmatched in the industry.
        </div>

        <div className="team-cards">
        <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <>
                <div className="team-descr">
                  Yael works to make property investing accessible, transparent
                  and streamlined with SolidBlock. Prior to that, Yael has had
                  over a decade of experience as a marketing executive within
                  the fintech, finance, telecom and energy industries, as well
                  as a long track record as an entrepreneur. Yael also co-chairs
                  the Israel region of the FIBREE.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen2}
            disableTouchListener
            onOpen={() => setBioOpen2(true)}
            onClose={() => setBioOpen2(false)}
          >
            <div className="team-item" onClick={() => setBioOpen2(!bioOpen2)}>
              <div className="team-img">
                <img src={Team2} alt="YAEL TAMAR" />
              </div>
              <div className="name">Yael Tamar</div>
              <div className="job">CEO Co-Founder</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 2: !bioOpen[2] })}
              >
                {bioOpen[2] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[2] ? "shown" : ""}`}>
                Yael works to make property investing accessible, transparent
                and streamlined with SolidBlock. Prior to that, Yael has had
                over a decade of experience as a marketing executive within the
                fintech, finance, telecom and energy industries, as well as a
                long track record as an entrepreneur. Yael also co-chairs the
                Israel region of the <a href="https://fibree.org/">FIBREE</a>.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <>
                <div className="team-descr">
                  In my career, I have led marketing for a $40B Global PE firm and a $5B public telecom company and I have served as an MD at a venture firm and Partner (and Investment committee) at a PE firm.
                  I have been a part of billions of institutional capital raises and advised dozens of venture and PE firms on their capital formation and go to market strategy. I have advised literally 100’s of 
                  companies on strategy and how to scale (which is the most fun by far) by growth, acquisitions and partnerships.  
                  I have watched the blockchain industry as an investor who was early in Coinbase and Bitcoin. I recently built and exited an impact asset management firm.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen13}
            disableTouchListener
            onOpen={() => setBioOpen13(true)}
            onClose={() => setBioOpen13(false)}
          >
            <div className="team-item" onClick={() => setBioOpen13(!bioOpen13)}>
              <div className="team-img">
                <img src={President} alt="Laura Brightsen" />
              </div>
              <div className="name">Laura Brightsen</div>
              <div className="job">President</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 13: !bioOpen[13] })}
              >
                {bioOpen[13] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[13] ? "shown" : ""}`}>
                In my career, I have led marketing for a $40B Global PE firm and a $5B public telecom company and I have served as an MD at a venture firm and Partner (and Investment committee) at a PE firm.
                I have been a part of billions of institutional capital raises and advised dozens of venture and PE firms on their capital formation and go to market strategy. I have advised literally 100’s of 
                companies on strategy and how to scale (which is the most fun by far) by growth, acquisitions and partnerships.  
                I have watched the blockchain industry as an investor who was early in Coinbase and Bitcoin. I recently built and exited an impact asset management firm.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <div className="team-descr">
                Yuval brings more than 15 years of technology and product
                management experience to SolidBlock. He’s helped both startups
                and large enterprises bring new media and marketing plans to
                life. Yuval’s vision for{" "}
                <span className="text__uppercase">SolidBlock</span> is to
                democratize private equity, venture capital, and real estate
                investing.
              </div>
            }
            open={bioOpen1}
            disableTouchListener
            onOpen={() => setBioOpen1(true)}
            onClose={() => setBioOpen1(false)}
          >
            <div className="team-item" onClick={() => setBioOpen1(!bioOpen1)}>
              <div className="team-img">
                <img src={Team1} alt="Yuval Wirzberger" />
              </div>
              <div className="name">Yuval Wirzberger</div>
              <div className="job">CIO&Co Founder</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 1: !bioOpen[1] })}
              >
                {bioOpen[1] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[1] ? "shown" : ""}`}>
                Yuval brings more than 15 years of technology and product
                management experience to SolidBlock. He’s helped both startups
                and large enterprises bring new media and marketing plans to
                life. Yuval’s vision for SolidBlock is to democratize private
                equity, venture capital, and real estate investing.
              </div>
            </div>
          </Tooltip>
          
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <>
                <div className="team-descr">
                  Ever since I was a child, I’ve loved to build things. This led me to
                  study computer science at Hebrew University and found 200apps, a
                  boutique software studio. I’ve led dozens
                  of technical projects including the establishment of Israel’s first
                  ever drone show company. Now, in a move towards blockchain technology,
                  I am helping to build the future of tokenized real estate.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen3}
            disableTouchListener
            onOpen={() => setBioOpen3(true)}
            onClose={() => setBioOpen3(false)}
          >
            <div className="team-item" onClick={() => setBioOpen3(!bioOpen3)}>
              <div className="team-img">
                <img src={Team3} alt="Dan Bystritsky" />
              </div>
              <div className="name">Dan Bystritsky</div>
              <div className="job">CTO</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 3: !bioOpen[3] })}
              >
                {bioOpen[3] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[3] ? "shown" : ""}`}>
                Ever since I was a child, I’ve loved to build things. This led me to
                study computer science at Hebrew University and found 200apps, a
                boutique software studio. I’ve led dozens
                of technical projects including the establishment of Israel’s first
                ever drone show company. Now, in a move towards blockchain technology,
                I am helping to build the future of tokenized real estate.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <>
                <div className="team-descr">
                  Moran serves as SolidBlock’s Chief Operations Officer (COO)
                  executing strategy through oversight and management of the
                  company’s departments including marketing, tech, legal
                  business development, project management and human resources.
                  Drawing on more than a decade of experience as an entrepreneur
                  and project manager for lifestyle startups, she brings with
                  her a diverse skill set with proficiencies in strategy
                  building and implementation, software, applications, digital
                  marketing platforms and management.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen4}
            disableTouchListener
            onOpen={() => setBioOpen4(true)}
            onClose={() => setBioOpen4(false)}
          >
            <div className="team-item" onClick={() => setBioOpen4(!bioOpen4)}>
              <div className="team-img">
                <img src={Team4} alt="Moran Hertzanu Weiss" />
              </div>
              <div className="name">Moran Hertzanu Weiss</div>
              <div className="job">Director of Operations</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 4: !bioOpen[4] })}
              >
                {bioOpen[4] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[4] ? "shown" : ""}`}>
                Moran serves as SolidBlock’s Chief Operations Officer (COO)
                executing strategy through oversight and management of the
                company’s departments including marketing, tech, legal business
                development, project management and human resources. Drawing on
                more than a decade of experience as an entrepreneur and project
                manager for lifestyle startups, she brings with her a diverse
                skill set with proficiencies in strategy building and
                implementation, software, applications, digital marketing
                platforms and management.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  With more than 10 years working with startups, agencies and Fortune 200 
                  companies on marketing, product and business development, Gavi joins SolidBlock 
                  as Head of Marketing and Business Development. Gavi has worked in a variety of 
                  industries including electronic cigarettes, nutritional supplements, e-commerce, 
                  non-profits, and agencies. He has personally managed $1 million+ in profitable FB 
                  Ad spends, and has a deep passion for crypto and blockchain and how they are changing the world.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen14}
            disableTouchListener
            onOpen={() => setBioOpen14(true)}
            onClose={() => setBioOpen14(false)}
          >
            <div className="team-item" onClick={() => setBioOpen14(!bioOpen14)}>
              <div className="team-img">
                <img src={Team14} alt="Gavi Zietlin" />
              </div>
              <div className="name">Gavi Zeitlin</div>
              <div className="job">Head of Marketing</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 14: !bioOpen[14] })}
              >
                {bioOpen[14] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[14] ? "shown" : ""}`}>
              With more than 10 years working with startups, agencies and Fortune 200 
              companies on marketing, product and business development, Gavi joins SolidBlock 
              as Head of Marketing and Business Development. Gavi has worked in a variety of 
              industries including electronic cigarettes, nutritional supplements, e-commerce, 
              non-profits, and agencies. He has personally managed $1 million+ in profitable FB 
              Ad spends, and has a deep passion for crypto and blockchain and how they are changing the world.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            enterTouchDelay={100}
            title={
              <>
                <div className="team-descr">
                  Helena holds a master’s degree in Public Law from Sao Paulo University
                  and worked as a public defender in Brazil before moving to Israel.
                  In Tel Aviv, Helena entered the crypto-startup ecosystem,
                  focusing her skills in management, business development, marketing,
                  and communications. She is passionate about empowering people through
                  new technologies, particularly with regard to gender equality issues.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen5}
            disableTouchListener
            onOpen={() => setBioOpen5(true)}
            onClose={() => setBioOpen5(false)}
          >
            <div className="team-item" onClick={() => setBioOpen5(!bioOpen5)}>
              <div className="team-img">
                <img src={Team5} alt="Helena Henkin" />
              </div>
              <div className="name">Helena Henkin</div>
              <div className="job">LATAM business manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 5: !bioOpen[5] })}
              >
                {bioOpen[5] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[5] ? "shown" : ""}`}>
                Helena holds a master’s degree in Public Law from Sao Paulo University
                and worked as a public defender in Brazil before moving to Israel.
                In Tel Aviv, Helena entered the crypto-startup ecosystem,
                focusing her skills in management, business development, marketing,
                and communications. She is passionate about empowering people through
                new technologies, particularly with regard to gender equality issues.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  With a degree in business management and accountancy, Yotam joins SolidBlock as the CFO. Previously, 
                  Yotam owned and managed several businesses in the London property market and guided an IPO for a financial 
                  firm on the Israeli bursa. A father of four, Yotam divides his time between London and Israel, and is part 
                  owner of a chain of street food operations in London.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen16}
            disableTouchListener
            onOpen={() => setBioOpen16(true)}
            onClose={() => setBioOpen16(false)}
          >
            <div className="team-item" onClick={() => setBioOpen16(!bioOpen16)}>
              <div className="team-img">
                <img src={Team16} alt="Irena Zaslavsky" />
              </div>
              <div className="name">Yotam Yinhal</div>
              <div className="job">CFO</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 16: !bioOpen[16] })}
              >
                {bioOpen[16] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[16] ? "shown" : ""}`}>
                With a degree in business management and accountancy, Yotam joins SolidBlock as the CFO. Previously, 
                Yotam owned and managed several businesses in the London property market and guided an IPO for a financial 
                firm on the Israeli bursa. A father of four, Yotam divides his time between London and Israel, and is part 
                owner of a chain of street food operations in London.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  After experiencing many different fields, like retail, production of events and political
                  campaign, teaching languages (English, Italian and Portuguese for foreigners)
                  volunteering in different projects for animal rights (Vanessa is a passionate vegan activist and advocate),
                  she's joining the world of real estate tokenization and blockchain technology at Solid Block.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen6}
            disableTouchListener
            onOpen={() => setBioOpen6(true)}
            onClose={() => setBioOpen6(false)}
          >
            <div className="team-item" onClick={() => setBioOpen6(!bioOpen6)}>
              <div className="team-img">
                <img src={Team6} alt="Vanessa Lodispoto Zohar" />
              </div>
              <div className="name">Vanessa Lodispoto Zohar</div>
              <div className="job">Community Manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 6: !bioOpen[6] })}
              >
                {bioOpen[6] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[6] ? "shown" : ""}`}>
                After experiencing many different fields, like retail,
                production of events and political campaign, teaching
                languages (English, Italian and Portuguese for foreigners)
                volunteering in different projects for animal rights
                (Vanessa is a passionate vegan activist and advocate),
                she's joining the world of real estate tokenization and blockchain technology at Solid Block.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Rachel Idelevich Is an Israeli attorney, litigator and legal advisor, contract manager, advocacy worker and educator
                  with over 15 years of professional experience in the private, public, and academic sectors in Israel.
                  She has been practicing law in the areas such as employment and immigration, class actions, contract law,
                  NGO compliance, health law and human rights. Rachel represented and counseled various commercial companies and civil
                  society organizations, served as a legal advisor and legal manager for several law firms and NGOs.
                  Attorney Idelevich received her Master of Law degree in 2009 on a full scholarship from Washington
                  College of Law, American University (International Legal Studies Program) and served as a clinical
                  instructor at Buchmann Faculty of Law, Tel Aviv University, and as an academic assistant at Peres Academic Center in Israel.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen7}
            disableTouchListener
            onOpen={() => setBioOpen7(true)}
            onClose={() => setBioOpen7(false)}
          >
            <div className="team-item" onClick={() => setBioOpen7(!bioOpen7)}>
              <div className="team-img">
                <img src={Team7} alt="Rachel Idelevich" />
              </div>
              <div className="name">Rachel Idelevich</div>
              <div className="job">HR and Legal Manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 7: !bioOpen[7] })}
              >
                {bioOpen[7] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[7] ? "shown" : ""}`}>
                Rachel Idelevich Is an Israeli attorney, litigator and legal advisor, contract manager, advocacy worker and educator
                with over 15 years of professional experience in the private, public, and academic sectors in Israel.
                She has been practicing law in the areas such as employment and immigration, class actions, contract law,
                NGO compliance, health law and human rights. Rachel represented and counseled various commercial companies and civil
                society organizations, served as a legal advisor and legal manager for several law firms and NGOs.
                Attorney Idelevich received her Master of Law degree in 2009 on a full scholarship from Washington
                College of Law, American University (International Legal Studies Program) and served as a clinical
                instructor at Buchmann Faculty of Law, Tel Aviv University, and as an academic assistant at Peres Academic Center in Israel.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Coming from a varied background combining the artistic, digital, marketing,
                  and historical worlds, Rachael has acquired a unique understanding of what
                  people desire and how to bring it to them. From blogging, social media, social media marketing,
                  community management and engagement, digital media, user behavior, content
                  creation and management, she joins the Solidblock team to promote and enhance user engagement.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen8}
            disableTouchListener
            onOpen={() => setBioOpen8(true)}
            onClose={() => setBioOpen8(false)}
          >
            <div className="team-item" onClick={() => setBioOpen8(!bioOpen8)}>
              <div className="team-img">
                <img src={Team8} alt="Rachael  Guth" />
              </div>
              <div className="name">Rachael  Guth</div>
              <div className="job">Customer Success Manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 8: !bioOpen[8] })}
              >
                {bioOpen[8] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[8] ? "shown" : ""}`}>
                Coming from a varied background combining the artistic, digital, marketing,
                and historical worlds, Rachael has acquired a unique understanding of what
                people desire and how to bring it to them. From blogging, social media, social media marketing,
                community management and engagement, digital media, user behavior, content
                creation and management, she joins the Solidblock team to promote and enhance user engagement.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  15 years of experience in digital and print design. Aaron has led and managed
                  creative teams from ideation to execution. He’s had the privilege of working
                  with Fortune 200 brands and top creative agencies. Aaron is passionate about
                  projects that make a difference.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen9}
            disableTouchListener
            onOpen={() => setBioOpen9(true)}
            onClose={() => setBioOpen9(false)}
          >
            <div className="team-item" onClick={() => setBioOpen9(!bioOpen9)}>
              <div className="team-img">
                <img src={Team9} alt="Aaron Ovadia" />
              </div>
              <div className="name">Aaron Ovadia</div>
              <div className="job">Creative Director</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 9: !bioOpen[9] })}
              >
                {bioOpen[9] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[9] ? "shown" : ""}`}>
                15 years of experience in digital and print design. Aaron has led and managed
                creative teams from ideation to execution. He’s had the privilege of working
                with Fortune 200 brands and top creative agencies. Aaron is passionate about
                projects that make a difference.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Sivan Ivgi is SolidBlock’s Head of Legal and is responsible for the legal affairs of the company.
                  She holds an Executive LL.M., from Tel Aviv University in collaboration with the University of California,
                  Berkeley Boalt Hall, School of Law, and graduated with Honors (Cum Laude), and also holds an L.L.B.,
                  in International Commercial Law (Hons). Prior to joining SolidBlock, Sivan worked as a commercial-tech attorney
                  at a large hi-tech company and at the Global Corporate Department of Teva Pharmaceuticals where she advised the
                  company’s IT, Cyber Security, Digital (eHeath), Government Affairs, Corporate Functions and Global Real-Estate units.
                  Sivan is experienced in complex commercial transactions and also gained familiarity in real estate from her time working
                  at the contracts department of the Israeli Land Administration.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen10}
            disableTouchListener
            onOpen={() => setBioOpen10(true)}
            onClose={() => setBioOpen10(false)}
          >
            <div className="team-item" onClick={() => setBioOpen10(!bioOpen10)}>
              <div className="team-img">
                <img src={Team10} alt="Sivan Ivgi" />
              </div>
              <div className="name">Sivan Ivgi</div>
              <div className="job">Legal Counsel</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 10: !bioOpen[10] })}
              >
                {bioOpen[10] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[10] ? "shown" : ""}`}>
                Sivan Ivgi is SolidBlock’s Head of Legal and is responsible for the legal affairs of the company.
                She holds an Executive LL.M., from Tel Aviv University in collaboration with the University of California,
                Berkeley Boalt Hall, School of Law, and graduated with Honors (Cum Laude), and also holds an L.L.B.,
                in International Commercial Law (Hons). Prior to joining SolidBlock, Sivan worked as a commercial-tech attorney
                at a large hi-tech company and at the Global Corporate Department of Teva Pharmaceuticals where she advised the
                company’s IT, Cyber Security, Digital (eHeath), Government Affairs, Corporate Functions and Global Real-Estate units.
                Sivan is experienced in complex commercial transactions and also gained familiarity in real estate from her time working
                at the contracts department of the Israeli Land Administration.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  An award winning poet and author of The Lost Kitchen: Reflections and Recipes from
                  an Alzheimer’s Caregiver (Black Opal Books, 2019), Miriam transitioned post-COVID
                  into content writing. Prior to that, she worked for 18 years as a counselor helping
                  new immigrants adjust to life in Israel. Miriam’s love of words has served her well.
                  As a young writer she edited two newsletters at the Federal Reserve Board under Chairman
                  Alan Greenspan. Miriam writes a weekly blog at The Lost Kitchen,
                  describing the hardships of caring for a parent with Alzheimer’s and featuring related
                  recipes. She is a 30-year resident of Israel, and a mother of three.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen12}
            disableTouchListener
            onOpen={() => setBioOpen12(true)}
            onClose={() => setBioOpen12(false)}
          >
            <div className="team-item" onClick={() => setBioOpen12(!bioOpen12)}>
              <div className="team-img">
                <img src={Team12} alt="Miriam Green" />
              </div>
              <div className="name">Miriam Green</div>
              <div className="job">Content Manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 12: !bioOpen[12] })}
              >
                {bioOpen[12] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[12] ? "shown" : ""}`}>
                An award winning poet and author of The Lost Kitchen: Reflections and Recipes from
                an Alzheimer’s Caregiver (Black Opal Books, 2019), Miriam transitioned post-COVID
                into content writing. Prior to that, she worked for 18 years as a counselor helping
                new immigrants adjust to life in Israel. Miriam’s love of words has served her well.
                As a young writer she edited two newsletters at the Federal Reserve Board under Chairman
                Alan Greenspan. Miriam writes a weekly blog at The Lost Kitchen,
                describing the hardships of caring for a parent with Alzheimer’s and featuring related
                recipes. She is a 30-year resident of Israel, and a mother of three.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Irena has more than ten years of experience in global partnership and account management. 
                  With a degree in Business Marketing, Irena brings extensive knowledge in online marketing 
                  to her SolidBlock position as Account manager. She has a proven track record of 
                  building long-term relationships with clients and ensuring that value is provided to their 
                  businesses. Born in the Ukraine, Irena speaks Russian, Hebrew, English and Czech.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen15}
            disableTouchListener
            onOpen={() => setBioOpen15(true)}
            onClose={() => setBioOpen15(false)}
          >
            <div className="team-item" onClick={() => setBioOpen15(!bioOpen15)}>
              <div className="team-img">
                <img src={Team15} alt="Irena Zaslavsky" />
              </div>
              <div className="name">Irena Zaslavsky</div>
              <div className="job">Account manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 15: !bioOpen[15] })}
              >
                {bioOpen[15] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[15] ? "shown" : ""}`}>
                Irena has more than ten years of experience in global partnership and account management. 
                With a degree in Business Marketing, Irena brings extensive knowledge in online marketing 
                to her SolidBlock position as Account manager. She has a proven track record of 
                building long-term relationships with clients and ensuring that value is provided to their 
                businesses. Born in the Ukraine, Irena speaks Russian, Hebrew, English and Czech.
              </div>
            </div>
          </Tooltip>

          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Tasha Reshetnikova joins the marketing team as Social Media Strategist. A brand narrative and marketing professional with 
                  a focus on storytelling, Tasha’s major fields of interest are technology, sustainability, education and sports. Her 
                  educational background is in linguistics and filmmaking. She has 10+ years of experience in full-cycle visual content 
                  production and digital marketing. Though she was born in Russia, Tasha lived and studied in New York for three years. 
                  Her languages: Russian, English and a smattering of French and Hebrew. 
                  Look for her mark on our social media platforms, including Facebook, LinkedIn, Twitter, and Instagram.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen17}
            disableTouchListener
            onOpen={() => setBioOpen17(true)}
            onClose={() => setBioOpen17(false)}
          >
            <div className="team-item" onClick={() => setBioOpen17(!bioOpen17)}>
              <div className="team-img">
                <img src={Tasha} alt="Tasha Reshetnikova" />
              </div>
              <div className="name">Tasha Reshetnikova</div>
              <div className="job">Social Media manager</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 17: !bioOpen[17] })}
              >
                {bioOpen[17] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[17] ? "shown" : ""}`}>
                Tasha Reshetnikova joins the marketing team as Social Media Strategist. A brand narrative and marketing professional with 
                a focus on storytelling, Tasha’s major fields of interest are technology, sustainability, education and sports. Her 
                educational background is in linguistics and filmmaking. She has 10+ years of experience in full-cycle visual content 
                production and digital marketing. Though she was born in Russia, Tasha lived and studied in New York for three years. 
                Her languages: Russian, English and a smattering of French and Hebrew. 
                Look for her mark on our social media platforms, including Facebook, LinkedIn, Twitter, and Instagram.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Dante is from Minneapolis, Minnesota and is currently finishing up his final year of university 
                  studying Information Systems and Business Law. He is passionate about all things blockchain and 
                  loves helping young companies grow.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen18}
            disableTouchListener
            onOpen={() => setBioOpen18(true)}
            onClose={() => setBioOpen18(false)}
          >
            <div className="team-item" onClick={() => setBioOpen18(!bioOpen18)}>
              <div className="team-img">
                <img src={Dante} alt="Dante Reminick" />
              </div>
              <div className="name">Dante Reminick</div>
              <div className="job">Business Analyst</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 18: !bioOpen[18] })}
              >
                {bioOpen[18] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[18] ? "shown" : ""}`}>
                Dante is from Minneapolis, Minnesota and is currently finishing up his final year of university 
                studying Information Systems and Business Law. He is passionate about all things blockchain and 
                loves helping young companies grow.
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="top-start"
            title={
              <>
                <div className="team-descr">
                  Lydia Scherr is SolidBlock’s Web3 Research Lead where she has taken the lead in creating The SolidBlock Ecosystem. 
                  An accomplished professional with expertise in technology, business, data science, and global sustainable development. 
                  Lydia is also an award-winning speaker and business leader with an MA from Columbia University. With 10 years of experience in 
                  finance, research and business development. In addition to her work for SolidBlock, Lydia has part ownership in a family insurance 
                  marketing agency with more than 1000 sales agents across the US and more than $2B in premium under management. Lydia is passionate 
                  about using her experience to enhance mass adoption of blockchain technology.
                  <div className="tooltip-corner"></div>
                </div>
              </>
            }
            open={bioOpen19}
            disableTouchListener
            onOpen={() => setBioOpen19(true)}
            onClose={() => setBioOpen19(false)}
          >
            <div className="team-item" onClick={() => setBioOpen19(!bioOpen19)}>
              <div className="team-img">
                <img src={Lydia} alt="Lydia Scherr" />
              </div>
              <div className="name">Lydia Scherr</div>
              <div className="job">Web3 Research Lead</div>
              <div
                className="more"
                onClick={() => setBioOpen({ ...bioOpen, 19: !bioOpen[19] })}
              >
                {bioOpen[19] ? "Hide" : "More info"}
              </div>
              <div className={`more-block ${bioOpen[19] ? "shown" : ""}`}>
                Lydia Scherr is SolidBlock’s Web3 Research Lead where she has taken the lead in creating The SolidBlock Ecosystem. 
                An accomplished professional with expertise in technology, business, data science, and global sustainable development. 
                Lydia is also an award-winning speaker and business leader with an MA from Columbia University. With 10 years of experience in 
                finance, research and business development. In addition to her work for SolidBlock, Lydia has part ownership in a family insurance 
                marketing agency with more than 1000 sales agents across the US and more than $2B in premium under management. Lydia is passionate 
                about using her experience to enhance mass adoption of blockchain technology.
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
export default AboutTeam;
