import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import { AddOfferingStep1, AddOfferingStep2, IssuerAccountStep1, IssuerAccountStep2 } from "..";


const IssuerPages = () => {
    return (
        <Switch>
            <Route
                exact
                component={IssuerAccountStep1}
                path="/issuer/account/1"
            />
            <Route
                exact
                component={IssuerAccountStep2}
                path="/issuer/account/2"
            />
            <Route
                exact
                component={AddOfferingStep1}
                path="/issuer/offering/1"
            />
            <Route
                exact
                component={AddOfferingStep2}
                path="/issuer/offering/2"
            />
            <Redirect from="/issuer" to="/issuer/account/1" exact />
        </Switch>
    );
};

export default IssuerPages;
