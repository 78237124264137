import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import StrategicPartners from '../../components/about-components/strategic-partners/strategicPartners';
import LandingCards from '../../components/landing-components/new-landing/landing-cards/landingCards';
import Awwards from '../../components/landing-components/new-landing/awwards';
import Buzz from '../../components/landing-components/new-landing/buzz/buzz';
import WhySolidblock from '../../components/landing-components/new-landing/why-solidblock/whySolidblock';
import RaiseCapital from '../../components/landing-components/new-landing/raise-capital/raiseCapital';
import Explained from '../../components/landing-components/new-landing/explained/explained';
import LandingHero from '../../components/landing-components/new-landing/landing-hero/landingHero';
import NewLandingNav from "../../components/headers-components/new-landing-nav/newLandingNav";
import mautic from 'mautic-tracking';
import TokenizationCourse from '../../components/landing-components/new-landing/tokenizationCourse';
import Podcast from '../../components/landing-components/new-landing/podcast';
import Blog from '../../components/landing-components/new-landing/blog';
import {Helmet} from "react-helmet";

function LandingPage() {
    useEffect(() => {
        Aos.init({
            once: true,
        });
        mautic.pageView({});
    }, [])
    
    return (
        <div className="new-landing">
            <Helmet>
                <title>Distributed investment banking - DIBS Capital</title>
                <meta name="description" content="DIBS Capital is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities."></meta>
                <meta name="Keywords" content="Distributed investment banking"></meta>
            </Helmet>
            <NewLandingNav />
            <LandingHero />
            <LandingCards />
            <Explained />
            <Buzz />
            <RaiseCapital />
            <Awwards/>
            <WhySolidblock />
            <Podcast />
            <TokenizationCourse />
            <Blog />
            {/* <Companies /> */}
            <StrategicPartners />
        </div>
    );
}

export default LandingPage;