import React from "react";

export default function CustomizedMenus() {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <div className="dropDownMenu">
      <ul className="topmenu" id="topmenuId">
        <li><span className="drop-controller">Asset Listing</span>
          <ul className="submenu">
            <li><a href={`${baseUrl}/tokenization-form`}>Tokenize your Asset</a></li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
