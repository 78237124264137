import { useDispatch, useSelector } from "react-redux";
import { getMainAssetInfo } from "../../../redux/actions/assetsAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import { AssetDataInterface } from "../../../interfaces/assetsInterface";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { LDB_ID } from "../../../constants/assetsId";
import AssetMap from "../../../components/assets/asset-main-view/asset-map/assetMap";
import AdvanceInfo from "../../../components/assets/ldb/advance-info/advanceItemInfo";
import AdvanceProjection from "../../../components/assets/ldb/advance-projection/advanceProjection";
import AdvanceContent from "../../../components/assets/ldb/advance-content/advanceItemContent";
import AdvanceHighlights from "../../../components/assets/ldb/advance-highlight/advanceHighlight";
import AssetDocuments from "../../../components/assets/asset-main-view/asset-documents/assetDocuments";
import AdvanceInvestmentDetails from "../../../components/assets/ldb/advance-investment-details/advanceInvestmentDetails";
// import AdvanceInvestorPerks from "../../../components/assets/ldb/advance-investor-perks/advance-investor-perks";
import AssetFullAccess from "../../../components/assets/ldb/asset-full-access/assetFullAccess";
import mautic from 'mautic-tracking';
import Spinner from "../../../components/spinner/spinner";

const LdbPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<MainAssetPageParams>();
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  useEffect(() => {
    if (!info.alias || info.id !== LDB_ID)
      dispatch(getMainAssetInfo(`${LDB_ID}`));
      
  }, [dispatch, id, info.alias, info.id]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  if(info.isLoading) return <Spinner />

  return (
    <div className="redfrog-page">
      <div className="asset-top">
        <div className="container">
          <AdvanceInfo />
        </div>
      </div>
      <div className="container">
        <div className="content">
          <div className="left">
            <AdvanceHighlights />
            <AdvanceContent />
            <AssetMap />
            <div className="mobile-blocks">
              <div className="divider"></div>
              {/* <AdvanceInvestorPerks /> */}
              {/* <AssetDocuments /> */}
            </div>
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
          <div className="right">
            <AdvanceInvestmentDetails />
            {localStorage.getItem("token") ? (
              <>
                <AdvanceProjection />
                <AssetDocuments />
              </>
            ) : (
              <AssetFullAccess />
            )}
            {/* <AdvanceInvestorPerks /> */}
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LdbPage;
