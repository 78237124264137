import * as type from "../../constants/types";

const initialState = {
    documents: [],
};
const foldersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_FOLDERS:
            return {
                ...state,
                documents: action.assetData,
            };
        default:
            return state;
    }
};

export default foldersReducer;
