import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addComma } from "../../constants/addCommaFunction";
import { 
  CheckoutInterface, 
  // PaymentsInterface 
} from "../../interfaces/assetsInterface";
import { getPDF } from "../../redux/actions/checkoutActions";
import ClipboardCopy from "../checkout/clipboardCopy";

function CheckoutWire() {
  // const paymentsInfo = useSelector(
  //   (state: PaymentsInterface) => state.paymentsReducer
  // );

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  const dispatch = useDispatch();

  const _isRedFrog = checkoutInfo.alias === "frOGI";

  const _isDetox = checkoutInfo.alias === "DDC";

  // if (!paymentsInfo.ncWirePayment) return null;

  const downloadPdfClick = () => {
    if (_isDetox || _isRedFrog){
      dispatch(getPDF([
        {
            "name": "WIRE TO",
            "value": "SOLIDBLOCK HOLDINGS LLC, BANK OF AMERICA"
        },
        {
            "name": "ABA (ROUTING NUMBER)",
            "value": "053000196"
        },
        {
          "name": "ACCOUNT NUMBER",
          "value": "2370 4659 3904"
        },
        {
          "name": "ADDRESS",
          "value": "500B GRAND STREET, NEW YORK, NY 10002"
        },
        {
          "name": "FFCT",
          "value": "OFFERING NAME AND INVESTOR NAME"
        },
      ]))
    } else {
      dispatch(getPDF([
        {
            "name": "WIRE TO",
            "value": "TRISTATE CAPITAL BANKONE OXFORD CENTRE, SUITE 2700301 GRANT STREETPITTSBURGH, PA 15219"
        },
        {
            "name": "ABA (ROUTING NUMBER)",
            "value": "0 43019 0 03"
        },
        {
          "name": "ACCOUNT NUMBER",
          "value": "0220003339"
        },
        {
          "name": "CUSTODIAN/ACCOUNT NAME",
          "value": "NORTH CAPITAL PRIVATE SECURITIES623 E FORT UNION BLVD, SUITE 101MIDVALE, UT 84047"
        },
        {
          "name": "FFCT",
          "value": "OFFERING NAME AND INVESTOR NAME"
        },
        {
          "name": "SWIFT ID",
          "value": "TRT TUS33"
        },
      ]))
    }
  }

  return (
    <div className="checkout-wire">
      <div className="title">
        Your tokens are reserved!
      </div>
      <div className="descr">
        In order to finailze this purchase, you must transfer funds to the following Escrow account:
      </div>
      <div className="fields">
        <div className="field-item">
          <div className="title">WIRE TO:</div>
          <div className="info">
            {_isDetox || _isRedFrog ? "SOLIDBLOCK HOLDINGS LLC, BANK OF AMERICA" : "TRISTATE CAPITAL BANKONE OXFORD CENTRE, SUITE 2700301 GRANT STREETPITTSBURGH, PA 15219"}
            <ClipboardCopy text={_isDetox || _isRedFrog ? "SOLIDBLOCK HOLDINGS LLC, BANK OF AMERICA" : "TRISTATE CAPITAL BANKONE OXFORD CENTRE, SUITE 2700301 GRANT STREETPITTSBURGH, PA 15219"}/>
          </div>
        </div>
        <div className="field-item">
          <div className="title">ABA (ROUTING NUMBER):</div>
          <div className="info">
            { _isDetox || _isRedFrog ? "053000196" : "0 43019 0 03"}
            <ClipboardCopy text={ _isDetox || _isRedFrog ? "053000196" : "0 43019 0 03"}/>
          </div>
        </div>
        <div className="field-item">
          <div className="title">ACCOUNT NUMBER: </div>
          <div className="info">
            { _isDetox || _isRedFrog ? "2370 4659 3904" : "0220003339"}
            <ClipboardCopy text={ _isDetox || _isRedFrog ? "2370 4659 3904" : "0220003339"}/>
          </div>
        </div>
        {_isDetox || _isRedFrog ? null :
        <div className="field-item">
          <div className="title">CUSTODIAN/ACCOUNT NAME:</div>
          <div className="info">
            NORTH CAPITAL PRIVATE SECURITIES623 E FORT UNION BLVD, SUITE 101MIDVALE, UT 84047
            <ClipboardCopy text="NORTH CAPITAL PRIVATE SECURITIES623 E FORT UNION BLVD, SUITE 101MIDVALE, UT 84047"/>
          </div>
        </div>}
        {_isDetox || _isRedFrog ? <div className="field-item">
          <div className="title">ADDRESS:</div>
          <div className="info">
            500B Grand Street, New York, NY 10002 
            <ClipboardCopy text="500B Grand Street, New York, NY 10002"/>
          </div>
        </div> : null
        }
        <div className="field-item">
          <div className="title">FFCT:</div>
          <div className="info">
            OFFERING NAME AND INVESTOR NAME
            <ClipboardCopy text="OFFERING NAME AND INVESTOR NAME"/>
          </div>
        </div>
        {_isDetox || _isRedFrog ? null :
        <div className="field-item">
          <div className="title">SWIFT ID:</div>
          <div className="info">
            TRT TUS33
            <ClipboardCopy text="TRT TUS33"/>
          </div>
        </div>}
        <div className="field-item">
          <div className="title"></div>
          <div className="info">
            <button onClick={downloadPdfClick} className="downloadPdfBtn">
              Download PDF
            </button>
          </div>
        </div>
      </div>
      <div className="title">
        What to expect next:
      </div>
      <div className="descr">
        <ul>
          <li>You will receive an email from SolidBlock confirming the payment.</li>
          <li>Your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} token allocation will appear in your account.</li>
          <li>This allocation will remain in reserved status until the project is funded. </li>
          <li>Once the project has been funded, your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} tokens will officially be distributed to your account.</li>
        </ul>
      </div>
      <div className="descr">
        View your <Link to="/my-assets" className="link">Assets Page</Link> or <Link to="/transaction-history" className="link">Transaction History</Link> to see the status of your order.
      </div>
    </div>
  );
}

export default CheckoutWire;
