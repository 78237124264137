import Secure from "./../../Assets/icons/secure.svg";

export default function SecureCheckout() {
  return (
    <div className="secure-checkout">
      <img src={Secure} alt="" />
      Secure Checkout
    </div>
  );
}
