import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";
import { THEME_COLOR } from "../../../constants/colors";
import { LabelInputProps } from "../../../interfaces/profileInterface";

const useStyles = makeStyles({
  root: {
    "& .MuiInput-input": {
      fontFamily: "Poppins, sans-serif",
      fontSize: 14,
      fontWeight: 500,
      color: "#1B1A21",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: THEME_COLOR,
    },
    "& label": {
      fontSize: 14,
      fontFamily: "Poppins",
    },
    "& .Mui-focused": {
      color: `${THEME_COLOR}  !important`,
    },
  },
  warning: {
    "& .MuiInput-input": {
      color: "#D80027",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#D80027",
    },
  },
});

function LabelInput(props: LabelInputProps) {
  const classes = useStyles();

  const { value, id, name, handler, label, disabled, maxLength, warning } =
    props;

  return (
    <>
      <TextField
        id={id}
        disabled={disabled}
        value={value}
        label={label}
        name={name}
        onChange={handler}
        className={`form-field ${classes.root} ${
          warning ? classes.warning : ""
        }`}
        inputProps={{
          maxLength: maxLength,
        }}
      />
    </>
  );
}

export default LabelInput;
