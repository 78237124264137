interface CodesInterface {
    [key: string]: string;
}
export const CRYPTO_CODES: CodesInterface = {
    ETH: "0x911Df6d3710376A72B7273F39f12e6f8584d47B0",
    BTC: "13ofF6PmMBRRru4Zzc1chgwcBsjPLuJg6K",
    DASH: "XbJJRmxBY8prY9BnUxF4YM4YiinND5ogtJ",
    DAI: "0x911Df6d3710376A72B7273F39f12e6f8584d47B0",
    USDC: "0x911Df6d3710376A72B7273F39f12e6f8584d47B0",
};

export const CRYPTO_CODES_LDB: CodesInterface = {
    ETH: "0x55fa004bFc5babA2cada47fd3FdA8E275cad0D95",
    BTC: "3EGyeWw4sPhTXmX335zHF6Tuuhd1xMUBvH",
    DASH: "XbJJRmxBY8prY9BnUxF4YM4YiinND5ogtJ",
    DAI: "0x55fa004bFc5babA2cada47fd3FdA8E275cad0D95",
    USDC: "0x55fa004bFc5babA2cada47fd3FdA8E275cad0D95",
};
export const CRYPTO_CODES_RFP: CodesInterface = {
    ETH: "0xc6f04CA4ce750cBb6aeb26b33ab0C6a72b9e85D8",
    BTC: "39gJP2No7sDdyNSx7oDTX8Vjw8VSnhtQFM",
    DASH: "XbJJRmxBY8prY9BnUxF4YM4YiinND5ogtJ",
    DAI: "0xc6f04CA4ce750cBb6aeb26b33ab0C6a72b9e85D8",
    USDC: "0xc6f04CA4ce750cBb6aeb26b33ab0C6a72b9e85D8",
};
export const CRYPTO_CODES_DTX: CodesInterface = {
    ETH: "0x3602c46138bf17085630ced3BBc356be7Abc3B1B",
    BTC: "3LXB9e49FCrmnb1B6vR5681iHnSj8qY2LV",
    DASH: "XbJJRmxBY8prY9BnUxF4YM4YiinND5ogtJ",
    DAI: "0x3602c46138bf17085630ced3BBc356be7Abc3B1B",
    USDC: "0x3602c46138bf17085630ced3BBc356be7Abc3B1B",
};

