import * as types from './../../constants/types'

const initialState = {
    documents: [],
    loading: false,
    error: false
}

const documentsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case types.GET_USER_DOCUMENTS_REQ:
            return {
                ...state,
                loading: true
            }
        case types.GET_USER_DOCUMENTS_SUC:
            return {
                documents: action.payload,
                loading: false,
                error: false
            }
        case types.GET_USER_DOCUMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        default: return state
    }
}

export default documentsReducer;