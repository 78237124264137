
import { useSelector } from "react-redux";

const InfoSection = () => {

    const accountInfo = useSelector((state: any) => state.createAccountReducer)
    return (
        <div className='info-section'>
            <p className='info-title'>ACCOUNT INFORMATION:</p>
            <div><p className='p1'>Account full name</p> <p className='p2'>{accountInfo.accountFullName}</p></div>
            <div><p className='p1'>Account nickname</p><p className='p2'>{accountInfo.accountNickName}</p></div>
            <div><p className='p1'>Routing number</p><p className='p2'>{accountInfo.routingNumber}</p></div>
            <div><p className='p1'>Account number</p><p className='p2'>{accountInfo.accountNumber}</p></div>
        </div>
    )
}

export default InfoSection