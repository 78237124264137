import { useState, lazy } from "react";
import { useSelector } from "react-redux";
import { FoldersListInterface } from "../../../../interfaces/assetsInterface";

const AssetDocument = lazy(() => import("../asset-document/assetDocument"));
const AssetSummary = lazy(() => import("../asset-summary/assetSummary"));
const AssetDocumentModal = lazy(() => import("../asset-document-modal/assetDocumentModal"));
const AssetFinancialProjection = lazy(() => import("../asset-final-projection/assetFinalProjection"));

function AssetGridComponent({ buyDigitalButtonHandler }: { buyDigitalButtonHandler: () => void }) {
    const [open, setOpen] = useState(false);
    const [docTitle, setDocTitle] = useState("");
    const [docsList, setDocsList] = useState([]);

    const foldersList = useSelector(
        (state: FoldersListInterface) => state.foldersList
    );
    const handleOpen = (id: string) => {
        const docs: any = foldersList.documents.find(
            (document: any) => document.id === id
        );
        setDocTitle(docs.name);
        setDocsList(docs.documents);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const notIncluded = ["Market Analysis", "Open Deck"]

    return (
        <div className="grid-areas">
            {open ? (
                <AssetDocumentModal
                    title={docTitle}
                    docs={docsList}
                    open={open}
                    setClose={handleClose}
                />
            ) : null}
            <AssetFinancialProjection />
            <AssetSummary />

            {/* <AssetMap /> */}
            <div className="docs-container">
                <span className="title">Documents</span>
                <div className="docs-list">
                    {foldersList.documents.filter((el: any) => !notIncluded.includes(el.name)).map((document: any) => {

                        return (
                            <AssetDocument
                                key={document.name + Math.random()}
                                handleOpen={() => handleOpen(document.id)}
                                length={document.documents.length}
                                name={document.name}
                            />
                        )

                    })}
                </div>
            </div>
        </div>
    );
}
export default AssetGridComponent;
