import { useState } from 'react'
import CloseIcon from '../../../components/accreditation-components/close-icon/closeIcon';
import LabelInput from "../../../components/profile-components/labelInput/labelInput";
import GetHelp from "../../../components/get-help/getHelp";
import { TextField, Button } from '@material-ui/core'
import { THEME_COLOR } from '../../../constants/colors'
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

function AddOfferingStep1() {

    const [accountInfo, setAccountInfo] = useState({
        companyName: '',
        offeringName: '',
        offeringType: '',
        targetAmount: '',
        minAmount: '',
        maxAmount: '',
        pricePerUnit: '',
        startDay: moment(new Date()).format("MM-DD-YYYY"),
        endDay: moment(new Date()).format("MM-DD-YYYY")
    })

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountInfo(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const startDateHandler = (date: MaterialUiPickersDate) => {
        const formatedDate = moment(date).format("MM-DD-YYYY");
        setAccountInfo({
            ...accountInfo,
            startDay: formatedDate,
        });
    }

    const endDateHandler = (date: MaterialUiPickersDate) => {
        const formatedDate = moment(date).format("MM-DD-YYYY");
        setAccountInfo({
            ...accountInfo,
            endDay: formatedDate,
        });
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <section className="add-offering-step1-page">
                <div className="add-offering-step1">
                    <CloseIcon />
                    <div className="form-title-container">
                        <h3 className="form-title">
                            Add offering
                    </h3>
                        <span className="step">
                            Step 1 of 2
                    </span>
                    </div>
                    <form className="add-offering-step1-form" onSubmit={(e) => e.preventDefault()}>
                        <LabelInput
                            value={accountInfo.companyName}
                            handler={inputHandler}
                            id="companyName"
                            name="companyName"
                            label="Company name"
                            disabled={false} />
                        <LabelInput
                            value={accountInfo.offeringName}
                            handler={inputHandler}
                            id="offeringName"
                            name="offeringName"
                            label="Offering name"
                            disabled={false} />
                        <label htmlFor="state" className="inputlabel-label">Offering type</label>
                        <TextField
                            select
                            className="form-field"
                            name="offeringType"
                            id="offeringType"
                            onChange={inputHandler}
                            value={accountInfo.offeringType} />

                        <LabelInput
                            value={accountInfo.targetAmount}
                            handler={inputHandler}
                            id="targetAmount"
                            name="targetAmount"
                            label="Target amount"
                            disabled={false} />
                        <LabelInput
                            value={accountInfo.minAmount}
                            handler={inputHandler}
                            id="minAmount"
                            name="minAmount"
                            label="Min. amount"
                            disabled={false} />
                        <LabelInput
                            value={accountInfo.maxAmount}
                            handler={inputHandler}
                            id="maxAmount"
                            name="maxAmount"
                            label="Max. amount"
                            disabled={false} />
                        <LabelInput
                            value={accountInfo.pricePerUnit}
                            handler={inputHandler}
                            id="pricePerUnit"
                            name="pricePerUnit"
                            label="Price per unit"
                            disabled={false} />
                        <label htmlFor="state" className="inputlabel-label">Start day</label>
                        <KeyboardDatePicker
                            disableFuture
                            openTo="date"
                            format="dd/MM/yyyy"
                            views={["year", "month", "date"]}
                            value={accountInfo.startDay}
                            onChange={startDateHandler}
                            className="day"
                        />
                        <label htmlFor="state" className="inputlabel-label">And day</label>
                        <KeyboardDatePicker
                            disableFuture
                            openTo="date"
                            format="dd/MM/yyyy"
                            views={["year", "month", "date"]}
                            value={accountInfo.startDay}
                            onChange={endDateHandler}
                            className="day"
                        />

                        <Button
                            style={{ backgroundColor: THEME_COLOR }}
                            className="accreditation-next-button"
                        >
                            Next ➝{" "}
                        </Button>
                    </form>
                    <GetHelp />
                </div>
            </section>
        </MuiPickersUtilsProvider>
    )
}

export default AddOfferingStep1;