import * as types from './../constants/types'
import { SendKYCQuestions, SendIdVerification, KYCInfoState } from './../../interfaces/KYCInterface'
import * as H from "history";

export const sendKYCInformation = function (kycInfo: KYCInfoState, history: H.History) {
    return {
        type: types.SEND_KYC_INFORMATION_REQ,
        payload: {
            kycInfo,
            history
        },
    }
}

export const sendKYCQuestions = function (object: SendKYCQuestions) {
    return {
        type: types.SEND_KYC_QUESTIONS_REQ,
        payload: object
    }
}

export const sendIdVerification = function (object: SendIdVerification) {
    return {
        type: types.SEND_ID_VERIFICATION_REQ,
        payload: object
    }
}

export const getStartKYCInfo = function () {
    return {
        type: types.GET_START_KYC_REQ
    }
}

export const closeKYCModal = function () {
    return {
        type: types.CLOSE_KYC_MODAL
    }
}

export const checkisKYC = function () {
    return {
        type: types.GET_IS_KYC_REQ
    }
}
