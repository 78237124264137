import * as type from "../../constants/types";

const initialState = {
    highlightsInfo: [],
};
const highlightsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_HIGHLIGHTS_INFO:
            return {
                ...state,
                highlightsInfo: action.assetData,
            };
        default:
            return state;
    }
};

export default highlightsReducer;
