// const infoAdvanced = {
//   title: "Detox Digital Coin Marketplace Site",
//   location: "United States",
//   description: "The Portfolio consists of US real estate to house the proliferation of programs for people affected by the opioid crisis",
//   totelRaise: "11000000",
//   minimumInvestment: "5000",
//   videos: [
//     "https://www.youtube.com/watch?v=Rf9M9Ix9-r0&ab_channel=OWN",
//     "https://youtu.be/KRcrv8uG7Y8"
//   ],
//   highlightItems: [
//       {highlight:"Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years."},
//       {highlight:"Industry revenues will climb to almost $99.4 billion by 2028."},
//       {highlight:"Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs."},
//       {highlight:"The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year."},
//       {highlight:"BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments."},
//       {highlight:"Facilities identified, renovated and leased prior to acquisition."},
//       {highlight:"“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent."},
//       {highlight:"Anticipated 5-year Annualized Return: 12%."},
//   ],
//   investedDetail: [
//       {field:"Revenue type", value:"NNN Lease"},
//       {field:"Capital Stack", value:"Equity"},
//       {field:"Property Type", value:"Medical Facility"},
//   ],
//   projectionDetail: [
//     {field:"Targeted ROI", value:"12%"},
//     {field:"Capital Structure", value:"35% Equity; 65% Debt"},
//     {field:"Equity Multiple", value:"1.62"},
//     {field:"Targeted IRR", value:"11% IRR and annual 4% cash-on-cash return"},
//   ],
//   content: {
//     offer: `<div>SolidBlock has teamed up with Holdun Family Office to create the Detox Digital Coin, a real estate investment opportunity to grow detox and behavioral health centers across the US.<br/><br/>
//     The offering addresses a rapidly expanding market due to the exploding behavioral health crisis which includes drug and alcohol addiction and mental health wellbeing.<br/><br/>
//     Detox Digital Coin uses blockchain technology to tokenize and raise capital. With a minimum investment of $5,000 USD, investors receive tokenized shares in the property, which are represented as Detox Digital Coins. These coins will be deposited into the investor’s account or digital wallet.<br/><br/>
//     Each new acquisition will be listed as a separate and distinct investment opportunity. The goal is to acquire up to 20 additional facilities.
//     </div>`,
//   }
// }

const AdvanceProjection = () => {
  return (
    <div className="financial-projection">
      <div className="title">Financial Projection</div>
      {/* {infoAdvanced.projectionDetail.map((el: any, i: number) => {
        return (
          <div  key={i}className="field-item">
              <div className="field">{el.field}</div>
              <div className="value">{el.value}</div>
          </div>
        );
      })} */}
      <div className="field-item">
        <div className="field">Targeted ROI (Annual)</div>
        <div className="value">12.03%</div>
      </div>
      <div className="field-item">
        <div className="field">Capital Structure</div>
        <div className="value">38% Preferred Equity<br/>62% Debt</div>
      </div>
      <div className="field-item">
        <div className="field">Equity Multiple</div>
        <div className="value">1.6</div>
      </div>
      <div className="field-item">
        <div className="field">Targeted IRR</div>
        <div className="value">11.05%</div>
      </div>
    </div>
  );
};

export default AdvanceProjection;
