// import { Button } from "@material-ui/core";
// import { useState } from "react";
// import { useHistory } from "react-router-dom";
// import CheckBoxInput from "../checkbox-input/checkboxInput";
// import { useSelector, useDispatch } from "react-redux";
// import { THEME_COLOR } from '../../constants/colors'
// import { postMyInvestmentsReq } from '../../redux/actions/myInvestmentsAction'
// import CircularProgress from '@material-ui/core/CircularProgress';
// // import { checkExternalAccount } from '../../redux/actions/achActions'
// import { achButtonsDisplay } from "../../constants/achConstants";
// import Ach from "./ach";
// import Crypto from "./crypto";
// import Check from "./check"
// import AchRouting from './ach-routing'
// import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
// import { CheckoutInterface } from "../../interfaces/assetsInterface";
// import Info from './../../Assets/icons/info.svg';
// import PaymentModal from "./paymentModal";
// const Bitmap = require("../../Assets/images/Bitmap.png").default;
// const Norton = require("../../Assets/images/norton.png").default;
const CentralPayment = (props: any) => {
    // let { path, url } = useRouteMatch();
    // const location = useLocation()
    // const dispatch = useDispatch()
    // const history = useHistory();
    // const [transactionType, setTransactionType] = useState('WIRE')


    // const [infoModal, setInfoModal] = useState(false);

    // const info = useSelector(
    //     (state: CheckoutInterface) => state.checkoutReducer
    // );
    // const progress = useSelector(
    //     (state: any) => state.loaderReducer.btnLoader
    // );
    // const cryptoVal = useSelector(
    //     (state: {
    //         cryptoReducer: {
    //             cryptoData: number,
    //             cryptoValue: number,
    //             cryptoSymbol: string
    //         }
    //     }) => state.cryptoReducer
    // )
    // const postPurchase = () => {
    //     if (transactionType === 'CRYPTO') {
    //         dispatch(postMyInvestmentsReq({
    //             transactionType,
    //             crypto: cryptoVal.cryptoSymbol,
    //             cryptoPrice: cryptoVal.cryptoValue / info.inputVal,
    //             spendCredit: creditsVal.credits,
    //             discountId: info.isActive ? info.discountId : '',
    //             transactionUnits: info.sliderVal,
    //             history,
    //             assetId: info.id
    //         }))
    //     } else {
    //         dispatch(postMyInvestmentsReq({
    //             transactionType,
    //             spendCredit: creditsVal.credits,
    //             discountId: info.isActive ? info.discountId : '',
    //             transactionUnits: info.sliderVal,
    //             history,
    //             assetId: info.id
    //         }))
    //     }
    // }
    // const creditsVal = useSelector(
    //     (state: {
    //         countCreditsReducer: {
    //             credits: number,

    //         }
    //     }) => state.countCreditsReducer
    // );

    // const checkBoxHandler = (transactionType: string) => {
    //     setTransactionType(transactionType)
    // }



    // const infoModalHandler = () => {
    //     setInfoModal(!infoModal);
    // }

    // const openInfoModal = (e: React.MouseEvent) => {
    //     e.stopPropagation();
    //     setInfoModal(true);
    // }

//   return later
//     const _isRedFrog = info.alias === "RFP"; 
//     const _isDetox = info.alias === "DDC";

    return (
        <div className="central-payment">
            {/* <PaymentModal
                open={infoModal}
                handler={infoModalHandler} />
            <div className="method-containers">
                <label
                    htmlFor="ach"
                    onClick={() => history.push(`${url}/ach`)}
                    style={{
                        border: `1px solid ${transactionType === 'WIRE' ? THEME_COLOR : "white"
                            }`,
                    }}
                    className="container"
                >
                    <div>
                        <CheckBoxInput
                            checked={transactionType === 'WIRE'}
                            checkBoxHandler={() => checkBoxHandler('WIRE')}
                            label=""
                            id={"ach"}
                        />
                        <h3>WIRE</h3>
                    </div>
                    <img
                        className="info"
                        src={Info}
                        alt="Info"
                        onClick={openInfoModal} />
                </label>
                <label

                    htmlFor="check"
                    onClick={() => {
                        if (_isDetox || _isRedFrog){
                            return
                        }
                        history.push(`${url}/check`)
                    }}
                    style={{
                        border: `1px solid ${transactionType === 'CHECK' ? THEME_COLOR : "white"
                            }`,
                    }}
                    className={`container ${_isDetox || _isRedFrog ? 'inactive' : ''}`}
                >
                    <div>
                        <CheckBoxInput
                            checked={transactionType === 'CHECK'}
                            checkBoxHandler={() => checkBoxHandler('CHECK')}
                            label=""
                            disabled={_isDetox || _isRedFrog}
                            id={"check"}
                        />
                        <h3>CHECK</h3>
                    </div>
                    <img
                        className="info"
                        src={Info}
                        alt="Info"
                        onClick={openInfoModal} />
                </label>
                <label
                    htmlFor="crypto"
                    onClick={() => {
                        // if (_isRedFrog || _isDetox){
                        //     return
                        // }
                        history.push(`${url}/crypto`)
                    }}
                    style={{
                        border: `1px solid ${transactionType === 'CRYPTO' ? THEME_COLOR : "white"
                            }`,
                    }}
                    // className={`container ${_isRedFrog || _isDetox ? 'inactive' : ''}`}
                    className="container"
                >
                    <div>
                        <CheckBoxInput
                            checked={transactionType === 'CRYPTO'}
                            checkBoxHandler={() => checkBoxHandler('CRYPTO')}
                            label=""
                            // disabled={_isRedFrog || _isDetox}
                            id={"crypto"}
                        />
                        <h3>Crypto</h3>
                    </div>
                    <img
                        className="info"
                        src={Info}
                        alt="Info"
                        onClick={openInfoModal} />
                </label> 
                 <label
                    htmlFor="wire"
                    onClick={() => dispatch(checkExternalAccount(history, url))}
                    style={{
                        border: `1px solid ${transactionType === 'ACH' ? THEME_COLOR : "white"
                            }`,
                    }}
                    className="container"
                >
                    <div>
                        <CheckBoxInput
                            checked={transactionType === 'ACH'}
                            checkBoxHandler={() => checkBoxHandler('ACH')}
                            label=""
                            id={"wire"}
                        />
                        <h3>ACH</h3>
                    </div>
                </label> 
             </div>
            <div className={`secure-container ${location.pathname.includes('wire/') ? 'secure-invisible' : ''}`}>
                <div>
                    <h2>Secure 256-bit encryption</h2>
                    <div className='secure-images'>
                        <img alt="" src={Bitmap} />
                        <img alt="" src={Norton} />
                    </div>
                </div>
            </div>
            <Switch>
                <Route path={`${path}/ach`}>
                    <Ach />
                </Route>
                <Route path={`${path}/crypto`}>
                    <Crypto />
                </Route>
                <Route path={`${path}/check`}>
                    <Check />
                </Route>
                <Route path={`${path}/wire`}>
                    <AchRouting />
                </Route>
            </Switch>
            {
                achButtonsDisplay.find(el => el === location.pathname) ?
                    null :
                    <Button style={{ backgroundColor: THEME_COLOR }}
                        onClick={() => postPurchase()}>
                        {progress ? <CircularProgress /> : 'Confirm'}
                    </Button>
            }

            <span
                className="input-error"
                style={{ display: 'block' }}>{info.errorMessage}</span> */}
        </div>
    );
};

export default CentralPayment;