import { THEME_COLOR } from "../../constants/colors";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    buyBtn: {
        background: THEME_COLOR,
        border: `2px solid ${THEME_COLOR} !important`,
        padding: '6px 28px',
        '&:hover': {
            background: THEME_COLOR,
        }
    }
});
const ExchangeItem = (props: any) => {
    const styles = useStyles()

    const sell = props.name
    const price = `${props.tradingPrice} ${props.tradeAlias}`
    const quantity = `${props.amount} ${props.alias}`
    const amount = `${+props.tradingPrice * +props.amount} ${props.tradeAlias}`

    return (
        <div className='item-container'>
            <div className='item-content'>
                <div className='logo-container'>
                    <p>{sell}</p>
                </div>
                <div className='asset-fields' >
                    <p className="mobile-title">
                        Price
                    </p>
                    <p>{price}</p>
                </div>
                <div className='asset-fields' >
                    <p className="mobile-title">
                        Quantity
                    </p>
                    <p> {quantity}</p>
                </div>
                <div className='asset-fields' >
                    <p className="mobile-title">
                        Total
                    </p>
                    <p>{amount} </p>
                </div>
                <div className='asset-fields' >
                    {props.buy ?
                        <Button onClick={() => props.openConfirmationModal(sell, price, quantity, amount, props.id, props.tradeAssetId)} classes={{ root: styles.buyBtn }}>Buy</Button> :
                        props.myOffers ?
                            <Button onClick={() => props.openCancelModal(props.tradeAssetId, sell)} classes={{ root: styles.buyBtn }}>Cancel</Button> : ''}
                </div>
            </div>

        </div>
    )
}

export default ExchangeItem