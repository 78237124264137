import { call, put, select, takeEvery } from "redux-saga/effects";
import { postApi } from "../shared/postApi";
// import { getTokenApi } from "../shared/getTokenApi";
import * as type from "../../constants/types";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import { LoginAction } from "../../../interfaces/actions-interfaces/registration-interfaces";
import mautic from 'mautic-tracking';
import { getTokenApi } from "../shared/getTokenApi";
import { SignInStore, SignInTFAresp } from "../../../interfaces/signInInterface";
import { sigInPostCodeAC, sigInSeconStepSetUpAC, signInResendCodeAC } from "../../actions/signInActions";
import { showWarningSnackbar } from "../../actions/registartionAction";

function* signinSaga(action: {
  type: string;
  payload: LoginAction;
}): TypedIterableIterator<any, any, number> {
  const { payload: { confirmData }, } = action;
  try {
    const userData = yield call(postApi, [action.payload.logindata, "auth/signIn"]);
    if (userData.data.googleExist){
      yield put({ type: type.GET_USERS_FAILED })
      yield put({
        type: type.SIGN_IN_ENABLE_BUTTON_SUBMIT,
      });
      action.payload.setErrorCB("If you log in with Google, you will not be able to log in with an email and password")
      return
    }
    if (!userData.data.phone) {
      if (action.payload.logindata.email) {
        mautic.pageView({ email: action.payload.logindata.email });
      }
      yield put({ type: type.GET_USERS_SUC, userdata: userData.data });

      localStorage.setItem("token", userData.data.token);
      localStorage.setItem("refreshToken", userData.data.refreshToken);
      localStorage.setItem("expiredTime", userData.data.expiredTime);

      if (confirmData?.email && confirmData?.code && confirmData?.clientId) {
        const confirm = yield call(getTokenApi, `auth/confirmEmail?code=${confirmData.code}&email=${encodeURIComponent(confirmData.email)}`);

        if (confirm.status >= 200 && confirm.status < 300) {
          action.payload.redirectTo ?
            action.payload.history.push(`${action.payload.redirectTo}`)
            :
            action.payload.history.push('/marketplace')
        } else action.payload.history.push('/login')
        return
      }

      if (action.payload.toCheckout) {
        mautic.pageView({ tags: 'checkout' });
        action.payload.history.push(`/checkout/overview/${action.payload.toCheckout.asset}/${action.payload.toCheckout.tokens}`)
        return
      }
      if (action.payload.redirectTo) {
        if (localStorage.getItem('formResult')) {
          action.payload.history.push('/tokenization-confirm')
        } else {
          action.payload.history.push(`${action.payload.redirectTo}`)
        }
        return
      }

      action.payload.history.push("/sign-up/login-redirect")
    }
    if (userData.data.phone && userData.data.Session && userData.data.username) {
      let resp: SignInTFAresp = userData.data
      yield put(sigInSeconStepSetUpAC(resp))
    }

  } catch (e: any) {
    yield put({ type: type.GET_USERS_FAILED })
    yield put({
      type: type.SIGN_IN_ENABLE_BUTTON_SUBMIT,
    });
    action.payload.setErrorCB(e.response.data.error)
  }
}

function* sigInPostCodeSaga(action: ReturnType<typeof sigInPostCodeAC>): TypedIterableIterator<any, any, number> {
  try {
    const loginState = yield select((state: { signInReducer: SignInStore }) => state.signInReducer)
    const request = {
      Session: loginState.Session,
      username: loginState.username,
      otp: action.payload.otp,
      shouldRemember: action.payload.shouldRemember,
    }
    const { data, status } = yield call(postApi, [request, "auth/singOtp"]);
    if (status === 200) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("expiredTime", data.expiredTime);
    }
    action.payload.history.push("/sign-up/login-redirect")
  }
  catch (e: any) {
    action.payload.failCB()
  }
}
function* sigInResendCodeSaga(action: ReturnType<typeof signInResendCodeAC>): TypedIterableIterator<any, any, number> {
  try {
    const loginState = yield select((state: { signInReducer: SignInStore }) => state.signInReducer)
    const payload = {
      email: loginState.email,
      password: loginState.password
    }
    const userData = yield call(postApi, [payload, "auth/signIn"]);
    if (userData.data.phone && userData.data.Session && userData.data.username) {
      action.payload.cb()
      let resp: SignInTFAresp = userData.data
      yield put(sigInSeconStepSetUpAC(resp))
    }
  }
  catch (e: any) {
    yield put(showWarningSnackbar(
      `Server Error`,
      e?.response?.data?.error || ''
    ))
  }
}


function* watchSignInSaga() {
  yield takeEvery(type.GET_SIGNIN_REQ, signinSaga);
  yield takeEvery(type.SIGN_IN_POST_TFA_CODE_REQ, sigInPostCodeSaga);
  yield takeEvery(type.SIGN_IN_RESEND_TFA_RESP, sigInResendCodeSaga);
}

export default watchSignInSaga;
