import { useHistory } from "react-router";
import CheckoutMenu from "../../../components/purchase-reusable-components/checkoutMenu";
import ConfirmationMain from "../../../components/confirmation/confirmationMain";

const ConfirmationPage = () => {
    const history = useHistory();

    return (
        <div className="confirmation-wrapper">
            <div className="confirmation-title">
                <div className="inner-title">
                    <h1>Confirmation</h1>
                    <p>step 3 of 3</p>
                </div>
            </div>
            <div className="confirmation-container">
                <CheckoutMenu history={history} />
                <ConfirmationMain />
            </div>
        </div>
    );

}

export default ConfirmationPage;
