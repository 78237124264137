
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Wire from './wire'
import Plaid from './plaid'
import InputSection from "./north/inputSection";
import InfoSection from './north/infoSection'
export default function AchRouting() {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/info-section`}>
                <InfoSection />
            </Route>
            <Route path={`${path}/input-section`}>
                <InputSection />
            </Route>
            <Route path={`${path}/plaid`}>
                <Plaid />
            </Route>
            <Route path={`${path}`}>
                <Wire />
            </Route>

        </Switch>
    )
}