
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export default function Back({ backTo }: { backTo: string }) {
    const history = useHistory();

    return (
        <span
            className="back-button-checkout"
            onClick={() => history.push(backTo)}
        >
            <ArrowBack />
            Back
        </span>
    );
}
