import { Button } from "@material-ui/core";

// import FacebookIcon from "../../../Assets/icons/facebook.svg";
import GoogleIcon from "../../../Assets/icons/google.svg";
// import LinkedInIcon from "../../../Assets/icons/linkedIn.svg";

// import {
//     AUTH_SOCIAL_FACEBOOK_LINK,
//     AUTH_SOCIAL_GOOGLE_LINK,
//     AUTH_SOCIAL_LINKEDIN_LINK,
// } from "../../../constants/auth";

const AuthSocial = () => {
    function onGoogleClick(){
        const current_page = window.location.pathname;
        if (current_page.includes('tokenization')){
            sessionStorage.setItem('fromTokenization','true');
        }
        window.open(process.env.REACT_APP_GOOGLE_LINK, '_self')
    }
    return (
        <div className="auth-page-social">
            <div className="social-continue">
                <div className="dash"></div>
                <div className="social-continue-txt">or continue with</div>
                <div className="dash"></div>
            </div>
            <div className="social-buttons">
                {/* <Button
                    onClick={() => window.open(process.env.REACT_APP_FACEBOOK_LINK)}
                    className="social-button facebook"
                >
                    <img src={FacebookIcon} alt=" " />
                    <span className="social-link">Sign In with Facebook</span>
                </Button> */}
                <Button
                    // onClick={() => window.open(process.env.REACT_APP_GOOGLE_LINK, '_self')}
                    onClick={onGoogleClick}
                    className="social-button google"
                >
                    <img src={GoogleIcon} alt=" " />
                    <span className="social-link-google">
                        Sign In with Google
                    </span>
                </Button>
                {/* <Button
                    onClick={() => window.open(process.env.REACT_APP_LINKEDIN_LINK)}
                    className="social-button linkedIn"
                >
                    <img src={LinkedInIcon} alt=" " />
                    <span className="social-link">Sign In with LinkedIn</span>
                </Button> */}
            </div>
        </div>
    );
};

export default AuthSocial;
