import info from "../../../../Assets/icons/info.svg";
import React from "react";
import classes from './style.module.scss'
import Checkbox from "@mui/material/Checkbox";

const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

const CheckboxTooltip = ({ text, tooltip, name, checkedSwitch, onChange, price, style, fee }: { fee?: number, style?: React.CSSProperties, price: number, text: string, tooltip: string, name: string, checkedSwitch: any, onChange: (a:boolean)=>void }) => {
    const [tooltipShow, setShow] = React.useState<boolean>(false);
    const showHide = () => {
        if (tooltipShow) {
            setShow(false)
        } else {
            setShow(true)
        }
    }
    return (
        <div className={classes.wrapper} style={style || undefined}>
            <div className={classes.shown} >
                <div className={classes.checkbox}>
                    <Checkbox
                        name={name}
                        checked={checkedSwitch}
                        onChange={(e)=>onChange(e.target.checked)}
                        style={{
                            color: "#0EC22B",
                        }}
                    />
                </div>
                <p className={classes.text}>
                    {text}<br />
                    {fee ? <span>+ {fee}% fee</span> : null}
                </p>

                {supportsTouch ?
                    <div className={classes.info} onClick={showHide}>
                        <img
                            src={info}
                            width={14}
                            alt=" "
                        />
                    </div>
                    :
                    <div className={classes.info} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                        <img
                            src={info}
                            width={14}
                            alt=" "
                        />
                    </div>
                }
                <div className={classes.price}>
                    ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
            </div>
            <p
                className={`${classes.tooltip_text} ${tooltipShow ? '' : classes.hidden}`}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {tooltip}
            </p>
        </div>
    )
}

export default CheckboxTooltip;