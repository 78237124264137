import * as type from "../../constants/types";

const initialState = {
    projectionInfo: [],
};
const projectionInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_PROJECTION_INFO:
            return {
                ...state,
                projectionInfo: action.assetData,
            };
        default:
            return state;
    }
};

export default projectionInfoReducer;
