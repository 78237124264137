import { lazy } from 'react'
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";

const MainInformationPage = lazy(() => import("./main-information-page/mainInformationPage"));
const SecurityPage = lazy(() => import("./security-page/securityPage"));
const DocumentsPage = lazy(() => import("./documents-page/documentsPage"));
const ReferralsPage = lazy(() => import("./referrals-page/referralsPage"));
const SubscriptionPage = lazy(() => import("./subscription-page/subscriptionPage"));

const ProfilePages = () => {
    return (
        <Switch>
            <Route
                exact
                component={MainInformationPage}
                path="/profile/main-information"
            />
            <Route exact component={SecurityPage} path="/profile/security" />
            <Route exact component={DocumentsPage} path="/profile/documents" />
            <Route exact component={ReferralsPage} path="/profile/referrals" />
            <Route exact component={SubscriptionPage} path="/profile/subscription" />
            <Redirect from="/profile" to="/profile/main-information" exact />
        </Switch>
    );
};

export default ProfilePages;
