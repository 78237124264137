import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './redux/reducers/index'
import rootSaga from './redux/sagas/index'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
export default createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)

)

sagaMiddleware.run(rootSaga)
