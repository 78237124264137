import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckoutInterface,
} from "../../interfaces/assetsInterface";
import {
  convertCurrency,
  getCryptoPrices,
} from "../../redux/actions/currencyConvertingActions";
import BTC from "./../../Assets/images/btc.png";
import ETH from "./../../Assets/images/eth.png";
import DASH from "./../../Assets/images/dash.png";
import DAI from "./../../Assets/images/DAI.png";
import USDC from "./../../Assets/images/USDC.png";
import QRCode from "qrcode.react";
import { CRYPTO_CODES, CRYPTO_CODES_LDB, CRYPTO_CODES_RFP, CRYPTO_CODES_DTX } from '../../constants/qrcodes';
import { Link } from "react-router-dom";
import ClipboardCopy from "../checkout/clipboardCopy";
import { addComma } from "../../constants/addCommaFunction";
import Spinner from "../spinner/spinner";

function CheckoutCrypto() {

  const dispatch = useDispatch();

  const [selectedCrypto, setSelectedCrypto] = useState("");

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  const cryptoPricesInfo = useSelector(
    (state: {
      cryptoReducer: {
        cryptoPrices: any,
      };
    }) => state.cryptoReducer.cryptoPrices
  );

  const cryptoInfo = useSelector(
    (state: {
      cryptoReducer: {
        cryptoData: number;
        cryptoValue: number;
        loading: boolean;
      };
    }) => state.cryptoReducer
  );

  const selectHandler = (cryptoType: string) => {
    const elem = document.getElementById("qrCode");
    setSelectedCrypto(cryptoType);
    if (elem) {
      setTimeout(() => {
        elem.scrollIntoView({block: "end"});
      }, 500);
    }
  };

  // useEffect(() => {
  //   dispatch(getCryptoExchangeRates(checkoutInfo.currency, checkoutInfo.total));
  // }, [dispatch, checkoutInfo.currency, checkoutInfo.total]);

  useEffect(() => {
    dispatch(getCryptoPrices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(convertCurrency(selectedCrypto, checkoutInfo.total, checkoutInfo.currency));
  }, [selectedCrypto, dispatch, checkoutInfo.total, checkoutInfo.currency]);


  return (
    <div className="checkout-crypto-wrapper">
    {cryptoInfo.loading ? (<Spinner/>):(
      <>
        <div className="title">
          Your tokens are reserved!
        </div>
        <div className="descr">
          In order to finalize this purchase, you must send your crypto payment to one of the addresses below:
        </div>
        <div className="checkout-crypto">
          <div className="title">Select a Cryptocurrency</div>
          <div className="methods">
            <div
              className={`method-item ${
                selectedCrypto === "BTC" ? "active" : ""
              }`}
              onClick={() => selectHandler("BTC")}
            >
              <img src={BTC} alt="" />
              BTC
            </div>
            <div
              className={`method-item ${
                selectedCrypto === "ETH" ? "active" : ""
              }`}
              onClick={() => selectHandler("ETH")}
            >
              <img src={ETH} alt="" />
              ETH
            </div>
            <div
              className={`method-item ${
                selectedCrypto === "DASH" ? "active" : ""
              }`}
              onClick={() => selectHandler("DASH")}
            >
              <img src={DASH} alt="" />
              DASH
            </div>
            <div
              className={`method-item ${
                selectedCrypto === "DAI" ? "active" : ""
              }`}
              onClick={() => selectHandler("DAI")}
            >
              <img src={DAI} alt="" />
              DAI
            </div>
            <div
              className={`method-item ${
                selectedCrypto === "USDC" ? "active" : ""
              }`}
              onClick={() => selectHandler("USDC")}
            >
              <img src={USDC} alt="" />
              USDC
            </div>
          </div>
          {selectedCrypto ? (<div>
            <p className="qr-title">Please send your crypto payment to the address below or via the QR code</p>
            {checkoutInfo.alias === "LDB" && (
                <div className="qrBox">
                    <QRCode className='qr-image' value={`${CRYPTO_CODES_LDB[selectedCrypto]}?amount=${cryptoInfo.cryptoValue}`} />
                    <div className="qr-right">
                      <span className="qr-title">{selectedCrypto}</span>
                      <span className="qr-wallet">{`${CRYPTO_CODES_LDB[selectedCrypto]}`} <ClipboardCopy text={`${CRYPTO_CODES_LDB[selectedCrypto]}`}/></span>
                      <span className="qr-title">Amount</span>
                      <span className="qr-wallet">{`${cryptoInfo.cryptoValue}`} <ClipboardCopy text={`${cryptoInfo.cryptoValue}`}/></span>
                      <span className="qr-title">{`1 ${selectedCrypto} = £${cryptoPricesInfo[selectedCrypto]?.GBP}`}</span>
                    </div>
                </div>
            )}
            {checkoutInfo.alias === "frOGI" && (
                <div className="qrBox">
                    <QRCode className='qr-image' value={`${CRYPTO_CODES_RFP[selectedCrypto]}?amount=${cryptoInfo.cryptoValue}`} />
                    <div className="qr-right">
                      <span className="qr-title">{selectedCrypto}</span>
                      <span className="qr-wallet">{`${CRYPTO_CODES_RFP[selectedCrypto]}`} <ClipboardCopy text={`${CRYPTO_CODES_RFP[selectedCrypto]}`}/></span>
                      <span className="qr-title">Amount</span>
                      <span className="qr-wallet">{`${cryptoInfo.cryptoValue}`} <ClipboardCopy text={`${cryptoInfo.cryptoValue}`}/></span>
                      <span className="qr-title">{`1 ${selectedCrypto} = $${cryptoPricesInfo[selectedCrypto]?.USD}`}</span>
                    </div>
                </div>
            )}
            {checkoutInfo.alias === "DDC" && (
                <div className="qrBox">
                    <QRCode className='qr-image' value={`${CRYPTO_CODES_DTX[selectedCrypto]}?amount=${cryptoInfo.cryptoValue}`} />
                    <div className="qr-right">
                      <span className="qr-title">{selectedCrypto}</span>
                      <span className="qr-wallet">{`${CRYPTO_CODES_DTX[selectedCrypto]}`} <ClipboardCopy text={`${CRYPTO_CODES_DTX[selectedCrypto]}`}/></span>
                      <span className="qr-title">Amount</span>
                      <span className="qr-wallet">{`${cryptoInfo.cryptoValue}`} <ClipboardCopy text={`${cryptoInfo.cryptoValue}`}/></span>
                      <span className="qr-title">{`1 ${selectedCrypto} = $${cryptoPricesInfo[selectedCrypto]?.USD}`}</span>
                    </div>
                </div>
            )}
            {checkoutInfo.alias !== "LDB" && checkoutInfo.alias !== "frOGI" && checkoutInfo.alias !== "DDC" && (
                <div className="qrBox">
                    <QRCode className='qr-image' value={`${CRYPTO_CODES[selectedCrypto]}?amount=${cryptoInfo.cryptoValue}`} />
                    <div className="qr-right">
                      <span className="qr-title">{selectedCrypto}</span>
                      <span className="qr-wallet">{`${CRYPTO_CODES[selectedCrypto]}`} <ClipboardCopy text={`${CRYPTO_CODES[selectedCrypto]}`}/></span>
                      <span className="qr-title">Amount</span>
                      <span className="qr-wallet">{`${cryptoInfo.cryptoValue}`} <ClipboardCopy text={`${cryptoInfo.cryptoValue}`}/></span>
                      <span className="qr-title">{`1 ${selectedCrypto} = $${cryptoPricesInfo[selectedCrypto]?.USD}`}</span>
                    </div>
                </div>
            )}
          </div>):null}
        </div>
        <div className="title" id="qrCode">
            What to expect next:
          </div>
          <div className="descr">
            <ul>
              <li>You will receive an email from SolidBlock confirming the payment.</li>
              <li>Your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} token allocation will appear in your account.</li>
              <li>This allocation will remain in reserved status until the project is funded. </li>
              <li>Once the project has been funded, your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} tokens will officially be distributed to your account.</li>
            </ul>
          </div>
          <div className="descr">
            View your <Link to="/my-assets" className="link">Assets Page</Link> or <Link to="/transaction-history" className="link">Transaction History</Link> to see the status of your order.
          </div>
        </>
        )}
    </div>
  );
}

export default CheckoutCrypto;
