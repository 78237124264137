import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { buyOffer, openSnackbar } from './../../redux/actions/exchangeActions';
import { TradeAssetInterface } from '../../interfaces/exchangeInterfaces'


function ExchangeConfirmationModal(props: { confirmationModal: any, closeConfirmationModal: () => void }) {
    const userTokens = useSelector((store: { tokensInfoReducer: { assetsTokens: { asset: TradeAssetInterface, tokens: number }[] } }) => store.tokensInfoReducer.assetsTokens);
    const { confirmationModal: { sell, price, quantity, total, open, id, tradeAssetId }, closeConfirmationModal } = props;
    const dispatch = useDispatch();
    const checkUserTokens = (id: string, tokens: string) => {
        let asset = userTokens.find((el) => el.asset.id === id)
        if (id === null) {
            dispatch(openSnackbar('Purchase is not available', 'Demo account'))
        } else if (asset) {
            asset.tokens - Number(tokens) >= 0 ? dispatch(buyOffer(tradeAssetId, id)) : dispatch(openSnackbar('Purchase is not available', "You dont't have enough funds"))
        }
    }
    return (
        <Dialog
            className="exch-confirmation-modal"
            open={open}
            onClose={closeConfirmationModal}>
            {/* <CloseIcon className="close" /> */}
            <span className="confirmation">
                Confirmation
            </span>
            <div className="content">
                <span className="title">
                    Sell
                </span>
                <span className="value">
                    {sell}
                </span>
                <span className="title">
                    Price per asset
                </span>
                <span className="value">
                    {price}
                </span>
                <span className="title">
                    Quantity
                </span>
                <span className="value">
                    {quantity}
                </span>
                <span className="title">
                    Total
                </span>
                <span className="value">
                    {total}
                </span>
            </div>
            <div className="buttons">
                <Button
                    color="primary"
                    className="cancel"
                    onClick={closeConfirmationModal}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className="buy"
                    onClick={() => {
                        checkUserTokens(id, total.replaceAll(/[a-z]/gi, '').trim())
                        closeConfirmationModal();
                    }}
                >
                    Buy
                </Button>
            </div>
        </Dialog>
    )
}

export default ExchangeConfirmationModal;