import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { THEME_COLOR } from '../../../constants/colors'
import TextField from '@material-ui/core/TextField'
import { createExternalAccount } from "../../../redux/actions/achActions";
import { useDispatch, useSelector } from "react-redux";

const InputSection = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const achStatus = useSelector((state: any) => state.achReducer.accountStatus)
    const error = useSelector((state: any) => state.createAccountReducer.errorMessage)
    const [btnEnabled, setBtnEnabled] = useState(false)
    const [extAccountFullName, setExtAccountFullName] = useState('')
    const [extNickName, setExtNickName] = useState('')
    const [extRoutingNumber, setExtRoutingNumber] = useState('')
    const [extAccountNumber, setExtAccountNumber] = useState('')
    useEffect(() => {
        !extAccountFullName || !extNickName || !extRoutingNumber || !extAccountNumber ? setBtnEnabled(false) : setBtnEnabled(true)
    }, [extAccountFullName, extNickName, extRoutingNumber, extAccountNumber])
    return (
        <div className='input-section'>
            <TextField onChange={(e) => setExtAccountFullName(e.target.value)} label='Account Full Name' />
            <TextField onChange={e => setExtNickName(e.target.value)} label='Account Nickname' />
            <TextField onChange={e => setExtRoutingNumber(e.target.value)} label='Routing Number' />
            <TextField onChange={e => setExtAccountNumber(e.target.value)} label='Account Number' />
            <Button style={{ backgroundColor: btnEnabled ? THEME_COLOR : 'gray' }}
                disabled={!btnEnabled}
                onClick={() => dispatch(createExternalAccount({ extAccountFullName, extNickName, extRoutingNumber, extAccountNumber }, history, achStatus))}
            >
                Confirm
            </Button>
            <p className='error'>{error}</p>
        </div>
    )
}

export default InputSection