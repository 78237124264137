import { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { LogoDark } from "../../../../constants/logo";
import HowWorksArrow from "./../../../../Assets/icons/how-works.svg";
import Opportunity from "./../../../../Assets/new-landing/opportunity.png";
import Tokenization from "./../../../../Assets/new-landing/tokenization.png";
import Trade from "./../../../../Assets/new-landing/trade.png";
import TestimonialsArrow from "./../../../../Assets/icons/chevron-right.svg";
import AdvancePresentaion from "../../asset-advansed-view/advance-presentation/advancePresentaion"; 
// import AdvancePresentaionLine from "../../asset-advansed-view/advance-presentation/advancePresentationLine";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import Image3 from "./../../../../Assets/images/redfrog/detox2.jpg";
import Image2 from "./../../../../Assets/images/redfrog/detox3.jpg";
import Image4 from "./../../../../Assets/images/redfrog/detox4.jpeg";

SwiperCore.use([Navigation]);

function AdvanceItemContent() {
  const [howWorksOpen, setHowWorksOpen] = useState(false);

  const assetInfo = useSelector(
    (state: AssetDataInterface) => state.mainAssetInfo
  );
  const infoAdvanced = {
    title: "Detox Digital Coin Marketplace Site",
    location: "United States",
    description: "The Portfolio consists of US real estate to house the proliferation of programs for people affected by the opioid crisis",
    totelRaise: "$7,200,000.00",
    minimumInvestment: "",
    videos: [
      "https://www.youtube.com/embed/Rf9M9Ix9-r0",
      "https://www.youtube.com/embed/KRcrv8uG7Y8",
      "https://www.youtube.com/embed/KLKlZroC9Js"  
    ],
    highlightItems: [
        {highlight:"Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years."},
        {highlight:"Industry revenues will climb to almost $99.4 billion by 2028."},
        {highlight:"Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs."},
        {highlight:"The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year."},
        {highlight:"BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments."},
        {highlight:"Facilities identified, renovated and leased prior to acquisition."},
        {highlight:"“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent."},
        {highlight:"Anticipated 5-year Annualized Return: 12%."},
    ],
    investedDetail: [
        {field:"Revenue type", value:"NNN Lease"},
        {field:"Capital Stack", value:"Equity"},
        {field:"Property Type", value:"Medical Facility"},
    ],
    projectionDetail: [
        {field:"Targeted ROI", value:"12%"},
        {field:"Capital Structure", value:"35% Equity; 65% Debt"},
        {field:"Equity Multiple", value:"1.62"},
        {field:"Targeted IRR", value:"15%"},
    ],
    documents: [
      {name:"PPM", link:"https://drive.google.com/file/d/1TZx9d38UNZ5aDtssaMykdVKzH1gNAfhz/view?usp=sharing"},
      {name:"Purchase Agreement", link:"https://drive.google.com/file/d/1TZx9d38UNZ5aDtssaMykdVKzH1gNAfhz/view?usp=sharing"},
      {name:"Investment Agreement", link:"https://drive.google.com/file/d/1zPMcFsHfTUUGWEh-T6eszRA7FOajrQWR/view?usp=sharing"},
      {name:"Operating Agreement", link:"#"},
      {name:"Miscellaneous", link:"https://drive.google.com/file/d/1rY-Iq40lyfGnwpAc0PNdL8cZ0fHMsbSn/view?usp=sharing"},
      {name:"Financial Summary", link:"https://docs.google.com/presentation/d/1p0lqCmNeOoPVoQ0HXVx-kNh2cDpGJKQKncbL6PgOUvk/edit?usp=sharing"},
      {name:"Financials for the first property Boca Raton", link:"https://docs.google.com/spreadsheets/d/16tKW9eb3sQqX0tqoCI1gNy06FY-hwxAG/edit#gid=1279156340"},
      {name:"FAQ", link:"https://docs.google.com/presentation/d/1p5sXF2C8PLNhmKbSPEYvD2PtNfqAa_yQ4sxYdz5zLI0/edit"},
    ],
    quotes: [
      {review:"We get into detox, they spin-dry you. And then it’s so overcrowded you can’t go onto the next step, even if you want to.", author:"Dave (The Opioid Diaries, Time Magazine 2017)"},
      {review:"I was walking along the beach, I dropped to my knees, I began crying because I realized that I’d gotten sober.", author:"Steven Tyler, Does the Noise in my Head Bother You?"},
      {review:"My treatment here was beyond any expectations. I would recommend this program to anyone dealing with addiction issues in their life.", author:"Ronnie, Recovery Unplugged, 3163 Canada Court, Lake Worth, FL"},
      {review:"Thrive is my new addition as my family and I have met the most compassionate people ever.", author:"Michele Pennington, Thrive Recovery Center, 4461 Medical Center Way, West Palm Beach, FL"},
    ],
    investorPerks: [],
    content: {
        offer: `SolidBlock has teamed up with Holdun Family Office to create the Detox Digital Coin, a real estate investment opportunity to grow detox and behavioral health centers across the US.<br/><br/>
        The offering addresses a rapidly expanding market due to the exploding behavioral health crisis which includes drug and alcohol addiction and mental health wellbeing.<br/><br/>
        Detox Digital Coin uses blockchain technology to tokenize and raise capital. With a minimum investment of $250 USD, investors receive tokenized shares in the property, which are represented as Detox Digital Coins. These coins will be deposited into the investor’s account or digital wallet.<br/><br/>
        Each new acquisition will be listed as a separate and distinct investment opportunity. The goal is to acquire up to 20 additional facilities.`,
        perks: `When you invest in Detox Digital Coins, you’ll be eligible to receive a 5% discount on your investment from Holt Xchange. Holt Xchange is an accelerator that is supported by Holdun for investing in the Detox Digital Coin portfolio. To apply for the promotion you will need to open an account with Holt.*<br/><br/>
        Investors who already have a Holt account and want to invest in Digital Detox Coin through the Holt portal will be directed to Holt’s landing page with information about the fund. You’ll then check out using SolidBlock’s payment system.<br/><br/>
        *Must keep the account open for a minimum of 1 year to exercise this option.`
    }
  }
  return (
    <div className="redfrog-content">
      <div className="image1">
        <img src={Image2} alt="" />
      </div>
      <div className="title">The Offer</div>
      <div className="subtitle">{" "}</div>
      <div 
        className="text" 
        // dangerouslySetInnerHTML={{__html: infoAdvanced.content.offer}}
      >
        <a
          href="http://www.solidblock.co/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          SolidBlock
        </a>{" "} has teamed up with <a
          href="https://www.holdun.com/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          Holdun
        </a>{" "} Family Office to create the Detox Digital Coin, 
        a real estate investment opportunity to grow detox and behavioral health centers across the US.<br/><br/>
        The offering addresses a rapidly expanding market due to the exploding behavioral health crisis which 
        includes drug and alcohol addiction and mental health wellbeing.<br/><br/>
        Detox Digital Coin uses blockchain technology to tokenize and raise capital. With a minimum investment of 
        $250 USD, investors receive <a
          href="https://blog.dibscapital.com/2021/10/29/tokenization-in-a-nutshell/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          tokenized shares
        </a>{" "} in the property, which are represented as Detox Digital Coins. 
        These coins will be deposited into the investor’s account or digital wallet.<br/><br/>
        Each new acquisition will be listed as a separate and distinct investment opportunity. 
        The goal is to acquire up to 20 additional facilities.
      </div>
      {/* <div className="title">Investor Perks</div>
      <div className="subtitle">In addition to your {assetInfo.alias} tokens, you'll receive perks for investing in the {assetInfo.title}</div>
      <div 
        className="text"
        // dangerouslySetInnerHTML={{__html: infoAdvanced.content.perks}}
      >
        In addition to your Detox Digital Coins, you’ll be eligible to receive a 5% discount on your investment 
        from Holt Xchange. Holt Xchange is an accelerator that is supported by Holdun for investing in the Detox 
        Digital Coin portfolio. To apply for the promotion you will need to <a
          href="https://joinholt.com/apply-now/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          open an account with Holt
        </a>{" "}.*<br/><br/>
        Investors who already have a Holt account and want to invest in Digital Detox Coin through the Holt portal 
        will be directed to Holt’s landing page with information about the fund. You’ll then check out using SolidBlock’s payment system.<br/><br/>

        <span className="smaller">*Must keep the account open for a minimum of 1 year to exercise this option.</span>
      </div> */}
      <AdvancePresentaion />
      <div className="image1">
        <img src={Image3} alt="" />
      </div>
      <div className="title">The Challenge</div>
      <div className="subtitle">The US is experiencing an opioid crisis of an unprecedented magnitude.</div>
      <div className="text">
        <ul style={{marginLeft:"0px", paddingLeft:"20px"}}>
          <li>100,306 drug <a
            href="https://pubmed.ncbi.nlm.nih.gov/27623005/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#0EC22B" }}
          >
            overdose
          </a>{" "} deaths in the United States during the 12-month period ending in April 2021, <b>an increase of 28.5%</b> from the year before.</li>
          <li>14,000+ treatment centers across the US.</li>
          <li>3.7 million persons received treatment in 2020, but facilities are filled to capacity and there are not enough beds.</li>
        </ul>
        The Centers for Disease Control and Prevention <a
          href="https://pubmed.ncbi.nlm.nih.gov/27623005/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          estimates
        </a>{" "} that the total “economic burden” of prescription opioid misuse alone in the United States is $78.5 billion a year.
      </div>
      <div className="image1">
        <img src={Image4} alt="" />
      </div>
      <div className="title">The Opportunity</div>
      <div className="subtitle"></div>
      <div className="text">
        Detox Digital Coin provides solutions for the different levels of care needed in behavioral health centers:
        <ul style={{marginLeft:"0px", paddingLeft:"20px"}}>
          <li>Detox Centers</li>
          <li>In-patient Drug and Alcohol Rehabilitation Centers</li>
          <li>Sober Living Post Rehabilitation Residential Housing</li>
          <li>Therapy Consulting Centers</li>
          <li>Outpatient Therapy Centers</li>
        </ul>
      </div>
      <div className="divider"></div>
      <div className="title" id='TheFirst'>The First Property for Acquisition.</div>
      <div className="subtitle" style={{marginTop:"15px"}}>{" "}</div>
      <div className="text">
          3163 Canada Ct., is an ideal investment. With an NNN Lease extend until 9/1/2028, and an annual 3% rent increase 
          built in, this represents an attractive 7.32% Net Capitalization Rate. In addition, holding Detox Digital Coins 
          in your crypto wallet exemplifies the future of real estate investment. Digital assets often bring in <a
            href="https://solidblock.co/real-estate-tokenization"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#0EC22B" }}
          >
            higher ROIs
          </a>, 
          in this case, a projected annual 12.03%. They also offer greater liquidity as the investor can trade or sell at will.
          <br/><br/>
          The building is currently 100% occupied on a 10-year net lease to Recovery Unplugged, and operated as 
          a fully licensed and accredited intensive inpatient detoxification program. The property underwent a 
          major renovation of the building’s interior, as well 
          as making significant aesthetic improvements to the exterior in 2017. <br/><br/>
            
          With an annual NOI of $459,016.00 and a rent escalation of 3% annually, the fully loaded acquisition price is $6,273,222.00. 
          This represents an attractive 7.32% effective Capitalization Rate and an annual 7.23% average cash-on-cash paid quarterly. 
          The capital stack is structured with a $3,978,140 debt and a $2,449,616.00 equity position, which includes issuance and placement costs.
          The lease has been modified to extend until 9/1/2028. The building is located just one mile from JFK Medical Center, a 486-bed acute care 
          and medical surgery facility. The property is easily accessible and just eight miles from Palm Beach International Airport.
      </div>
      <div className="how-works">
        <div className="top" onClick={() => setHowWorksOpen(!howWorksOpen)}>
          <img
            style={{top:"20%"}}
            src={HowWorksArrow}
            alt=""
            className={`arrow ${howWorksOpen ? "active" : ""}`}
          />
          <div className="works-title" style={{marginBottom:"0px"}}>How It Works</div>
        </div>
        <div className={`dropdown ${howWorksOpen ? "shown" : ""}`}>
          <div className="works-item">
            <div className="works-img">
              <img src={Opportunity} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Investing in Digital Securities with SolidBlock</div>
              <div className="item-descr">
                The Detox Digital Coin is interested in acquiring an additional 20
               detox centers in prime locations which will be rented to top addiction 
               recovery facilitators. Each acquisition will be presented as one in a 
               series of digital asset investments.
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Tokenization} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Tokenization</div>
              <div className="item-descr">
                With a minimum investment of $250, you’ll receive tokenized 
                shares in the acquisition of your choice that are represented 
                as virtual coins, i.e., Detox Digital Coins. These will be 
                deposited into your account or digital wallet.
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Trade} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Trade or Cash Out</div>
              <div className="item-descr">
                Once you own tokens in Detox Digital Coin, you can trade 
                or sell your digital tokens at any time. The projected IRR is 11.05% with 
                quarterly cash-on-cash payments of 7.23%.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-image">
        <Swiper loop navigation>
          {assetInfo.images.map((image,i) => (
            <SwiperSlide key={i}>
              <img src={image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="divider"></div>
      <div className="title">Background</div>
      <div className="subtitle"></div>
      <div className="text">
      There are currently five thriving facilities under management, 
      each with its unique and licensed approach to drug and alcohol 
      and mental health recovery.  <br/><br/>
      <a
          href="https://www.recoveryunplugged.com/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          Recovery Unplugged©
      </a>{" "}is one of the best known and effective recovery 
      programs in the business. They address the fallout of addiction and 
      incorporate music in each phase of care. Their long-term recovery rates 
      are four times the national average.  <br/><br/>
        
      Our inside knowledge of the bed-capacity and turnover rate for these 
      facilities compels us to seek additional buildings that can cater to 
      the needs of a growing at-risk population. Detox centers charge $15,000.00+ 
      per bed per week with private insurance paying most if not all costs.
      </div>
      <div className="reviews-slider">
        <Swiper
          className="reviews-swiper"
          navigation={{
            nextEl: ".testimonials-forward",
            prevEl: ".testimonials-back",
          }}
          loop
        >
          {infoAdvanced.quotes.map((quote,i) => (
            <SwiperSlide key={i}>
              <div className="slide-inner">
              <div className="review">
                “{quote.review}”
              </div>
              <div className="name">~ {quote.author}</div>
            </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img src={TestimonialsArrow} alt="" className="testimonials-back" />
        <img src={TestimonialsArrow} alt="" className="testimonials-forward" />
      </div>
      <div className="asset-image">
        <iframe src={infoAdvanced.videos[2]} title="New Strategic & Operational Partnership with Enjoy Group"/>
      </div>
      <div className="asset-image">
        <iframe src={infoAdvanced.videos[0]} title="Announcing a New Real Estate Marketing Partnership"/>
      </div>
      <div className="asset-image">
        <iframe src={infoAdvanced.videos[1]} title="New Strategic & Operational Partnership with Enjoy Group"/>
      </div>
      <div className="divider"></div>
      <div className="title">Partners You Can Trust</div>
      <div className="text">
        Detox Digital Coin is part of a three-tiered partnership and investor structure.<br/><br/> 
  
        <span className="green">Holdun Family Office, Ltd</span>. With an extensive reserve of knowledge based on this 
        company’s five-generational investing dynasty, Holdun offers financial guidance 
        to investors on their journey to leverage their financial security. <br/><br/>

        <span className="green">Medi Solutions Behavioral Health, LLC</span>, has ultimate responsibility for the management, 
        operations and investment decisions made on behalf of the partnership. With many years 
        of real estate transactions to their credit, its members have led investors to successful, 
        transformative, and equitable projects. They offer consulting in all areas related to identifying 
        up-and-coming real estate deals for the management, operations and investment decisions of their clients.<br/><br/>

        <span className="green">Omicrom Property Management, LLC.</span>, is a Delaware limited liability company that serves as
        the property manager for the real estate assets held by the Portfolio.
      </div>
      <div className="divider"></div>
      <div className="title">About SolidBlock</div>
      <div className="text">
        <img src={LogoDark} alt="" className="solidblock-logo" />
        <a
          href="http://www.solidblock.co/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          SolidBlock
        </a>{" "}SolidBlock is reimagining the future of real estate investment. Consider 
        us the NASDAQ of real estate. Using the stability and security of blockchain 
        technology, we create digital securities – or tokens – that correspond to real 
        world assets. <br/><br/>
        We have a global network of compliant partners that allows trading in multiple 
        jurisdictions. Our data-driven platform embeds market and transactional data on 
        each digital token we mine. <br/><br/>
        We offer our investors the ability to buy and sell any property, at anytime, 
        anywhere.
      </div>
      <div className="divider"></div>
      {/* <AdvancePresentaionLine /> */}
    </div>
  );
}

export default AdvanceItemContent;
