import * as types from "./../../constants/types";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import {
    SendKYCInformationSaga,
    SendKYCQuestionsSaga,
    SendIdVerificationSaga,
} from "./../../../interfaces/KYCInterface";
import { postTokenApi } from "./../shared/postTokenApi";
import { getTokenApi } from "./../shared/getTokenApi";
import { put, call, takeEvery } from "redux-saga/effects";

function* sendKYCInformationSaga(
    action: SendKYCInformationSaga
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "kyc";
        const kycResponse = yield call(postTokenApi, [
            action.payload.kycInfo,
            endpoint,
        ]);
        if (kycResponse.status >= 200 && kycResponse.status < 300) {
            if (!kycResponse.data.status) {
                yield put({
                    type: types.SEND_KYC_INFORMATION_STATUS_FALSE,
                    payload: kycResponse.data,
                });
                action.payload.history.push("/kyc/id-verification");
                return;
            }
            if (kycResponse.data.status && !kycResponse.data.questions) {
                yield put({ type: types.SEND_ID_VERIFICATION_SUC });
                action.payload.history.push("/marketplace");
                return;
            }
            if (kycResponse.data.status && kycResponse.data.questions) {
                yield put({
                    type: types.SEND_KYC_INFORMATION_SUC,
                    payload: kycResponse.data,
                });
                action.payload.history.push("/kyc/check");
                return;
            }
        } else throw kycResponse;
    } catch (e) {
        yield put({ type: types.SEND_KYC_INFORMATION_FAIL });
    }
}

function* sendKYCQuestionsSaga(
    action: SendKYCQuestionsSaga
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "kyc/answers";
        const kycQuestionsResponse = yield call(postTokenApi, [
            action.payload.answers,
            endpoint,
        ]);
        if (
            kycQuestionsResponse.status >= 200 &&
            kycQuestionsResponse.status < 300
        ) {
            yield put({
                type: types.SEND_KYC_QUESTIONS_SUC,
                payload: kycQuestionsResponse.data,
            });
            if (!kycQuestionsResponse.data.status) {
                action.payload.history.push("/kyc/id-verification");
            } else {
                action.payload.history.push("/marketplace");
            }
        } else throw kycQuestionsResponse;
    } catch (e) {
        yield put({ type: types.SEND_KYC_QUESTIONS_FAIL, payload: e });
    }
}

function* getStartKYCInfoSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user";
        const kycData = yield call(getTokenApi, endpoint);
        const states = yield call(getTokenApi, "states");
        if (kycData.status >= 200 && kycData.status < 300) {
            yield put({ type: types.GET_START_KYC_SUC, payload: kycData.data });
            yield put({ type: types.GET_KYC_STATES_SUC, payload: states.data });
        } else {
            throw kycData;
        }
    } catch (e) {
        yield put({ type: types.GET_START_KYC_FAIL, payload: e });
    }
}

function* sendIdVerificationSaga(
    action: SendIdVerificationSaga
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "kyc/document";
        const idVerificationResponse = yield call(postTokenApi, [
            action.payload.document,
            endpoint,
        ]);
        if (
            idVerificationResponse.status >= 200 &&
            idVerificationResponse.status < 300
        ) {
            yield put({
                type: types.SEND_ID_VERIFICATION_SUC,
                payload: idVerificationResponse.data,
            });
            action.payload.history.push("/marketplace");
        } else throw idVerificationResponse;
    } catch (e) {
        yield put({ type: types.SEND_ID_VERIFICATION_FAIL, payload: e });
    }
}

function* getIsKYCSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "kyc/check";
        const isKYC = yield call(getTokenApi, endpoint);
        if (isKYC.status >= 200 && isKYC.status < 300) {
            yield put({
                type: types.GET_IS_KYC_SUC,
                payload: { isKYC: isKYC.data.kycPassed, partyId: isKYC.data.partyId },
            });
        } else throw isKYC;
    } catch (e) { }
}

function* watchSendKYCInformationSaga() {
    yield takeEvery(types.SEND_KYC_INFORMATION_REQ, sendKYCInformationSaga);
    yield takeEvery(types.SEND_KYC_QUESTIONS_REQ, sendKYCQuestionsSaga);
    yield takeEvery(types.SEND_ID_VERIFICATION_REQ, sendIdVerificationSaga);
    yield takeEvery(types.GET_START_KYC_REQ, getStartKYCInfoSaga);
    yield takeEvery(types.GET_IS_KYC_REQ, getIsKYCSaga);
}

export default watchSendKYCInformationSaga;
