import { call, put, takeEvery } from "redux-saga/effects";
import { postApi } from "../shared/postApi";
import { postTokenApi } from "../shared/postTokenApi";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import * as type from "../../constants/types";
import { kycFileDeleteAC, kycGetAC, kycSendAC, kycSendFileAC, kycSetStep, newSignUpAccreditationDeleteFileAC, newSignUpAccreditationSendFileAC, newSignUpAccreditationSkipAC, newSignUpAccreditationSubmitAC, newSignUpCompanyInformationSubmitAC, newSignUpConfirmMobilePhoneAC, newSignUpResendTFACodeAC, newSignUpSetMobilePhoneAC, newSignUpSetMobilePhoneFromOauthAC, sendKycSecondStepAC, signUpAC } from "../../actions/newSignUpActions";
import { showWarningSnackbar } from "../../actions/registartionAction";
import { getTokenApi } from "../shared/getTokenApi";
import { kycSecondStepState } from "../../../interfaces/newSignUpInterfaces";
import { deleteTokenApi } from "../shared/deleteTokenApi";
import { checkRole } from "../../actions/headerActions";
import ReactPixel from 'react-facebook-pixel';
import mautic from 'mautic-tracking';

function* newSignupSaga(action: ReturnType<typeof signUpAC>): TypedIterableIterator<any, any, number> {
    try {
        const { data: { isExist: isEmailExist,  isGoogleExist: isGoogleAccExist} } = yield call(postApi, [{ email: action.payload.request.email }, "auth/email"]);
        action.payload.existEmailCb(isEmailExist)
        action.payload.existGogleEmailCb(isGoogleAccExist)
        if (isEmailExist || isGoogleAccExist) return

        const { data: response } = yield call(postApi, [action.payload.request, "auth/signup"]);
        if (action.payload.entityName) { localStorage.setItem('entityName', action.payload.entityName) }
        localStorage.setItem('token', response.token)
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('expiredTime', response.expiredTime)
        ReactPixel.track('CompleteRegistration');
        mautic.pageView({ email: action.payload.request.email, firstname: action.payload.request.firstName, lastname: action.payload.request.lastName, tags: 'signup_step_1, signup_step_2' });
        localStorage.removeItem('sitePromo')
        yield action.payload.history.push("/sign-up/verify-email")
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}
function* newSignUpKycPost(action: ReturnType<typeof kycSendAC>): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.NEW_SIGN_UP_KYC_START_LOADING })
        const { status } = yield call(postTokenApi, [action.payload.request, "kyc"]);

        if (status >= 200 && status < 300) {
            yield put(kycSetStep('documents'))
            yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        }
    } catch (e: any) {
        yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}
function* newSignUpKycGet(action: ReturnType<typeof kycGetAC>): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.NEW_SIGN_UP_KYC_START_LOADING })
        const { data: responce } = yield call(getTokenApi, "kyc/data");
        if (responce) {
            yield put({ type: type.NEW_SIGN_UP_KYC_GET_RESP, payload: responce })
            yield put(kycSetStep('documents'))
            yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpKycPostFile(action: ReturnType<typeof kycSendFileAC>): TypedIterableIterator<any, any, number> {
    try {
        const uploadData = yield call(
            postTokenApi,
            [
                action.payload.formData,
                `kyc/document`,
                {
                    onUploadProgress: action.payload.progressCB,
                }
            ]
        );
        if (uploadData?.response?.data?.error) {
            yield put(showWarningSnackbar(uploadData.response.data.error))
        } else if (uploadData?.data) {
            yield put({
                type: type.NEW_SIGN_UP_KYC_FILE_UPLOAD_RESP,
                payload: uploadData.data
            })
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* getKycSecondStep(): TypedIterableIterator<any, any, number> {
    try {
        const { data: responce } = yield call(getTokenApi, "kyc/files");
        if (responce) {
            yield put({ type: type.NEW_SIGN_UP_GET_KYC_SECOND_STEP_RESP, payload: responce as kycSecondStepState })
        }
    } catch (e: any) {
        yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        if (e?.response?.data?.error !== "KYC data not found") {
            yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
        }
    }
}

function* deleteKycFile(action: ReturnType<typeof kycFileDeleteAC>): TypedIterableIterator<any, any, number> {
    try {
        const { data: responce } = yield call(deleteTokenApi, "kyc/document", { documentId: action.payload.id });
        if (responce === 'OK') {
            yield put({
                type: type.NEW_SIGN_UP_KYC_FILE_DELETE_RESP,
                payload: {
                    id: action.payload.id
                }
            })
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* kycPostSecondStepSaga(action: ReturnType<typeof sendKycSecondStepAC>): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.NEW_SIGN_UP_KYC_START_LOADING })
        const { data: responce } = yield call(postTokenApi, [{ fullNameOnId: action.payload.fullNameOnId, documentType: action.payload.documentType }, "kyc/verify"]);
        if (responce === 'OK') {
            mautic.pageView({ tags: 'investor_kyc_step_2' });
            yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
            yield put(checkRole())
            action.payload.history.push("/sign-up/accreditation-request")
        }
    } catch (e: any) {
        yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpAccreditationSkipSaga(action: ReturnType<typeof newSignUpAccreditationSkipAC>): TypedIterableIterator<any, any, number> {
    try {
        const { status } = yield call(postTokenApi, [{}, "accreditation/skip",]);
        if (status === 200) {
            yield put(checkRole())
            action.payload.history.push("/sign-up/accreditation-done")
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* accreditationGetFilesSaga(): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.NEW_SIGN_UP_KYC_START_LOADING })
        const { data: responce } = yield call(getTokenApi, "accreditation/document");
        if (responce) {
            yield put({
                type: type.NEW_SIGN_UP_ACCREDITATION_GET_FILES_RESP,
                payload: responce
            })
        }
        yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
    } catch (e: any) {
        yield put({ type: type.NEW_SIGN_UP_KYC_STOP_LOADING })
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpAccreditationPostFile(action: ReturnType<typeof newSignUpAccreditationSendFileAC>): TypedIterableIterator<any, any, number> {
    try {
        const uploadData = yield call(
            postTokenApi,
            [
                action.payload.formData,
                "accreditation/document",
                {
                    onUploadProgress: action.payload.progressCB,
                }
            ]
        );
        if (uploadData?.response?.data?.error) {
            yield put(showWarningSnackbar(uploadData.response.data.error))
        } else if (uploadData?.data) {
            yield put({
                type: type.NEW_SIGN_UP_ACCREDITATION_POST_FILES_RESP,
                payload: uploadData.data
            })
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpAccreditationDeleteFile(action: ReturnType<typeof newSignUpAccreditationDeleteFileAC>): TypedIterableIterator<any, any, number> {
    try {
        const { data: responce } = yield call(deleteTokenApi, "accreditation/document", { documentId: action.payload.id });
        if (responce === 'OK') {
            yield put({
                type: type.NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_RESP,
            })
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}
function* newSignUpAccreditationSubmitSaga(action: ReturnType<typeof newSignUpAccreditationSubmitAC>): TypedIterableIterator<any, any, number> {
    try {
        const { status } = yield call(postTokenApi, [{}, 'accreditation/transact',]);
        if (status === 200) {
            yield put(checkRole())
            mautic.pageView({ tags: 'investor_accreditation_step_1, investor_accreditation_step_2' });
            action.payload.history.push("/sign-up/accreditation-done")
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpCompanyInfoPostSaga(action: ReturnType<typeof newSignUpCompanyInformationSubmitAC>): TypedIterableIterator<any, any, number> {
    try {
        const { status } = yield call(postTokenApi, [action.payload.companyInfo, 'entity',]);
        if (status === 200) {
            yield put(checkRole())
            action.payload.history.push("/sign-up/accreditation-done")
            localStorage.removeItem('entityName')
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpSetMobilePhoneSaga(action: ReturnType<typeof newSignUpSetMobilePhoneAC>): TypedIterableIterator<any, any, number> {
    try {
        const { status } = yield call(postTokenApi, [{ phoneNumber: action.payload.phoneNumber }, 'auth/phone',]);
        if (status === 200) {
            action.payload.history.push('/sign-up/verify-phone')
        }
    } catch (e: any) {
        action.payload.enableButtonCb()
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpSetMobilePhoneFromOauthSaga(action: ReturnType<typeof newSignUpSetMobilePhoneFromOauthAC>): TypedIterableIterator<any, any, number> {
    try {
        const response = yield call(postTokenApi, [action.payload.formData, 'auth/oauth/updateUser ',]);
        if (response.status === 200) {
            yield put(checkRole(() => action.payload.history.push('/sign-up/verify-phone')))
        }
    } catch (e: any) {
        action.payload.enableButtonCb()
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}


function* newSignUpResendTFACodeSaga(action: ReturnType<typeof newSignUpResendTFACodeAC>): TypedIterableIterator<any, any, number> {
    try {
        const { status } = yield call(postTokenApi, [{}, 'auth/phone/resendOtp',]);
        if (status === 200) {
            action.payload.doneCB()
        }
    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, e?.response?.data?.error));
    }
}

function* newSignUpConfirmMobilePhoneSaga(action: ReturnType<typeof newSignUpConfirmMobilePhoneAC>): TypedIterableIterator<any, any, number> {
    try {
        const { data } = yield call(postTokenApi, [action.payload.request, 'auth/phone/verify',]);
        if (data === 'OK') {
            sessionStorage.removeItem('mtfaNumber')
            sessionStorage.removeItem('mtfaCountry')
            yield put(checkRole())
            action.payload.history.push("/sign-up/verify-identity-request")
        }
    } catch (e: any) {
        action.payload.submitFailCb()
    }
}

function* watchNewSignUpSaga() {
    yield takeEvery(type.NEW_SIGN_UP_REQUEST, newSignupSaga);
    yield takeEvery(type.NEW_SIGN_UP_KYC_POST, newSignUpKycPost);
    yield takeEvery(type.NEW_SIGN_UP_KYC_GET_REQ, newSignUpKycGet);
    yield takeEvery(type.NEW_SIGN_UP_KYC_FILE_UPLOAD_REQ, newSignUpKycPostFile);
    yield takeEvery(type.NEW_SIGN_UP_GET_KYC_SECOND_STEP_REQ, getKycSecondStep);
    yield takeEvery(type.NEW_SIGN_UP_KYC_FILE_DELETE_REQ, deleteKycFile);
    yield takeEvery(type.NEW_SIGN_UP_POST_KYC_SECOND_STEP_REQ, kycPostSecondStepSaga);
    yield takeEvery(type.NEW_SIGN_UP_ACCREDITATION_GET_FILES_REQ, accreditationGetFilesSaga);
    yield takeEvery(type.NEW_SIGN_UP_ACCREDITATION_POST_FILES_REQ, newSignUpAccreditationPostFile);
    yield takeEvery(type.NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_REQ, newSignUpAccreditationDeleteFile);
    yield takeEvery(type.NEW_SIGN_UP_ACCREDITATION_SUBMIT, newSignUpAccreditationSubmitSaga);
    yield takeEvery(type.NEW_SIGN_UP_ACCREDITATION_SKIP_REQ, newSignUpAccreditationSkipSaga);
    yield takeEvery(type.NEW_SIGN_UP_COMPANY_INFO_SUBMIT_REQ, newSignUpCompanyInfoPostSaga);
    yield takeEvery(type.NEW_SIGN_UP_MOBILE_SET_UP_REQ, newSignUpSetMobilePhoneSaga);
    yield takeEvery(type.NEW_SIGN_UP_MOBILE_SET_UP_OAUTH_REQ, newSignUpSetMobilePhoneFromOauthSaga);
    yield takeEvery(type.NEW_SIGN_UP_MOBILE_RESEND_CODE_REQ, newSignUpResendTFACodeSaga);
    yield takeEvery(type.NEW_SIGN_UP_MOBILE_CONFIRM_UP_REQ, newSignUpConfirmMobilePhoneSaga);

}
export default watchNewSignUpSaga;