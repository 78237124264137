import * as types from "./../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { getTokenApi } from "./../shared/getTokenApi";
import { call, put, takeEvery } from "redux-saga/effects";
import { checkoutPurchase, productsCheckout } from "../../actions/checkoutActions";
import { postTokenApi } from "../shared/postTokenApi";
import { showWarningSnackbar } from "../../actions/registartionAction";
import { postBlobTokenApi } from "../shared/postBlockTokenApi";

function* checkoutSaga(action: any): TypedIterableIterator<any, any, number> {
  try {
    yield put({ type: types.TOGGLE_LOADER });
    const endpoint = "asset/checkout/" + action.payload.id;
    const checkoutInfo = yield call(getTokenApi, endpoint);
    if (checkoutInfo.status >= 200 && checkoutInfo.status < 300) {
      yield put({
        type: types.GET_CHECKOUT_INFO_SUC,
        payload: {
          checkoutData: checkoutInfo.data,
          tokens: action.payload.tokens,
        },
      });
      yield put({ type: types.TOGGLE_LOADER });
    } else throw checkoutInfo;
  } catch (e:any) {
    if (e.response.data) {
      action.payload.history.push("/login");
      yield put({ type: types.TOGGLE_LOADER });
    }

    yield put({ type: types.GET_USER_IMAGE_FAIL });
  }
}

function* discountSaga(payload: any): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `discount?discountName=${payload.discount}&assetId=${payload.id}`;
    const discountInfo = yield call(getTokenApi, endpoint);
    if (discountInfo.status >= 200 && discountInfo.status < 300) {
      yield put({
        type: types.APPLY_DISCOUNT_SUC,
        payload: discountInfo.data,
      });
    } else throw discountInfo;
  } catch (e:any) {
    yield put({
        type: types.APPLY_DISCOUNT_FAIL,
        errorMessage: e.response.data.message,
    });
  }
}

function* getPaymentsSaga(
  action: any
): TypedIterableIterator<any, any, number> {
  try {
    const paymentsRes = yield call(
      getTokenApi,
      `asset/${action.payload}/payments`
    );
    if (paymentsRes.status >= 200 && paymentsRes.status < 300) {
      yield put({
        type: types.GET_ASSET_PAYMENTS_SUC,
        payload: paymentsRes.data,
      });
    } else throw paymentsRes;
  } catch (e) {
    yield put({
      type: types.GET_ASSET_PAYMENTS_FAIL,
    });
  }
}

function* productsCheckoutSaga(
  action: ReturnType<typeof productsCheckout>
): TypedIterableIterator<any, any, number> {
  try{
  const responce = yield call(
    postTokenApi,
    [action.payload,`tokenization/checkout/create-checkout-session`]
  )
  action.payload.callBack(responce?.data?.url)
  }catch(e:any){
    yield put(showWarningSnackbar('Server error',e?.message))
  }
}

function* checkoutPurchaseSaga(
  action: ReturnType<typeof checkoutPurchase>
): TypedIterableIterator<any, any, number> {
  try{
  const responce = yield call(
    postTokenApi,
    [action.payload,`purchase/stripe`]
  )
  action.payload.callBack(responce.data.url)
  }catch(e:any){
    yield put(showWarningSnackbar('Server error',e?.message))
  }
}

function* getPdfSaga(
  action: any
): TypedIterableIterator<any, any, number> {
  try {
    const wirePdf = yield call(
      postBlobTokenApi,
      [action.payload,`purchase/wire/pdf`]
    );
    if (wirePdf.status >= 200 && wirePdf.status < 300) {
      // console.log(wirePdf.data);
      // yield put({
      //   type: types.GET_ASSET_PAYMENTS_SUC,
      //   payload: wirePdf.data,
      // });
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(new Blob([wirePdf.data], {type: 'application/pdf'}));
      link.setAttribute('download', 'wirePayment.pdf');
      document.body.appendChild(link);
			link.click();
      document.body.removeChild(link);
    } else throw wirePdf;
  } catch (e) {
    console.log('get pdf error ', e)
  }
}

function* watchCheckoutSaga() {
  yield takeEvery(types.GET_CHECKOUT_INFO_REQ, checkoutSaga);
  yield takeEvery(types.GET_WIRE_PDF, getPdfSaga);
  yield takeEvery(types.APPLY_DISCOUNT_REQ, discountSaga);
  yield takeEvery(types.GET_ASSET_PAYMENTS_REQ, getPaymentsSaga);
  yield takeEvery(types.CHECKOUT_PRODUCTS, productsCheckoutSaga);
  yield takeEvery(types.CHECKOUT_PURCHASE, checkoutPurchaseSaga);
}

export default watchCheckoutSaga;
