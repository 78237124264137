import { useState, useEffect } from "react";
import LogoCube from "./../../../Assets/new-landing/icon.png";
import LogoDark from "./../../../Assets/new-landing/DIBS-Letters-new.svg";
import { Link } from "react-router-dom";
import Menu from "../../landing-components/new-landing/dropdown/dropDown";
import MenuTokenization from "../../landing-components/new-landing/dropdown/dropDownTokenization";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import { ReactComponent as Burger } from "./../../../Assets/new-landing/menu.svg";
import Close from "./../../../Assets/new-landing/close.svg";
import Dropdown from "./../../../Assets/new-landing/dropdown.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserImage } from "../../../redux/actions/headerActions";
import { UserImageState } from "../../../interfaces/headerInterface";
import DefaultImage from "../../../Assets/images/default-avatar.svg";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AccountNavCollapse from "../account-nav/desktop-account-nav/account-nav-collapse/accountNavCollapse";

export default function LandingHeader() {
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getUserImage());
    }
  }, [dispatch]);

  const userData = useSelector((state: UserImageState) => state.headerReducer);

  const userImageUrl = userData.userImage || DefaultImage;

  const mobileMenuHandler = () => {
    document.body.classList.toggle("overflow");
    setMenuOpen(!menuOpen);
  };

  const [mobileSubmenuOpen, setMobileSubmenuOpen] = useState(false);
  const [mobileSubmenuTokenizationOpen, setMobileSubmenuTokenizationOpen] = useState(false);
  const [reminder, setReminder] = useState(true);

  useEffect(() => {
    //remove fields accreditationSkipped ready , coz this fields should not affect on reminder
    let needReminder = Object.entries(userData.steps)
      .filter(a => a[0] !== 'accreditationSkipped' && a[0] !== 'ready' && a[0] !== 'accreditation')
      .map(i => i[1]).includes(false)
    if (!userData.isEntity && !userData.steps.accreditation && !userData.steps.accreditationSkipped) {
      needReminder = true
    }
    setReminder(needReminder);
  }, [userData.steps, userData.isEntity])


  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const expiredTime = localStorage.getItem("expiredTime");

  const adminLink = `${process.env.REACT_APP_ADMIN_LINK}/auth?token=${token}&refreshToken=${refreshToken}&expiredTime=${expiredTime}`;

  const adminLinkElement =
    userData.role > 5 ? (
      <a
        className="collapse-header-item"
        href={adminLink}
        style={{ textDecoration: "none" }}
      >
        <div className="collapse-header-text">
          <LockOutlinedIcon
            fill="#333333"
            style={{ width: 20, marginRight: 7 }}
          />
          Admin
        </div>
      </a>
    ) : null;

  return (
    <header className={`new-landing-nav scrolled`}>
      <div className="container" onMouseLeave={() => setOpen(true)}>
        <Link className="logo" to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={LogoCube} alt="solidblock cube" className="logo-cube" />
          <img src={LogoDark} alt="solidblock logo" className="logo-main" />
        </Link>
        <nav className="nav">
          <div className="navSubmenus">
            <Link to="/about" className="menu-item">
              About
            </Link>
            <Link to="/brokers" className="menu-item">
              Brokers
            </Link>
            <span className="menu-item dropdown">
              <MenuTokenization />
            </span>
            <span className="menu-item dropdown">
              <Menu />
            </span>
          </div>
          <div>
            <div className="landing-header-desktop-buttons">
              {localStorage.getItem("token") ? (
                <>
                  <Button
                    style={{ backgroundColor: "#8A00F6" }}
                    className="get-started-button"
                    onClick={() => history.push("/tokenization-form")}
                  >
                    Tokenize
                  </Button>
                  <div className={`logout ${reminder ? 'reminder' : ''}`}>
                    <div className="logout-icon-container">
                      <img
                        onMouseOverCapture={() => setOpen(false)}
                        alt="logout-icon"
                        src={userImageUrl}
                        onClick={() => setOpen(!open)}
                      ></img>
                    </div>
                    <AccountNavCollapse
                      setOpen={setOpen}
                      open={open}
                      admin={adminLinkElement}
                    />
                  </div>
                </>
              ) : (
                <>
                  <a
                    href="https://forms.gle/NwNGDaA6KiNWsdiv8"
                    className="get-started-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Broker Signup
                  </a>
                </>
              )}
            </div>
          </div>
        </nav>
        <div className="burger-nav" onClick={mobileMenuHandler}>
          <Burger />
        </div>
      </div>
      {/* mobile menu */}
      <div className={`mobile-menu ${menuOpen ? "open" : "hidden"}`}>
        <div className="top">
          <div className="logo">
            <img src={LogoCube} alt="solidblock cube-mobile" className="logo-cube-mobile" />
            <img src={LogoDark} alt="solidblock logo-mobile" className="logo-main-mobile" />
          </div>
          <img src={Close} alt="Close" onClick={mobileMenuHandler} />
        </div>
        <div className="nav-mobile">
          <span
            className="nav-link"
            onClick={() => {
              setMobileSubmenuOpen(!mobileSubmenuOpen);
            }}
          >
            Learn {"  "}
            <img alt="dropdown" src={Dropdown} />
          </span>
          <div
            className={`dropdown-mobile ${mobileSubmenuOpen ? "active" : ""}`}
          >
            <span className="sub-link" onClick={() =>
              window.location.assign("https://dibscapital.com/real-estate-tokenization")
            }>
              Understanding Tokenization
            </span>
            <span className="sub-link" onClick={() =>
              window.open("https://ams.solidblock.co/solidblock-value-video")
            }>
              How to Make Assets Liquid (Video)
            </span>
            <Link to="/DAH" className="sub-link">
              Digital Asset Ebook
            </Link>
            <Link to="/course" className="sub-link">
              Tokenization Course
            </Link>
            <span className="sub-link" onClick={() =>
              window.open("https://blog.dibscapital.com/")
            }>
              Blog
            </span>
            <span
              className="sub-link"
              onClick={() =>
                window.open("https://podcast.dibscapital.com/")
              }
            >
              Podcast
            </span>
          </div>
          <span
            className="nav-link"
            onClick={() => {
              setMobileSubmenuTokenizationOpen(!mobileSubmenuTokenizationOpen);
            }}
          >
            Tokenization {"  "}
            <img alt="dropdown" src={Dropdown} />
          </span>
          <div
            className={`dropdown-mobile ${mobileSubmenuTokenizationOpen ? "active" : ""}`}
          >
            <Link to="/tokenization-form" className="sub-link">
              Tokenize your Asset
            </Link>
          </div>
          <Link className="nav-link" to="/about">
            About
          </Link>
          <Link className="nav-link" to="/brokers">
            Brokers
          </Link>
        </div>
        <div className="bottom">
        <a
              href="https://forms.gle/NwNGDaA6KiNWsdiv8"
              className="sign-up"
              target="_blank"
              rel="noreferrer"
            >
              Broker Signup
            </a>
        </div>
      </div>
    </header>
  );
}
