import * as type from "../../constants/types";
import { forgotPassword } from '../../../constants/errors'
const initialState = {
    email: "",
    verificationCode: "",
    newPassword: "",
    codeRedirect: false,
    emailStatus: true,
    codeStatus: true
};
const forgotReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.SEND_EMAIL:
            return {
                ...state,
                email: action.email,
                emailStatus: true
            };
        case type.FORGOT_PASSWORD_REDIRECT:
            return {
                ...state,
                codeRedirect: true,
                codeStatus: true
            };
        case type.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                codeStatus: false
            }
        case type.EMAIL_FOR_FORGOTTEN_FAIL:
            let newMessage: string = forgotPassword[action.message] ? forgotPassword[action.message] : 'something gone wrong please contact us'
            return {
                ...state,
                emailStatus: false,
                message: newMessage
            }
        case type.CLEAR_FORGOT_PASSWORD_ERRORS:
            return {
                ...state,
                emailStatus: true,
                codeStatus: true
            }
        default:
            return state;
    }
};

export default forgotReducer;
