import { call, put, takeEvery } from "redux-saga/effects";
import { getCryptoApi } from "./../shared/getCryptoApi";
import * as type from "../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { getCryptoPriceApi } from "../shared/getCryptoPriceApi";

interface requestedCrypto {
    status: number;
    data: {
        [key: string]: string;
    };
}
function* convertCurrencySaga(action: {
    type: string;
    currency: string;
    total: number;
}): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.TOGGLE_LOADER });
        const currencyData: requestedCrypto = yield call(getCryptoApi, [
            action.currency,
            action.total,
        ]);
        if (currencyData.status >= 200 && currencyData.status < 300) {
            yield put({
                type: type.SET_CONVERTED_VALUE,
                payload: currencyData.data,
                value: action.total,
            });
            yield put({ type: type.TOGGLE_LOADER });
        } else {
            throw currencyData;
        }
    } catch (e) {
        yield put({ type: type.GET_USER_DATA_FAIL, payload: e });
    }
}

function* getCryptoPriceCurrencySaga(action: {
    type: string;
}): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: type.TOGGLE_LOADER });
        const currencyPrices: requestedCrypto = yield call(getCryptoPriceApi);
        if (currencyPrices.status >= 200 && currencyPrices.status < 300) {
            yield put({
                type: type.SET_CONVERTED_PRICES,
                payload: currencyPrices.data,
            });
            yield put({ type: type.TOGGLE_LOADER });
        } else {
            throw currencyPrices;
        }
    } catch (e) {
        yield put({ type: type.GET_USER_DATA_FAIL, payload: e });
    }
}

function* watchGetUserDataSaga() {
    yield takeEvery(type.CURRENCY_CONVERTING, convertCurrencySaga);
    yield takeEvery(type.CURRENCY_CRYPTO_PRICE, getCryptoPriceCurrencySaga);
}

export default watchGetUserDataSaga;
