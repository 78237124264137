import Perks from "./../../../../Assets/images/perks.png";

function InvestorPerks() {
  return (
    <div className="investor-perks">
      <img src={Perks} alt="" className="icon" />
      <div className="top">
        <div className="title">Investor Perks</div>
        <div className="descr">
          In addition to your frOGI tokens, you'll receive perks for investing
          in the Red Frog Beach Portfolio
        </div>
      </div>

      <div className="field-item">
        <div className="invest">Invest $10k</div>
        <div className="bonus">
          Receive a fifth night free on a visit to Red Frog
        </div>
      </div>
      <div className="field-item">
        <div className="invest">Invest $25k</div>
        <div className="bonus">
          Receive fourth and fifth night free on a visit to Red Frog
        </div>
      </div>
      <div className="field-item">
        <div className="invest">Invest $50k </div>
        <div className="bonus">Receive a five night stay free at Red Frog</div>
      </div>
      <div className="field-item">
        <div className="invest">Invest $100k </div>
        <div className="bonus">
          Receive either 10 nights stay free (can be split up) or $10k in
          upgrades in connection with a real estate purchase
        </div>
      </div>
      <div className="field-item">
        <div className="invest">Invest $250k </div>
        <div className="bonus">
          Receive $25k in upgrades in connection with a real estate purchase or
          3% off the purchase of real estate
        </div>
      </div>
      <div className="field-item">
        <div className="invest">Invest $500k</div>
        <div className="bonus">
          Receive $50k in upgrades in connection with a real estate purchase or
          5% off the purchase of real estate
        </div>
      </div>
      <div className="field-item last">
        <div className="bonus">
          Reservations can be made for two persons in a villa or condominium
          subject to availability and may exclude blackout dates.
        </div>
      </div>
    </div>
  );
}

export default InvestorPerks;
