import * as type from "../../constants/types";

const initialState = {
    lat: 0,
    lng: 0,
    description: "",
    link: "",
    title: "",
};
const locationInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_LOCATION_INFO:
            return {
                ...state,
                lat: action.assetData.location.lat,
                lng: action.assetData.location.lng,
                description: action.assetData.marketAnalyze.description,
                link: action.assetData.marketAnalyze.link,
                title: action.assetData.title,
            };
        default:
            return state;
    }
};

export default locationInfoReducer;
