import * as types from "./../../constants/types";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    represents: "",
    documents: [""],
    planToRaise: "",
    customDoc: "",
};

const contactsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.FIRST_STEP_INFO:
            const { value, firstName, lastName, email, phone, companyName } =
                action.data;
            return {
                ...state,
                represents: value,
                firstName,
                lastName,
                email,
                phone,
                company: companyName,
            };
        case types.SECOND_STEP_INFO:
            const { documents, planToRaise, customDoc } = action.data;
            return {
                ...state,
                documents,
                planToRaise,
                customDoc,
            };

        default:
            return state;
    }
};

export default contactsReducer;
