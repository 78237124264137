import { call, put, takeEvery } from "redux-saga/effects";
import { postTokenApi } from "../shared/postTokenApi";
import { ChangePasswordSaga } from './../../../interfaces/profileInterface'
import * as type from "../../constants/types";
import { TypedIterableIterator } from './../../../interfaces/interfaces'
function* changePasswordSaga(action: ChangePasswordSaga): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "auth/changePassword";
        const userData = yield call(postTokenApi, [action.payload, endpoint]);
        console.log(userData)
        if (userData.status >= 200 && userData.status < 300) {
            yield put({
                type: type.CHANGE_PASSWORD_SUC,
                payload: "Password successfully changed",
            });
        } else {
            throw userData;
        }
    } catch (e:any) {
        yield put({
            type: type.CHANGE_PASSWORD_FAIL,
            payload: e.response.data.error,
        });
    }
}

function* watchChangePasswordSaga() {
    yield takeEvery(type.CHANGE_PASSWORD_REQ, changePasswordSaga);
}


export default watchChangePasswordSaga;
