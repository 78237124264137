import { Checkbox } from '@material-ui/core';
const Icon = require('../../Assets/images/Icon.svg').default
const CheckedIcon = require('../../Assets/images/checkedIcon.svg').default
const uncheckedIcon = require('../../Assets/images/uncheckedIcon.svg').default
const CheckoutMenu = (props: any) => {
    const history = props.history.location.pathname;

    return (
        <div className="left-container">
            <div className="checkbox">
                <Checkbox
                    disabled={true}
                    checkedIcon={<img alt='' src={CheckedIcon} />}
                    icon={<img alt='' src={Icon} />}
                    checked={!history.includes('/checkout')}
                />
                <p className='check'>Checkout</p>
            </div>
            <div className="checkbox">
                <Checkbox
                    disabled={true}
                    checkedIcon={<img alt='' src={CheckedIcon} />}
                    icon={history.includes('/payment') ? <img alt='' src={Icon} /> : <img alt='' src={uncheckedIcon} />}
                    checked={history === '/confirmation'}
                />
                <p className={history === '/confirmation' || history.includes('/payment') ? 'check' : 'uncheck'}>Payment Method</p>
            </div>
            <div className="checkbox">
                <Checkbox
                    disabled={true}
                    icon={history === '/confirmation' ? <img alt='' src={Icon} /> : <img alt='' src={uncheckedIcon} />}
                />
                <p className={history === '/confirmation' ? 'check' : 'uncheck'}>Confirmation</p>
            </div>
        </div>
    );
};

export default CheckoutMenu;
