import { call, put, takeEvery } from "redux-saga/effects";
import { postImageTokenApi } from "./../shared/postImageToken";
import { deleteTokenApi } from "../shared/deleteTokenApi";
import * as type from "../../constants/types";
import { ChangeUserImageSaga } from './../../../interfaces/profileInterface'
import { TypedIterableIterator } from './../../../interfaces/interfaces'

function* changeUserImageSaga(action: ChangeUserImageSaga): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user/image";
        const userData = yield call(postImageTokenApi, [
            action.payload,
            endpoint,
        ]);
        if (userData.status >= 200 && userData.status < 300) {
            yield put({
                type: type.CHANGE_USER_IMAGE_SUC,
                payload: userData.data,
            });
            yield put({
                type: type.PUT_HEADER_IMAGE,
                payload: userData.data
            })
        } else {
            throw userData;
        }
    } catch (e) {
        yield put({ type: type.CHANGE_USER_IMAGE_FAIL, payload: e });
    }
}

function* watchChangeUserImage() {
    yield takeEvery(type.CHANGE_USER_IMAGE_REQ, changeUserImageSaga);
}

function* deleteUserImageSaga(
    action: any
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user/image";
        const userData = yield call(deleteTokenApi, endpoint);
        if (userData.status >= 200 && userData.status < 300) {
            yield put({ type: type.DELETE_USER_IMAGE_SUC, payload: null });
            yield put({
                type: type.PUT_HEADER_IMAGE,
                payload: userData.data
            })
        } else {
            throw userData;
        }
    } catch (e) { }
}

function* watchDeleteUserImageSaga() {
    yield takeEvery(type.DELETE_USER_IMAGE_REQ, deleteUserImageSaga);
}


export { watchChangeUserImage, watchDeleteUserImageSaga };
