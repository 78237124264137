import * as type from "../../constants/types";

const initialState = {
  id: "",
  creditMultiplier: "",
  currency: "",
  alias: "",
  imagePath: "",
  location: "",
  maxAmount: 0,
  minAmount: 0,
  creditTokenPrice: 0,
  title: "",
  unitPrice: 0,
  credits: "",
  discountId: "",
  discountActive: false,
  discountError: false,
  discountSize: 0,
  discountDiff: 0,
  tokens: 0,
  total: 0,
  checkoutLoading: false,
  purchasesCount: 0,
  purchasesTokens: 0,
  purchasesAmount: 0,
  transactionType: "",
  assetPayments: []
};
const checkoutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GET_CHECKOUT_INFO_SUC:
      const {
        id,
        creditMultiplier,
        currency,
        alias,
        imagePath,
        location,
        maxAmount,
        minAmount,
        title,
        unitPrice,
        creditTokenPrice,
      } = action.payload.checkoutData.asset;
      return {
        ...state,
        id,
        creditMultiplier,
        currency,
        alias,
        imagePath,
        location,
        minAmount,
        maxAmount,
        creditTokenPrice,
        title,
        unitPrice,
        credits: action.payload.credits,
        tokens: action.payload.tokens,
        total: action.payload.tokens * unitPrice,
      };
    case type.APPLY_DISCOUNT_SUC:
      return {
        ...state,
        discountActive: true,
        discountError: false,
        discountId: action.payload.id,
        discountSize: action.payload.discountSize,
        discountDiff: (state.tokens * state.unitPrice) * action.payload.discountSize,
        total: (state.tokens * state.unitPrice) * (1 - action.payload.discountSize)
      }
    case type.APPLY_DISCOUNT_FAIL:
      return {
        ...state,
        discountActive: false,
        discountError: true,
        discountDiff: 0,
        total: state.tokens * state.unitPrice
      }
    case type.CLEAR_DISCOUNT:
      return {
        ...state,
        discountActive: false,
        discountError: false,
        discountDiff: 0,
      }
    default:
      return state;
  }
};

export default checkoutReducer;
