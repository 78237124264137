import { useState } from 'react';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AccreditationModalProps } from "../../../../interfaces/authInterfaces";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import { subscribeUpcoming } from './../../../../redux/actions/assetsAction';
import {
    useDispatch,
    // useSelector 
} from 'react-redux';
//import { UserImageState } from '../../../../interfaces/headerInterface';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: "100%",
            maxWidth: "583px",
            boxShadow: theme.shadows[5],
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing(2, 4, 2, 3),
        },
        input: {
            marginRight: 44,
            maxWidth: 270,
            width: '100%',
            marginBottom: 10
        },
        send: {
            color: '#fff',
            width: 102,
            '& .MuiButton-label': {
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: 14,
                textTransform: 'none'
            }
        }
    })
);

export default function ComingSoonModal(props: AccreditationModalProps) {
    const classes = useStyles();

    const { open, handleModal, title, assetId } = props;

    // const userData = useSelector((state: UserImageState) => state.headerReducer);

    const [email, setEmail] = useState('');

    const dispatch = useDispatch();

    const subscribe = () => {
        if (!assetId) return
        dispatch(subscribeUpcoming({ email: email, assetId }))
        handleModal()
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="coming-soon">
                        <div className={`${classes.paper} `}>
                            <div>
                                <h2 className="file-title">
                                    {title === 'UPCOMING' ? 'Project is coming very soon' : 'This asset is funded'}
                                </h2>
                                <p className='modal-text'>
                                    Leave your email to be among the first to gain access.
                                </p>
                                <div className="form">
                                    <TextField
                                        value={email}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        className={classes.input}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.send}
                                        onClick={subscribe}>Send</Button>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ height: "22%" }}
                            className="close-desktop"
                        >
                            <CloseIcon
                                onClick={handleModal}
                                style={{
                                    marginLeft: "10px",
                                    cursor: "pointer"
                                }}
                                className="close-icon"
                            />
                        </div>
                    </div>
                </Fade>
            </Modal >
        </div >
    );
}
