const AUTH_HEADER_HEADING =
    "A few clicks away from creating your real estate asset.";
const AUTH_HEADER_SUB_HEADING =
    "We love helping entrepreneurs build great things.";

const AUTH_HEADER_HEAD_INVESTOR = "A few clicks away from growing your global real estate portfolio"
const AUTH_HEADER_SUB_INVESTOR = "We love helping investors build great things"
const DESKTOP = "desktop";
const MOBILE = "mobile";

// const US = ["1"];
const CODES: any = {
    "1": {
        name: "United States",
        country_code: "US",
        flag: require("../Assets/images/flags/us.svg"),
    },
    "6": {
        name: "Canada",
        country_code: "CA",
        flag: require("../Assets/images/flags/Canada.png"),
    },
    "376": {
        name: "Andorra",
        country_code: "AD",
        flag: require("../Assets/images/flags/ad.svg"),
    },
    "971": {
        name: "United Arab Emirates",
        country_code: "AE",
        flag: require("../Assets/images/flags/ae.svg"),
    },
    "93": {
        name: "Afghanistan",
        country_code: "AF",
        flag: require("../Assets/images/flags/af.svg"),
    },
    "1268": {
        name: "Antigua and Barbuda",
        country_code: "AG",
        flag: require("../Assets/images/flags/ag.svg"),
    },
    "1264": {
        name: "Anguilla",
        country_code: "AI",
        flag: require("../Assets/images/flags/ai.svg"),
    },
    "355": {
        name: "Albania",
        country_code: "AL",
        flag: require("../Assets/images/flags/al.svg"),
    },
    "374": {
        name: "Armenia",
        country_code: "AM",
        flag: require("../Assets/images/flags/am.svg"),
    },
    "599": {
        name: "Netherlands Antilles",
        country_code: "AN",
        flag: require("../Assets/images/flags/an.svg"),
    },
    "244": {
        name: "Angola",
        country_code: "AO",
        flag: require("../Assets/images/flags/ao.svg"),
    },
    "672": {
        name: "Antarctica",
        country_code: "AQ",
        flag: require("../Assets/images/flags/aq.svg"),
    },
    "54": {
        name: "Argentina",
        country_code: "AR",
        flag: require("../Assets/images/flags/ar.svg"),
    },
    "1684": {
        name: "American Samoa",
        country_code: "AS",
        flag: require("../Assets/images/flags/as.svg"),
    },
    "43": {
        name: "Austria",
        country_code: "AT",
        flag: require("../Assets/images/flags/at.svg"),
    },
    "61": {
        name: "Australia",
        country_code: "AU",
        flag: require("../Assets/images/flags/au.svg"),
    },
    "297": {
        name: "Aruba",
        country_code: "AW",
        flag: require("../Assets/images/flags/aw.svg"),
    },
    "358": {
        name: "Aland Islands",
        country_code: "AX",
        flag: require("../Assets/images/flags/ax.svg"),
    },
    "994": {
        name: "Azerbaijan",
        country_code: "AZ",
        flag: require("../Assets/images/flags/az.svg"),
    },
    "387": {
        name: "Bosnia and Herzegovina",
        country_code: "BA",
        flag: require("../Assets/images/flags/ba.svg"),
    },
    "1246": {
        name: "Barbados",
        country_code: "BB",
        flag: require("../Assets/images/flags/bb.svg"),
    },
    "880": {
        name: "Bangladesh",
        country_code: "BD",
        flag: require("../Assets/images/flags/bd.svg"),
    },
    "32": {
        name: "Belgium",
        country_code: "BE",
        flag: require("../Assets/images/flags/be.svg"),
    },

    "7": {
        name: "Russia",
        country_code: "RU",
        flag: require("../Assets/images/flags/ru.svg"),
    },
    "20": {
        name: "Egypt",
        country_code: "EG",
        flag: require("../Assets/images/flags/eg.svg"),
    },
    "27": {
        name: "South Africa",
        country_code: "ZA",
        flag: require("../Assets/images/flags/za.svg"),
    },
    "30": {
        name: "Greece",
        country_code: "GR",
        flag: require("../Assets/images/flags/gr.svg"),
    },
    "31": {
        name: "Netherlands",
        country_code: "NL",
        flag: require("../Assets/images/flags/nl.svg"),
    },
    "33": {
        name: "France",
        country_code: "FR",
        flag: require("../Assets/images/flags/fr.svg"),
    },
    "34": {
        name: "Spain",
        country_code: "ES",
        flag: require("../Assets/images/flags/es.svg"),
    },
    "36": {
        name: "Hungary",
        country_code: "HU",
        flag: require("../Assets/images/flags/hu.svg"),
    },
    "39": {
        name: "Italy",
        country_code: "IT",
        flag: require("../Assets/images/flags/it.svg"),
    },
    "40": {
        name: "Romania",
        country_code: "RO",
        flag: require("../Assets/images/flags/ro.svg"),
    },
    "41": {
        name: "Switzerland",
        country_code: "CH",
        flag: require("../Assets/images/flags/ch.svg"),
    },
    "44": {
        name: "Jersey",
        country_code: "JE",
        flag: require("../Assets/images/flags/je.svg"),
    },
    "": {
        name: "United Kingdom",
        country_code: "UK",
        flag: require("../Assets/images/flags/gb.svg"),
    },
    "45": {
        name: "Denmark",
        country_code: "DK",
        flag: require("../Assets/images/flags/dk.svg"),
    },
    "46": {
        name: "Sweden",
        country_code: "SE",
        flag: require("../Assets/images/flags/se.svg"),
    },
    "47": {
        name: "Svalbard and Jan Mayen",
        country_code: "SJ",
        flag: require("../Assets/images/flags/sj.svg"),
    },
    "48": {
        name: "Poland",
        country_code: "PL",
        flag: require("../Assets/images/flags/pl.svg"),
    },
    "49": {
        name: "Germany",
        country_code: "DE",
        flag: require("../Assets/images/flags/de.svg"),
    },
    "51": {
        name: "Peru",
        country_code: "PE",
        flag: require("../Assets/images/flags/pe.svg"),
    },
    "52": {
        name: "Mexico",
        country_code: "MX",
        flag: require("../Assets/images/flags/mx.svg"),
    },
    "53": {
        name: "Cuba",
        country_code: "CU",
        flag: require("../Assets/images/flags/cu.svg"),
    },
    "55": {
        name: "Brazil",
        country_code: "BR",
        flag: require("../Assets/images/flags/br.svg"),
    },
    "56": {
        name: "Chile",
        country_code: "CL",
        flag: require("../Assets/images/flags/cl.svg"),
    },
    "57": {
        name: "Colombia",
        country_code: "CO",
        flag: require("../Assets/images/flags/co.svg"),
    },
    "58": {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        country_code: "VE",
        flag: require("../Assets/images/flags/ve.svg"),
    },
    "60": {
        name: "Malaysia",
        country_code: "MY",
        flag: require("../Assets/images/flags/my.svg"),
    },
    "62": {
        name: "Indonesia",
        country_code: "ID",
        flag: require("../Assets/images/flags/id.svg"),
    },
    "63": {
        name: "Philippines",
        country_code: "PH",
        flag: require("../Assets/images/flags/ph.svg"),
    },
    "64": {
        name: "New Zealand",
        country_code: "NZ",
        flag: require("../Assets/images/flags/nz.svg"),
    },
    "65": {
        name: "Singapore",
        country_code: "SG",
        flag: require("../Assets/images/flags/sg.svg"),
    },
    "66": {
        name: "Thailand",
        country_code: "TH",
        flag: require("../Assets/images/flags/th.svg"),
    },
    "77": {
        name: "Kazakhstan",
        country_code: "KZ",
        flag: require("../Assets/images/flags/kz.svg"),
    },
    "81": {
        name: "Japan",
        country_code: "JP",
        flag: require("../Assets/images/flags/jp.svg"),
    },
    "82": {
        name: "Korea, Republic of South Korea",
        country_code: "KR",
        flag: require("../Assets/images/flags/kr.svg"),
    },
    "84": {
        name: "Vietnam",
        country_code: "VN",
        flag: require("../Assets/images/flags/vn.svg"),
    },
    "86": {
        name: "China",
        country_code: "CN",
        flag: require("../Assets/images/flags/cn.svg"),
    },
    "90": {
        name: "Turkey",
        country_code: "TR",
        flag: require("../Assets/images/flags/tr.svg"),
    },
    "91": {
        name: "India",
        country_code: "IN",
        flag: require("../Assets/images/flags/in.svg"),
    },
    "92": {
        name: "Pakistan",
        country_code: "PK",
        flag: require("../Assets/images/flags/pk.svg"),
    },
    "94": {
        name: "Sri Lanka",
        country_code: "LK",
        flag: require("../Assets/images/flags/lk.svg"),
    },
    "95": {
        name: "Myanmar",
        country_code: "MM",
        flag: require("../Assets/images/flags/mm.svg"),
    },
    "98": {
        name: "Iran, Islamic Republic of Persian Gulf",
        country_code: "IR",
        flag: require("../Assets/images/flags/ir.svg"),
    },
    "211": {
        name: "South Sudan",
        country_code: "SS",
        flag: require("../Assets/images/flags/ss.svg"),
    },
    "212": {
        name: "Morocco",
        country_code: "MA",
        flag: require("../Assets/images/flags/ma.svg"),
    },
    "213": {
        name: "Algeria",
        country_code: "DZ",
        flag: require("../Assets/images/flags/dz.svg"),
    },
    "216": {
        name: "Tunisia",
        country_code: "TN",
        flag: require("../Assets/images/flags/tn.svg"),
    },
    "218": {
        name: "Libya",
        country_code: "LY",
        flag: require("../Assets/images/flags/ly.svg"),
    },
    "220": {
        name: "Gambia",
        country_code: "GM",
        flag: require("../Assets/images/flags/gm.svg"),
    },
    "221": {
        name: "Senegal",
        country_code: "SN",
        flag: require("../Assets/images/flags/sn.svg"),
    },
    "222": {
        name: "Mauritania",
        country_code: "MR",
        flag: require("../Assets/images/flags/mr.svg"),
    },
    "223": {
        name: "Mali",
        country_code: "ML",
        flag: require("../Assets/images/flags/ml.svg"),
    },
    "224": {
        name: "Guinea",
        country_code: "GN",
        flag: require("../Assets/images/flags/gn.svg"),
    },
    "225": {
        name: "Cote d'Ivoire",
        country_code: "CI",
        flag: require("../Assets/images/flags/ci.svg"),
    },
    "226": {
        name: "Burkina Faso",
        country_code: "BF",
        flag: require("../Assets/images/flags/bf.svg"),
    },
    "227": {
        name: "Niger",
        country_code: "NE",
        flag: require("../Assets/images/flags/ne.svg"),
    },
    "228": {
        name: "Togo",
        country_code: "TG",
        flag: require("../Assets/images/flags/tg.svg"),
    },
    "229": {
        name: "Benin",
        country_code: "BJ",
        flag: require("../Assets/images/flags/bj.svg"),
    },
    "230": {
        name: "Mauritius",
        country_code: "MU",
        flag: require("../Assets/images/flags/mu.svg"),
    },
    "231": {
        name: "Liberia",
        country_code: "LR",
        flag: require("../Assets/images/flags/lr.svg"),
    },
    "232": {
        name: "Sierra Leone",
        country_code: "SL",
        flag: require("../Assets/images/flags/sl.svg"),
    },
    "233": {
        name: "Ghana",
        country_code: "GH",
        flag: require("../Assets/images/flags/gh.svg"),
    },
    "234": {
        name: "Nigeria",
        country_code: "NG",
        flag: require("../Assets/images/flags/ng.svg"),
    },
    "235": {
        name: "Chad",
        country_code: "TD",
        flag: require("../Assets/images/flags/td.svg"),
    },
    "236": {
        name: "Central African Republic",
        country_code: "CF",
        flag: require("../Assets/images/flags/cf.svg"),
    },
    "237": {
        name: "Cameroon",
        country_code: "CM",
        flag: require("../Assets/images/flags/cm.svg"),
    },
    "238": {
        name: "Cape Verde",
        country_code: "CV",
        flag: require("../Assets/images/flags/cv.svg"),
    },
    "239": {
        name: "Sao Tome and Principe",
        country_code: "ST",
        flag: require("../Assets/images/flags/st.svg"),
    },
    "240": {
        name: "Equatorial Guinea",
        country_code: "GQ",
        flag: require("../Assets/images/flags/gq.svg"),
    },
    "241": {
        name: "Gabon",
        country_code: "GA",
        flag: require("../Assets/images/flags/ga.svg"),
    },
    "242": {
        name: "Congo",
        country_code: "CG",
        flag: require("../Assets/images/flags/cg.svg"),
    },
    "243": {
        name: "Congo, The Democratic Republic of the Congo",
        country_code: "CD",
        flag: require("../Assets/images/flags/cd.svg"),
    },
    "245": {
        name: "Guinea-Bissau",
        country_code: "GW",
        flag: require("../Assets/images/flags/gw.svg"),
    },
    "246": {
        name: "British Indian Ocean Territory",
        country_code: "IO",
        flag: require("../Assets/images/flags/io.svg"),
    },
    "248": {
        name: "Seychelles",
        country_code: "SC",
        flag: require("../Assets/images/flags/sc.svg"),
    },
    "249": {
        name: "Sudan",
        country_code: "SD",
        flag: require("../Assets/images/flags/sd.svg"),
    },
    "250": {
        name: "Rwanda",
        country_code: "RW",
        flag: require("../Assets/images/flags/rw.svg"),
    },
    "251": {
        name: "Ethiopia",
        country_code: "ET",
        flag: require("../Assets/images/flags/et.svg"),
    },
    "252": {
        name: "Somalia",
        country_code: "SO",
        flag: require("../Assets/images/flags/so.svg"),
    },

    "253": {
        name: "Djibouti",
        country_code: "DJ",
        flag: require("../Assets/images/flags/dj.svg"),
    },
    "254": {
        name: "Kenya",
        country_code: "KE",
        flag: require("../Assets/images/flags/ke.svg"),
    },
    "255": {
        name: "Tanzania, United Republic of Tanzania",
        country_code: "TZ",
        flag: require("../Assets/images/flags/tz.svg"),
    },
    "256": {
        name: "Uganda",
        country_code: "UG",
        flag: require("../Assets/images/flags/ug.svg"),
    },
    "257": {
        name: "Burundi",
        country_code: "BI",
        flag: require("../Assets/images/flags/bi.svg"),
    },
    "258": {
        name: "Mozambique",
        country_code: "MZ",
        flag: require("../Assets/images/flags/mz.svg"),
    },
    "260": {
        name: "Zambia",
        country_code: "ZM",
        flag: require("../Assets/images/flags/zm.svg"),
    },
    "261": {
        name: "Madagascar",
        country_code: "MG",
        flag: require("../Assets/images/flags/mg.svg"),
    },
    "262": {
        name: "Mayotte",
        country_code: "YT",
        flag: require("../Assets/images/flags/yt.svg"),
    },
    "263": {
        name: "Zimbabwe",
        country_code: "ZW",
        flag: require("../Assets/images/flags/zw.svg"),
    },
    "264": {
        name: "Namibia",
        country_code: "NA",
        flag: require("../Assets/images/flags/na.svg"),
    },
    "265": {
        name: "Malawi",
        country_code: "MW",
        flag: require("../Assets/images/flags/mw.svg"),
    },
    "266": {
        name: "Lesotho",
        country_code: "LS",
        flag: require("../Assets/images/flags/ls.svg"),
    },
    "267": {
        name: "Botswana",
        country_code: "BW",
        flag: require("../Assets/images/flags/bw.svg"),
    },
    "268": {
        name: "Swaziland",
        country_code: "SZ",
        flag: require("../Assets/images/flags/sz.svg"),
    },
    "269": {
        name: "Comoros",
        country_code: "KM",
        flag: require("../Assets/images/flags/km.svg"),
    },
    "291": {
        name: "Eritrea",
        country_code: "ER",
        flag: require("../Assets/images/flags/er.svg"),
    },
    "298": {
        name: "Faroe Islands",
        country_code: "FO",
        flag: require("../Assets/images/flags/fo.svg"),
    },
    "299": {
        name: "Greenland",
        country_code: "GL",
        flag: require("../Assets/images/flags/gl.svg"),
    },
    "350": {
        name: "Gibraltar",
        country_code: "GI",
        flag: require("../Assets/images/flags/gi.svg"),
    },
    "351": {
        name: "Portugal",
        country_code: "PT",
        flag: require("../Assets/images/flags/pt.svg"),
    },
    "352": {
        name: "Luxembourg",
        country_code: "LU",
        flag: require("../Assets/images/flags/lu.svg"),
    },
    "353": {
        name: "Ireland",
        country_code: "IE",
        flag: require("../Assets/images/flags/ie.svg"),
    },
    "354": {
        name: "Iceland",
        country_code: "IS",
        flag: require("../Assets/images/flags/is.svg"),
    },
    "356": {
        name: "Malta",
        country_code: "MT",
        flag: require("../Assets/images/flags/mt.svg"),
    },
    "357": {
        name: "Cyprus",
        country_code: "CY",
        flag: require("../Assets/images/flags/cy.svg"),
    },
    "359": {
        name: "Bulgaria",
        country_code: "BG",
        flag: require("../Assets/images/flags/bg.svg"),
    },
    "370": {
        name: "Lithuania",
        country_code: "LT",
        flag: require("../Assets/images/flags/lt.svg"),
    },
    "371": {
        name: "Latvia",
        country_code: "LV",
        flag: require("../Assets/images/flags/lv.svg"),
    },
    "372": {
        name: "Estonia",
        country_code: "EE",
        flag: require("../Assets/images/flags/ee.svg"),
    },
    "373": {
        name: "Moldova",
        country_code: "MD",
        flag: require("../Assets/images/flags/md.svg"),
    },
    "375": {
        name: "Belarus",
        country_code: "BY",
        flag: require("../Assets/images/flags/by.svg"),
    },
    "377": {
        name: "Monaco",
        country_code: "MC",
        flag: require("../Assets/images/flags/mc.svg"),
    },
    "378": {
        name: "San Marino",
        country_code: "SM",
        flag: require("../Assets/images/flags/sm.svg"),
    },
    "379": {
        name: "Holy See (Vatican City State)",
        country_code: "VA",
        flag: require("../Assets/images/flags/va.svg"),
    },
    "380": {
        name: "Ukraine",
        country_code: "UA",
        flag: require("../Assets/images/flags/ua.svg"),
    },
    "381": {
        name: "Serbia",
        country_code: "RS",
        flag: require("../Assets/images/flags/rs.svg"),
    },
    "382": {
        name: "Montenegro",
        country_code: "ME",
        flag: require("../Assets/images/flags/me.svg"),
    },
    "385": {
        name: "Croatia",
        country_code: "HR",
        flag: require("../Assets/images/flags/hr.svg"),
    },
    "386": {
        name: "Slovenia",
        country_code: "SI",
        flag: require("../Assets/images/flags/si.svg"),
    },
    "389": {
        name: "Macedonia",
        country_code: "MK",
        flag: require("../Assets/images/flags/mk.svg"),
    },
    "420": {
        name: "Czech Republic",
        country_code: "CZ",
        flag: require("../Assets/images/flags/cz.svg"),
    },
    "421": {
        name: "Slovakia",
        country_code: "SK",
        flag: require("../Assets/images/flags/sk.svg"),
    },
    "423": {
        name: "Liechtenstein",
        country_code: "LI",
        flag: require("../Assets/images/flags/li.svg"),
    },
    "500": {
        name: "South Georgia and the South Sandwich Islands",
        country_code: "GS",
        flag: require("../Assets/images/flags/gs.svg"),
    },
    "501": {
        name: "Belize",
        country_code: "BZ",
        flag: require("../Assets/images/flags/bz.svg"),
    },
    "502": {
        name: "Guatemala",
        country_code: "GT",
        flag: require("../Assets/images/flags/gt.svg"),
    },
    "503": {
        name: "El Salvador",
        country_code: "SV",
        flag: require("../Assets/images/flags/sv.svg"),
    },
    "504": {
        name: "Honduras",
        country_code: "HN",
        flag: require("../Assets/images/flags/hn.svg"),
    },
    "505": {
        name: "Nicaragua",
        country_code: "NI",
        flag: require("../Assets/images/flags/ni.svg"),
    },
    "506": {
        name: "Costa Rica",
        country_code: "CR",
        flag: require("../Assets/images/flags/cr.svg"),
    },
    "507": {
        name: "Panama",
        country_code: "PA",
        flag: require("../Assets/images/flags/pa.svg"),
    },
    "508": {
        name: "Saint Pierre and Miquelon",
        country_code: "PM",
        flag: require("../Assets/images/flags/pm.svg"),
    },
    "509": {
        name: "Haiti",
        country_code: "HT",
        flag: require("../Assets/images/flags/ht.svg"),
    },
    "590": {
        name: "Saint Martin",
        country_code: "MF",
        flag: require("../Assets/images/flags/mf.svg"),
    },
    "591": {
        name: "Bolivia, Plurinational State of",
        country_code: "BO",
        flag: require("../Assets/images/flags/bo.svg"),
    },
    "593": {
        name: "Ecuador",
        country_code: "EC",
        flag: require("../Assets/images/flags/ec.svg"),
    },
    "594": {
        name: "French Guiana",
        country_code: "GF",
        flag: require("../Assets/images/flags/gf.svg"),
    },
    "595": {
        name: "Paraguay",
        country_code: "PY",
        flag: require("../Assets/images/flags/py.svg"),
    },
    "596": {
        name: "Martinique",
        country_code: "MQ",
        flag: require("../Assets/images/flags/mq.svg"),
    },
    "597": {
        name: "Suriname",
        country_code: "SR",
        flag: require("../Assets/images/flags/sr.svg"),
    },
    "598": {
        name: "Uruguay",
        country_code: "UY",
        flag: require("../Assets/images/flags/uy.svg"),
    },
    "670": {
        name: "Timor-Leste",
        country_code: "TL",
        flag: require("../Assets/images/flags/tl.svg"),
    },
    "673": {
        name: "Brunei Darussalam",
        country_code: "BN",
        flag: require("../Assets/images/flags/bn.svg"),
    },
    "674": {
        name: "Nauru",
        country_code: "NR",
        flag: require("../Assets/images/flags/nr.svg"),
    },
    "675": {
        name: "Papua New Guinea",
        country_code: "PG",
        flag: require("../Assets/images/flags/pg.svg"),
    },
    "676": {
        name: "Tonga",
        country_code: "TO",
        flag: require("../Assets/images/flags/to.svg"),
    },
    "677": {
        name: "Solomon Islands",
        country_code: "SB",
        flag: require("../Assets/images/flags/sb.svg"),
    },
    "678": {
        name: "Vanuatu",
        country_code: "VU",
        flag: require("../Assets/images/flags/vu.svg"),
    },
    "679": {
        name: "Fiji",
        country_code: "FJ",
        flag: require("../Assets/images/flags/fj.svg"),
    },
    "680": {
        name: "Palau",
        country_code: "PW",
        flag: require("../Assets/images/flags/pw.svg"),
    },
    "681": {
        name: "Wallis and Futuna",
        country_code: "WF",
        flag: require("../Assets/images/flags/wf.svg"),
    },
    "682": {
        name: "Cook Islands",
        country_code: "CK",
        flag: require("../Assets/images/flags/ck.svg"),
    },
    "683": {
        name: "Niue",
        country_code: "NU",
        flag: require("../Assets/images/flags/nu.svg"),
    },
    "685": {
        name: "Samoa",
        country_code: "WS",
        flag: require("../Assets/images/flags/ws.svg"),
    },
    "686": {
        name: "Kiribati",
        country_code: "KI",
        flag: require("../Assets/images/flags/ki.svg"),
    },
    "687": {
        name: "New Caledonia",
        country_code: "NC",
        flag: require("../Assets/images/flags/nc.svg"),
    },
    "688": {
        name: "Tuvalu",
        country_code: "TV",
        flag: require("../Assets/images/flags/tv.svg"),
    },
    "689": {
        name: "French Polynesia",
        country_code: "PF",
        flag: require("../Assets/images/flags/pf.svg"),
    },
    "690": {
        name: "Tokelau",
        country_code: "TK",
        flag: require("../Assets/images/flags/tk.svg"),
    },
    "691": {
        name: "Micronesia, Federated States of Micronesia",
        country_code: "FM",
        flag: require("../Assets/images/flags/fm.svg"),
    },
    "692": {
        name: "Marshall Islands",
        country_code: "MH",
        flag: require("../Assets/images/flags/mh.svg"),
    },
    "850": {
        name: "Korea, Democratic People's Republic of Korea",
        country_code: "KP",
        flag: require("../Assets/images/flags/kp.svg"),
    },
    "852": {
        name: "Hong Kong",
        country_code: "HK",
        flag: require("../Assets/images/flags/hk.svg"),
    },
    "853": {
        name: "Macao",
        country_code: "MO",
        flag: require("../Assets/images/flags/mo.svg"),
    },
    "855": {
        name: "Cambodia",
        country_code: "KH",
        flag: require("../Assets/images/flags/kh.svg"),
    },
    "856": {
        name: "Laos",
        country_code: "LA",
        flag: require("../Assets/images/flags/la.svg"),
    },
    "872": {
        name: "Pitcairn",
        country_code: "PN",
        flag: require("../Assets/images/flags/pn.svg"),
    },
    "886": {
        name: "Taiwan",
        country_code: "TW",
        flag: require("../Assets/images/flags/tw.svg"),
    },
    "960": {
        name: "Maldives",
        country_code: "MV",
        flag: require("../Assets/images/flags/mv.svg"),
    },
    "961": {
        name: "Lebanon",
        country_code: "LB",
        flag: require("../Assets/images/flags/lb.svg"),
    },
    "962": {
        name: "Jordan",
        country_code: "JO",
        flag: require("../Assets/images/flags/jo.svg"),
    },
    "963": {
        name: "Syrian Arab Republic",
        country_code: "SY",
        flag: require("../Assets/images/flags/sy.svg"),
    },
    "964": {
        name: "Iraq",
        country_code: "IQ",
        flag: require("../Assets/images/flags/iq.svg"),
    },
    "965": {
        name: "Kuwait",
        country_code: "KW",
        flag: require("../Assets/images/flags/kw.svg"),
    },
    "966": {
        name: "Saudi Arabia",
        country_code: "SA",
        flag: require("../Assets/images/flags/sa.svg"),
    },
    "967": {
        name: "Yemen",
        country_code: "YE",
        flag: require("../Assets/images/flags/ye.svg"),
    },
    "968": {
        name: "Oman",
        country_code: "OM",
        flag: require("../Assets/images/flags/om.svg"),
    },
    "970": {
        name: "Palestinian Territory, Occupied",
        country_code: "PS",
        flag: require("../Assets/images/flags/ps.svg"),
    },
    "972": {
        name: "Israel",
        country_code: "IL",
        flag: require("../Assets/images/flags/il.svg"),
    },
    "973": {
        name: "Bahrain",
        country_code: "BH",
        flag: require("../Assets/images/flags/bh.svg"),
    },
    "974": {
        name: "Qatar",
        country_code: "QA",
        flag: require("../Assets/images/flags/qa.svg"),
    },
    "975": {
        name: "Bhutan",
        country_code: "BT",
        flag: require("../Assets/images/flags/bt.svg"),
    },
    "976": {
        name: "Mongolia",
        country_code: "MN",
        flag: require("../Assets/images/flags/mn.svg"),
    },
    "977": {
        name: "Nepal",
        country_code: "NP",
        flag: require("../Assets/images/flags/np.svg"),
    },
    "992": {
        name: "Tajikistan",
        country_code: "TJ",
        flag: require("../Assets/images/flags/tj.svg"),
    },
    "993": {
        name: "Turkmenistan",
        country_code: "TM",
        flag: require("../Assets/images/flags/tm.svg"),
    },
    "995": {
        name: "Georgia",
        country_code: "GE",
        flag: require("../Assets/images/flags/ge.svg"),
    },
    "996": {
        name: "Kyrgyzstan",
        country_code: "KG",
        flag: require("../Assets/images/flags/kg.svg"),
    },
    "998": {
        name: "Uzbekistan",
        country_code: "UZ",
        flag: require("../Assets/images/flags/uz.svg"),
    },
    "1242": {
        name: "Bahamas",
        country_code: "BS",
        flag: require("../Assets/images/flags/bs.svg"),
    },
    "1284": {
        name: "Virgin Islands, British",
        country_code: "VG",
        flag: require("../Assets/images/flags/vg.svg"),
    },
    "1340": {
        name: "Virgin Islands, U.S.",
        country_code: "VI",
        flag: require("../Assets/images/flags/vi.svg"),
    },
    "1441": {
        name: "Bermuda",
        country_code: "BM",
        flag: require("../Assets/images/flags/bm.svg"),
    },
    "1473": {
        name: "Grenada",
        country_code: "GD",
        flag: require("../Assets/images/flags/gd.svg"),
    },
    "1649": {
        name: "Turks and Caicos Islands",
        country_code: "TC",
        flag: require("../Assets/images/flags/tc.svg"),
    },
    "1664": {
        name: "Montserrat",
        country_code: "MS",
        flag: require("../Assets/images/flags/ms.svg"),
    },
    "1670": {
        name: "Northern Mariana Islands",
        country_code: "MP",
        flag: require("../Assets/images/flags/mp.svg"),
    },
    "1671": {
        name: "Guam",
        country_code: "GU",
        flag: require("../Assets/images/flags/gu.svg"),
    },
    "1758": {
        name: "Saint Lucia",
        country_code: "LC",
        flag: require("../Assets/images/flags/lc.svg"),
    },
    "1767": {
        name: "Dominica",
        country_code: "DM",
        flag: require("../Assets/images/flags/dm.svg"),
    },
    "1784": {
        name: "Saint Vincent and the Grenadines",
        country_code: "VC",
        flag: require("../Assets/images/flags/vc.svg"),
    },
    "1849": {
        name: "Dominican Republic",
        country_code: "DO",
        flag: require("../Assets/images/flags/do.svg"),
    },
    "1868": {
        name: "Trinidad and Tobago",
        country_code: "TT",
        flag: require("../Assets/images/flags/tt.svg"),
    },
    "1869": {
        name: "Saint Kitts and Nevis",
        country_code: "KN",
        flag: require("../Assets/images/flags/kn.svg"),
    },
    "1876": {
        name: "Jamaica",
        country_code: "JM",
        flag: require("../Assets/images/flags/jm.svg"),
    },
    "1939": {
        name: "Puerto Rico",
        country_code: "PR",
        flag: require("../Assets/images/flags/pr.svg"),
    },
    "345": {
        name: "Cayman Islands",
        country_code: "KY",
        flag: require("../Assets/images/flags/ky.svg"),
    },
    "290": {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        country_code: "SH",
        flag: require("../Assets/images/flags/sh.svg"),
    },
};
// const KEYS = Object.keys(CODES).sort((a, b) => +a - +b);
const COUNTRIES = Object.keys(CODES).sort((a, b) =>
    CODES[a].name.localeCompare(CODES[b].name)
);
// const COUNTRIES_SORTED = US.concat(COUNTRIES.slice(1, COUNTRIES.length));

const COUNTRIES_NEW = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1268' },
    { code: 'AI', label: 'Anguilla', phone: '1264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Aland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the Congo', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of Micronesia', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of Persian Gulf', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of Korea", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of South Korea', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of Macedonia', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russia', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
    { code: '', label: '', phone: '' },
];

export {
    AUTH_HEADER_HEADING,
    AUTH_HEADER_SUB_HEADING,
    AUTH_HEADER_HEAD_INVESTOR,
    AUTH_HEADER_SUB_INVESTOR,
    DESKTOP,
    MOBILE,
    // COUNTRIES_SORTED,
    COUNTRIES,
    CODES,
    COUNTRIES_NEW
};
