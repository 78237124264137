import { call, takeEvery, put } from "redux-saga/effects";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import prepareSlugStories from "../../../components/marketplace-components/marketplace-stories/prepareSlugStories";
import { getApi } from "./../shared/getApi";
import {
    fetchMarketplaceStories,
    fetchCategoryStories,
} from "./../../../shared/api-functions/fetchStories";
import * as type from "../../constants/types";
import {
    OpenStoriesSaga,
    NextStoriesSaga,
} from "./../../../interfaces/marketplaceInterface";

function* getStoriesListSaga(): TypedIterableIterator<any, any, number> {
    try {
        const stories = yield call(fetchMarketplaceStories);

        if (stories.status <= 200 && stories.status < 300) {
            yield put({
                type: type.GET_STORIES_LIST_SUC,
                payload: stories.data,
            });
        } else {
            throw stories;
        }
    } catch (e) {
        yield put({ type: type.GET_STORIES_LIST_FAIL });
    }
}

function* openStoriesSaga(
    action: OpenStoriesSaga
): TypedIterableIterator<any, any, number> {
    try {
        const slugStories = yield call(fetchCategoryStories, [
            action.payload.story.category,
        ]);
        if (slugStories.status >= 200 && slugStories.status < 300) {
            const readySlugStories = yield call(prepareSlugStories, [
                slugStories.data,
                action.payload.story.imagePath,
            ]);
            yield put({
                type: type.OPEN_STORIES_SUC,
                payload: {
                    index: action.payload.index,
                    category: action.payload.story.category,
                    slugStories: readySlugStories,
                },
            });
        } else throw slugStories;
    } catch (e) {
    }
}

function* nextStoriesSaga(
    action: NextStoriesSaga
): TypedIterableIterator<any, any, number> {
    try {
        let category;
        if (action.payload.clickValue !== null) {
            category =
                action.payload.marketplace.storiesList[
                    action.payload.clickValue
                ].category;
        } else {
            category =
                action.payload.marketplace.storiesList[
                    action.payload.marketplace.activeStoryIndex + 1
                ].category;
        }
        const imagePath =
            action.payload.marketplace.storiesList[
                action.payload.marketplace.activeStoryIndex
            ].imagePath;
        const slugStories = yield call(fetchCategoryStories, [category]);
        if (slugStories.status >= 200 && slugStories.status < 300) {
            const readySlugStories = yield call(prepareSlugStories, [
                slugStories.data,
                imagePath,
            ]);
            yield put({
                type: type.NEXT_STORIE_SUC,
                payload: readySlugStories,
            });
        } else throw slugStories;
    } catch (e) {}
}

function* getAssetsListSaga(action: {type: string, payload: string}): TypedIterableIterator<any, any, number> {
    try {
        const assetsList = yield call(getApi, `asset/all?fields=landingCard`);
        if (assetsList.status >= 200 && assetsList.status < 300) {
            yield put({
                type: type.GET_ASSETS_LIST_SUC,
                payload: assetsList.data,
            });
        } else throw assetsList;
    } catch (e) {
        yield put({ type: type.GET_ASSETS_LIST_FAIL, payload: e });
    }
}

function* watchMarketplaceSaga() {
    yield takeEvery(type.GET_STORIES_LIST_REQ, getStoriesListSaga);
    yield takeEvery(type.OPEN_STORIES_REQ, openStoriesSaga);
    yield takeEvery(type.NEXT_STORIE_REQ, nextStoriesSaga);
    yield takeEvery(type.GET_ASSETS_LIST_REQ, getAssetsListSaga);
}

export { watchMarketplaceSaga };
