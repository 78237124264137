import AssetInfo from "../../../components/assets/asset-main-view/asset-info/assetInfo";
import AssetGridComponent from "../../../components/assets/asset-main-view/asset-grid-component/assetGridComponent";
// import AssetStage from '../../../components/assets/asset-main-view/asset-stage/assetStage';
import AssetInfoIpad from "../../../components/assets/asset-main-view/asset-info/asset-info-ipad/assetInfoIpad";
// import AssetBidArea from '../../../components/assets/asset-main-view/asset-bid-area/assetBidArea';
import { useDispatch, useSelector } from "react-redux";
import { getMainAssetInfo } from "../../../redux/actions/assetsAction";
import VerifyModal from "../../../components/modals/verify-modal/verifyModal"; 
import Banner from '../../../components/marketplace-components/banner/banner';
import Spinner from '../../../components/spinner/spinner';
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AssetDataInterface } from "../../../interfaces/assetsInterface";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import  ComingSoonModal from "../../../components/assets/asset-main-view/coming-soon-modal/comingSoonModal";
import { UserImageState } from "../../../interfaces/headerInterface";
import { openVerifyModal } from "./../../../redux/actions/headerActions";
import { useHistory } from "react-router-dom";
import RedfrogPage from "../redfrog-page/redfrogPage";
import DetoxPage from "../detox-page/detoxPage";
import LdbPage from "../ldb-page/ldbPage";
import { RED_FROG_ID, DETOX_ID, LDB_ID, AIRBC_ID } from "../../../constants/assetsId";
import mautic from 'mautic-tracking';
import AirPage from "../air-page/airPage";

const MainAssetPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { id } = useParams<MainAssetPageParams>();
  const loader = useSelector(
    (state: {
      loaderReducer: {
        loader: boolean;
      };
    }) => state.loaderReducer
    );
    const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
    const userData = useSelector((state: UserImageState) => state.headerReducer);
    
    const handleModal = () => {
      setOpen(!open);
    };
    const isAssetDisabled = () => {
      return info.status === "UPCOMING" || info.status === "FUNDED";
    };

  useEffect(() => {
    if (!info.alias || info.id !== id) dispatch(getMainAssetInfo(id));
  }, [dispatch, id, info.alias, info.id]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  useEffect(() => {
    if (info.status === "INACTIVE") {
      history.push("/marketplace");
    }
  }, [info, history]);

  const buyDigitalButtonHandler = () => {
    if (info.alias === "ASPD") {
      window.open("https://www.tzero.com/asset/ASPD", "_blank");
      return;
    }
    if (userData.role === 2) {
      dispatch(openVerifyModal());
      return;
    }
    if (isAssetDisabled()) {
      handleModal();
      return;
    }
    if (!localStorage.getItem('token')){
      sessionStorage.setItem('fromAsset',`/checkout/overview/${info.id}/5000`);
      history.push(`/login?toCheckout=true&toAsset=${info.id}&tokens=5000`)
      return
    }
    mautic.pageView({ tags: 'checkout'});
    history.push(`/checkout/overview/${info.id}/5000`)
  };

  if (info.id === RED_FROG_ID) return <RedfrogPage />
  if (info.id === DETOX_ID) return <DetoxPage />
  if (info.id === LDB_ID) return <LdbPage />
  if (info.id === AIRBC_ID) return <AirPage />

  return (
    <div className="asset-wrapper">
      {localStorage.getItem("token") ? <Banner /> : ""}
      {loader.loader ? (
        <Spinner />
      ) : (
        <div className="asset-container">
          <ComingSoonModal
            title={info.status}
            open={open}
            handleModal={handleModal}
            assetId={info.id}
          />
          <div className="info-containers">
            <AssetInfoIpad
              handleModal={handleModal}
              buyDigitalButtonHandler={buyDigitalButtonHandler}
              funded={info.status === "FUNDED"}              
            />
            <AssetInfo
              handleModal={handleModal}
              buyDigitalButtonHandler={buyDigitalButtonHandler}
              funded={info.status === "FUNDED"}
            />
          </div>
          {/* <AssetStage /> */}
          <AssetGridComponent
            buyDigitalButtonHandler={buyDigitalButtonHandler}
          />
          {/* <AssetBidArea handleModal={handleModal} /> */}
          <VerifyModal />
        </div>
      )}
    </div>
  );
};

export default MainAssetPage;
