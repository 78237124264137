import { useSelector } from "react-redux";
import { HighlightsInterface } from "../../../../interfaces/assetsInterface";

function AdvanceHighlights() {
  const highlights = useSelector(
    (state: HighlightsInterface) => state.highlightsInfo
);
  return (
    <div className="asset-highlights">
      <div className="title">Highlights</div>
      <ul className="highlights-list">
        {highlights.highlightsInfo.map((el: any, i: number) => {
            return (
                <li key={i} className="highlight-item">
                    <p>{el.highlight}</p>
                </li>
            );
        })}
      </ul>
    </div>
  );
}

export default AdvanceHighlights;