import { useState } from "react";
import { useSelector } from "react-redux";
import { MONTH_NAMES } from "../../../../constants/myInvestmentsConstants";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

import BuyArrow from "./../../../../Assets/icons/buy.svg";
import Question from "./../../../../Assets/icons/question-tooltip.png";
// import { useSelector } from 'react-redux';
// import { AssetDataInterface } from '../../../../interfaces/assetsInterface';

function AssetTopRight(props: {
  toggleCheckout: () => void;
  checkout: boolean;
}) {
  const { toggleCheckout, checkout } = props;

  const assetInfo = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
  const endDateParse = new Date(assetInfo.endDate);
  const endMounth = endDateParse.getMonth();
  const endDay = endDateParse.getDay();
  const endYear = endDateParse.getFullYear();

  const timerField = (endDate: string, status: string) => {
    const endTime = Date.parse(endDate);
    const diff = +endTime - +new Date();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (status === "FUNDED" || days < 0) {
      return 0;
    }
    return days;
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="asset-top-right-wrapper">
      <div className={`asset-top-right ${checkout ? "to-checkout" : ""}`}>
        <div className="raised">$6,000,000</div>
        {/* <div className="line">
          <div className="filled" style={{ width: `${progress}%` }}></div>
        </div> */}
        <div className="percentage">
          {/* <span className="green">0%</span> */}
          First closing
        </div>
        <div className="info-field">
          <div className="value">$1</div>
          <div className="field">Price per digital share / frOGI token </div>
        </div>
        <div className="info-field">
          <div className="value">${assetInfo.minAmount}</div>
          <div className="field">Minimum Investment</div>
        </div>
        <div className={`info-field last ${tooltipOpen ? "tooltiped" : ""}`}>
          <div className="value">{timerField(assetInfo.endDate, assetInfo.status)}</div>
          <div className="field">
            Days Left to Invest
            {/* <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            placement="bottom"
            open
            title={
              <div >
                324 days left to invest in current round. Round ends  Dec 27, 2022
              </div>
            }
          > */}
            <img
              src={Question}
              alt=""
              className="question"
              onMouseOver={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            />
            {/* </Tooltip> */}
            <div className="tooltip-wrapper">
              <div className="days-left-tooltip">
                {timerField(assetInfo.endDate, assetInfo.status)} days left to invest in current round. Round ends {endDay}{' '}
                {MONTH_NAMES[endMounth]} {endYear}
              </div>
            </div>
          </div>
        </div>
        <div className="buy" onClick={toggleCheckout}>
          Buy Digital Shares
          <img src={BuyArrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AssetTopRight;
