import * as types from "./../../constants/types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getTokenApi } from "./../shared/getTokenApi";
import { postTokenApi } from "../shared/postTokenApi"
import { TypedIterableIterator } from "./../../../interfaces/interfaces";

function* getUserDocumentsSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = 'user/documents'
        const documents = yield call(getTokenApi, endpoint)
        if (documents.status >= 200 && documents.status < 300) {
            yield put({
                type: types.GET_USER_DOCUMENTS_SUC,
                payload: documents.data,
            });
        } else throw documents;
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL })
    }
}

function* uploadUserDocument(action: any): TypedIterableIterator<any, any, number> {
    try {
        const lowerType = action.payload.type.toLowerCase()
        const endpoint = `user/${lowerType}?${lowerType}Id=${action.payload.id}`
        const kycDoc = yield call(postTokenApi, [action.payload.document, endpoint])
        if (kycDoc.status >= 200 && kycDoc.status < 300) {
            yield put({
                type: types.GET_USER_DOCUMENTS_REQ
            })
        } else throw kycDoc
    } catch (e) {

    }
}

function* watchGetUserDocumentsSaga() {
    yield takeEvery(types.GET_USER_DOCUMENTS_REQ, getUserDocumentsSaga)
    yield takeEvery(types.UPLOAD_USER_DOC_REQ, uploadUserDocument)
}

export default watchGetUserDocumentsSaga;
