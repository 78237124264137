import React from "react";

export default function CustomizedMenus() {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <div className="dropDownMenu">
      <ul className="topmenu" id="topmenuId">
            <li><span className="drop-controller">Learn</span>
                <ul className="submenu">
                    <li><a href={`${baseUrl}/real-estate-tokenization`}>Understanding Tokenization</a></li>
                    <li><a href="https://ams.solidblock.co/solidblock-value-video" target="_blank" rel="noreferrer">How to Make Assets Liquid (Video)</a></li>
                    <li><a href={`${baseUrl}/DAH`}>Digital Asset Ebook</a></li>
                    <li><a href={`${baseUrl}/course`}>Tokenization Course</a></li>
                    <li><a href="https://podcast.dibscapital.com/" target="_blank" rel="noreferrer">Podcast</a></li>
                    <li><a href="https://blog.dibscapital.com/" target="_blank" rel="noreferrer">Blog</a></li>
                </ul>
            </li>
      </ul>
    </div>
  );
}
