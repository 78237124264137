import { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import { LogoDark } from "../../../../constants/logo";
import HowWorksArrow from "./../../../../Assets/icons/how-works.svg";
import Opportunity from "./../../../../Assets/new-landing/opportunity.png";
import Tokenization from "./../../../../Assets/new-landing/tokenization.png";
import Trade from "./../../../../Assets/new-landing/trade.png";
// import TestimonialsArrow from "./../../../../Assets/icons/chevron-right.svg";
import AdvancePresentaion from "../../ldb/advance-presentation/advancePresentaion"; 
// import AdvancePresentaionLine from "../../asset-advansed-view/advance-presentation/advancePresentationLine";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";

SwiperCore.use([Navigation]);

function AdvanceItemContent() {
  const [howWorksOpen, setHowWorksOpen] = useState(false);

  const assetInfo = useSelector(
    (state: AssetDataInterface) => state.mainAssetInfo
  );
  const infoAdvanced = {
    content: {
        offer: `London Digital Bonds is your ticket to the future of real estate. Invest for as little as GBP 250 in prime discounted London residential properties and flip your investment as prices in the market rise.</br><br/>
        Experience the resiliency of London as businesses acclimate after the world-wide Covid pandemic of 2020.`,
        investment: `If buying an entire house is off-limits due to rising rents, pay freezes and a lack of affordability, we invite you to invest in a fractionalized asset.</br><br/>
        London Digital Bond’s asset-backed blockchain technology opens London’s prime real estate to a new crowd of investors. Take advantage of a low investment threshold to own prime London real estate.</br><br/>        
        With a with a low investment and immediate returns, you control your investment. Our secured digital platform makes your real estate investing accessible, transparent, and streamlined with the use of blockchain technology.`
    }
  }

  return (
    <div className="redfrog-content">
      <div className="image1">
        <img src={assetInfo.images[1]} alt="" />
      </div>
      <div className="title">The Offer</div>
      <div className="subtitle">{" "}</div>
      <div 
        className="text" 
        dangerouslySetInnerHTML={{__html: infoAdvanced.content.offer}}
      >
      </div>
      <AdvancePresentaion />
      <div className="swiper-image">
        <Swiper loop navigation>
          {assetInfo.images.map((image,i) => (
            <SwiperSlide key={i}>
              <img src={image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="title">Affordable Investment</div>
      <div className="subtitle"></div>
      <div className="text" dangerouslySetInnerHTML={{__html: infoAdvanced.content.investment}}>
      </div>
      <div className="image1">
        <img src={assetInfo.images[4]} alt="" />
      </div>
      <div className="how-works">
        <div className="top" onClick={() => setHowWorksOpen(!howWorksOpen)}>
          <img
            style={{top:"20%"}}
            src={HowWorksArrow}
            alt=""
            className={`arrow ${howWorksOpen ? "active" : ""}`}
          />
          <div className="works-title" style={{marginBottom:"0px"}}>How It Works</div>
        </div>
        <div className={`dropdown ${howWorksOpen ? "shown" : ""}`}>
          <div className="works-item">
            <div className="works-img">
              <img src={Opportunity} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Opportunity</div>
              <div className="item-descr">
                We believe in London’s resilience. 
                London Digital Bonds has the potential for significant capital 
                appreciation with a minimum investment of GBP 250.
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Tokenization} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Tokenization</div>
              <div className="item-descr">
                Once you become an investor, you can trade 
                or sell your digital tokens at any time. 
                The projected Annual cash yield is 4.7% - 6%. 
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Trade} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Trade</div>
              <div className="item-descr">
                Once you own tokens in London Digital Bonds, 
                you can trade or sell your digital tokens at any time.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="title">Background</div>
      <div className="subtitle">London is one of the world’s strongest property markets – and there’s never been a better time for you to invest.</div>
      <div className="text">
        Throughout history, London’s real estate market has proven that it is resilient, with stable and highly regulated assets. 
        The city’s track record of long-term growth is trusted by investors from around the world. Work with one of the country's 
        leading developers to identify the most promising investments.
      </div>
      <div className="image1">
        <img src={assetInfo.images[2]} alt="" />
      </div>
      <div className="title">Why UK property?</div>
      <div className="subtitle">UK property: the proven investment in times of wider uncertainty</div>
      <div className="text">
        The investment strengths of real estate have never been as important as they are right now.
        <br/><br/>
        UK property has a long, credible record of resilience and stability in times of wider economic upheaval.
        <br/><br/>
        Already, there is positive data that suggests UK real estate could be one of the strongest post-COVID-19 investments you can make.
        <br/><br/>
        <span className="text-title">4 key strengths of investing in UK property:</span>
        <ul className="highlights-list">
          <li className="highlight-item">
              <p>Regular income, in the form of yields.</p>
          </li>
          <li className="highlight-item">
              <p>Performance underpinned by supply and demand.</p>
          </li>
          <li className="highlight-item">
              <p>A physical asset you own.</p>
          </li>
          <li className="highlight-item">
              <p>Long-term growth.</p>
          </li>
        </ul>
      </div>
      <div className="divider"></div> 
      <div className="title">Why invest in London?</div>
      <div className="subtitle">UK property: the proven investment in times of wider uncertainty.</div>
      <div className="text">
        London rivals that of New York as a financial world center and low-interest rates 
        mean that buyers can expect good levels of leverage and a well-regulated climate for borrowing.
        <br/><br/>
        Capital appreciation is strong and rental yields remain consistent. Investors are 
        faced with a wide choice of properties and areas in which to choose from, meaning that 
        investments can be tuned specifically to adapt to client needs. A well-chosen initial buy-to-let 
        purchase can morph into a home for a son or daughter studying abroad or a second residence, whilst 
        increasing its value and generating a healthy income.
        <br/><br/>
        Being a global market with investment from Asia, the Americas, India, Russia, wider Europe, and the 
        Middle East ensures London is not overexposed to the fortunes of any particular region, ultimately 
        hedging against risk. There are no restrictions on resales and overseas investors benefit from the 
        same buying process as UK nationals.
        <br/><br/>
        London has long been considered the place to be to invest in property. England’s capital city 
        sees flocks of tourists, overseas workers, and investors heading to London year after year to 
        take advantage of the sights, attractions, and opportunities available.
      </div>
      <div className="image1">
        <img src={assetInfo.images[3]} alt="" />
      </div>
      <div className="title">Is property in London a good investment?</div>
      <div className="subtitle"></div>
      <div className="text">
        London property investment can be a worthwhile venture if you are selective with the areas you choose 
        to invest in. If not, you could find that your investment suffers due to low rental yields, dwindling 
        demand, and slow property price growth in many parts of London.
        <br/><br/>
        Let’s take a look at the London property market’s potential in regards to house prices, rental yields, 
        and future capital growth.
        <br/><br/>
        <span className="text-title">High property prices for investment properties in London</span>
        <br/>
        The Office for National Statistics reports that 20 years ago the average London home cost £115,000, 
        growing to an average of £351,000 in 2008 and standing at around £671,412 by 2018. As of November 2020, 
        Zoopla valuation data calculates an average London property asking price of £897,391.
        <br/><br/>
        The more affluent London locations such as Kensington and Chelsea tend to have the highest property prices, 
        with the average asking price of a two-bed apartment in Chelsea currently averaging out at £1,417,254.
        <br/><br/>
        Capital appreciation had long been the main force driving people to buy London investment properties, with the 
        potential for London properties to grow in value over recent years. If an investor had purchased a London property 
        for £200,000 in 2007, near Kings Cross station, for example, this would now be worth over £300,000.
        <br/><br/>
        While London house price growth may have offered a lucrative property investment option in the past, however, predictions 
        highlight a less promising outlook for future growth. By 2024, London is predicted to have the lowest property price growth 
        in the UK, with an expected rate of just 12.7% according to Savills.
        <br/><br/>
        <span className="text-title">Potential for future growth with investment opportunities in London</span>
        <br/>
        While the opportunities for property investment London offers aren’t looking particularly promising, things are starting 
        to look up in the future. Several areas in London are expected to experience property growth in the coming years.
        <br/><br/>
        Whitechapel, Canary Wharf, Earls Court, Old Oak Park, and Croydon are all predicted to make their mark on the property map, 
        and make perfect choices if you’re wondering where to invest in London property.
        <br/><br/>
        This is due to several regeneration projects set in place for the city, with plans for improved transport links and the 
        creation of thousands of new homes and jobs. Select London investment properties have been predicted to reach rental yields as high as 6%.
      </div>
      <div className="divider"></div>
      <div className="title">Industry Analysis</div>
      <div className="subtitle">Mature market</div>
      <div className="text">
        UK real estate has been the preferred asset for generations of global investors.
        <ul className="greenList">
          <li>
            <div className="liTitle">300% ↑</div>
            <div className="liText">Average UK property prices have increased by 300% in the last 20 years.</div>
          </li>
          <li>
            <div className="liTitle">Stable asset</div>
            <div className="liText">Unlike other investments, UK property performance is underpinned by supply/demand levels.</div>
          </li>
          <li>
            <div className="liTitle">Highly regulated</div>
            <div className="liText">The UK has a clear and comprehensive legal system that offers investors a high level of protection.</div>
          </li>
        </ul>
      </div>
      <div className="image1">
        <img src={assetInfo.images[5]} alt="" />
      </div>
      <div className="title">Partners You Can Trust</div>
      <div className="text">
        London Digital Bonds is the outcome of a partnership between London Chelsea Real Estate (LCRE) and SolidBlock.
        <br/><br/>
        <span className="green">SolidBlock</span> wraps real estate assets in a compliant crypto structure that lets investors buy and sell at any time 
        on the SolidBlock marketplace or participating exchanges.
        <br/><br/>
        The company is best known for its role in the world’s first successful commercial property tokenization, 
        raising $18M for the St. Regis Resort in Aspen, Colorado.
        <br/><br/>
        <span className="green">LCRE</span> is a consultancy firm founded nearly a decade ago and serving a wide array of global investors interested 
        in the coveted London real estate market. Their investors obtain optimal profit while benefiting from LCRE’s rich 
        knowhow and maximum safety.
      </div>
      <div className="divider"></div>
    </div>
  );
}

export default AdvanceItemContent;
