import { tokenItemInDashboard } from "../../../interfaces/applyTokenizationInterface";
import * as types from "./../../constants/types";

const initialState = {
  firstStep: {
    projectName: null,
    describe: null,
    location: null,
    tokenName: null,
    tokenSymbol: null,
    launchData: null,
    tokenizationId: null,
  },
  secondStep: {
    totalRaise: 0,
    minRaise: 0,
    expected: "",
    alreadyInvested: 0,
    typeOffering: "",
    radioFunding: "",
    debt: {
      willingToPay: 0,
      paidBasis: "",
      lengthTerm: "",
    },
    equity: {
      percentage: 0,
      valuation: 0,
      anyDebt: "",
    },
    tokenizationId: null,
  },
  thirdStep: {
    squareFeet: null,
    priceSqm: null,
    other: "",
    singleOrPortfolio: "",
    describes: "",
    valuation: "",
    highlights: "",
    ownAsset: "",
    investedAsset: "",
    debtAsset: "",
    lender: "",
    radioHeld: "",
    radioProfits: "",
    annualCash: null,
    IRR: null,
    ROI: null,
    tokenizationId: null,
  },
  fourthStep: {
    name: "",
    previousName: "",
    ssnein: "",
    webAddress: "",
    country: "",
    addressFirst: "",
    addressSecond: "",
    zip: "",
    city: "",
    state: "",
    other: "",
    property: false,
    investment: false,
    operating: false,
    assetManager: false,
    ownerOperator: false,
    developer: false,
    brokerBuyer: false,
    contractor: false,
    entityType: "",
    tokenizationId: null,
  },
  fivethStep: {
    alreadyRaised: "",
    interestedInvestors: "",
    entity: null,
    ppm: null,
    subscription: null,
    investment: null,
    financialModel: null,
    presentation: null,
    tokenizationId: null,
    documents: [],
  },
  tokensList: [] as Array<tokenItemInDashboard>,
  subscription: {
    product: "",
    name: "",
    price: "",
  },
  currentSubscription: "",
  subscriptionId: "",
  products: [],
  isLoading: false,
  tokensLoading: true,
  isFileLoading: false,
  isTokenLoading: false,
  tokenizationId: null,
  error: false,
  actualStep: 1,
  documents: [],
  formResult: '',
  isPaymentMade: false,
};

const ATStepsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        actualStep: 1,
        tokensList: [] as Array<tokenItemInDashboard>,
        subscription: {
          product: "",
          name: "",
          price: "",
        },
        currentSubscription: "",
      };
    case types.GET_TOKENIZATION_PAID_STATUS_SUC:
      return {
        ...state,
        formResult: action.payload.formResult,
        isPaymentMade: action.payload.isPaymentMade,
      };
    case types.CANCELL_SUBSCRIPTION_SUC:
      return {
        ...state,
        subscription: {
          product: "",
          name: "",
          price: "",
        },
        currentSubscription: "",
        subscriptionId: "",
      }
    case types.SEND_AT_DASHBOARD_REQ:
      return {
        ...state,
        isTokenLoading: true,
        error: false,
      };
    case types.SEND_AT_FIRST_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case types.SEND_AT_FIRST_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        isTokenLoading: false,
        error: false,
        tokenizationId: action.payload.tokenizationId,
        firstStep: {
          projectName: action.payload.projectName,
          describe: action.payload.describe,
          location: action.payload.location,
          tokenName: action.payload.tokenName,
          tokenSymbol: action.payload.tokenSymbol,
          launchData: action.payload.launchData,
          tokenizationId: action.payload.tokenizationId,
        },
        actualStep: 2,
      };
    case types.SEND_AT_STEP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case types.SEND_AT_SECOND_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case types.SEND_AT_SECOND_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        tokenizationId: action.payload.tokenizationId,
        secondStep: {
          totalRaise: action.payload.totalRaise,
          minRaise: action.payload.minRaise,
          expected: action.payload.expected,
          alreadyInvested: action.payload.alreadyInvested,
          typeOffering: action.payload.typeOffering,
          radioFunding: action.payload.radioFunding,
          debt: {
            paidBasis: action.payload.debt.paidBasis,
            lengthTerm: action.payload.lengthTerm,
            willingToPay: action.payload.willingToPay,
          },
          equity: {
            percentage: action.payload.equity.percentage,
            valuation: action.payload.equity.valuation,
            anyDebt: action.payload.equity.anyDebt,
          },
          tokenizationId: action.payload.tokenizationId,
        },
        actualStep: 3,
      };

    case types.SEND_AT_THIRD_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case types.SEND_AT_THIRD_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        tokenizationId: action.payload.tokenizationId,
        thirdStep: {
          squareFeet: action.payload.squareFeet,
          priceSqm: action.payload.priceSqm,
          other: action.payload.other,
          singleOrPortfolio: action.payload.singleOrPortfolio,
          describes: action.payload.describes,
          valuation: action.payload.valuation,
          highlights: action.payload.highlights,
          ownAsset: action.payload.ownAsset,
          investedAsset: action.payload.investedAsset,
          debtAsset: action.payload.debtAsset,
          lender: action.payload.lender,
          radioHeld: action.payload.radioHeld,
          radioProfits: action.payload.radioProfits,
          annualCash: action.payload.annualCash,
          IRR: action.payload.IRR,
          ROI: action.payload.ROI,
          tokenizationId: action.payload.tokenizationId,
        },
        actualStep: 4,
      };
    case types.SEND_AT_FOURTH_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case types.SEND_AT_FOURTH_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        tokenizationId: action.payload.tokenizationId,
        fourthStep: {
          name: action.payload.name,
          previousName: action.payload.previousName,
          ssnein: action.payload.ssnein,
          webAddress: action.payload.webAddress,
          country: action.payload.country,
          addressFirst: action.payload.addressFirst,
          addressSecond: action.payload.addressSecond,
          zip: action.payload.zip,
          city: action.payload.city,
          state: action.payload.state,
          other: action.payload.other,
          property: action.payload.property,
          investment: action.payload.investment,
          operating: action.payload.operating,
          assetManager: action.payload.assetManager,
          ownerOperator: action.payload.ownerOperator,
          developer: action.payload.developer,
          brokerBuyer: action.payload.brokerBuyer,
          contractor: action.payload.contractor,
          entityType: action.payload.entityType,
          tokenizationId: action.payload.tokenizationId,
        },
        actualStep: 5,
      };
    case types.SEND_AT_FIVETH_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case types.SEND_AT_FIVETH_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        tokenizationId: action.payload.tokenizationId,
        fivethStep: {
          ...state.fivethStep,
          alreadyRaised: action.payload.alreadyRaised,
          interestedInvestors: action.payload.interestedInvestors,
          entity: action.payload.entity,
          ppm: action.payload.ppm,
          subscription: action.payload.subscription,
          investment: action.payload.investment,
          financialModel: action.payload.investment,
          presentation: action.payload.presentation,
          tokenizationId: action.payload.tokenizationId,
        },
        actualStep: 1,
      };
    case types.GET_AT_FIRST_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.GET_AT_FIRST_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        firstStep: {
          projectName: action.payload.projectName,
          describe: action.payload.describe,
          location: action.payload.location,
          tokenName: action.payload.tokenName,
          tokenSymbol: action.payload.tokenSymbol,
          launchData: action.payload.launchData,
          tokenizationId: action.payload.tokenizationId,
        },
      };

    case types.GET_AT_SECOND_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.GET_AT_SECOND_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        secondStep: {
          totalRaise: action.payload.totalRaise,
          minRaise: action.payload.minRaise,
          expected: action.payload.expected,
          alreadyInvested: action.payload.alreadyInvested,
          typeOffering: action.payload.typeOffering,
          radioFunding: action.payload.radioFunding,
          debt: {
            paidBasis: action.payload.debt.paidBasis,
            lengthTerm: action.payload.debt.lengthTerm,
            willingToPay: action.payload.debt.willingToPay,
          },
          equity: {
            percentage: action.payload.equity.percentage,
            valuation: action.payload.equity.valuation,
            anyDebt: action.payload.equity.anyDebt,
          },
          tokenizationId: action.payload.tokenizationId,
        },
      };

    case types.GET_AT_THIRD_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.GET_AT_THIRD_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        thirdStep: {
          squareFeet: action.payload.squareFeet,
          priceSqm: action.payload.priceSqm,
          other: action.payload.other,
          singleOrPortfolio: action.payload.singleOrPortfolio,
          describes: action.payload.describes,
          valuation: action.payload.valuation,
          highlights: action.payload.highlights,
          ownAsset: action.payload.ownAsset,
          investedAsset: action.payload.investedAsset,
          debtAsset: action.payload.debtAsset,
          lender: action.payload.lender,
          radioHeld: action.payload.radioHeld,
          radioProfits: action.payload.radioProfits,
          annualCash: action.payload.annualCash,
          IRR: action.payload.IRR,
          ROI: action.payload.ROI,
          tokenizationId: action.payload.tokenizationId,
        },
      };

    case types.GET_AT_FOURTH_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.GET_AT_FOURTH_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        fourthStep: {
          name: action.payload.name,
          previousName: action.payload.previousName,
          ssnein: action.payload.ssnein,
          webAddress: action.payload.webAddress,
          country: action.payload.country,
          addressFirst: action.payload.addressFirst,
          addressSecond: action.payload.addressSecond,
          zip: action.payload.zip,
          city: action.payload.city,
          state: action.payload.state,
          other: action.payload.other,
          property: action.payload.property,
          investment: action.payload.investment,
          operating: action.payload.operating,
          assetManager: action.payload.assetManager,
          ownerOperator: action.payload.ownerOperator,
          developer: action.payload.developer,
          brokerBuyer: action.payload.brokerBuyer,
          contractor: action.payload.contractor,
          entityType: action.payload.entityType,
          tokenizationId: action.payload.tokenizationId,
        },
      };

    case types.GET_AT_FIVETH_STEP_REQ:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.GET_AT_FIVETH_STEP_SUC:
      return {
        ...state,
        isLoading: false,
        error: false,
        fivethStep: {
          alreadyRaised: action.payload.alreadyRaised,
          interestedInvestors: action.payload.interestedInvestors,
          entity: action.payload.entity,
          ppm: action.payload.ppm,
          subscription: action.payload.subscription,
          investment: action.payload.investment,
          financialModel: action.payload.investment,
          presentation: action.payload.presentation,
          tokenizationId: action.payload.tokenizationId,
          documents: action.payload.documents,
        },
      };

    case types.GET_AT_STEP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case types.GET_TOKENIZATION_BY_USER_REQ:
      return {
        ...state,
        tokensLoading: true,
        error: false,
        actualStep: 1,
        firstStep: {
          projectName: null,
          tokenSymbol: null,
        },
      };

    case types.GET_TOKENIZATION_BY_USER_SUC:
      return {
        ...state,
        tokensLoading: false,
        tokensList: action.payload,
        // tokenizationId: action.payload.id,
      };

    case types.GET_TOKENIZATION_BY_USER_FAIL:
      return {
        ...state,
        tokensLoading: false,
        error: true,
        tokenizationId: null,
      };

    case types.AT_STEP_CHANGE:
      return {
        ...state,
        actualStep: action.payload.step,
      };

    case types.AT_ADD_FILE_REQ:
      return {
        ...state,
        isFileLoading: true,
        error: false,
      };

    case types.AT_ADD_FILE_SUC:
      return {
        ...state,
        isFileLoading: false,
        error: false,

        fivethStep: {
          //...state.fivethStep,
          alreadyRaised: action.payload.stepInfo.alreadyRaised,
          interestedInvestors: action.payload.stepInfo.interestedInvestors,
          entity: action.payload.stepInfo.entity,
          ppm: action.payload.stepInfo.ppm,
          subscription: action.payload.stepInfo.subscription,
          investment: action.payload.stepInfo.investment,
          financialModel: action.payload.stepInfo.investment,
          presentation: action.payload.stepInfo.presentation,
          tokenizationId: action.payload.stepInfo.tokenizationId,

          documents: [...state.fivethStep.documents, action.payload.file],
        }
      };

    case types.AT_DELETE_FILE_REQ:
      return {
        ...state,
        // isLoading: true,
        error: false,
      };

    case types.AT_DELETE_FILE_SUC:
      return {
        ...state,
        // isLoading: false,
        error: false,
        fivethStep: {
          alreadyRaised: action.payload.stepInfo.alreadyRaised,
          interestedInvestors: action.payload.stepInfo.interestedInvestors,
          entity: action.payload.stepInfo.entity,
          ppm: action.payload.stepInfo.ppm,
          subscription: action.payload.stepInfo.subscription,
          investment: action.payload.stepInfo.investment,
          financialModel: action.payload.stepInfo.investment,
          presentation: action.payload.stepInfo.presentation,
          tokenizationId: action.payload.stepInfo.tokenizationId,
          documents: [...state.fivethStep.documents.filter((item: any) => item.id !== action.payload.fileId)]
        }
      };

    case types.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: { ...action.payload }
      };

    case types.GET_CURRENT_SUBSCRIPTION_SUC:
      return {
        ...state,
        subscription: {
          name: action.payload.tokenizationSubscription.tokenizationProduct.name,
          price: action.payload.tokenizationSubscription.tokenizationProduct.price
        },
        currentSubscription: action.payload.tokenizationSubscription.tokenizationProduct.name,
        subscriptionId: action.payload.tokenizationSubscription.subscription
      };

    case types.SET_PRODUCTS_NOTIFICATIION_CART:
      return {
        ...state,
        products: action.payload
      };

    case types.SET_TOKENIZATION_PICTURE_RESP:
      return {
        ...state,
        tokensList:state.tokensList.map(token=>{
          if(token.id !== action.payload.tokenizationId)return token
          return {...token,imageUrl:action.payload.imageUrl}
        })
        
      };
    default:
      return state;
  }
};

export default ATStepsReducer;
