import * as types from './../../constants/types'
import { TypedIterableIterator } from './../../../interfaces/interfaces'
import { getTokenApi } from './../shared/getTokenApi'
import { call, put, takeEvery } from 'redux-saga/effects'
import { checkRole } from '../../actions/headerActions'

function* headerSaga(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = 'user/image'
        const userData = yield call(getTokenApi, endpoint)
        if (userData.status >= 200 && userData.status < 300) {
            yield put({ type: types.GET_USER_IMAGE_SUC, payload: userData.data.imagePath })
        } else throw userData
    } catch (e) {
        yield put({ type: types.GET_USER_IMAGE_FAIL })
    }
}

function* checkIsAdminSaga(action: ReturnType<typeof checkRole>): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = 'user/role'
        const role = yield call(getTokenApi, endpoint)
        if (role.status >= 200 && role.status < 300) {
            yield put({ type: types.CHECK_IS_ADMIN_SUC, payload: role.data })
            if (action.payload.callBack){
                action.payload.callBack()
            }
        } else throw role
    } catch (e) {
        yield put({ type: types.CHECK_IS_ADMIN_FAIL })
    }
}

function* watchHeaderSaga() {
    yield takeEvery(types.GET_USER_IMAGE_REQ, headerSaga)
    yield takeEvery(types.CHECK_IS_ADMIN_REQ, checkIsAdminSaga)
}

export default watchHeaderSaga