import * as type from "../../constants/types";

const initialState = {
    accountStatus: "",
    link: "",
};
const achReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.ACH_STATUS:
            return {
                ...state,
                accountStatus: action.status,
            };
        case type.USE_WIDGET_LINK:
            return {
                ...state,
                link: action.link,
            };
        default:
            return state;
    }
};

export default achReducer;
