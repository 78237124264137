import * as type from '../../constants/types'

const initialState = {
    referralCode: '',
    referrals: [],
    invitationSent: false,
    invitationError: false,
    loading: false,
    error: false
}

const referralsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_USER_REFERRALS_REQ:
            return {
                ...state,
                loading: true
            }
        case type.GET_USER_REFERRALS_SUC:
            return {
                referralCode: action.payload.referralCode,
                referrals: action.payload.referrals,
                loading: false
            }
        case type.GET_USER_REFERRALS_FAIL:
            return {
                ...state,
                loading: false,
                error: false
            }
        case type.INVITE_REFERRAL_REQ:
            return{
                ...state,
                invitationSent: false,
                invitationError: false
            }
        case type.INVITE_REFERRAL_SUC:
            return{
                ...state,
                invitationSent: true,
                invitationError: false
            }
        case type.INVITE_REFERRAL_FAIL:
            return{
                ...state,
                invitationSent: false,
                invitationError: true
            }
        default: return state
    }
}

export default referralsReducer;