import * as type from '../../constants/types'

const initialState = {
    loading: false,
    error: false,
    message: ''
}

const changePasswordReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.CHANGE_PASSWORD_REQ:
            return {
                ...state,
                message: '',
                loading: true
            }
        case type.CHANGE_PASSWORD_SUC:
            return {
                loading: false,
                error: false,
                message: action.payload
            }
        case type.CHANGE_PASSWORD_FAIL:
            return {
                loading: false,
                error: true,
                message: action.payload
            }
        case type.CLEAR_SECURITY_MESSAGE:
            return {
                ...state,
                message: ''
            }
        default:
            return state
    }

}

export default changePasswordReducer;