import * as types from "./../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { put, takeEvery, call } from "redux-saga/effects";
import { getTokenApi } from "./../shared/getTokenApi";
import { postTokenApi } from "../shared/postTokenApi";

function* getUserReferralsSaga(
  action: any
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "user/referral";
    const referrals = yield call(getTokenApi, endpoint);
    if (referrals.status >= 200 && referrals.status < 300) {
      yield put({
        type: types.GET_USER_REFERRALS_SUC,
        payload: referrals.data,
      });
    } else throw referrals;
  } catch (e) {
    yield put({ type: types.GET_USER_REFERRALS_FAIL });
  }
}

function* inviteReferralSaga(
  action: any
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "user/referral";
    const referralRes = yield call(postTokenApi, [
      {
        email: action.payload,
        path: "sign-up/auth?referralCode=",
      },
      endpoint,
    ]);
    if (referralRes.status >= 200 && referralRes.status < 300) {
        yield put({type: types.INVITE_REFERRAL_SUC})
    } else throw referralRes
  } catch (e) {
    yield put({type: types.INVITE_REFERRAL_FAIL})
  }
}

function* watchGetUserReferralsSaga() {
  yield takeEvery(types.GET_USER_REFERRALS_REQ, getUserReferralsSaga);
  yield takeEvery(types.INVITE_REFERRAL_REQ, inviteReferralSaga);
}

export default watchGetUserReferralsSaga;
