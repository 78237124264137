import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { postOffer } from '../../redux/actions/exchangeActions'
import { useSelector, useDispatch } from "react-redux";
import { THEME_COLOR } from "../../constants/colors";
import { TradeAssetInterface } from "../../interfaces/exchangeInterfaces"
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: 0,
            marginTop: 20,
            width: "100%",
        },
    })
);

const FindBuyerSection = (
    props:
        {
            hideTradeModal: () => void,
        }
) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const [asset, setAsset] = React.useState("");
    const [assetId, setAssetId] = React.useState("");
    const [tradeAsset, setTradeAsset] = React.useState('Bitcoin(BTC)')
    const [tradeAssetId, setTradeAssetId] = React.useState<string | undefined>("")
    const [assetAlias, setAssetAlias] = React.useState("")
    const [tradeAssetAlias, setTradeAssetAlias] = React.useState<string | undefined>("")
    const [tradeAmount, setTradeAmount] = React.useState("");
    const [tradeUnitPrice, setTradeUnitPrice] = React.useState('')
    const [optionalAmount, setOptionalAmount] = React.useState("")
    const [totalAmount, setTotalAmount] = React.useState("")
    const exchangeItems = useSelector((store: { tempItemsReducer: { tempItems: TradeAssetInterface[] } }) => store.tempItemsReducer.tempItems);
    const mainExchangeItems = useSelector((store: { tempItemsReducer: { mainExchangeItems: { asset: TradeAssetInterface, tokens: number }[] } }) => store.tempItemsReducer.mainExchangeItems)
    
    useEffect(()=>{
        setAssetAlias(mainExchangeItems.find(item=>item.asset.id === assetId)?.asset.alias||'')
    }
    ,[assetId, mainExchangeItems])
    
    const handleAssetChange = (
        event: SelectChangeEvent<string>
    ) => {
        setAsset(event.target.value);
    };
    const handleTradeAmount = (id: string) => {
        setTradeAmount(String(mainExchangeItems.find((asset: { asset: TradeAssetInterface, tokens: number }) => asset.asset.id === id)?.tokens))
        setAssetId(id)
    }
    const handleExchangeAmount = (value: string) => {

        if (Number(value) > Number(tradeAmount) || Number(value) < 0 ||
            typeof +value !== 'number' || isNaN(+value)) return
        setOptionalAmount(value)
    }
    useEffect(() => {
        const asset = exchangeItems.find((el) => el.title === 'Bitcoin(BTC)')
        setTradeAssetAlias(asset?.alias)
        setTradeAssetId(asset?.id)
    }, [exchangeItems])
    useEffect(() => {
        const total = Number(optionalAmount) * Number(tradeUnitPrice)
        setTotalAmount(String(total))
    }, [optionalAmount, tradeUnitPrice])
    useEffect(() => {
        setOptionalAmount(tradeAmount)
    }, [tradeAmount])

    return (
        <>
            <div className="trade-modal-section">
                <FormControl className={classes.formControl}>
                    <InputLabel
                        shrink
                        id="trade-asset-label-label"
                        className="trade-input-label"
                    >
                        Select asset
                    </InputLabel>
                    <Select
                        labelId="trade-asset-label-label"
                        disabled={!Boolean(mainExchangeItems.length)}
                        id="trade-asset-label"
                        value={asset}
                        onChange={handleAssetChange}
                        displayEmpty
                        className="trade-input"
                    >
                        {
                            mainExchangeItems.map((asset: { asset: TradeAssetInterface, tokens: number }) => {
                                return (<MenuItem onClick={() => handleTradeAmount(asset.asset.id)} key={asset.asset.title + asset.asset.id} value={asset.asset.title}>
                                    {asset.asset.title}
                                </MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>

                    <InputLabel
                        shrink
                        id="trade-asset-label-label"
                        className="trade-input-label"
                    >
                        Exchange to

                    </InputLabel>


                    <Select
                        labelId="trade-asset-label-label"
                        id="trade-asset-label"
                        value={tradeAsset}
                        onChange={(e) => {
                            setTradeAsset(e.target.value as string)
                        }}
                        className="trade-input"


                    >
                        {
                            exchangeItems.map((asset: TradeAssetInterface) => {
                                return (<MenuItem onClick={() => {
                                    setTradeAssetAlias(asset.alias)
                                    setTradeAssetId(asset.id)
                                }} key={asset.title + asset.id} value={asset.title}>
                                    {asset.title}
                                </MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <div className='double-label'>
                        <InputLabel
                            htmlFor="trade-amount"
                            className="trade-input-label"
                            shrink={true}
                        >
                            Quantity
                        </InputLabel>
                        <p>Balance: {assetAlias} {tradeAmount}</p>
                    </div>
                    <div className='inner-div'>
                        <div className='alias-div'>
                            {assetAlias}
                        </div>
                        <Input
                            id="trade-amount"
                            value={optionalAmount}
                            onChange={e => handleExchangeAmount(e.target.value)}
                            className="trade-input"
                            placeholder=""
                        />
                    </div>
                </FormControl>

                <FormControl className={classes.formControl}>

                    <InputLabel
                        htmlFor="trade-amount"
                        className="trade-input-label"
                        shrink={true}
                    >
                        Price per asset
                    </InputLabel>
                    <div className='inner-div'>
                        <div className='alias-div'>
                            {tradeAssetAlias}
                        </div>
                        <Input
                            id="trade-amount"
                            value={tradeUnitPrice}
                            onChange={e => setTradeUnitPrice(e.target.value)}
                            className="trade-input"
                            placeholder=""
                        />
                    </div>
                </FormControl>
                <div className='total'>
                    <p className='total-p'>
                        Total:
                    </p>
                    <p className='alia-p'>{tradeAssetAlias} </p>
                    <p className='amount-p'>{totalAmount} </p>

                </div>
            </div>
            <Button disabled={totalAmount === '0'} onClick={() => {
                dispatch(postOffer({ amount: optionalAmount, assetId, tradeAssetId: tradeAssetId, crypto: tradeAssetId ? null : tradeAssetAlias, tradeUnitPrice }))
                props.hideTradeModal()
            }

            } className={`close-btn ${totalAmount === '0' ? 'disable' : ''}`} style={{ backgroundColor: THEME_COLOR }}>
                Post Offer
            </Button>
        </>
    );
};

export default FindBuyerSection;
