import { THEME_COLOR } from "../../../../constants/colors";
import MobileAccountNav from "../../account-nav/mobile-account-nav/mobileAccountNav";
import { LogoSecondary } from "./../../../../constants/logo";

function AccreditationHeader() {
    return (
        <>
            <div
                className="accreditation-header"
                style={{ backgroundColor: THEME_COLOR }}
            >
                <img src={LogoSecondary} alt="White Label" className="accreditation-logo"/>
                <div className="description">
                    <h2 className="title">
                        Tell us some information about yourself to start
                        investing
                    </h2>
                    <span className="subtitle">
                        It will takes less than 5 minutes
                    </span>
                </div>
            </div>
            <MobileAccountNav />
        </>
    );
}

export default AccreditationHeader;
