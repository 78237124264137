import * as type from "../../constants/types";

const initialState = {

    credits: 0
};
const countCreditsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.SPENT_CREDITS_COUNTING:

            return {
                ...state,
                credits: action.credits

            };
        default:
            return state;
    }
};

export default countCreditsReducer;
