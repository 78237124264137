import { useState } from "react";

import Image1 from "./../../../../Assets/images/redfrog/content1.jpg";
import Image3 from "./../../../../Assets/images/redfrog/content3.jpg";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { LogoDark } from "../../../../constants/logo";
import HowWorksArrow from "./../../../../Assets/icons/how-works.svg";
import Opportunity from "./../../../../Assets/new-landing/opportunity.png";
import Tokenization from "./../../../../Assets/new-landing/tokenization.png";
import Trade from "./../../../../Assets/new-landing/trade.png";
import TestimonialsArrow from "./../../../../Assets/icons/chevron-right.svg";
import RedfrogPresentaion from "../redfrog-presentation/redfrogPresentaion";
import RedfrogPresentaionLine from "../redfrog-presentation/redfrogPresentationLine";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import LivingRoom from "./../../../../Assets/images/redfrog/livingroom.jpg";
import Kitchen from "./../../../../Assets/images/redfrog/kitchen.jpg";
import Club from "./../../../../Assets/images/redfrog/club.jpeg";
import GirlInPool from "./../../../../Assets/images/redfrog/girlinpool.jpeg";
import Hammock from "./../../../../Assets/images/redfrog/hammock.jpeg";

SwiperCore.use([Navigation]);

function RedfrogContent() {
  const [howWorksOpen, setHowWorksOpen] = useState(false);

  const assetInfo = useSelector(
    (state: AssetDataInterface) => state.mainAssetInfo
  );

  return (
    <div className="redfrog-content">
      <div className="image1">
        <img src={Image1} alt="" />
      </div>
      <div className="title">The Offer</div>
      <div className="subtitle">Expanding the Resort of the Future</div>
      <div className="text">
        SolidBlock (SB) and Blue Sea Holdings (BSH), the US holding company of
        Oceans Group International (OGI)––the developer of Red Frog Beach Island
        Resort & Spa (RFB)––have teamed up to offer investors a digital
        securities investment opportunity in BSH including RFB and all other OGI
        owned or operated assets.
        <br />
        We’re transforming traditional real estate investing with the disruptive
        power of blockchain technology.
        <br />
        <br />
        With a minimum investment, you can join at any stage of this commercial
        hospitality development and expansion project.
        <br />
        <br />
        The expansion of Red Frog Beach is an unprecedented opportunity to be a
        part of a conscientiously sustainable destination resort. Backed by 18
        years of successful acquisition, development and operations, coupled
        with the preservation in perpetuity of 1,000+ acres of flora and fauna,
        investors can be confident in the project’s financial, environmental and
        social accountability.
      </div>
      <RedfrogPresentaion />
      <div className="swiper-image">
        <Swiper loop navigation>
          {assetInfo.images.map((image,i) => (
            <SwiperSlide key={i}>
              <img src={image}  alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="title">The Attraction</div>
      <div className="subtitle">Falling in love with Red Frog Beach</div>
      <div className="text">
        Red Frog Beach Resort & Spa is a unique enclave encompassing 1,388 acres
        of Isla Bastimentos in the archipelago of Bocas del Toro, Panama. It
        includes 5 miles (8 km) of some of the most stunning beaches as well as
        a deep-water marina. With more than $47M invested to date, Red Frog
        Beach welcomes more than 10,000 visitors a year to its fully operational
        resort. Significant pent-up demand for travel in a post-COVID
        environment is apparent and reflected in high reservation rates for Red
        Frog’s coming season
      </div>
      <div className="how-works">
        <div className="top" onClick={() => setHowWorksOpen(!howWorksOpen)}>
          <img
            src={HowWorksArrow}
            alt=""
            className={`arrow ${howWorksOpen ? "active" : ""}`}
          />
          <div className="works-title">How It Works</div>
          <div className="works-subtitle">
            Investing in Digital Securities with SolidBlock
          </div>
        </div>
        <div className={`dropdown ${howWorksOpen ? "shown" : ""}`}>
          <div className="works-item">
            <div className="works-img">
              <img src={Opportunity} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Opportunity</div>
              <div className="item-descr">
                Blue Sea Holdings is raising $11M to expand Red Frog Beach
                Resort & Spa and preserve the unique ecosystem of Isla
                Bastimentos in the Caribbean.
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Tokenization} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Tokenization</div>
              <div className="item-descr">
                Once you become an investor in BSH, you can trade or sell your
                digital tokens at any time. The projected ROI is 15%.
              </div>
            </div>
          </div>
          <div className="works-item">
            <div className="works-img">
              <img src={Trade} alt="" />
            </div>
            <div className="works-content">
              <div className="item-title">Trade or Liquidate</div>
              <div className="item-descr">
                Once you become a preferred investor in OGI, you can trade or
                sell your digital tokens at any time. The projected ROI is 15%
                with 5% cash-on-cash return, and quarterly distribution.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="image1">
        <img src={Image3} alt="" />
      </div>
      <div className="title">Background</div>
      <div className="subtitle">Sustainability, Luxury and Opportunity </div>
      <div className="text">
        Red Frog Beach boasts adventure by day, comfort by night and positive
        impact all year long. <br />
        <br />
        The Resort is committed to preserving 80% of the island property
        forever, providing 100% solar power, implementing various conservation
        projects, reforesting cattle lands, and improving the lives of those
        living in the island community through its non-profit foundation and
        volunteer-led projects. The Resort has received the coveted Green Globe
        certification three years in a row, reflecting its commitment to
        sustainable practices within the tourism industry – the only destination
        in Panama to achieve this impressive distinction. <br /> <br />
        The rainforests are home to red frogs, sloths, monkeys and more than 100
        species of birds.
      </div>
      <div className="reviews-slider">
        <Swiper
          className="reviews-swiper"
          navigation={{
            nextEl: ".testimonials-forward",
            prevEl: ".testimonials-back",
          }}
          loop
        >
          <SwiperSlide>
            <div className="slide-inner">
              <div className="review">
                “The first – and only – resort in Panama to be Green Globe
                certified for sustainable practices in the tourism industry.”
              </div>
              <div className="name">– Green Globe</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner">
              <div className="review">
                “Our country [has] become one of the premier places to
                visit...because of the group’s strategic approach to our
                developing economy.”
              </div>
              <div className="name">
                - Juan Silvera, International Attorney, Panama
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner">
              <div className="review">
                “2019 Travelers’ Choice Award Winner”
              </div>
              <div className="name">Awarded to Top 1% of Hotels</div>
            </div>
          </SwiperSlide>
        </Swiper>
        <img src={TestimonialsArrow} alt="" className="testimonials-back" />
        <img src={TestimonialsArrow} alt="" className="testimonials-forward" />
      </div>
      <div className="title">What To Expect</div>
      <div className="subtitle">The Allure of Red Frog</div>
      <div className="text">
        <span className="text-title">Partnerships and Collaborations</span>{" "}
        <br />
        Understanding that together is better, the Red Frog team partners with
        diverse experts to achieve sustained and mutual goals, such as 100%
        solar energy and an additional marina. <br />
        <div className="asset-image">
          <iframe src="https://www.youtube.com/embed/CTNgh802XLc" title="Announcing a New Real Estate Marketing Partnership"/>
        </div>
        <div className="asset-image">
          <iframe src="https://www.youtube.com/embed/ZFI_5AV0N3Y" title="New Strategic & Operational Partnership with Enjoy Group"/>
        </div>
        <span className="text-title">Investment in Infrastructure</span> <br />
        A $50M investment by Panama’s Tourism Ministry in a new airport that
        directly links Panama City International Airport <br />
        with Bocas del Toro is in progress. <br />
        <br />
        <span className="text-title">Existing Revenue Stream </span> <br />
        With the diversification of Red Frog’s hospitality and sustainable
        tourist attractions, including ancillary services from the resort, there
        is an existing and consistent revenue stream.
      </div>
      <div className="divider"></div>
      <div className="title">Business Strategy for Expansion</div>
      <div className="subtitle">
        Two Distinct Components to Generate Returns
      </div>
      <div className="text">
        When you invest in the Red Frog Beach expansion, you gain entry to the
        Preferred Equity level in the Capital Stack. Our investors are the first
        to receive 100% of their money invested plus 8% interest annually on
        their investment, and 40% of the remaining monies after the current
        shareholders are paid their catch-up.
      </div>
      <div className="two-compontent">
        <div className="component">
          <div className="component-title">Component One </div>
          <div className="component-text">
            Sale and rental of both existing and newly constructed hospitality
            micro-communities and private villas.
          </div>
        </div>
        <div className="component-divider"></div>
        <div className="component">
          <div className="component-title">Component Two </div>
          <div className="component-text">
            Joint ventures with developers, hotel and resort brands and
            operators to build additional luxury resorts & hotels.
          </div>
        </div>
      </div>
      <div className="image1">
        <img src={LivingRoom} alt="" />
      </div>
      <div className="image1">
        <img src={Kitchen} alt="" />
      </div>
      <div className="image1">
        <img src={Club} alt="" />
      </div>
      <div className="title">Market Analysis</div>
      <div className="subtitle">
        The Caribbean Continues to be a Blue Chip Tourist Destination
      </div>
      <div className="text">
        The Caribbean set an all-time record for visitor arrivals in 2019,
        totaling 32 million tourists, the highest total in the region’s history,
        according to a{" "}
        <a
          href="https://www.onecaribbean.org/buy-cto-tourism-statistics/annual-statistical-report/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          report
        </a>{" "}
        from the Caribbean Tourism Organization. Now, after a long year, tourism
        numbers are picking up, and there are signs that 2021 numbers will
        surpass 2020 figures by more than 20%.
      </div>
      <div className="image1">
        <img src={GirlInPool} alt="" />
      </div>
      <div className="image1">
        <img src={Hammock} alt="" />
      </div>
      <div className="title">Partners You Can Trust</div>
      <div className="text">
        <img src={LogoDark} alt="" className="solidblock-logo" />
        We are reimagining the future of real estate investment. Consider us the
        NASDAQ of real estate. Using the stability and security of blockchain
        technology, we create fractionalized securities --or tokens-- that
        correspond to real world assets. We have a global network of compliant
        partners that allows trading in multiple jurisdictions. Our data-driven
        platform embeds market and transactional data on each digital token we
        mine. We offer our investors the ability to buy and sell any property at
        any time anywhere.
      </div>
      <br />
      <div className="subtitle">Blue Sea Holdings</div>
      <div className="text">
        <a href="https://www.blueseaholdingsllc.com/" target="_blank" rel="noreferrer" style={{ color: "#0EC22B" }}>Blue Sea Holdings’</a>  Panamanian wholly-owned subsidiary, <a href="http://www.oceansgroupintl.com/" target="_blank" rel="noreferrer" style={{ color: "#0EC22B" }}>Oceans Group International</a> , was founded more than 18 years ago to develop real estate
        and provide expertise across the continuum of development and operations
        for large-scale projects. Blue Sea Holdings’ investors and management
        team include seasoned entrepreneurs and business leaders who focus on a
        wide range of responsibilities, including strategic planning, design,
        engineering, financing, construction, operations, property management,
        and information systems.
        <br />
        <br />
        The company’s largest development project to date is Red Frog Beach
        Resort & Spa. The team maintains its commitment to socially responsible
        principles and sustainable practices within the tourism industry, as
        evidenced by Red Frog being the first resort in Panama to receive the
        prestigious Green Globe Certification.
      </div>
      <RedfrogPresentaionLine />
    </div>
  );
}

export default RedfrogContent;
