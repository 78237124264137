const infoAdvanced = {
  highlightItems: [
    {highlight:"London Digital Bond is a property fund that invests in prime residential real estate, locating exceptional opportunities at highly discounted prices that experience a great value increase after refurbishment."},
    {highlight:"London is the world’s third-biggest hotspot for international real estate investment."},
    {highlight:"London is known for being shielded from fluctuations in global markets."},
    {highlight:"Profit from the stability and upside potential of London residential property."},
    {highlight:"Brexit is expected to trigger a surge in the housing market."},
    {highlight:"From July 2020 until April 2021 the UK government has reduced stamp duty on properties below £500,000, which will initially save London Chelsea up to £15,000 on property purchases."},
    {highlight:"Floating rate bond backend by single-familt properties in London's in-demand neghborhoods,that have undergone a buy & flip operation."},
  ]
}

function AdvanceHighlights() {
  return (
    <div className="asset-highlights">
      <div className="title">Highlights</div>
      <ul className="highlights-list">
        {infoAdvanced.highlightItems.map((el: any, i: number) => {
            return (
                <li key={i} className="highlight-item">
                    <p>{el.highlight}</p>
                </li>
            );
        })}
      </ul>
    </div>
  );
}

export default AdvanceHighlights;