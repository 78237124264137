import * as types from "../constants/types";
import { History } from "history";
import {
    FirstSignupStep,
    // SecondSignupStep,
    ThirdSignupStep,
    VerificationCodeData,
    ConfirmData,
    firstStepSubmit,
    newFirstStepSubmit
} from "../../interfaces/actions-interfaces/registration-interfaces";
import { UserDataShort } from "../../interfaces/profileInterface";

export const isEmailExists = function (firstStepObject: firstStepSubmit) {
    return {
        type: types.CHECK_IF_EMAIL_EXISTS,
        payload: firstStepObject
    }
}
export const newRegistratonFirstStep = function (firstStepObject: newFirstStepSubmit) {
    return {
        type: types.REGISTRATION_FIRST_STEP,
        payload: firstStepObject
    }
}
export const isEmailExistsTok = function (firstStepObject: any) {
    return {
        type: types.CHECK_IF_EMAIL_EXISTS_TOK,
        payload: firstStepObject
    }
}
export const clearError = function () {
    return {
        type: types.CLEAR_ERROR,
    };
};

export const firstSignup = function (firstStepInfo: FirstSignupStep) {
    return {
        type: types.PUT_USER_EMAIL_PSW,
        payload: firstStepInfo,
    };
};
export const secondSignup = function (secondStepInfo: any) {
    return {
        type: types.GET_USERS_AUTH,
        payload: secondStepInfo,
    };
};
export const secondSignupTok = function (secondStepInfo: any) {
    return {
        type: types.GET_USERS_AUTH_TOK,
        payload: secondStepInfo,
    };
};
export const thirdSignup = function (thirdStepInfo: ThirdSignupStep) {
    return {
        type: types.GET_USERS_AUTH,
        payload: thirdStepInfo,
    };
};
export const emailForgotten = function (email: {
    email: string;
    history: History;
    errorMessageCB:(message:string)=>void
}) {
    return {
        type: types.EMAIL_FOR_FORGOTTEN,
        payload: email,
    };
};

export const forgotPasswordResendEmailCodeAC = (payload:{
    donecb:()=>void,
})=>({
    type:types.FORGOT_PASSWORD_RESEND_EMAIL_REQ,
    payload,
})

export const clearForgotPasswordErrors = function () {
    return {
        type: types.CLEAR_FORGOT_PASSWORD_ERRORS,
    };
};

export const sendVerificationCode = function (
    verificationInfo: VerificationCodeData
) {
    return {
        type: types.SEND_VERIFICATION_CODE,
        payload: verificationInfo,
    };
};
export const resendCode = function () {
    return {
        type: types.RESEND_CODE_REQ,
    };
};
export const setUserData = function (object: UserDataShort) {
    return {
        type: types.SET_USER_DATA,
        payload: object,
    };
};

export const showWarningSnackbar = function (message: string,secMessage?:string) {
    return {
        type: types.SNACKBAR_RESPONSE,
        mainMessage: message,
        secMessage: secMessage||"",
        status: "warning",
    };
};

export const showSuccessSnackbar = function (message: string,secMessage?:string) {
    return {
        type: types.SNACKBAR_RESPONSE,
        mainMessage: message,
        secMessage: secMessage||"",
        status: "success",
    };
};

export const confirmEmail = function (confirmData: {
    code: string,
    email: string,
    history: History,
}) {
    return {
        type: types.CONFIRM_EMAIL_REQ,
        payload: confirmData
    }
}
export const confirmEmailTokenizationUser = function (confirmData: ConfirmData) {
    return {
        type: types.CONFIRM_EMAIL_REQ_TOK,
        payload: confirmData
    }
}

export const resendPhoneCode = function () {
    return {
        type: types.RESEND_PHONE_CODE_REQ
    }
}

export const confirmPhone = function (code: string, history: History) {
    return {
        type: types.CONFIRM_PHONE_REQ,
        payload: {
            code,
            history
        }
    }
}

export const updateUserPhone = function (userData: { phone: string, id: string }) {
    return {
        type: types.UPDATE_USER_PHONE,
        payload: userData
    }
}
