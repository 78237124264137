import CheckoutStages from "../../../components/new-checkout/checkoutStages";
import CheckboxInput from "../../../components/checkbox-input/checkboxInput";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import BuyArrow from "./../../../Assets/icons/buy.svg";
import {
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckoutInterface,
} from "../../../interfaces/assetsInterface";
import { addComma } from "../../../constants/addCommaFunction";
import { getMyInvestmentsReq, postMyInvestmentsReq } from "../../../redux/actions/myInvestmentsAction";
import mautic from 'mautic-tracking';
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { checkoutPurchase, getCheckoutInfoReq } from "../../../redux/actions/checkoutActions";
import { useHistory, useParams } from "react-router";
import Spinner from '../../../components/spinner/spinner';
import { makeStyles } from "@material-ui/styles";
import checkingMark from '../../../Assets/icons/checking-mark.svg'
import { InvestmentsInterface } from "../../../interfaces/myInvestymentsInterface";
import moment from "moment";
import { UserImageState } from "../../../interfaces/headerInterface";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  icon: {
    border: `2px solid #8f948f`,
  },
  checkedIcon: {
    border: `2px solid #8f948f`,
    backgroundColor: "#8f948f"
  }
});

function CheckoutPayment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, tokens } = useParams<MainAssetPageParams>();

  useEffect(() => {
    dispatch(getCheckoutInfoReq(id, history, +tokens));
    dispatch(getMyInvestmentsReq())
    if (!tokens || !id) history.push("/marketplace");
  }, [id, tokens, history, dispatch]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  const _isRedFrog = checkoutInfo.alias === "frOGI";
  const _isDetox = checkoutInfo.alias === "DDC";
  const _isLBD = checkoutInfo.alias === "LDB";

  const loader = useSelector(
    (state: {
      loaderReducer: {
        btnLoader: boolean;
      };
    }) => state.loaderReducer
  );

  const [selectedMethod, setSelectedMethod] = useState("");

  const renderPurchase = () => {

    return {
      assetId: checkoutInfo.id,
      transactionType: selectedMethod,
      spendCredit: 0,
      discountId: checkoutInfo.discountId,
      transactionUnits: checkoutInfo.tokens,
      history,
    };
  };

  const investmentsData = useSelector((store: InvestmentsInterface) => store.myInvestments.investmentsList);
  const userData = useSelector((state: UserImageState) => state.headerReducer);

  const postPurchase = () => {
    let purchasesCount = 0;
    let purchasesTokens = 0;
    let purchasesAmount = 0;
    investmentsData.forEach((item) => {
      purchasesCount += item.purchases.length;
      let subSum = 0;
      let subSumAmount = 0;
      item.purchases.forEach((purchase) => {
        subSum += +purchase.amount;
        subSumAmount += +purchase.amount * +purchase.unitPrice;
      })
      purchasesTokens += subSum;
      purchasesAmount += subSumAmount;
      subSum = 0;
      subSumAmount = 0;
    })
    if (selectedMethod === "CARD") {
      mautic.pageView(
        {
          email: userData.email,
          last_asset_purchased: checkoutInfo.alias,
          amount_purchased: checkoutInfo.total,
          date_last_purchase: moment().format("YYYY-MM-DD"),
          last_payment_method: 'credit_card',
          total_purchases_count: purchasesCount + 1,
          total_purchases_amount: purchasesTokens + checkoutInfo.tokens,
          total_purchases_value: purchasesAmount + checkoutInfo.tokens * checkoutInfo.unitPrice,
          tags: 'investor'
        }
      );
      dispatch(checkoutPurchase(
        {
          "assetId": checkoutInfo.id,
          "transactionUnits": checkoutInfo.tokens,
          "discountId": checkoutInfo.discountId,
          "spendCredit": 0
        },
        (url) => { document.location.assign(url) }
      ))
      return
    };
    mautic.pageView(
      {
        email: userData.email,
        last_asset_purchased: checkoutInfo.alias,
        amount_purchased: checkoutInfo.total,
        date_last_purchase: moment().format("YYYY-MM-DD"),
        last_payment_method: selectedMethod.toLowerCase(),
        total_purchases_count: purchasesCount + 1,
        total_purchases_amount: purchasesTokens + checkoutInfo.tokens,
        total_purchases_value: purchasesAmount + checkoutInfo.tokens * checkoutInfo.unitPrice,
        tags: 'investor'
      }
    );
    dispatch(postMyInvestmentsReq(renderPurchase()));
  };

  const [notVerify, setNotVerify] = useState(false);

  useEffect(() => {
    if (!userData.isRoleLoaded) return
    if (!userData.steps.email || !userData.steps.cell || userData.firstTryAfterOauth) {
      setNotVerify(true)
      return
    }
    if (userData.isEntity && userData.steps.companyInformation) {
      setNotVerify(true)
      return
    }
    if (!userData.isEntity) {
      if (!userData.steps.identity) {
        setNotVerify(true)
        return
      }
      if ((!userData.steps.accreditation && !userData.steps.accreditationSkipped)) {
        setNotVerify(true)
        return
      }
    }
    setNotVerify(false)
  }, [userData])

  const renderBanner = (className?: string) => {
    if (!userData.isRoleLoaded) {
      return null
    }
    // user not entered phone from oauth 
    if (userData.firstTryAfterOauth) {
      return (
        <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
          <span>In order to complete your order, you must finish your phone number verification  </span>
          <Link className="link" to="/sign-up/country-phone">
            Verification
          </Link>
        </div>
      )
    }
    //common cases 
    if (!userData.steps.email) {
      return (
        <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
          <span>In order to complete your order, you must finish your email verification</span>
          <Link className="link" to="/sign-up/verify-email">
            Verify
          </Link>
        </div>
      )
    }
    if (!userData.steps.cell) {
      return (
        <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
          <span>In order to complete your order, you must finish your phone number verification  </span>
          <Link className="link" to="/sign-up/country-phone">
            Verification
          </Link>
        </div>
      )
    }
    if (userData.isEntity) {
      //entity case
      if ((!userData.steps.companyInformation)) {
        return (
          <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
            <span>In order to complete your order, you must finish your company information verification</span>
            <Link className="link" to="/sign-up/company-information">
              Verify information
            </Link>
          </div>
        )
      }
    }
    if (!userData.isEntity) {
      //individual case
      if (!userData.steps.identity) {
        return (
          <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
            <span>In order to complete your order, you must finish your identity verification.</span>
            <Link className="link" to="/sign-up/kyc" >
              Verify Now
            </Link>
          </div>
        )
      }
      if ((!userData.steps.accreditation && !userData.steps.accreditationSkipped)) {
        return (
          <div className={className || "banner"} style={className ? undefined : { backgroundColor: "#F64A00" }}>
            <span>In order to complete your order, you must finish your identity verification.</span>
            <Link className="link" to="/sign-up/accreditation">
              Verify Now
            </Link>
          </div>
        )
      }
    }
    return <></>
  }

  if (loader.btnLoader) return <Spinner />;

  return (
    <div className="checkout-payment">
      {renderBanner()}
      <CheckoutStages />
      <div className="container">
        <div className="left">
          <div className="head">
            Select a Payment Method
          </div>
          <div className="methods">
            {!_isLBD ?
              <label className="method-item">
                <CheckboxInput
                  disabled={false}
                  checked={selectedMethod === "WIRE"}
                  id="credits"
                  label=""
                  checkBoxHandler={() => { setSelectedMethod("WIRE") }}
                />
                <span className="label">Wire</span>
              </label>
              :
              <label className="method-item" style={{ cursor: "default" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      icon={<span className={classes.icon + " custom-icon"}></span>}
                      checkedIcon={<span className={classes.checkedIcon + " custom-icon"}><img alt='' src={checkingMark} className="custom-checked" /></span>}
                    />
                  }
                  className="notifications-checkbox"
                  label=""
                />
                <span className="label" style={{ color: "#8f948f" }}>Wire</span>
              </label>
            }
            {_isDetox || _isRedFrog || _isLBD ? null :
              <label className="method-item">
                <CheckboxInput
                  disabled={false}
                  checked={selectedMethod === "CHECK"}
                  id="credits"
                  label=""
                  checkBoxHandler={() => { setSelectedMethod("CHECK") }}
                />
                <span className="label">Check</span>
              </label>
            }
            {_isLBD && <label className="method-item" style={{ cursor: "default" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    icon={<span className={classes.icon + " custom-icon"}></span>}
                    checkedIcon={<span className={classes.checkedIcon + " custom-icon"}><img alt='' src={checkingMark} className="custom-checked" /></span>}
                  />
                }
                className="notifications-checkbox"
                label=""
              />
              <span className="label" style={{ color: "#8f948f" }}>Check</span>
            </label>}
            <label className="method-item">
              <CheckboxInput
                disabled={false}
                checked={selectedMethod === "CRYPTO"}
                id="credits"
                label=""
                checkBoxHandler={() => setSelectedMethod("CRYPTO")}
              />
              <span className="label">Crypto</span>
            </label>
            {checkoutInfo.tokens <= 999999 && checkoutInfo.tokens >= 1000 && (checkoutInfo.alias === 'frOGI' || checkoutInfo.alias === 'DDC') ? (
              <label className="method-item">
                <CheckboxInput
                  disabled={false}
                  checked={selectedMethod === "CARD"}
                  id="credits"
                  label=""
                  checkBoxHandler={() => setSelectedMethod("CARD")}
                />
                <span className="label">Credit Card</span>
              </label>) : (
              <label className="method-item" style={{ cursor: "default" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      icon={<span className={classes.icon + " custom-icon"}></span>}
                      checkedIcon={<span className={classes.checkedIcon + " custom-icon"}><img alt='' src={checkingMark} className="custom-checked" /></span>}
                    />
                  }
                  className="notifications-checkbox"
                  label=""
                />
                <span className="label" style={{ color: "#8f948f" }}>Credit Card</span>
              </label>
            )}
          </div>
        </div>
        <div className="right">
          <div className="wrapper">
            <div className="title">Order Summary</div>
            <div className="image">
              <img src={checkoutInfo.imagePath} alt="" />
            </div>
            <div className="asset">
              <span className="name">{`${checkoutInfo.title.substr(0, 22).trim()}...`}</span>
              <span className="cost">
                {addComma(String(checkoutInfo.tokens * checkoutInfo.unitPrice))}{' '}{checkoutInfo.currency}
              </span>
            </div>
            <div className="tokens">
              {checkoutInfo.tokens} {checkoutInfo.alias} tokens
            </div>
            <div className="discount">
              <span className="descr">Discount code applied</span>
              <span className="applied">-{checkoutInfo.discountDiff}</span>
            </div>
            {/* <div className="credits">
              <span className="descr">0 Credits appllied</span>
              <span className="applied">0</span>
            </div> */}
            <div className="total">
              <span className="title">Total:</span>
              <div className="amount">
                {addComma(String(checkoutInfo.total))}{" "}
                <span className="currency"> {checkoutInfo.currency}</span>
              </div>
            </div>
            <button
              disabled={!selectedMethod || notVerify}
              className={`next ${!selectedMethod || notVerify ? "disabled" : ""
                }`}
              onClick={postPurchase}
            >
              Continue Order
              <img src={BuyArrow} alt="" />
            </button>
            {selectedMethod && notVerify ? renderBanner("verifyText") : (null)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPayment;
