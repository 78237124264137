import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { closeSnackbar } from '../../redux/actions/exchangeActions'
import { useSelector, useDispatch } from "react-redux";


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleSnackbar() {
    const dispatch = useDispatch()
    const open = useSelector((store: any) => store.snackbarReducer.open);
    const message = useSelector((store: any) => store.snackbarReducer.mainMessage);
    const secMessage = useSelector((store: any) => store.snackbarReducer.secMessage);
    const status = useSelector((store: any) => store.snackbarReducer.status);


    const handleSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar())

    }

    return (
        <div className='snackbar'>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbar}
            >

                <Alert severity={status}>
                    <div className="snack-inner">
                        <div className='snack-text'>
                            <p className="p1">{message}</p>
                            <p className="p2">{status === 'success' ? '' : secMessage}</p>
                        </div>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(closeSnackbar())}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </Alert>

            </Snackbar>
        </div>
    );
}