import { useEffect } from "react";
import aos from "aos";
import AboutTeam from "../../components/about-components/about-team/aboutTeam";
import AboutCards from "../../components/about-components/about-cards/aboutCards";
import StrategicPartners from "../../components/about-components/strategic-partners/strategicPartners";
import NewLandingNav from "../../components/headers-components/new-landing-nav/newLandingNav";
import CapitalAndSponsors from "../../components/about-components/capitalandsponsors/CapitalAndSponsors";
import UniversalAccess from "../../components/about-components/universalAccess/universalAccess";
import {Helmet} from "react-helmet";
import mautic from 'mautic-tracking';

function AboutPage() {
  useEffect(() => {
    mautic.pageView({});
    aos.init();
  }, []);

  return (
    <div className="about-page">
      <Helmet>
          <title>About | SolidBlock Digital Platform - SolidBlock</title>
          <meta name="description" content="SolidBlock is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities."></meta>
          <meta name="Keywords" content="SolidBlock digital platform"></meta>
      </Helmet>
      <NewLandingNav />
      <AboutTeam />
      <CapitalAndSponsors/>
      <UniversalAccess/>
      <StrategicPartners />
      <AboutCards />
      {/* <Companies /> */}
    </div>
  );
}

export default AboutPage;
