import axios from "axios";

const getCryptoApi = (params: any) => {
    return axios
        .get(
            `https://min-api.cryptocompare.com/data/price?fsym=${params[0]}&tsyms=BTC,ETH,DASH,DAI,USDC`
        )
        .then((data) => {
            return data;
        })
};

export { getCryptoApi };
