// import { THEME_COLOR } from "../../constants/colors";
// import Geo from '../../Assets/images/geo.svg';
// // import Timer from '../../Assets/images/Timer.svg';
// import { useSelector } from "react-redux";
// import { addComma } from '../../constants/addCommaFunction'
// import { CheckoutInterface } from "../../interfaces/assetsInterface";

const ImageContainer = (props: {
    className: string
}) => {
    // const info = useSelector(
    //     (state: CheckoutInterface) => state.checkoutReducer
    // );
    return (
        <div className={`right-container  `}>
            {/* <div className='inner-right'> */}
{/* 
                <img alt='' src={info.imagePath} />
                <div className='inner-image'>
                    <div className='inner-1'>
                        <h2>{info.title}</h2>
                        <div className='info-container'>
                            <div className='info1'>
                                <img alt='' src={Geo} />
                                <p>{info.location} </p>
                            </div>
                            {/* <div className='info2'>
                                <img alt='' src={Timer} />
                                <div><h4>Asset Stage 2</h4><p>15% Discount rate.</p><p>7 days 22 hours 13 min</p></div>
                            </div> */}
                        {/* </div>
                    </div>
                    <div className='credit-container'>
                        <h2 style={{ color: THEME_COLOR }}>{info.inputVal < 0 ? '0' : addComma(String(Math.ceil(info.inputVal * Number(info.creditMultiplier))))} credits</h2>
                        <p>You will earn in credits towards the next deal</p>
                    </div>
                </div>
            </div>  */}
        </div>
    );
};

export default ImageContainer;
