import { lazy, useState, Suspense } from "react";
import IconButton from "@material-ui/core/IconButton";
import { useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Logo, LogoDark } from './../../../../constants/logo'

import {
    LANDING_CATEGORIES_AUTHED,
    LANDING_CATEGORIES,
    AUTH_MOBILE_CATEGORIES,
} from "../../../../constants/nav";
import Spinner from "../../../spinner/spinner";
const MobileNavDrawer = lazy(() => import("../../mobile-nav-drawer/mobileNavDrawer"))

const LandingNavMobile = () => {
    const [navShow, setNavShow] = useState(false);
    const history = useHistory();
    const { pathname } = useLocation();
    const header = history.location.pathname.includes('/asset-view/') || history.location.pathname.includes('/onboarding')
    const navCategories = localStorage.getItem("token")
        ? LANDING_CATEGORIES_AUTHED
        : LANDING_CATEGORIES;
    const authed = localStorage.getItem("token")

    return (
        <Suspense fallback={<Spinner />}>
            <div className={`landing-nav-mobile ${header ? 'add-margin' : ''}`}>
                <img
                    onClick={() => history.push("/")}
                    className="landing-nav-logo"
                    src={
                        AUTH_MOBILE_CATEGORIES.includes(pathname) || header
                            ? LogoDark
                            : Logo
                    }
                    alt="solidblock"
                />

                <IconButton
                    aria-label="Open drawer"
                    edge="start"
                    onClick={() => setNavShow(true)}
                    className="landing-nav-burger"
                >
                    <MenuIcon fontSize="medium" fill="#000"/>
                </IconButton>
                <MobileNavDrawer
                    navCategories={navCategories}
                    navShow={navShow}
                    setNavShow={setNavShow}
                    authed={authed}
                />
            </div>
        </Suspense>
    );
};

export default LandingNavMobile;
