import { useState, useEffect } from 'react'
import { THEME_COLOR } from "../../constants/colors";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import VerifyModal from "../../components/modals/verify-modal/verifyModal"; 
import CancelModal from '../../components/exchange-componnets/cancelModal'
import ExchangeConfirmationModal from '../../components/exchange-componnets/exchangeConfirmationModal'
import ExchangeModal from '../../components/exchange-componnets/exchangeModal'
import ExchangeItem from '../../components/exchange-componnets/exchangeItem'
import TuneIcon from '@material-ui/icons/Tune'
import Phu from '.././../Assets/images/test-phu.png'
import TradeModal from "../../components/my-investments-components/tradeModal";
import { useSelector, useDispatch } from "react-redux";
import { ExchangeOffersInterface, OfferInterface } from '../../interfaces/exchangeInterfaces'
import { exchangeHandler, getAllOffers, getTempItems, getUserAssetsTokens } from '../../redux/actions/exchangeActions'
import { PopperPlacementType } from '@material-ui/core/Popper';
import Snackbar from '../../components/exchange-componnets/snakBar'
import { UserImageState } from "../../interfaces/headerInterface";
import { openVerifyModal } from "../../redux/actions/headerActions";


const useStyles = makeStyles({
    offersBtn: {
        color: `${THEME_COLOR} !important`,
        '&': { border: `2px solid ${THEME_COLOR} !important` },
    },
    tradesBtn: {
        background: THEME_COLOR,
        border: `2px solid ${THEME_COLOR} !important`,
        marginLeft: 16,
        '&:hover': {
            background: THEME_COLOR,
        }
    }
});

const ExchangePage = () => {
    const dispatch = useDispatch()
    const styles = useStyles()
    const [svgColor, setSvgColor] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const [tradeModalIsOpen, setTradeModalIsOpen] = useState(false);

    const exchangeItemsData = useSelector((store: { exchangeItemsReducer: ExchangeOffersInterface }) => store.exchangeItemsReducer.representItems);

    const [exchangeItems, setExchangeItems] = useState<OfferInterface[]>([])
    const userData = useSelector((state: UserImageState) => state.headerReducer)
    useEffect(() => {
        setExchangeItems(exchangeItemsData)
    }, [exchangeItemsData])


    const [confirmationModal, setConfirmationModal] = useState({
        open: false,
        sell: '',
        price: '',
        quantity: '',
        id: '',
        total: '',
        tradeAssetId: ''
    })

    const [cancelModal, setCancelModal] = useState({
        open: false,
        tradeAssetId: '',
        tradeAsset: ''
    })

    const openCancelModal = (tradeAssetId: string, tradeAsset: string) => {
        setCancelModal({
            open: true,
            tradeAssetId,
            tradeAsset
        })
    }

    const closeCancelModal = () => {
        setCancelModal({
            open: false,
            tradeAssetId: '',
            tradeAsset: ''
        })
    }

    const openConfirmationModal = (sell: any, price: any, quantity: any, total: any, id: any, tradeAssetId: any) => {
        setConfirmationModal({
            open: true,
            sell,
            price,
            quantity,
            total,
            id,
            tradeAssetId
        })
    }
    const closeConfirmationModal = () => {
        setConfirmationModal({
            open: false,
            sell: '',
            price: '',
            quantity: '',
            total: '',
            id: '',
            tradeAssetId: ''
        })
    }


    const [myOffers, setMyOffers] = useState(false);
    useEffect(() => {
        dispatch(getAllOffers())
        dispatch(getUserAssetsTokens())
    }, [dispatch])

    const handleClick = (newPlacement: PopperPlacementType, event: any) => {
        dispatch(exchangeHandler(svgColor))
        setSvgColor(!svgColor)
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const hideTradeModal = () => {
        setTradeModalIsOpen(false);
        setOpen(false)
    };
    const showTradeModal = () => {
        if (userData.role === 2) {
            dispatch(openVerifyModal())
            return
        }
        dispatch(getTempItems())
        setTradeModalIsOpen(true);
    };

    const exchangeColor = useSelector((store: any) => store.exchangeReducer.exchange);
    const userId = useSelector((store: { exchangeItemsReducer: ExchangeOffersInterface }) => store.exchangeItemsReducer.userId)

    const filterOffers = (filteredItems: OfferInterface[]) => {
        setExchangeItems(filteredItems)
    }

    const myOffersHandler = () => {
        if (!myOffers) {
            setMyOffers(true)
            const tempArr = [...exchangeItems].filter((item: any) => item.userId === userId)
            setExchangeItems(tempArr)
            return
        }
        if (myOffers) {
            setMyOffers(false)
            setExchangeItems(exchangeItemsData)
            return
        }

    }

    const renderOffers = exchangeItems

    const renderExchangeItems = (
        renderOffers.map((el: OfferInterface) => {
            return (
                <ExchangeItem
                    img={Phu}
                    name={el.asset.title}
                    key={el.id}
                    tradeAlias={el.tradeAsset ? el.tradeAsset.alias : el.crypto}
                    alias={el.asset.alias}
                    id={el.tradeAssetId}
                    tradingPrice={el.tradeUnitPrice}
                    tradeAssetId={el.id}
                    amount={el.amount}
                    buy={el.userId !== userId}
                    myOffers={myOffers}
                    openConfirmationModal={openConfirmationModal}
                    openCancelModal={openCancelModal} />
            )
        })
    )

    const emptyExchange = (
        <div className="empty-exchange">
            <span className="title">We don’t have any exchanges yet</span>
            <span className="descr">Please wait a little and soon there will be offers</span>
        </div>
    )

    const emptyMyExchange = (
        <div className="empty-exchange">
            <span className="title">You don’t have any exchanges yet</span>
            <span className="descr">Please start trading to making exchanges</span>
            <span
                className="start"
                style={{ backgroundColor: THEME_COLOR }}
                onClick={showTradeModal}>Start trading</span>
        </div>
    )

    const renderContent = () => {
        if (renderOffers.length) {
            return renderExchangeItems
        }
        if (!renderOffers.length && !myOffers) {
            return emptyExchange
        }
        if (!renderOffers.length && myOffers) {
            return emptyMyExchange
        }
    }


    return (

        <div className='main-exchange'>
            <Snackbar />
            {tradeModalIsOpen && (
                <TradeModal hideTradeModal={hideTradeModal} />
            )}
            <ExchangeModal
                open={open}

                anchorEl={anchorEl}
                placement={placement}
                handlerClick={handleClick}
                filterOffers={filterOffers}
                hideTradeModal={hideTradeModal}
                myOffers={myOffers}
                userId={userId}
                exchangeItems={exchangeItems} />
            <div className='exchange-options'>
                <h2>Exchange</h2>
                <div className='exchange-buttons'>
                    <Button
                        className='exchange-btn'
                        classes={{ root: styles.offersBtn }}
                        onClick={myOffersHandler}>
                        {myOffers ? "All offers" : "My offers"}
                    </Button>
                    <Button classes={{ root: styles.tradesBtn }} onClick={showTradeModal} className='exchange-btn'>
                        Trade
                    </Button>
                    <div className='svg-block svg-block-mobile'>
                        <span onClick={(e) => handleClick('bottom-end', e)}>
                            <TuneIcon style={{ fill: svgColor ? THEME_COLOR : 'black' }} />
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ background: `${exchangeColor ? 'none' : ''}` }} className='exchange-params'>
                <div className='inner-params'>
                    <div className='sell-container'>
                        <p>
                            Sell
                        </p>
                    </div>
                    <div className='other-fields'>
                        <p>
                            Price
                        </p>
                    </div>
                    <div className='other-fields'>
                        <p>
                            Quantity
                        </p>
                    </div>
                    <div className='other-fields'>
                        <p>
                            Total
                        </p>
                    </div>
                    <div className='svg-block '>
                        <span onClick={(e) => handleClick('bottom-end', e)}>
                            <TuneIcon style={{ fill: svgColor ? THEME_COLOR : 'black' }} />

                        </span>
                    </div>
                </div>
            </div>
            <div className='exchange-lastchild'>
                {renderContent()}
            </div>
            <ExchangeConfirmationModal
                confirmationModal={confirmationModal}
                closeConfirmationModal={closeConfirmationModal} />
            <CancelModal
                closeCancelModal={closeCancelModal}
                cancelModal={cancelModal} />
            <VerifyModal />
        </div>
    )
}

export default ExchangePage