import * as types from "../constants/types";

export const getCryptoExchangeRates = function (
    currency: string,
    total: number
) {
    return {
        type: types.CURRENCY_CONVERTING,
        currency: currency,
        total: total,
    };
};
export const getCryptoPrices = function () {
    return {
        type: types.CURRENCY_CRYPTO_PRICE,
    };
};
export const convertCurrency = function (crypto: string, total: number, currency: string) {
    return {
        type: types.CONVERT_CURRENCY,
        crypto: crypto,
        total: total,
        currency: currency,
    };
};
