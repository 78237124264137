import { entityProgressSteps, individualProgressSteps } from '../../../interfaces/authInterfaces'
import { userData } from '../../../interfaces/headerInterface'
import * as types from './../../constants/types'

const initialSteps :individualProgressSteps&entityProgressSteps ={
    email: false,
    cell: false,
    identity: false,
    accreditation: false,
    ready: false,
    accreditationSkipped:false,
    companyInformation:false,
}


const initialState = {
    userImage: null,
    role: 0,
    phoneVerified: false,
    email: '',
    phone: '',
    id: '',
    loading: false,
    error: false,
    verifyOpen: false,
    isEntity:false,
    isUsCitizen:false,
    steps:initialSteps,
    isRoleLoaded:false,
    fromSite:'',
    firstTryAfterOauth:false,
}

const headerReducer = (state:userData = initialState, action: any):userData => {
    switch (action.type) {
        case types.GET_USER_IMAGE_REQ:
            return {
                ...state,
                loading: true
            }
        case types.GET_USER_IMAGE_SUC:
            return {
                ...state,
                userImage: action.payload,
                loading: false
            }
        case types.GET_USER_IMAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        case types.PUT_HEADER_IMAGE:
            return {
                ...state,
                userImage: action.payload.imagePath
            }
        case types.CHECK_IS_ADMIN_REQ:
            return {
                ...state,
                loading: true
            }
        case types.CHECK_IS_ADMIN_SUC:
            return {
                ...state,
                fromSite:action.payload.fromSite,
                role: action.payload.role,
                phoneVerified: action.payload.phoneVerified,
                email: action.payload.email,
                phone: action.payload.phone,
                id: action.payload.id,
                isUsCitizen:action.payload.isUsCitizen,
                isEntity:action.payload.isEntity,
                steps:{
                    ...action.payload.steps,
                    ready:false,              
                },
                loading: false,
                isRoleLoaded:true,
                firstTryAfterOauth:!action.payload.steps,
            }
        case types.CHECK_IS_ADMIN_FAIL:
            return {
                ...state,
                role: 0,
                phoneVerified: false,
                loading: false
            }
        case types.OPEN_VERIFY_MODAL:
            return {
                ...state,
                verifyOpen: true
            }
        case types.CLOSE_VERIFY_MODAL:
            return {
                ...state,
                verifyOpen: false
            }
        default: return state
    }
}

export default headerReducer;