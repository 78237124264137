import { lazy } from 'react'
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";

const KYCFirst = lazy(() => import("./KYC-first/KYCFirst"));
const KYCCheck = lazy(() => import("./KYC-check/KYCCheck"));
const KYCIDVerification = lazy(() => import("./KYC-ID-verification/KYCIDVerification"));

const ProfilePages = () => {
    return (
        <Switch>
            <Route exact component={KYCFirst} path="/kyc/first" />
            <Route exact component={KYCCheck} path="/kyc/check" />
            <Route
                exact
                component={KYCIDVerification}
                path="/kyc/id-verification"
            />
            <Redirect from="/kyc" to="/kyc/first" exact />
        </Switch>
    );
};

export default ProfilePages;
