import { useState } from "react";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import { UserImageState } from "../../../../interfaces/headerInterface";
// import { useSelector } from "react-redux";
// import { AssetDataInterface } from "../../../../interfaces/assetsInterface"; 
import mountxImg from "./../../../../Assets/images/mountx.png";
import AssetCheckout from "./../asset-checkout/assetCheckout";
import AssetTopRight from "./../asset-top-right/assetTopRight";
// import img from "./../../../../Assets/images/mountx1.jpg";

function AdvanceItemInfo() {
  const infoAdvanced = {
    title: "London Digital Bonds",
    alias: "LDB",
    location: "London, England",
    description: "Fixed rate bond backed by single-family properties in London's in-demand nighborhoods, that are bought and sold in a fix and flip model with income distributed as interest to investors.",
    videos: [
      'https://www.youtube.com/embed/nQhgv0SCfVE'
    ],
  }
  // const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const [checkout, setCheckout] = useState(false);

  const toggleCheckout = () => {
    setCheckout(!checkout);
  };
  
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);
  const userData = useSelector((state: UserImageState) => state.headerReducer);

  const [load, setLoaded] = useState(true);

  return (
    <div className="asset-info">
      <div className="asset-title">
        <div>
          <div className="title">{infoAdvanced.title} <span>(${info.alias})</span></div>
          <div className="location">{infoAdvanced.location}</div>
          <div className="descr">{infoAdvanced.description}</div>
        </div>
        {userData.fromSite ==='mountx'?
          <img src={mountxImg} alt="mountx" className="promo" />
          :
          ''
        }
      </div>
      <div className="asset-info-main">
        <div className="left">
          <div className="asset-image">
              {load ? (
                <div className="loading"></div>
              ) : null}
              <iframe
                src={infoAdvanced.videos[0]}
                title="Detox Video"
                frameBorder="0"
                onLoad={() => {setLoaded(false)}}
              />
          </div>
        </div>
        <div className="right">
          {checkout ? null : (
            <AssetTopRight
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          )}
          {checkout ? (
            <AssetCheckout
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdvanceItemInfo;
