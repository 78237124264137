import { useHistory } from "react-router";
import { useEffect } from "react";
import CentralPayment from "../../../components/payment-method/centralPayment";
import CheckoutMenu from "../../../components/purchase-reusable-components/checkoutMenu";
import ImageContainer from '../../../components/purchase-reusable-components/imageContainer'
import CheckoutBack from '../../../components/back-button/checkout-back'
import { CheckoutInterface } from "../../../interfaces/assetsInterface"
import { useSelector } from "react-redux";
const PaymentPage = () => {
    const history = useHistory();
    const info = useSelector(
        (state: CheckoutInterface) => state.checkoutReducer
    );
    useEffect(() => {
        if (!info.id) {
            history.push('/marketplace')
        }
    })
    return (
        <div className="payment-wrapper">
            <div className="payment-title">
                <div className="inner-title">
                    <CheckoutBack backTo='/checkout' />
                    <h1>Payment Method</h1>
                    <p>step 2 of 3</p>
                </div>
            </div>
            <div className="checkout-container">
                <CheckoutMenu history={history} />
                <CentralPayment />
                <ImageContainer className='right-container-payment' />
            </div>
        </div>
    );
};

export default PaymentPage;
