import { useState } from "react";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import AssetCheckout from "../../asset-advansed-view/asset-checkout/assetCheckout";
import AssetTopRight from "../../asset-advansed-view/asset-top-right/assetTopRight";

function AdvanceItemInfo() {
  const [checkout, setCheckout] = useState(false);

  const toggleCheckout = () => {
    setCheckout(!checkout);
  };
  
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  return (
    <div className="asset-info">
      <div className="asset-title">
        <div>
          <div className="title">{info.title} <span>(${info.alias})</span></div>
          <div className="location">{info.location}</div>
          <div className="descr">{info.description}</div>
        </div>
      </div>
      <div className="asset-info-main">
        <div className="left">
          <div className="asset-image">
            <img src={info.images[0]} alt="" />
          </div>
        </div>
        <div className="right">
          {checkout ? null : (
            <AssetTopRight
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          )}
          {checkout ? (
            <AssetCheckout
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdvanceItemInfo;
