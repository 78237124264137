import * as types from "./../../constants/types";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import { postTokenApi } from "./../shared/postTokenApi";
import { getTokenApi } from "./../shared/getTokenApi";
import { put, call, takeEvery } from "redux-saga/effects";
import {
  DeleteTokenizationFileSaga,
  GetSubscriptionSaga,
  GetFirstStepSaga,
  GetFivethStepSaga,
  GetFourthStepSaga,
  GetSecondStepSaga,
  GetThirdStepSaga,
  SendFirstStepSaga,
  SendFivethStepSaga,
  SendFourthStepSaga,
  SendSecondStepSaga,
  SendThirdStepSaga,
  SendTokenizationFileSaga,
  setTokenizationPictureAction,
  ATfivethStep,
  getAllProductsAction,
  getPaidStatus,
  setPaidStatus,
} from "../../../interfaces/applyTokenizationInterface";
import { deleteTokenApi } from "../shared/deleteTokenApi";
import { addTokenizationFile, stripeOnDonePayment } from "../../actions/applyTokenizationActions";
import { showSuccessSnackbar, showWarningSnackbar } from "../../actions/registartionAction";

function* sendATDashboardSaga(
  action: SendFirstStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/project-details";
    const atResponse = yield call(postTokenApi, [
      action.payload.firstStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      if (atResponse.status) {
        const endpoint = "tokenization/user";
        const tokenizationData = yield call(getTokenApi, endpoint);
        if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
          yield put({
            type: types.GET_TOKENIZATION_BY_USER_SUC,
            payload: tokenizationData.data,
          });
          yield put({
            type: types.SEND_AT_FIRST_STEP_SUC,
            payload: atResponse.data,
          });
        } else {
          throw tokenizationData;
        }
        return;
      }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL });
  }
}

function* getCurrentSubscriptionSaga(
  action: GetSubscriptionSaga
): TypedIterableIterator<any, any, number> {
  let atResponse
  try {
    const endpoint = "tokenization/checkout/purchases";
    atResponse = yield call(postTokenApi, [
      { tokenizationId: action.payload.tokenizationId },
      endpoint,
    ]);
    if (
      atResponse.status >= 200 && atResponse.status < 300
      &&
      atResponse.data.tokenizationSubscription
    ) {
      yield put({
        type: types.GET_CURRENT_SUBSCRIPTION_SUC,
        payload: atResponse.data,
      });
      return;

    } else throw atResponse;
  } catch (e) {
    console.log('GET_SUBSCRIPTION_FAIL', e);
  }
}

function* checkTokenizationNameSaga(
  action: { payload: string, type: string, callBack: (prop: { symbol: string }) => void }
): TypedIterableIterator<any, any, number> {

  const atResponse = yield call(postTokenApi, [
    { name: action.payload },
    `tokenization/project-details/checkTicker`,
  ]);
  yield call(
    action.callBack,
    atResponse.data
  );
}

function* checkTokenizationSymbolSaga(
  action: { payload: string, type: string, callBack: (prop: boolean) => void }
): TypedIterableIterator<any> {
  const atResponse = yield call(postTokenApi, [
    { name: action.payload },
    'tokenization/project-details/isValidSymbol',
  ])
  if (atResponse?.response?.status) {
    yield call(
      action.callBack,
      false
    );
  } else {
    yield call(
      action.callBack,
      atResponse?.data?.status
    );
  }


}

function* sendATfirstStepSaga(
  action: SendFirstStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/project-details";
    const atResponse = yield call(postTokenApi, [
      action.payload.firstStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      sessionStorage.setItem('tokenizationId', atResponse.data.tokenizationId)
      if (atResponse.status) {
        yield put({
          type: types.SEND_AT_FIRST_STEP_SUC,
          payload: atResponse.data,
        });
        return;
      }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL }); // , payload: e
  }
}

function* sendATsecondStepSaga(
  action: SendSecondStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/offering-terms";
    const atResponse = yield call(postTokenApi, [
      action.payload.secondStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      if (atResponse.status) {
        yield put({
          type: types.SEND_AT_SECOND_STEP_SUC,
          payload: atResponse.data,
        });
        return;
      }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL }); // , payload: e
  }
}

function* sendATthirdStepSaga(
  action: SendThirdStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/assetInfo";
    const atResponse = yield call(postTokenApi, [
      action.payload.thirdStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      if (atResponse.status) {
        yield put({
          type: types.SEND_AT_THIRD_STEP_SUC,
          payload: atResponse.data,
        });
        return;
      }
      // if (!atResponse.data.status) {
      //     action.payload.history.push("/kyc/id-verification");
      // } else {
      //     action.payload.history.push("/marketplace");
      // }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL }); // , payload: e
  }
}

function* sendATfourthStepSaga(
  action: SendFourthStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/company";
    const atResponse = yield call(postTokenApi, [
      action.payload.fourthStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      if (atResponse.status) {
        yield put({
          type: types.SEND_AT_FOURTH_STEP_SUC,
          payload: atResponse.data,
        });
        return;
      }
      // if (!atResponse.data.status) {
      //     action.payload.history.push("/kyc/id-verification");
      // } else {
      //     action.payload.history.push("/marketplace");
      // }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL }); // , payload: e
  }
}

function* sendATfivethStepSaga(
  action: SendFivethStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/document";
    const atResponse = yield call(postTokenApi, [
      action.payload.fivethStep,
      endpoint,
    ]);
    if (atResponse.status >= 200 && atResponse.status < 300) {
      if (atResponse.status) {
        yield put({
          type: types.SEND_AT_FIVETH_STEP_SUC,
          payload: atResponse.data,
        });
        return;
      }
      // if (!atResponse.data.status) {
      //     action.payload.history.push("/kyc/id-verification");
      // } else {
      //     action.payload.history.push("/marketplace");
      // }
    } else throw atResponse;
  } catch (e) {
    yield put({ type: types.SEND_AT_STEP_FAIL }); // , payload: e
  }
}

function* getTokenizationByUserSaga(): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/user";
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_TOKENIZATION_BY_USER_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_TOKENIZATION_BY_USER_FAIL, payload: e });
  }
}

function* getATFirstStepSaga(
  action: GetFirstStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/project-details/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_AT_FIRST_STEP_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* getATSecondStepSaga(
  action: GetSecondStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/offering-terms/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_AT_SECOND_STEP_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* getATThirdStepSaga(
  action: GetThirdStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/assetInfo/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_AT_THIRD_STEP_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* getATFourthStepSaga(
  action: GetFourthStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/company/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_AT_FOURTH_STEP_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* getATFivethStepSaga(
  action: GetFivethStepSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/document/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_AT_FIVETH_STEP_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* uploadATFileSaga(
  
  action: {
    type: typeof types.AT_UPLOAD_FILE_REQ,
    payload: {
      formData: FormData,
      tokenizationId: string | null,
      stepInfo: ATfivethStep,
      progressCB: (d: any) => void,
      doneCB: () => void,
    }
  }
): TypedIterableIterator<any, any, number> {
  try{
  const uploadData = yield call(
    postTokenApi,
    [
      action.payload.formData,
      `tokenization/document/file/${action.payload.tokenizationId}`,
      {
        onUploadProgress: action.payload.progressCB,
      }
    ]
  );
  if(uploadData?.response?.data?.error){
    yield put(showWarningSnackbar(uploadData.response.data.error))

  }
  yield call(action.payload.doneCB)
  yield put(addTokenizationFile(
    uploadData,
    action.payload.tokenizationId,
    action.payload.stepInfo)
  )
  }catch(e){
    console.log(e)
  }


}
function* sendATFileSaga(
  action: SendTokenizationFileSaga
): TypedIterableIterator<any, any, number> {
  try {
    if (action.payload.file.status >= 200 && action.payload.file.status < 300) {
      yield put({
        type: types.AT_ADD_FILE_SUC,
        payload: {
          file: action.payload.file.data,
          stepInfo: action.payload.stepInfo,
        },
      });
      return;
    } else {
      throw action.payload.file;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* deleteATFileSaga(
  action: DeleteTokenizationFileSaga
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/document/file/${action.payload.fileId}`;
    const tokenizationData = yield call(deleteTokenApi, [endpoint]);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.AT_DELETE_FILE_SUC,
        payload: {
          fileId: action.payload.fileId,
          stepInfo: action.payload.stepInfo,
        },
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}
function* setTokenizationPictureSaga(action: setTokenizationPictureAction): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = `tokenization/image/${action.payload.tokenizationId}`;
    const tokenizationData = yield call(postTokenApi, [
      action.payload.formData,
      endpoint,
    ]);
    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.SET_TOKENIZATION_PICTURE_RESP,
        payload: {
          imageUrl: tokenizationData.data.imageUrl,
          tokenizationId: tokenizationData.data.id,
        }
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* getProductsSaga(action: getAllProductsAction): TypedIterableIterator<any, any, number>{
  try {
    const {data} = yield call(getTokenApi, `admin/tokenization/payment/product`);
    yield call(action.payload, data);
  } catch (e) {
    yield put({ type: types.GET_AT_STEP_FAIL, payload: e });
  }
}

function* stripeOnDonePaymentSaga(action: ReturnType<typeof stripeOnDonePayment>): TypedIterableIterator<any, any, number>{
  try {
    const {data} = yield call(postTokenApi, [
      {sessionId:action.payload.sessionId},
      `tokenization/checkout/create-portal-session`,
    ]);
    if(data?.url){
      yield put(showSuccessSnackbar('Payment success'))
    }
  } catch (e:any) {
    yield put(showWarningSnackbar('Error',e?.message||''));
  }
}

function* getTokenizationPaidStatusSaga(
  action: {
    type: string;
    payload: getPaidStatus;
  }
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/marketer/status";
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_TOKENIZATION_PAID_STATUS_SUC,
        payload: tokenizationData.data,
      });
      if ( tokenizationData.data.formResult === "A" ){
        action.payload.history.push("/tokenization-thanks");
      }
      if ( tokenizationData.data.formResult === "B" && !tokenizationData.data.isPaymentMade ){
        action.payload.history.push("/tokenization-form");
      }
      // console.log('status ', tokenizationData.data)
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    action.payload.history.push("/tokenization-form");
    yield put({ type: types.GET_TOKENIZATION_BY_USER_FAIL, payload: e });
  }
}

function* getTokenizationPaidStatusWithoutRedirectSaga(
  action: {
    type: string;
  }
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/marketer/status";
    const tokenizationData = yield call(getTokenApi, endpoint);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      yield put({
        type: types.GET_TOKENIZATION_PAID_STATUS_SUC,
        payload: tokenizationData.data,
      });
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_TOKENIZATION_BY_USER_FAIL, payload: e });
  }
}

function* setTokenizationPaidStatusSaga(
  action: {
    type: string;
    payload: setPaidStatus;
  }
): TypedIterableIterator<any, any, number> {
  try {
    const endpoint = "tokenization/marketer/status";
    const tokenizationData = yield call(postTokenApi, [
      {"formResult":action.payload},
      endpoint,
    ]);

    if (tokenizationData.status >= 200 && tokenizationData.status < 300) {
      return;
    } else {
      throw tokenizationData;
    }
  } catch (e) {
    yield put({ type: types.GET_TOKENIZATION_BY_USER_FAIL, payload: e });
  }
}

function* tokenizationPurchaseSaga(
  action: any
): TypedIterableIterator<any, any, number> {
  try{
  const responce = yield call(
    postTokenApi,
    [action.payload,`tokenization/stripe/setUp`]
  )
  action.payload.callBack(responce.data.url)
  }catch(e:any){
    yield put(showWarningSnackbar('Server error',e?.message))
  }
}

function* watchSendATsteps() {
  yield takeEvery(types.SET_TOKENIZATION_PICTURE_REQ, setTokenizationPictureSaga);
  yield takeEvery(types.GET_ALL_PRODUCTS, getProductsSaga);
  yield takeEvery(types.SEND_AT_DASHBOARD_REQ, sendATDashboardSaga);
  yield takeEvery(types.GET_CURRENT_SUBSCRIPTION, getCurrentSubscriptionSaga);
  yield takeEvery(types.STIPRE_DONE_PAYMENT_REQEST, stripeOnDonePaymentSaga);
  yield takeEvery(types.SEND_AT_FIRST_STEP_REQ, sendATfirstStepSaga);
  yield takeEvery(types.SEND_AT_SECOND_STEP_REQ, sendATsecondStepSaga);
  yield takeEvery(types.SEND_AT_THIRD_STEP_REQ, sendATthirdStepSaga);
  yield takeEvery(types.SEND_AT_FOURTH_STEP_REQ, sendATfourthStepSaga);
  yield takeEvery(types.SEND_AT_FIVETH_STEP_REQ, sendATfivethStepSaga);
  yield takeEvery(
    types.GET_TOKENIZATION_BY_USER_REQ,
    getTokenizationByUserSaga
  );
  yield takeEvery(types.CHECK_TOKENIZATION_NAME_REQUEST, checkTokenizationNameSaga);
  yield takeEvery(types.GET_TOKENIZATION_PAID_STATUS_REQ, getTokenizationPaidStatusSaga);
  yield takeEvery(types.GET_TOKENIZATION_PAID_STATUS_WITHOUT_REDIRECT_REQ, getTokenizationPaidStatusWithoutRedirectSaga);
  yield takeEvery(types.SET_TOKENIZATION_PAID_STATUS_REQ, setTokenizationPaidStatusSaga);
  yield takeEvery(types.TOKENIZATION_PURCHASE_REQ, tokenizationPurchaseSaga);
  yield takeEvery(types.CHECK_TOKENIZATION_SYMBOL_REQUEST, checkTokenizationSymbolSaga);
  yield takeEvery(types.GET_AT_FIRST_STEP_REQ, getATFirstStepSaga);
  yield takeEvery(types.GET_AT_SECOND_STEP_REQ, getATSecondStepSaga);
  yield takeEvery(types.GET_AT_THIRD_STEP_REQ, getATThirdStepSaga);
  yield takeEvery(types.GET_AT_FOURTH_STEP_REQ, getATFourthStepSaga);
  yield takeEvery(types.GET_AT_FIVETH_STEP_REQ, getATFivethStepSaga);

  yield takeEvery(types.AT_UPLOAD_FILE_REQ, uploadATFileSaga);
  yield takeEvery(types.AT_ADD_FILE_REQ, sendATFileSaga);
  yield takeEvery(types.AT_DELETE_FILE_REQ, deleteATFileSaga);
}

export default watchSendATsteps;
