import { useLocation } from "react-router";
// import { HashLink } from 'react-router-hash-link';

import logoFacebook from "./../../Assets/icons/logo-facebook.svg";
import logoLinkedIn from "./../../Assets/icons/logo-linkedin.svg";
import logoTwitter from "./../../Assets/icons/logo-twitter.svg";

import youtubeIcon from "./../../Assets/icons/socials/youtube.svg"
import instagramIcon from "./../../Assets/icons/socials/instagram.svg"
import linkedinIcon from "./../../Assets/icons/socials/linkedin.svg"
import twitterIcon from "./../../Assets/icons/socials/twitter.svg"
import discordIcon from "./../../Assets/icons/socials/discord.svg"
import facebookIcon from "./../../Assets/icons/socials/facebook.svg"
import benzinga from "./../../Assets/images/benzinga.png"

import { NOT_FOOTER_CATEGORIES } from "../../constants/footer";
import { CASE_STUDY_HREF, PRIVACY_POLICY_HREF, TERMS_CONDITIONS_HREF } from "../../constants/agreements";
import { Link } from "react-router-dom";

function Footer() {
    const { pathname } = useLocation();

    if (NOT_FOOTER_CATEGORIES.includes(pathname)) {
        return <></>;
    }

    return (
        <footer>
            <div className="w-container">
                <div className="footer-company-desktop">
                    <div className="company">
                        © DIBS Capital {new Date().getFullYear()}
                        <br /> All Rights Reserved
                    </div>
                </div>
                <div className="footer-menu-container">
                    <div className="footer-menu-item">
                        <h6>Company</h6>
                        <Link
                            to='/about'
                            className="link"
                        >
                            About
                        </Link>
                        <Link
                            to='/contact'
                            className="link"
                        >
                            Contact
                        </Link>
                        <a href="https://blog.dibscapital.com/" className="link">Blog</a>
                    </div>
                    <div className="footer-menu-item">
                        <h6>Resources</h6>
                        <a
                            href={CASE_STUDY_HREF}
                            rel="noreferrer"
                            target="_blank"
                            className="link"
                        >
                            Case Study
                        </a>
                        <a
                            href={TERMS_CONDITIONS_HREF}
                            rel="noreferrer"
                            target="_blank"
                            className="link"
                        >
                            Terms of use
                        </a>
                        <a
                            href={PRIVACY_POLICY_HREF}
                            rel="noreferrer"
                            target="_blank"
                            className="link"
                        >
                            Privacy policy
                        </a>
                    </div>
                    <div className="footer-menu-item">
                        <h6>Follow us</h6>
                        <p className="social-links">
                            <a
                                href="https://www.linkedin.com/company/dibscapital/"
                                className="link social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={linkedinIcon} alt="Linkedin" />
                            </a>
                            <a
                                href="https://www.facebook.com/dibscapital/"
                                className="link social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={facebookIcon} alt="Facebook" />
                            </a>
                            <a
                                href="https://twitter.com/DIBS_Capital"
                                className="link social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={twitterIcon} alt="Twitter" />
                            </a>
                            <a
                                href="https://discord.gg/99CCVmsQ7e"
                                className="link social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={discordIcon} alt="Discord" />
                            </a>
                            <a
                                className="link social-link"
                                href="https://www.instagram.com/dibscapital/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={instagramIcon} alt="Instagram" />
                            </a>
                            <a
                                className="link social-link"
                                href="https://www.youtube.com/@dibscapital"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={youtubeIcon} alt="YouTube" />
                            </a>

                        </p>
                        <div className="social-icons">
                            <a
                                href="https://www.linkedin.com/company/dibscapital/"
                                className="link social-link-icon"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={logoLinkedIn} alt="linkedin" />
                            </a>
                            <a
                                href="https://www.facebook.com/dibscapital/"
                                className="link social-link-icon"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={logoFacebook} alt="facebook" />
                            </a>
                            <a
                                href="https://twitter.com/DIBS_Capital"
                                className="link social-link-icon"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={logoTwitter} alt="twitter" />
                            </a>
                            <a
                                href="https://discord.gg/99CCVmsQ7e"
                                className="link social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={discordIcon} alt="Discord" />
                            </a>
                            <a
                                className="link social-link-icon"
                                href="https://www.instagram.com/dibscapital/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={instagramIcon} alt="Instagram" />
                            </a>
                            <a
                                className="link social-link-icon"
                                href="https://www.youtube.com/@dibscapital"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img src={youtubeIcon} alt="YouTube" />
                            </a>
                        </div>
                    </div>
                    <div className="footer-menu-item">
                        <img src={benzinga} alt="" style={{ width: "150px", marginTop: "15px", marginBottom: "10px" }} />
                    </div>
                </div>
            </div>
            <div className="footer-company-mobile">
                <div className="company">
                    © DIBS Capital {new Date().getFullYear()}
                    <br /> All Rights Reserved
                </div>
            </div>
            <div className="w-container disclaimer">
                This site is operated by SDIB Inc. (operating under brand name DIBS Capital), which is not a
                registered broker-dealer or investment advisor. SDIB Inc.
                does not give investment advice, endorsement, analysis or recommendations with respect to any
                securities. Offers to sell, or the solicitations of offers to buy, any security can only be made
                through official offering documents. Investors should conduct their own due diligence, not rely
                on the financial assumptions or estimates displayed on this website, and are encouraged to
                consult with a financial advisor attorney,
                accountant, and any other professional that can help understand
                and assess the risks associated with any investment opportunity.
                SDIB Inc. does not guarantee any investment performance,
                outcome or return of capital for any investment opportunity
                posted on this site. By accessing this site and any pages
                thereof, you agree to be bound by the <a href="https://docs.dibscapital.com/DIBS+Terms+of+Service.pdf">
                    Terms of Service
                </a> and {' '}
                <a href="https://docs.dibscapital.com/DIBS+Privacy+Policy.pdf">
                    Privacy Policy.
                </a>
            </div>
        </footer>
    );
}

export default Footer;
