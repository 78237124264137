import * as types from "./../constants/types";
import { PostPurchaseInfo } from "../../interfaces/myInvestymentsInterface";

export const getMyInvestmentsReq = () => {
    return {
        type: types.GET_MY_INVESTMENTS_REQ,
    };
};
export const getHistoryReq = () => {
    return {
        type: types.GET_MY_HISTORY_REQ,
    };
};
export const postMyInvestmentsReq = (info: PostPurchaseInfo) => {
    return {
        type: types.POST_MY_INVESTMENTS_REQ,
        payload: info,
    };
};

export const toggleHistoryInfo = (id: string) => {
    return {
        type: types.TOGGLE_HISTORY,
        payload: id,
    };
};
