import { refreshTokenApi } from "./refreshToken";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

const deleteWithBodyApi = async (action: any) => {
    let ourDate: any = localStorage.getItem("expiredTime");
    let final: any = new Date(ourDate * 1000);
    let date: any = new Date();
    if (final - date <= 0) {
        await refreshTokenApi();
    }
    return axios
        .delete(url + action[1],{
            data: action[0],
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        })
        .then((res: any) => res)
        .catch((err: any) => {
            if (err.response.status === 401){
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('expiredTime')
            }
            throw err
        });
};

export { deleteWithBodyApi };
