import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

function CheckoutStages() {
  let { path } = useRouteMatch();
  const history = useHistory();

  return (
    <div className="checkout-stages">
      <div className="line">
        <div
          onClick={() => {
            if (path.includes("/checkout/payment")) {
              history.goBack();
            }
          }}
          className={`first ${
            !path.includes("/checkout/payment") && !path.includes("/checkout/confirmation")
              ? "active"
              : ""
          }`}
        >
          <span className="label">Overview</span>
        </div>
        <div
          className={`second ${path.includes("/checkout/payment") ? "active" : ""}`}
        >
          <span className="label">Payment</span>
        </div>
        <div
          className={`third ${
            path.includes("/checkout/confirmation") ? "active" : ""
          }`}
        >
          <span className="label">Confirmation</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutStages;
