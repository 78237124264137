import React from "react";

function AssetHighlights() {
  return (
    <div className="asset-highlights">
      <div className="title">Highlights</div>
      <ul className="highlights-list">
        <li className="highlight-item">10,000 visitors per year.</li>
        <li className="highlight-item">
          32 million tourists in 2019{" "}
          <a 
          href="https://www.onecaribbean.org/buy-cto-tourism-statistics/annual-statistical-report/"
          target="_blank"
          rel="noreferrer"
          style={{color: '#0EC22B'}}>
            (Caribbean Tourism Organization).
          </a>
        </li>
        <li className="highlight-item">Projected 15% annualized ROI.</li>
        <li className="highlight-item">
          Entry to the Preferred Equity level in the Capital Stack.
        </li>
        <li className="highlight-item">
          Long-term returns for shareholders in the parent company.
        </li>
        <li className="highlight-item">
          Sustainability of the ecosystem and local communities.
        </li>
        <li className="highlight-item">
          Preservation of 1000+ acres of rainforest in perpetuity.
        </li>
        <li className="highlight-item">
          A 1:1 ratio to the US dollar of Panama’s national currency, the
          Balboa.
        </li>
      </ul>
    </div>
  );
}

export default AssetHighlights;
