import * as types from "./../constants/types";

export const exchangeHandler = (exchange: boolean) => {
    return {
        type: types.ECHANGE_COLOR,
        exchange,
    };
};

export const getAllOffers = () => {
    return {
        type: types.GET_ALL_EXCHANGE_OFFERS,
    };
};

export const getTempItems = () => {
    return {
        type: types.GET_TEMP_ITEMS,
    };
};

export const getTempTokens = (id: string) => {
    return {
        type: types.GET_TEMP_TOKENS,
        id,
    };
};
export const postOffer = (tradeInfo: object) => {
    return {
        type: types.POST_TRADE_OFFER,
        tradeInfo,
    };
};
export const buyOffer = (id: string, cryptoId: string) => {
    return {
        type: types.BUY_EXCHANGED_OFFER,
        id,
        cryptoId,
    };
};

export const cancelOffer = (id: string) => {
    return {
        type: types.CANCEL_OFFER,
        id,
    };
};

export const closeSnackbar = () => {
    return {
        type: types.CLOSE_SNACKBAR,
    };
};

export const getUserAssetsTokens = () => {
    return {
        type: types.GET_USER_ASSETS_TOKENS,
    };
};

export const checkUserTokens = (id: string, total: string) => {
    return {
        type: types.CHECK_USER_TOKENS,
        id,
        total,
    };
};

export const openSnackbar = (main: string, secMessage: string) => {
    return {
        type: types.SNACKBAR_RESPONSE,
        mainMessage: main,
        secMessage: secMessage,
        status: "warning",
    };
};
