// import { useHistory } from "react-router-dom";
// import { Button } from "@material-ui/core";
// import Geo from '../../Assets/images/geo.svg';
// import { ReactComponent as Crown } from '../../Assets/images/crown.svg';
// import { CURRENCY_SYMBOLS } from '../../constants/myInvestmentsConstants'
// import { addComma } from '../../constants/addCommaFunction'
// import { useSelector } from "react-redux";
// import { CheckoutInterface } from "../../interfaces/assetsInterface";
// import { THEME_COLOR } from "../../constants/colors";
const ConfirmationMain = () => {
    // const info = useSelector(
    //     (state: CheckoutInterface) => state.checkoutReducer
    // );;
    // const history = useHistory()
    return (
        <div className='confirmation-component'>
            {/* <div className='inner-confirmation'>
                <div className='confirmation-1'>
                    <div className='crown-container'>
                        <img alt='' className='crown-asset' src={info.imagePath} />
                        <Crown className="crown-img" fill={THEME_COLOR} />
                    </div>
                    <div className='confirmation-overview'>
                        <h2>{info.title}</h2>
                        <div className='geo'><img alt='' src={Geo} /><p>{info.location} </p></div>
                    </div>
                </div>
                <div className='confirmation-2'>
                    <h2>Cogratulations! Your  {CURRENCY_SYMBOLS[info.currency]}{addComma(String(info.inputVal))} bid will be processed and we’ll tell you once we get the payment.</h2>
                    <p>Usually it takes around 48 hours for getting
                    such email. Wishing you the best. Meanwhile, you could check all of your investment assets or find another one to buy.
                </p>
                    <Button
                        onClick={() => history.push('/my-investments')}
                        style={{ backgroundColor: THEME_COLOR }}>My investments</Button>
                </div>
            </div> */}

        </div>
    )
}

export default ConfirmationMain