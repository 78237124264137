import * as types from "./../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { postApi } from "./../shared/postApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* contactsSaga(action: any): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "contact";
        const exchangeItems = yield call(postApi, [action.data, endpoint]);
        if (exchangeItems.status >= 200 && exchangeItems.status < 300) {
            action.callBack()
            yield put({
                type: types.SNACKBAR_RESPONSE,
                mainMessage: "Your message has been sent",
                secMessage: "Your payment ....",
                status: "success",
            });
        } else throw exchangeItems;
    } catch (e) {
        yield put({
            type: types.SNACKBAR_RESPONSE,
            mainMessage: "Something's gone wrong",
            secMessage: "Your payment ....",
            status: "warning",
        });
    }
}

function* partnersContactsSaga(
    action: any
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "message/request";
        const exchangeItems = yield call(postApi, [action.data, endpoint]);

        if (exchangeItems.status >= 200 && exchangeItems.status < 300) {
            yield put({
                type: types.SNACKBAR_RESPONSE,
                mainMessage: "Your message has been sent",
                secMessage: "Your payment ....",
                status: "success",
            });
        } else throw exchangeItems;
    } catch (e) {
        yield put({
            type: types.SNACKBAR_RESPONSE,
            mainMessage: "Something's gone wrong",
            secMessage: "Your payment ....",
            status: "warning",
        });
    }
}
function* onboardingReqSaga(
    action: any
): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "message/offering";
        const documents = JSON.parse(JSON.stringify(action.data));
        documents.documents.push(action.data.customDoc);
        const exchangeItems = yield call(postApi, [documents, endpoint]);

        if (exchangeItems.status >= 200 && exchangeItems.status < 300) {
            yield put({
                type: types.SNACKBAR_RESPONSE,
                mainMessage: "Your message has been sent",
                secMessage: "Your payment ....",
                status: "success",
            });
        } else throw exchangeItems;
    } catch (e) {
        yield put({
            type: types.SNACKBAR_RESPONSE,
            mainMessage: "Something's gone wrong",
            secMessage: "Your payment ....",
            status: "warning",
        });
    }
}

function* watchContactsSaga() {
    yield takeEvery(types.SEND_CONTACTS_DATA, contactsSaga);
    yield takeEvery(types.SET_PARTNERS_CONTACTS, partnersContactsSaga);
    yield takeEvery(types.SEND_ONBOARDING_DATA, onboardingReqSaga);
}

export default watchContactsSaga;
