import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import mautic from 'mautic-tracking';
import Spinner from '../../../components/spinner/spinner';
import { showWarningSnackbar } from "../../../redux/actions/registartionAction";
import { useDispatch } from "react-redux";
const axios = require("axios");

function SocialAuth(props: any) {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        mautic.pageView({});
        const code = props.location.search.slice(
            6,
            Number(props.location.search.length)
        );
        const error = props.location.search.includes("commonAccountExist");
        if (error){
            dispatch(showWarningSnackbar(
                `Once you are signed in via an email and password, you will not be able to use your Google account to log in`
            ));
            history.push("/login")
            return
        }
        axios
            .post(`${process.env.REACT_APP_API_KEY}auth/oauth/signIn`, {
                code: code,
                redirect: `${process.env.REACT_APP_BASE_URL}/social-auth`,
                referralCode: localStorage.getItem("referralCode")
            })
            .then((res: any) => {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("refreshToken", res.data.refreshToken);
                localStorage.setItem("expiredTime", res.data.expiredTime);
                localStorage.removeItem("referralCode")
                history.push("/sign-up/login-redirect")
            });
    });

    return <div>
         <Spinner />
    </div>;
}

export default SocialAuth;
