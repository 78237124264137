// const infoAdvanced = {
//   title: "Detox Digital Coin Marketplace Site",
//   highlightItems: [
//     {highlight:"Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years."},
//     {highlight:"Industry revenues will climb to almost $99.4 billion by 2028."},
//     {highlight:"Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs."},
//     {highlight:"The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year."},
//     {highlight:"BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments."},
//     {highlight:"Facilities identified, renovated and leased prior to acquisition."},
//     {highlight:"“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent."},
//     {highlight:"Anticipated 5-year Annualized Return: 12%."},
//   ]
// }

function AdvanceHighlights() {
  return (
    <div className="asset-highlights">
      <div className="title">Highlights</div>
      <ul className="highlights-list">
        <li className="highlight-item">Behavioral health centers (BHCs) are <a
          href="https://www.globenewswire.com/en/news-release/2021/06/14/2246343/0/en/US-Behavioral-Health-Market-to-Hit-USD-77-62-billion-with-3-6-CAGR-till-2028-Emergence-of-Virtual-Reality-based-Treatment-Options-to-Accelerate-Growth-says-Fortune-Business-Insight.html"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          forecast
        </a>{" "}to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years and industry 
          revenues will climb
        to almost $99.4 billion by 2028.</li>
        <li className="highlight-item">The <a
          href="https://www.zillow.com/fl/home-values/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0EC22B" }}
        >
          median value
        </a>{" "}of a Florida house was $270,560 in January, an 8.6% increase over the previous year.</li>
        <li className="highlight-item">BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments.</li>
        <li className="highlight-item">Facilities identified, renovated and leased prior to acquisition.</li>
        <li className="highlight-item">“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent.</li>
        <li className="highlight-item">Anticipated 5-year Annualized Return: 12.03%.</li>
        {/* {infoAdvanced.highlightItems.map((el: any, i: number) => {
            return (
                <li key={i} className="highlight-item">
                    <p>{el.highlight}</p>
                </li>
            );
        })} */}
      </ul>
    </div>
  );
}

export default AdvanceHighlights;