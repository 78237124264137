import { useState } from 'react'
import CloseIcon from '../../../components/accreditation-components/close-icon/closeIcon';
import LabelInput from "../../../components/profile-components/labelInput/labelInput";
import GetHelp from "../../../components/get-help/getHelp";
import { TextField, Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { THEME_COLOR } from '../../../constants/colors'

function IssuerAccountStep2() {

    const [accountInfo, setAccountInfo] = useState({
        middleInitial: '',
        ssn: '',
        USResident: '',
        USCitizen: '',
        birthDate: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        additional: '',
        issiungCountry: '',
    })

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountInfo(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }


    return (
        <section className="issuer-step2-page">
            <div className="issuer-step2">
                <CloseIcon />
                <div className="form-title-container">
                    <h3 className="form-title">
                        Create issuer account
                    </h3>
                    <span className="step">
                        Step 2 of 2
                    </span>
                </div>
                <form className="issuer-step2-form" onSubmit={(e) => e.preventDefault()}>
                    <LabelInput
                        value={accountInfo.middleInitial}
                        handler={inputHandler}
                        id="middleInitial"
                        name="middleInitial"
                        label="Middle initial"
                        disabled={false} />
                    <LabelInput
                        value={accountInfo.ssn}
                        handler={inputHandler}
                        id="ssn"
                        name="ssn"
                        label="SSN"
                        disabled={false} />
                    <label className="checkbox-label">U.S. resident?</label>
                    <RadioGroup
                        onChange={inputHandler}
                        name="USResident"
                        value={accountInfo.USResident}
                        className="checkbox-group">
                        <FormControlLabel
                            label="Yes"
                            value="Yes"
                            control={<Radio style={{ color: THEME_COLOR }} />}
                            name="USResident"
                        />
                        <FormControlLabel
                            label="No"
                            value="No"
                            control={<Radio style={{ color: THEME_COLOR }} />}
                            name="USResident" />
                    </RadioGroup>
                    <label className="checkbox-label">U.S. citizen?</label>
                    <RadioGroup
                        onChange={inputHandler}
                        name="USCitizen"
                        value={accountInfo.USCitizen}
                        className="checkbox-group">
                        <FormControlLabel
                            label="Yes"
                            value="Yes"
                            control={<Radio style={{ color: THEME_COLOR }} />}
                            name="USCitizen"
                        />
                        <FormControlLabel
                            label="No"
                            value="No"
                            control={<Radio style={{ color: THEME_COLOR }} />}
                            name="USCitizen" />
                    </RadioGroup>
                    <LabelInput
                        value={accountInfo.birthDate}
                        handler={inputHandler}
                        id="birthDate"
                        name="birthDate"
                        label="Date of birth"
                        disabled={false} />
                    <LabelInput
                        value={accountInfo.address1}
                        handler={inputHandler}
                        id="address1"
                        name="address1"
                        label="Address line 1"
                        disabled={false} />
                    <LabelInput
                        value={accountInfo.address2}
                        handler={inputHandler}
                        id="address2"
                        name="address2"
                        label="Address line 2"
                        disabled={false} />
                    <LabelInput
                        value={accountInfo.city}
                        handler={inputHandler}
                        id="city"
                        name="city"
                        label="City"
                        disabled={false} />
                    <label htmlFor="state" className="inputlabel-label">State</label>
                    {/* <TextField
                        select
                        className="form-field"
                        name="state"
                        id="state"
                        onChange={inputHandler}
                        value={accountInfo.state} /> */}
                    <LabelInput
                        value={accountInfo.zip}
                        handler={inputHandler}
                        id="zip"
                        name="zip"
                        label="ZIP"
                        disabled={false} />
                    <LabelInput
                        value={accountInfo.country}
                        handler={inputHandler}
                        id="country"
                        name="country"
                        label="Country"
                        disabled={false} />
                    <label htmlFor="state" className="inputlabel-label">Additional info</label>
                    <TextField
                        value={accountInfo.additional}
                        onChange={inputHandler}
                        id="additional"
                        name="additional"
                        multiline
                        rowsMax={6}
                        className="form-field" />
                    <LabelInput
                        value={accountInfo.issiungCountry}
                        handler={inputHandler}
                        id="issiungCountry"
                        name="issiungCountry"
                        label="Issuing country"
                        disabled={false} />
                    <Button
                        style={{ backgroundColor: THEME_COLOR }}
                        className="accreditation-next-button"
                    >
                        Next ➝{" "}
                    </Button>
                </form>
                <GetHelp />
            </div>
        </section>
    )
}

export default IssuerAccountStep2;