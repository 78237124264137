import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { THEME_COLOR } from "../../constants/colors";
import { CheckBoxProps } from "../../interfaces/checkBoxInterface";
import checkingMark from './../../Assets/icons/checking-mark.svg'

const useStyles = makeStyles({
    icon: {
        border: `2px solid ` + THEME_COLOR,
    },
    checkedIcon: {
        border: `2px solid ` + THEME_COLOR,
        backgroundColor: THEME_COLOR
    }
});

const CheckboxInput = (props: CheckBoxProps) => {
    const classes = useStyles();

    const { label, checkBoxHandler, checked, disabled, id,style } = props;

    return (
        <FormControlLabel
            style={style}
            control={
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    name={label}
                    id={id}
                    onChange={(e) => {
                        checkBoxHandler(e);
                    }}
                    icon={<span className={classes.icon + " custom-icon"}></span>}
                    checkedIcon={<span className={classes.checkedIcon + " custom-icon"}><img alt='' src={checkingMark} className="custom-checked" /></span>}
                />
            }
            label={label ? label : null}
            className="notifications-checkbox"
        />
    );
};

export default CheckboxInput;
