import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from "react-redux";
import { useEffect } from 'react'
import { useState } from 'react';
import { useHistory } from "react-router-dom";

export default function Plaid() {
    const link = useSelector((state: any) => state.achReducer.link)
    const history = useHistory()
    const [widgetMessage, setWidgetMessage] = useState('')
    const messageHandler = (response: any) => {
        if (response.statusCode === '101') {
            setWidgetMessage('Account created successfully, please confirm your payment !')
        } else if (response.errorCode === '404') {
            history.push('/payment-method/wire')

        } else {
            setWidgetMessage('Something gone wrong')
        }
    }

    const parseWidget = (e: any) => {

        typeof (e.data) === 'string' ? messageHandler(JSON.parse(e.data)) : console.log('wr')
    }
    useEffect(() => {
        window.addEventListener('message', parseWidget)
        return () => window.removeEventListener('message', parseWidget)
    })


    return (
        <FormControl component="fieldset">
            {/* <Button style={{ backgroundColor: THEME_COLOR }}
                onClick={() => history.push('plaid')}>
                Next
            </Button> */}
            {widgetMessage ? <p>{widgetMessage}</p> : <iframe id='iframe' className='iframe' title='plaid-widget' src={link} height="650px" width="400px"></iframe>}

        </FormControl>
    );
}