import { lazy, useEffect } from 'react'
import "@brainhubeu/react-carousel/lib/style.css";
import mautic from 'mautic-tracking';
import {Helmet} from "react-helmet";
const Banner = lazy(() => import('./../../components/marketplace-components/banner/banner'))
// const MarketplaceStories = lazy(() => import('./../../components/marketplace-components/marketplace-stories/marketplaceStories'))
const Cards = lazy(() => import('./../../components/marketplace-components/cards/cards'))
const KycModal = lazy(() => import('./../../components/accreditation-components/kyc-modal/kycModal'))

function MarketplacePage() {
    useEffect(() => {
        mautic.pageView({});
    }, [])
    return (
        <div className="marketplace-page">
            <Helmet>
                <title>Get the Best Online Marketplace Platform - DIBS Capital</title>
                <meta name="description" content="DIBS Capital is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities."></meta>
                <meta name="Keywords" content="Best online marketplace platform"></meta>
            </Helmet>
            <Banner />
            {/* <MarketplaceStories /> */}
            <div className="marketplace-content">
                <Cards />
            </div>
            <KycModal />
        </div>
    )
}

export default MarketplacePage;