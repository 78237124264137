import { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Route } from 'react-router';

const AccreditationFirst = lazy(() => import('./accreditation/accreditationFirst'));
const AccreditationWay = lazy(() => import('./accreditation-way/accreditationWay'));
const AccreditationTaxReturn = lazy(() => import('./accreditation-tax-return/accreditationTaxReturn'));

const ProfilePages = () => {
	return (
		<Switch>
			<Route exact component={AccreditationFirst} path="/accreditation/first" />
			<Route exact component={AccreditationWay} path="/accreditation/way" />
			<Route exact component={AccreditationTaxReturn} path="/accreditation/tax-return" />
			<Route exact component={AccreditationTaxReturn} path="/accreditation/account-statement" />
			<Route exact component={AccreditationTaxReturn} path="/accreditation/letter" />
			{/* <Redirect from="/accreditation" to="/accreditation/first" exact /> */}
			<Redirect from="/accreditation" to="/accreditation/way" exact />
		</Switch>
	);
};

export default ProfilePages;
