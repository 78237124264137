import * as types from "./../../constants/types";

const initialState = {
    exchangeItems: [],
    userId: "",
    representItems: [],
};

const exchangeItemsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.ALL_EXCAHNGE_OFFERS:
            return {
                ...state,
                exchangeItems: action.payload,
                userId: action.userId,
                representItems: action.payload,
            };
        case types.FILTER_OFFERS:
            const newArr = initialState.exchangeItems.filter((el: any) => {
                return el.tradeAssetId !== action.payload;
            });
            return {
                ...state,
                exchangeItems: newArr,
                representItems: newArr,
            };
        default:
            return state;
    }
};

export default exchangeItemsReducer;
