import * as types from "./../../constants/types";

const initialState = {
    tempItems: [],
    tokens: "",
    mainExchangeItems: [],
};

const tempItemsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.TEMP_ITEMS:
            let Crypto = [
                {
                    alias: "BTC",
                    id: undefined,
                    title: "Bitcoin(BTC)",
                },
                {
                    alias: "DASH",
                    id: undefined,
                    title: "Dash(DASH)",
                },
                {
                    alias: "ETH",
                    id: undefined,
                    title: "Ethereum(ETH)",
                },
            ];
            return {
                ...state,
                tempItems: [...Crypto, ...action.payload.assets],
            };
        case types.MAIN_EXCHANGE_ITEMS:
            return {
                ...state,
                mainExchangeItems: action.payload,
            };
        case types.TEMP_TOKENS:
            return {
                ...state,
                tokens: action.payload,
            };
        default:
            return state;
    }
};

export default tempItemsReducer;
