import closeIconSvg from './../../../Assets/icons/Close.svg'
import {useHistory} from 'react-router-dom'

function CloseIcon() {

    const history = useHistory();

    return (
        <>
            <img src={closeIconSvg} 
            alt="Close" 
            className="accreditation-close-icon"
            onClick={() => history.push('/marketplace')} />
        </>
    )
}

export default CloseIcon;