import PropTypes from "prop-types";
import { THEME_COLOR } from "../../constants/colors";

const DEFAULT = "default";
const BORDER_LINK = "border-link";
const LINK = "link";

function getStyles(type: string) {
    switch (type) {
        case LINK:
            return "button-link";
        case BORDER_LINK:
            return "button-border-link";
        default:
            return "button-default";
    }
}

function getIcon(Icon: any) {
    if (Icon)
        return (
            <span className="button-icon">
                <Icon />
            </span>
        );
    return null;
}

function Button(props: any) {
    return (
        <div
            className={`button-container ${getStyles(props.type)} ${props.className}`}
            onClick={props.onClick}
            style={{ backgroundColor: THEME_COLOR }}
        >
            {getIcon(props.icon)}
            <span>{props.text}</span>
            {props.img && <img className="button-container__img" src={props.img} alt="img" />}
        </div>
    );
}

Button.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    img: PropTypes.string,
};

Button.defaultProps = {
    text: "text",
    type: DEFAULT,
    className: "",
    icon: undefined,
    img: null,
};

export default Button;
