import { call, put, takeEvery } from "redux-saga/effects";
import { getTokenApi } from "./../shared/getTokenApi";
import * as type from "../../constants/types";
import { CancelSubscriptionSaga, GetUserDataSaga } from './../../../interfaces/profileInterface'
import { TypedIterableIterator } from './../../../interfaces/interfaces'
import { deleteWithBodyApi } from "../shared/deleteWithBodyApi";
function* getUserDataSaga(action: GetUserDataSaga): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user";
        const userData = yield call(getTokenApi, endpoint);
        if (userData.status >= 200 && userData.status < 300) {
            yield put({ type: type.GET_USER_DATA_SUC, payload: userData.data });
        } else {
            throw userData;
        }
    } catch (e) {
        yield put({ type: type.GET_USER_DATA_FAIL, payload: e });
    }
}

function* cancelSubscriptionSaga(action: CancelSubscriptionSaga): TypedIterableIterator<any, any, number> {    
    try {
        const currentSub = {
            "subscription":`${action.payload}`,
        };
        const endpoint = "tokenization/checkout/subscription";
        const userData = yield call(deleteWithBodyApi, [currentSub, endpoint ]);
        if (userData.status >= 200 && userData.status < 300) {
            yield put({ type: type.CANCELL_SUBSCRIPTION_SUC, payload: userData.data });
        } else {
            throw userData;
        }
    } catch (e) {
        yield put({ type: type.GET_USER_DATA_FAIL, payload: e });
    }
}

function* watchGetUserDataSaga() {
    yield takeEvery(type.GET_USER_DATA_REQ, getUserDataSaga);
    yield takeEvery(type.CANCELL_SUBSCRIPTION_REQ, cancelSubscriptionSaga);
}


export default watchGetUserDataSaga;
