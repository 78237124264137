import { Link } from "react-router-dom";

function EmptyInvestment(props: { type: 'transction-history' | 'my-assets' }) {
  if (props.type === 'my-assets') return (
    <div className="empty-invest">
      <span className="title">Sorry, you don&apos;t have any assets yet.</span>
      <span className="subtitle">If you have any <b>pending orders</b> they&apos;ll appear in your{" "}
        <Link to="/transaction-history">Transaction History</Link>
      </span>
    </div>
  );
  return (
    <div className="empty-invest">
      <span className="title">Sorry, you don&apos;t have any transactions yet.</span>
      <span className="subtitle">
        <Link to="/marketplace">
          Explore The Marketplace
        </Link> </span>
    </div>
  );
}

export default EmptyInvestment;
