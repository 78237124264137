export const forgotPassword: forgotPasswordInterface = {
    'You cannot reset your password because you have not confirmed your mail, contact us for help':
        'You cannot reset your password because you have not confirmed your mail, contact us for help',
    'Sign in with Google or contact us': 'Sign in with Google or contact us',
    'UserModel with this email doesn\'t exist': 'User with this email doesn\'t exist'

}

interface forgotPasswordInterface {
    [key: string]: string;
}