import { Component } from "react";
import { THEME_COLOR } from "../../constants/colors";
import LandingBGWEBP from "./../../Assets/images/landing/whitelabel-bg.webp";
const LandingBGJP = require("./../../Assets/images/landing/whitelabel-bg.jp2")
  .default;

class ErrorBoundry extends Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error: Error) {
    return {
      error: true,
    };
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error-page">
          <picture>
            <source type="image/webp" srcSet={LandingBGWEBP} className="bg" />
            <img src={LandingBGJP} alt="" className="bg" />
          </picture>
          <div className="error-content">
            <h1>Whoops!</h1>
            <span>Something went wrong</span>
            <a href="/" style={{ backgroundColor: THEME_COLOR }}>
              Home
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundry;
