import React, {useEffect} from "react";
import CheckoutStages from "../../../components/new-checkout/checkoutStages";
// import Chat from "./../../../Assets/icons/chat.svg";
import { useHistory, useParams } from "react-router-dom";
import { CheckoutInterface } from "../../../interfaces/assetsInterface";
import mautic from 'mautic-tracking';
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { getCheckoutInfoReq } from "../../../redux/actions/checkoutActions";
import { useDispatch, useSelector } from "react-redux";
import CheckoutWire from "../../../components/new-checkout/checkoutWire";
import CheckoutCheck from "../../../components/new-checkout/checkoutCheck";
import CheckoutCrypto from "../../../components/new-checkout/checkoutCrypto";
import CheckoutStripe from "../../../components/new-checkout/checkoutStripe";
import AchRouting from "../../../components/payment-method/ach-routing";
import { addComma } from "../../../constants/addCommaFunction";
import { useLocation } from "react-router";
import ReactPixel from 'react-facebook-pixel';

function CheckoutConfirmation() {

  const dispatch = useDispatch();
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  
  const selectedMethod = query.get("method");

  const { id, tokens } = useParams<MainAssetPageParams>();

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  useEffect(() => {
    if (!tokens || !id) history.push("/marketplace");
  }, [id, tokens, history]);

  useEffect(() => {
    mautic.pageView({});
    dispatch(getCheckoutInfoReq(id, history, +tokens));
  }, [dispatch, history, id, tokens]);

  useEffect(() => {
    if (tokens && checkoutInfo.currency.length>0){
      ReactPixel.track('Purchase', {value: +tokens, currency: checkoutInfo.currency});
    }
  }, [tokens, checkoutInfo.currency])

  const renderSelectedMethod = () => {
    switch (selectedMethod) {
      case "wire":
        return <CheckoutWire />;
      case "check":
        return <CheckoutCheck />;
      case "crypto":
        return <CheckoutCrypto />;
      case "card":
        return <CheckoutStripe />;
      case "ACH":
        return <AchRouting />;
      default:
        return null;
    }
  };

  return (
    <div className="checkout-payment">
      <CheckoutStages />
      <div className="container">
        <div className="left">
          {renderSelectedMethod()}
        </div>
        <div className="right">
          <div className="wrapper">
            <div className="title">Order Summary</div>
            <div className="image">
              <img src={checkoutInfo.imagePath} alt="" />
            </div>
            <div className="asset">
              <span className="name">{`${checkoutInfo.title.substr(0, 22).trim()}...`}</span>
              <span className="cost">
              {addComma(String(checkoutInfo.tokens * checkoutInfo.unitPrice))}{' '}{checkoutInfo.currency}
              </span>
            </div>
            <div className="tokens">
              {checkoutInfo.tokens} {checkoutInfo.alias} tokens
            </div>
            <div className="discount">
              <span className="descr">Discount code applied</span>
              <span className="applied">-{checkoutInfo.discountDiff}</span>
            </div>
            {/* <div className="credits">
              <span className="descr">0 Credits appllied</span>
              <span className="applied">0</span>
            </div> */}
            <div className="total">
              <span className="title">Total:</span>
              <div className="amount">
                {/* {numberWithCommasDots(checkoutInfo.total)}{" "} */}
                {addComma(String(checkoutInfo.total))}{" "}
                <span className="currency"> {checkoutInfo.currency}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutConfirmation;
