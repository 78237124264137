function AdvanceInvestmentDetails() {
    const infoAdvanced = {
        title: "Detox Digital Coin Marketplace Site",
        location: "United States",
        description: "The Portfolio consists of US real estate to house the proliferation of programs for people affected by the opioid crisis",
        highlightItems: [
            {highlight:"Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years."},
            {highlight:"Industry revenues will climb to almost $99.4 billion by 2028."},
            {highlight:"Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs."},
            {highlight:"The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year."},
            {highlight:"BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments."},
            {highlight:"Facilities identified, renovated and leased prior to acquisition."},
            {highlight:"“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent."},
            {highlight:"Anticipated 5-year Annualized Return: 12%."},
        ],
        investedDetail: [
            {field:"Revenue type", value:"NNN Lease"},
            {field:"Capital Stack", value:"Equity"},
            {field:"Property Type", value:"Medical Facility"},
        ]
      }

    return (
        <div className="investment-details">
        <div className="title">Investment Details</div>
        {infoAdvanced.investedDetail.map((el: any, i: number) => {
                return (
                    <div  key={i}className="field-item">
                        <div className="field">{el.field}</div>
                        <div className="value">{el.value}</div>
                </div>
                );
            })}
        </div>
    );
}

export default AdvanceInvestmentDetails;
