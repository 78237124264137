import React from "react";

function InvestmentDetails() {
  return (
    <div className="investment-details">
      <div className="title">Investment Details</div>
      <div className="field-item">
        <div className="field">Revenue type</div>
        <div className="value">Mixed</div>
      </div>
      <div className="field-item">
        <div className="field">Capital stack</div>
        <div className="value">Preferred Equity</div>
      </div>
      <div className="field-item">
        <div className="field">Property type</div>
        <div className="value">Mixed use </div>
      </div>
    </div>
  );
}

export default InvestmentDetails;
