import { call, put, takeEvery } from "redux-saga/effects";
import { putTokenApi } from "./../shared/putTokenApi";
import * as type from "../../constants/types";
import { TypedIterableIterator } from './../../../interfaces/interfaces'
import { ChangeUserDataSaga } from './../../../interfaces/profileInterface'

function* changeUserDataSaga(action: ChangeUserDataSaga): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "user";
        const {
            id,
            firstName,
            lastName,
            email,
            country,
            isUSCitizen,
            isAccredited,
            state,
            city,
            address,
            zipCode,
            phone,
            fromSite,
        } = action.payload;

        const currentUserData = {
            id,
            firstName,
            lastName,
            email,
            country,
            isUSCitizen,
            isAccredited,
            state,
            city,
            address,
            zipCode,
            phone,
            fromSite,
        };
        const userData = yield call(putTokenApi, [endpoint, currentUserData]);
        if (userData.status >= 200 && userData.status < 300) {
            yield put({
                type: type.CHANGE_USER_DATA_SUC,
                payload: userData.data,
            });
        } else {
            throw userData;
        }
    } catch (e) {
        yield put({ type: type.CHANGE_USER_DATA_FAIL, payload: e });
    }
}

function* watchChangeUserDataSaga() {
    yield takeEvery(type.CHANGE_USER_DATA_REQ, changeUserDataSaga);
}



export default watchChangeUserDataSaga;
