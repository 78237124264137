import { tokenItemInDashboard } from "../../../interfaces/applyTokenizationInterface";
import * as type from "../../constants/types";
const initialState = {
    email: "",
    password: "",
    referralCode: null,
    loading: false,
    firstName: "",
    lastName: "",
    isUSCitizen: false,
    redirect: false,
    country: "",
    isAccredited: false,
    message: "",
    loginRedirect: false,
    userId: "",
    userdata: {},
    phoneNumber: "",
    id: "",
    wrongCode: false
};
const registrationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_SIGNIN_REQ:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
                loading: true,
                message: "",
            };
        case type.SET_USER_DATA:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                country: action.payload.country,
                phoneNumber: action.payload.phone,
            }
        case type.CLEAR_ERROR:
            return {
                ...state,
                message: "",
            };
        case type.GET_USERS_SUC:
            return {
                ...state,
                loading: false,
                message: "",
                loginRedirect: true,
                actualStep: 1,
                tokensList: [] as Array<tokenItemInDashboard>,
                subscription: {
                    product: "",
                    name: "",
                    price: "",
                },
                currentSubscription: "",
            };
        case type.PUT_USER_EMAIL_PSW:
            return {
                ...state,
                loading: false,
                email: action.payload.email,
                password: action.payload.password,
                referralCode: action.payload.referralCode,
                // firstName:action.payload.firstName,
                // lastName:action.payload.lastName,
                entity:action.payload.entity,
                message: "",
            };
        case type.PUT_USER_OTHER_DATA:
            return {
                ...state,
                isUSCitizen: action.payload.isUSCitizen,
                isEmailNotification: action.payload.isEmailNotification,
                country: action.payload.country,
                phoneNumber: action.payload.phoneNumber,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                message: "",
            };
        case type.USER_SUGNUP:
            return {
                ...state,
                redirect: true,
                message: "",
                userId: action.userdata.userId,
                id: action.userdata.id,
            };
        case type.GET_USERS_FAILED:
            return {
                ...state,
                message: action.message,
            };
        case type.GET_USER_REGISTRATION_FAILED:
            return {
                ...state,
                message: action.message,
            };
        case type.CONFIRM_PHONE_REQ:
            return {
                ...state,
                wrongCode: false
            }
        case type.CONFIRM_PHONE_FAIL:
            return {
                ...state,
                wrongCode: true
            }
        default:
            return state;
    }
};

export default registrationReducer;
