import { useState, useEffect } from 'react'
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CheckboxInput from '../../components/checkbox-input/checkboxInput'
import { ExchangeOffersInterface } from '../../interfaces/exchangeInterfaces'
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@material-ui/core';
import { useSelector } from "react-redux";
import { THEME_COLOR } from '../../constants/colors';

const ExchangeModal = (props: any) => {

    //const { exchangeItems } = props;

    const [boxesNames, setBoxesNames] = useState([''])
    const [tradeNames, setTradeNames] = useState(['']);
    const exchangeItems = useSelector((store: { exchangeItemsReducer: ExchangeOffersInterface }) => store.exchangeItemsReducer.exchangeItems);

    const [checkbox, setCheckbox] = useState<any>([])

    const [amount, setAmount] = useState({
        min: '',
        max: ''
    })

    const [tradingPrice, setTradingPrice] = useState({
        min: '',
        max: '',
        alias: 'All'
    });

    useEffect(() => {
        const names: Set<string> = new Set('')
        const tradeNames: Set<string> = new Set('')

        exchangeItems.forEach((el: any) => {
            names.add(el.asset.title)
            tradeNames.add(el.tradeAsset ? el.tradeAsset.alias : el.crypto)
        })
        const newnames: string[] = Array.from(names)
        const tradeNamesArr = Array.from(tradeNames)

        setBoxesNames(newnames)
        setTradeNames(['All', ...tradeNamesArr])

    }, [exchangeItems])

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (checkbox.includes(e.target.name)) {
            const tempArr = [...checkbox].filter((el: string) => el !== e.target.name)
            setCheckbox(tempArr)
            return
        }
        setCheckbox((prev: any) => [...prev, e.target.name])
    }

    const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof +e.target.value === 'number' && !isNaN(+e.target.value)) {
            setAmount({
                ...amount,
                [e.target.name]: e.target.value
            })
        }
    }

    const tradingPriceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof +e.target.value === 'number' && !isNaN(+e.target.value)) {
            setTradingPrice({
                ...tradingPrice,
                [e.target.name]: e.target.value
            })
        }
    }

    const tradingPriceAliasHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTradingPrice({
            ...tradingPrice,
            alias: e.target.value
        })
    }


    const applyFilter = () => {
        let filteredItems: any = [...exchangeItems];
        if (props.myOffers) {
            filteredItems = filteredItems.filter((item: any) => item.userId === props.userId)
        }
        if (checkbox.length) {
            filteredItems = filteredItems.filter((item: any) => checkbox.includes(item.asset.title))
        }
        if (amount.min) {
            filteredItems = filteredItems.filter((item: any) => item.amount >= +amount.min)
        }
        if (amount.max) {
            filteredItems = filteredItems.filter((item: any) => item.amount <= +amount.max)
        }
        if (tradingPrice.alias && tradingPrice.alias !== 'All') {
            filteredItems = filteredItems.filter((item: any) =>
                item.tradeAsset?.alias === tradingPrice.alias || item.crypto === tradingPrice.alias)
        }

        if (tradingPrice.min) {
            filteredItems = filteredItems.filter((item: any) => +item.tradeUnitPrice >= +tradingPrice.min)
        }
        if (tradingPrice.max) {
            filteredItems = filteredItems.filter((item: any) => +item.tradeUnitPrice <= +tradingPrice.max)
        }

        props.filterOffers(filteredItems)
    }

    const cancelFilter = () => {
        setCheckbox([])
        setAmount({
            min: '',
            max: ''
        })
        setTradingPrice({
            min: '',
            max: '',
            alias: ''
        })
        props.filterOffers(exchangeItems)
    }

    return (
        <Popper className='paper-container' open={props.open} anchorEl={props.anchorEl} placement={props.placement} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper >
                        <div className='inner-modal'>
                            <span className="clear" 
                            style={{color: THEME_COLOR}}
                            onClick={cancelFilter}>
                                Clear filter
                            </span>
                            <h3>Asset</h3>
                            <div>
                                <FormGroup>
                                    {
                                        boxesNames.map(el => {
                                            return <CheckboxInput
                                                key={el}
                                                label={el}
                                                checkBoxHandler={checkboxHandler}
                                                checked={checkbox.includes(el)} />
                                        })
                                    }
                                </FormGroup>
                            </div>
                            <div className='divider'></div>
                            <div className="amount">
                                <h3 >Amount</h3>
                            </div>
                            <div className="amount-content">
                                <TextField
                                    placeholder="Min"
                                    name="min"
                                    className="amount-input"
                                    value={amount.min}
                                    onChange={amountHandler} />
                                <span>to</span>
                                <TextField
                                    name="max"
                                    placeholder="Max"
                                    className="amount-input"
                                    onChange={amountHandler}
                                    value={amount.max} />
                            </div>
                            <div className='divider'></div>
                            <div className="trading-price">
                                <h3>Price per asset</h3>
                                <TextField
                                    select
                                    className="price-select"
                                    value={tradingPrice.alias}
                                    onChange={tradingPriceAliasHandler}>
                                    {
                                        tradeNames.map((item: any) => (
                                            <MenuItem
                                                key={item}
                                                value={item}>
                                                {item}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                            <div className="trading-price-content">
                                <TextField
                                    placeholder="Min"
                                    className="amount-input"
                                    name="min"
                                    value={tradingPrice.min}
                                    onChange={tradingPriceHandler} />
                                <span>to</span>
                                <TextField
                                    placeholder="Max"
                                    className="amount-input"
                                    name="max"
                                    value={tradingPrice.max}
                                    onChange={tradingPriceHandler} />
                            </div>
                            <div className="buttons">
                                <Button
                                    color="primary"
                                    className="cancel"
                                    onClick={(e) => {
                                        cancelFilter()
                                        props.handlerClick('bottom-end', e)
                                    }
                                    }>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="apply"
                                    onClick={(e) => {

                                        props.handlerClick('bottom-end', e)
                                        applyFilter()
                                    }}>
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>
    )
}

export default ExchangeModal