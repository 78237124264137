import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Chat from './../../../../Assets/new-landing/chat.png';

SwiperCore.use([Navigation]);

function Buzz() {
    return (
        <section className="buzz">
            <div className="container">
                <div className="buzz-img">
                    <img src={Chat} alt="buzz" loading="lazy" />
                </div>
                <h2 className="title">
                    The Buzz on SolidBlock 
                </h2>
                <Swiper
                    slidesPerView={1}
                    navigation
                    loop>
                    <SwiperSlide>
                        <div className="slide-inner">
                            <div className="slide-descr">
                                “SolidBlock is doing something so unique. I love how they’re making commercial real estate, which is one of the most illiquid asset classes, liquid.”
                            </div>
                            <div className="author">
                                Dhaval Kapadia
                            </div>
                            <div className="job">
                                Startup Steroid’s Co-Founder
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-inner">
                            <div className="slide-descr">
                                “They provide access to the real estate sector for all levels of society by tokenizing the asset or portfolio and removing the minimum investment hurdle.”
                            </div>
                            <div className="author">
                                6th Annual Real Estate Innovations Overview
                            </div>
                            <div className="job">
                                2021
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}

export default Buzz;