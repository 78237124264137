const NOT_FOOTER_CATEGORIES = [
    "/contact",
    '/sign-up/login-redirect',
    "/login",
    "/sign-up/auth",
    "/sign-up/confirmation",
    "/sign-up/info",
    "/onboarding/1",
    "/onboarding/2",
    "/onboarding/3",
    "/sign-up/accreditation",
    "/forgot-password/email",
    "/forgot-password/new-password",
    "/social-auth",
    "/profile/main-information",
    "/profile/security",
    "/profile/documents",
    "/profile/referrals",
    "/profile/subscription",
    "/profile/main-information",
    "/accreditation/first",
    "/accreditation/way",
    "/accreditation/tax-return",
    "/accreditation/account-statement",
    "/accreditation/letter",
    "/confirmation",
    "/kyc/first",
    "/kyc/check",
    "/kyc/id-verification",
    "/my-assets",
    "/issuer/account/1",
    "/issuer/account/2",
    "/issuer/offering/1",
    "/issuer/offering/2",
    "/sign-up/mountx",
    "/phone-confirmation",
    "/email-confirmation",
    "/",
    "/about",
    "/real-estate-tokenization",
    "/tokenization-form",
    "/tokenization-thanks",
    "/google-auth-reg",
];

export { NOT_FOOTER_CATEGORIES };
