import { THEME_COLOR } from "../../constants/colors";
import { useHistory } from "react-router-dom";

const GetHelp = () => {
  const history = useHistory();
  return (
    <div className="get-help-container">
      <span className="get-help">
        Lost or have trouble?
        <span
          className="get-help-GREEN"
          style={{ color: THEME_COLOR, cursor: 'pointer' }}
          onClick={() => history.push('/contact')}
        >
          {" "}
          Get help ➝
        </span>
      </span>
    </div>
  );
};

export default GetHelp;
