import * as types from "./../../constants/types";

const initialState = {
    exchange: false,
};

const exchangeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.ECHANGE_COLOR:
            return {
                ...state,
                exchange: !action.exchange,
            };
        default:
            return state;
    }
};

export default exchangeReducer;
