import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { cancelOffer } from './../../redux/actions/exchangeActions';

function CancelModal(props: { cancelModal: any, closeCancelModal: () => void }) {

    const { closeCancelModal, cancelModal: { open, tradeAssetId, tradeAsset } } = props;

    const dispatch = useDispatch();

    return (
        <Dialog
            className="exch-confirmation-modal"
            open={open}
            onClose={closeCancelModal}>
            {/* <CloseIcon className="close" /> */}
            <span className="confirmation">
                Cancel the offer
            </span>
            <p className="cancel-text">
                Are you sure you would like to cancel the offer {tradeAsset}?
            </p>
            <div className="buttons">
                <Button
                    color="primary"
                    className="cancel"
                    onClick={closeCancelModal}>
                    Back
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className="buy"
                    onClick={() => {
                        dispatch(cancelOffer(tradeAssetId))
                        closeCancelModal()
                    }}
                >
                    Cancel
                </Button>
            </div>
        </Dialog>
    )
}

export default CancelModal;