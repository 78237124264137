import React from 'react'
import { addComma } from '../../../../constants/addCommaFunction'
import { CURRENCY_SYMBOLS } from '../../../../constants/myInvestmentsConstants'

function AssetField({ value, title, currency, isEndtime }: 
    { value: string | number, title: string | number, currency: string, isEndtime?: boolean }) {
    return (
        <div className="field">
            <div className="field-value">
                {typeof value === "number" && !isEndtime
                    ? CURRENCY_SYMBOLS[currency] +
                    addComma(String(value))
                    : value}
            </div>
            <div className="field-title">
                {title}
            </div>
        </div>
    )
}

export default AssetField