import { useState } from 'react'
import CloseIcon from '../../../components/accreditation-components/close-icon/closeIcon';
import LabelInput from "../../../components/profile-components/labelInput/labelInput";
import GetHelp from "../../../components/get-help/getHelp";
import { TextField, Button } from '@material-ui/core'
import { THEME_COLOR } from '../../../constants/colors'

function IssuerAccountStep1() {

    const [accountInfo, setAccountInfo] = useState({
        companyName: '',
        companyState: '',
        entityType: '',
        accountType: '',
        companyID: ''
    })

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountInfo(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <section className="issuer-step1-page">
            <div className="issuer-step1">
                <CloseIcon />
                <div className="form-title-container">
                    <h3 className="form-title">
                        Create issuer account
                    </h3>
                    <span className="step">
                        Step 1 of 2
                    </span>
                </div>
                <form className="issuer-step1-form" onSubmit={(e) => e.preventDefault()}>

                    <LabelInput
                        value={accountInfo.companyName}
                        handler={inputHandler}
                        id="companyName"
                        name="companyName"
                        label="Company name"
                        disabled={false} />
                    <label htmlFor="companyState" className="inputlabel-label">Company state</label>
                    <TextField
                        select
                        className="form-field"
                        name="companyState"
                        id="companyState"
                        onChange={inputHandler}
                        value={accountInfo.companyState} />
                    <label htmlFor="entityType" className="inputlabel-label">Entity type</label>
                    <TextField
                        select
                        className="form-field"
                        name="entityType"
                        id="entityType"
                        onChange={inputHandler}
                        value={accountInfo.entityType} />
                    <label htmlFor="accountType" className="inputlabel-label">Account type</label>
                    <TextField
                        select
                        className="form-field"
                        name="accountType"
                        id="accountType"
                        onChange={inputHandler}
                        value={accountInfo.accountType} />
                    <LabelInput
                        value={accountInfo.companyID}
                        handler={inputHandler}
                        id="companyID"
                        name="companyID"
                        label="Company Tax ID"
                        disabled={false} />
                    <Button
                        style={{ backgroundColor: THEME_COLOR }}
                        className="accreditation-next-button"
                    >
                        Next ➝{" "}
                    </Button>
                </form>
                <GetHelp />
            </div>
        </section>
    )
}

export default IssuerAccountStep1;