import { useEffect, useState } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMyInvestmentsReq } from '../../redux/actions/myInvestmentsAction'
import { getTempItems } from '../../redux/actions/exchangeActions'
import { InvestmentsInterface, Purchases } from '../../interfaces/myInvestymentsInterface'
import EmptyInvestment from "../../components/my-investments-components/emptyInvestment";
import mautic from 'mautic-tracking';
import { Link } from "react-router-dom";
import { checkisKYC } from "../../redux/actions/kycActions";
import { checkisAccredited } from "../../redux/actions/accreditationActions";
import { numberWithCommasDots } from "../../constants/numberWithCommasDots";
import { addComma } from "../../constants/addCommaFunction";
import PaymentStatus from "../../components/my-investments-components/paymentStatus";
import { MarketplaceInterface } from "../../interfaces/marketplaceInterface";
import { getAssetsList } from "../../redux/actions/assetsAction";
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@mui/material";
import TradeModal from "../../components/my-investments-components/tradeModal";
import { UserImageState } from "../../interfaces/headerInterface";
import { openVerifyModal } from "../../redux/actions/headerActions";

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "black",
        },
        "& .MuiPaginationItem-root.Mui-selected": {
            color: "black",
            backgroundColor: "#0EC22B1A",
        }
    }
}));

function MyInvestmentsPage() {
    const userData = useSelector((state: UserImageState) => state.headerReducer)
    const dispatch = useDispatch()
    const classes = useStyles();

    useEffect(() => {
        mautic.pageView({});
        dispatch(getAssetsList());
        dispatch(getMyInvestmentsReq())
        dispatch(getTempItems())
        dispatch(checkisAccredited());
        dispatch(checkisKYC());
    }, [dispatch]);

    const investmentsData = useSelector((store: InvestmentsInterface) => store.myInvestments.investmentsList);
    const marketplace = useSelector((state: MarketplaceInterface) => state.marketplace);

    const assetsPerPage = 30;
    const pages = Math.round(investmentsData.length / assetsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const [isTradeModalOpen, setTradeModalIsOpen] = useState(false);

    const hideTradeModal = () => {
        setTradeModalIsOpen(false);
    };
    const showTradeModal = () => {
        if (userData.role === 2) {
            dispatch(openVerifyModal())
            return
        }
        dispatch(getTempItems())
        setTradeModalIsOpen(true);
    };

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, [currentPage]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * assetsPerPage - assetsPerPage;
        const endIndex = startIndex + assetsPerPage;
        return investmentsData.slice(startIndex, endIndex);
    };

    const renderTotalReserved = (id: string, alias: string, unitPrice: number, purchases: Purchases[]) => {
        const total = purchases.filter((purchase: any) => purchase.status === "SETTLED").reduce((sum: number, current) => sum + +current.amount, 0);
        const asset = marketplace.assetsList.find(asset => asset.id === id);
        return (
            <>
                <div className="token-row grid-rows">
                    <div className="tokenItem">{addComma(String(total))} {alias}</div>
                    <div className="tokenItem">{alias === "LDB" ? (`£${numberWithCommasDots(unitPrice)}`) : (`$${numberWithCommasDots(unitPrice)}`)}</div>
                    <div className="tokenItem">{alias === "LDB" ? (`£${addComma(String(unitPrice * +total))}`) : (`$${addComma(String(unitPrice * +total))}`)}</div>
                    <div className="buttons tokenItem">
                        <Link to={`/asset-view/${id}`} className="buyLink button">Buy</Link>
                        <Button disabled onClick={showTradeModal} className="disabled button">Sell</Button>
                        <Button disabled className="disabled button">Send</Button>
                    </div>
                    <div className="statusField tokenItem">
                        <PaymentStatus asset={asset} tooltip={`SETTLED`} />
                    </div>
                </div>
            </>
        )
    }

    const renderTotalReservedMobile = (id: string, alias: string, unitPrice: number, purchases: Purchases[]) => {
        const total = purchases.filter((purchase: any) => purchase.status === "SETTLED").reduce((sum: number, current) => sum + +current.amount, 0);
        const asset = marketplace.assetsList.find(asset => asset.id === id);
        return (
            <>
                <div className={`table-row-mobile`}>
                    <div className="mobileRow">
                        <div className="gridItem">Token Balance</div>
                        <div className="tokenItem">{addComma(String(total))} {alias}</div>
                    </div>
                    <div className="mobileRow">
                        <div className="gridItem">Token Value</div>
                        <div className="tokenItem">{alias === "LDB" ? (`£${numberWithCommasDots(unitPrice)}`) : (`$${numberWithCommasDots(unitPrice)}`)}</div>
                    </div>
                    <div className="mobileRow">
                        <div className="gridItem">Total Balance</div>
                        <div className="tokenItem">{alias === "LDB" ? (`£${addComma(String(unitPrice * +total))}`) : (`$${addComma(String(unitPrice * +total))}`)}</div>
                    </div>
                    <div className="mobileRow">
                        <div className="gridItem">Action</div>
                        <div className="buttons tokenItem">
                            <Link to={`/asset-view/${id}`} className="buyLink button">Buy</Link>
                            <Button disabled className="disabled button">Sell</Button>
                            <Button disabled className="disabled button">Send</Button>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="gridItem">Status</div>
                        <div className="statusField"><PaymentStatus asset={asset} tooltip={`SETTLED`} /></div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="my-investments-page-wrapper">
            {isTradeModalOpen && (
                <TradeModal hideTradeModal={hideTradeModal} />
            )}
            <div className="my-investments-page-container">
                <div className="my-investments-title-container">
                    <h2 className="my-investments-title">My Assets</h2>
                    <Link to="/transaction-history" className="link">Transaction History</Link>
                </div>
                <div className="investment-table">
                    <div className="table-titles table-row">
                        <div className="rowTitle">Token</div>
                        <div className="grid-rows">
                            <div className="gridItem">Token Balance</div>
                            <div className="gridItem">Token Value</div>
                            <div className="gridItem">Total Value</div>
                            <div className="gridItem">Action</div>
                            <div className="gridItem">Status</div>
                        </div>
                    </div>
                    {investmentsData.filter(asset => asset.totalAmount).length ? (<>
                        {getPaginatedData().map((asset, index) => {
                            const { id, alias, name, purchases, unitPrice } = asset
                            if(! asset.totalAmount) return null
                            return (
                                <React.Fragment key={asset.name + index}>
                                    <div className="table-row">
                                        <div className="token-name">
                                            <div className="alias">{alias}</div>
                                            <div className="name">{name}</div>
                                        </div>
                                        <div className="token-rows">
                                            {purchases
                                                .filter((purchase: any) => purchase.status === "SETTLED").length > 0 ? (
                                                renderTotalReserved(id, alias, unitPrice, purchases)
                                            ) : null}
                                            {/* Not Settled render */}
                                            {/* {purchases.length > 0 ? (
                                            purchases.filter(purchase => purchase.status !== "SETTLED").map((purchase, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <div className="token-row grid-rows">
                                                            <div className="tokenItem">{addComma(String(purchase.amount))} {alias}</div>
                                                            <div className="tokenItem">{ alias === "LDB" ? (`£${numberWithCommasDots(unitPrice)}`) : (`$${numberWithCommasDots(unitPrice)}`)}</div>
                                                            <div className="tokenItem">{alias === "LDB" ? (`£${addComma(String(unitPrice * +purchase.amount))}`) : (`$${addComma(String(unitPrice * +purchase.amount))}`)}</div>
                                                            <div className="buttons tokenItem">
                                                                <Link to={`/asset-view/${id}`} className="buyLink button">Buy</Link>
                                                                <p className="disabled button">Sell</p>
                                                                <p className="disabled button">Send</p>
                                                            </div>
                                                            <div className="statusField tokenItem">
                                                                <PaymentStatus asset={null} tooltip={`${purchase.status}`}/>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) 
                                            })
                                        ):null} */}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </>) : (<EmptyInvestment type='my-assets' />)}

                    {investmentsData.filter(asset => asset.totalAmount).length ? (<>
                        {getPaginatedData().map((asset, index) => {
                            const { id, alias, name, purchases, unitPrice } = asset
                            if(! asset.totalAmount) return null
                            return (
                                <React.Fragment key={asset.name + index}>
                                    <div className="token-name-mobile">
                                        <div className="title">Token</div>
                                        <div className="right">
                                            <div className="alias">{alias}</div>
                                            <div className="name">{name}</div>
                                        </div>
                                    </div>
                                    {purchases.filter((purchase: any) => purchase.status === "SETTLED").length > 0 ? (
                                        renderTotalReservedMobile(id, alias, unitPrice, purchases)
                                    ) : null}
                                    {/* Not Settled render */}
                                    {/* {purchases.length > 0 ? (
                                    purchases.filter(purchase => purchase.status !== "SETTLED").map((purchase, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <div className={`table-row-mobile`}>
                                                    <div className="mobileRow">
                                                        <div className="gridItem">Token Balance</div>
                                                        <div className="tokenItem">{addComma(String(purchase.amount))} {alias}</div>
                                                    </div>
                                                    <div className="mobileRow">
                                                        <div className="gridItem">Token Value</div>
                                                        <div className="tokenItem">{ alias === "LDB" ? (`£${numberWithCommasDots(unitPrice)}`) : (`$${numberWithCommasDots(unitPrice)}`)}</div>
                                                    </div>
                                                    <div className="mobileRow">
                                                        <div className="gridItem">Total Balance</div>
                                                        <div className="tokenItem">{alias === "LDB" ? (`£${addComma(String(unitPrice * +purchase.amount))}`) : (`$${addComma(String(unitPrice * +purchase.amount))}`)}</div>
                                                    </div>
                                                    <div className="mobileRow">
                                                        <div className="gridItem">Action</div>
                                                        <div className="buttons tokenItem">
                                                            <Link to={`/asset-view/${id}`} className="buyLink button">Buy</Link>
                                                            <p className="disabled button">Sell</p>
                                                            <p className="disabled button">Send</p>
                                                        </div>
                                                    </div>
                                                    <div className="mobileRow">
                                                        <div className="gridItem">Status</div>
                                                        <div className="statusField"><PaymentStatus asset={null} tooltip={`${purchase.status}`}/></div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ) 
                                    })
                                ):null} */}
                                </React.Fragment>
                            )
                        })}
                    </>) : (null)}
                </div>
                {investmentsData.length > assetsPerPage ? (
                    <div className="pagination">
                        <Pagination count={pages} classes={{ ul: classes.ul }} shape="rounded" page={currentPage} onChange={handleChange} />
                    </div>
                ) : (null)}
            </div>
        </div>
    );
}

export default MyInvestmentsPage;
