import { useDispatch, useSelector } from "react-redux";
import { getMainAssetInfo } from "../../../redux/actions/assetsAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import { AssetDataInterface } from "../../../interfaces/assetsInterface";
import { MainAssetPageParams } from "../../../interfaces/mainAssetPageInterfaces";
import { AIRBC_ID } from "../../../constants/assetsId";
import AssetMap from "../../../components/assets/asset-main-view/asset-map/assetMap";
import AdvanceInfo from "../../../components/assets/air/advance-info/advanceItemInfo";
import AdvanceContent from "../../../components/assets/air/advance-content/advanceItemContent";
import AdvanceHighlights from "../../../components/assets/air/advance-highlight/advanceHighlight";
import mautic from 'mautic-tracking';
import Spinner from "../../../components/spinner/spinner";

const AirPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<MainAssetPageParams>();
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  useEffect(() => {
    if (!info.alias || info.id !== AIRBC_ID)
      dispatch(getMainAssetInfo(`${AIRBC_ID}`));
      
  }, [dispatch, id, info.alias, info.id]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  if(info.isLoading) return <Spinner />

  return (
    <div className="redfrog-page">
      <div className="asset-top">
        <div className="container">
          <AdvanceInfo />
        </div>
      </div>
      <div className="container">
        <div className="content">
          <div className="left">
            <AdvanceHighlights />
            <AdvanceContent />
            <AssetMap />
            <div className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
              Back to top
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirPage;
