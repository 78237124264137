import { call, put, takeEvery } from "redux-saga/effects";
import { getTokenApi } from "../shared/getTokenApi";
import { postTokenApi } from "../shared/postTokenApi";
import * as type from "../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { PostPurchaseInfo } from "../../../interfaces/myInvestymentsInterface";
function* getMyInvestments(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "purchase";
        const myInvestmentsData = yield call(getTokenApi, endpoint);
        if (myInvestmentsData.status >= 200 && myInvestmentsData.status < 300) {
            yield put({
                type: type.GET_MY_INVESTMENTS,
                payload: myInvestmentsData.data.assetPurchases,
                credits: myInvestmentsData.data.userCredit,
            });
        } else {
            throw myInvestmentsData;
        }
    } catch (e) {
        yield put({
            type: type.CHANGE_PASSWORD_FAIL,
            payload: "Server Error",
        });
    }
}
function* getMyHistory(): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "purchase/history";
        const myHistoryData = yield call(getTokenApi, endpoint);
        if (myHistoryData.status >= 200 && myHistoryData.status < 300) {
            yield put({
                type: type.GET_MY_HISTORY,
                payload: myHistoryData.data,
            });
        } else {
            throw myHistoryData;
        }
    } catch (e) {
        yield put({
            type: type.CHANGE_PASSWORD_FAIL,
            payload: "Server Error",
        });
    }
}
function* postMyInvestments(action: {
    type: string;
    payload: PostPurchaseInfo;
}): TypedIterableIterator<any, any, number> {
    yield put({ type: type.TOGGLE_BTN_LOADER });
    try {
        const endpoint = "purchase";
        const myInvestmentsData = yield call(postTokenApi, [
            action.payload,
            endpoint,
        ]);
        if (myInvestmentsData.status >= 200 && myInvestmentsData.status < 300) {
            action.payload.history.push(`/checkout/confirmation/${action.payload.assetId}/${action.payload.transactionUnits}?method=${action.payload.transactionType.toLowerCase()}`);
            yield put({ type: type.TOGGLE_BTN_LOADER });
        } else {
            throw myInvestmentsData;
        }
    } catch (e) {
        yield put({ type: type.TOGGLE_BTN_LOADER });
        yield put({
            type: type.CHECKOUT_ERROR,
            payload: "Something gone wrong,please try again or contact us for support !",
        });
    }
}

function* myInvestmetsSaga() {
    yield takeEvery(type.GET_MY_INVESTMENTS_REQ, getMyInvestments);
    yield takeEvery(type.GET_MY_HISTORY_REQ, getMyHistory);
}

function* postInvestmetsSaga() {
    yield takeEvery(type.POST_MY_INVESTMENTS_REQ, postMyInvestments);
}
export { myInvestmetsSaga, postInvestmetsSaga };
