import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../../constants/regex";
import {
  AssetDataInterface,
  FoldersListInterface,
} from "../../../../interfaces/assetsInterface";
import { getPresentation } from "../../../../redux/actions/assetsAction";
import PptIcon from "./../../../../Assets/images/ppt.png";

function RedfrogPresentaionLine() {
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const documentsList = useSelector(
    (state: FoldersListInterface) => state.foldersList
  );

  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const presentationHandler = () => {
    setError("");
    if (!email) {
      setError("Please, enter your email");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email");
      return;
    }
    dispatch(
      getPresentation({
        assetId: info.id,
        email: email,
      })
    );
    const folder = documentsList.documents.find(
      (doc) => doc.name === "presentation"
    );
    if (folder && folder.documents.length) {
      localStorage.setItem("gotRedfrogPresentation", "true");
      const docLink = folder.documents[0].link;
      window.open(docLink);
    }
  };

  if (localStorage.getItem("gotRedfrogPresentation") || info.allowPresentation || localStorage.getItem('token')) return null;

  return (
    <div className="redfrog-presentation-line">
      <div className="inner">
        {info.allowPresentation ? null : (
          <input
            className="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        )}
        <span className="enter" onClick={presentationHandler}>
          Download Investor Presentation
        </span>
        <img src={PptIcon} alt="" className="ppt" />
      </div>
      <span className="input-error">{error}</span>
      {info.allowPresentation ? (
        <span className="downloaded">Thanks for downloading!</span>
      ) : null}
    </div>
  );
}

export default RedfrogPresentaionLine;
