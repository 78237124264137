import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { THEME_COLOR } from "../../../../constants/colors";

const MobileNavDrawer = ({
    setNavShow,
}: {
    setNavShow: (navShow: boolean) => void;
}) => {
    const history = useHistory();

    return (
        <Button
            className="drawer-button"
            onClick={() => {
                setNavShow(false);
                history.push("/sign-up/auth");
            }}
            style={{
                backgroundColor: THEME_COLOR
            }}
        >
            Get started
        </Button>
    );
};

export default MobileNavDrawer;
