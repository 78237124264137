import { useEffect } from "react";
// import MarketplaceIcon from "./../../../Assets/icons/marketplace-banner.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UserImageState } from "../../../interfaces/headerInterface";
import { checkRole } from "../../../redux/actions/headerActions";
import { entityProgressSteps, individualProgressSteps } from "../../../interfaces/authInterfaces";

function Banner() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')){
      dispatch(checkRole());
    }
  }, [dispatch]);

  const userData = useSelector((state: UserImageState) => state.headerReducer);
  let steps

  if (!userData.isRoleLoaded) {
    return null
  }
  // user not entered phone from oauth 
  if(userData.firstTryAfterOauth){
    return (
      <div className="banner">
        <span>Please, verify your phone number </span>
        <Link className="link" to="/sign-up/country-phone">
          Verification
        </Link>
      </div>
    )
  }

  //common cases 
  if (!userData.steps.email) {
    return (
      <div className="banner">
        <span>Email verification required </span>
        <Link className="link" to="/sign-up/verify-email">
          Verify
        </Link>
      </div>
    )
  }
  if (!userData.steps.cell) {
    return (
      <div className="banner">
        <span>Please, verify your phone number </span>
        <Link className="link" to="/sign-up/country-phone">
          Verification
        </Link>
      </div>
    )

  }
  if (userData.isEntity) {
    //entity case
    steps = userData.steps as entityProgressSteps
    if ((!steps.companyInformation)) {
      return (
        <div className="banner">
          <span>Please, verify your company information</span>
          <Link className="link" to="/sign-up/company-information">
            Verify information
          </Link>
        </div>
      )
    }
  }
  if (!userData.isEntity) {
    //individual case
    steps = userData.steps as individualProgressSteps
    if (!steps.identity) {
      return <div className="banner">
        <span>KYC is required for investing</span>
        <Link className="link" to="/sign-up/kyc" >
          Start KYC Proccess
        </Link>
      </div>
    }
    if ((!steps.accreditation && !steps.accreditationSkipped)) {
      return (
        <div className="banner">
          <span>
            Start investing now. Please, provide your ID info to begin your
            investing{" "}
          </span>
          <Link className="link" to="/sign-up/accreditation">
            Start Accreditation
          </Link>
        </div>
      )
    }
  }
  return <></>
}

export default Banner;