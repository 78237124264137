import { useState, useEffect, memo } from 'react'
// import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
// import {
//     StaticGoogleMap,
//     Marker,
//     Path,
// } from 'react-static-google-map';

import { useSelector } from "react-redux";
// import { THEME_COLOR } from "../../../../constants/colors";
import { LocationInterface } from "../../../../interfaces/assetsInterface";
// import AssessmentIcon from "@material-ui/icons/Assessment";

// import USA from "../../../../Assets/images/USA.png";
// import MarkerDefault from "../../../../Assets/images/assetsView/marker.svg";
import Spinner from '../../../spinner/spinner';

function AssetMap() {

    const [load, setLoad] = useState(false)

    const coordinates = useSelector(
        (state: LocationInterface) => state.locationInfo
    );
    //AIzaSyBQS2GXaOL_OwhjjZ0cnGYpS59xq29FCsk

    useEffect(() => {
        setLoad(true)

        return () => { setLoad(false) }
    }, [])

    const position = {
        lat: coordinates.lat,
        lng: coordinates.lng
    };

    if (!coordinates.lat || !coordinates.lng) return null

    if (load) {
        return (
            <div className="map-area">
                <span className="title">Location</span>
                {/* <StaticGoogleMap size="600x600" apiKey="AIzaSyCXrllCjz71PufaGZo-ZHE6rUWb82Agaco">
                    <Marker location="6.4488387,3.5496361" color="blue" label="P" />
                </StaticGoogleMap> */}
                <img className='map-img' src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/url-https%3A%2F%2Fdocs.mapbox.com%2Fapi%2Fimg%2Fcustom-marker.png(${position.lng + ',' + position.lat})/${position.lng + ',' + position.lat},6/720x300?access_token=pk.eyJ1Ijoia2lyaWxsLWlqaW5nIiwiYSI6ImNrcWpkd3Q0bjJ4MGEyc3F0ejFpdTQyYXIifQ.okjXNaHyPEWxchgYsD2PYQ`} alt="" />
                {/* <LoadScript
                    googleMapsApiKey="AIzaSyBQS2GXaOL_OwhjjZ0cnGYpS59xq29FCsk"
                >
                    <GoogleMap
                        mapContainerClassName="map-box-container"
                        center={position}
                        zoom={10}
                    >
                        <Marker position={position} icon={MarkerDefault} />
                    </GoogleMap>
                </LoadScript> */}


                {/* <div className="market-analysis">
                    <div className="inner-analysis">
                        <img className="usa-img" alt="USA" src={USA}></img>
                        <h2>Market Analysis</h2>
                        <h3>{coordinates.title}</h3>
                        <p>{coordinates.description}</p>
                        <div className="analysis-label">
                            <AssessmentIcon style={{ fill: THEME_COLOR }} />
                            <a href={coordinates.link} style={{ color: THEME_COLOR }}>
                                See Full Market Analytics
                        </a>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    } else return <Spinner />

}

export default memo(AssetMap);
