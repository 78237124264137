import * as types from "./../../constants/types";
import { TypedIterableIterator } from "./../../../interfaces/interfaces";
import { getTokenApi } from "./../shared/getTokenApi";
import { putTokenApi } from "../shared/putTokenApi";
import { postTokenApi } from "../shared/postTokenApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* checkAccontSaga(
    payload: any
): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: types.TOGGLE_LOADER });
        const endpoint = "purchase/ach/account";
        const checkoutInfo = yield call(getTokenApi, endpoint);
        if (checkoutInfo.status >= 200 && checkoutInfo.status < 300) {
            yield put({
                type: types.GET_EXTERNAL_ACCOUNT_INFO,
                payload: checkoutInfo.data,
            });
            payload.history.push(`${payload.url}/wire`);
            yield put({ type: types.TOGGLE_LOADER });
        } else throw checkoutInfo;
    } catch (e:any) {
        if (e.response.data) {
            yield put({
                type: types.ACH_STATUS,
                status: e.response.data.error,
            });
            payload.history.push(`${payload.url}/wire`);

            yield put({ type: types.TOGGLE_LOADER });
        }
    }
}

function* getLinkSaga(payload: any): TypedIterableIterator<any, any, number> {
    try {
        yield put({ type: types.TOGGLE_LOADER });
        const endpoint = "purchase/ach/link";
        const checkoutInfo = !payload.achStatus
            ? yield call(putTokenApi, [endpoint, {}])
            : yield call(getTokenApi, endpoint);
        if (checkoutInfo.status >= 200 && checkoutInfo.status < 300) {
            payload.history.push(`${payload.url}/plaid`);
            yield put({
                type: types.USE_WIDGET_LINK,
                link: checkoutInfo.data.link,
            });
            // yield put({ type: types.TOGGLE_LOADER });
        } else throw checkoutInfo;
    } catch (e) {
        if (e.response.data) {
            // payload.history.push("wire");
            // yield put({ type: types.TOGGLE_LOADER });
        }
        // yield put({ type: types.GET_USER_IMAGE_FAIL });
    }
}
function* createExternalAccount(
    payload: any
): TypedIterableIterator<any, any, number> {
    try {
        // yield put({ type: types.TOGGLE_LOADER });
        const endpoint = "purchase/ach/account";
        const checkoutInfo = !payload.achStatus
            ? yield call(putTokenApi, [endpoint, payload.info])
            : yield call(postTokenApi, [payload.info, endpoint]);
        if (checkoutInfo.status >= 200 && checkoutInfo.status < 300) {
            yield put({
                type: types.GET_EXTERNAL_ACCOUNT_INFO,
                payload: checkoutInfo.data,
            });
            payload.history.push("info-section");
            // yield put({ type: types.TOGGLE_LOADER });
        } else throw checkoutInfo;
    } catch (e) {
        if (e.response.data) {
            yield put({
                type: types.CREATE_ACCOUNT_ERROR,
                message: e.response.data.error,
            });
        }
    }
}

function* watchCheckAccountSaga() {
    yield takeEvery(types.CHECK_EXTERNAL_ACCOUNT, checkAccontSaga);
    yield takeEvery(types.GET_WIDGET_LINK, getLinkSaga);
    yield takeEvery(types.CREATE_EXTERNAL_ACCOUNT, createExternalAccount);
}

export default watchCheckAccountSaga;
