import React from "react";
import { Link } from "react-router-dom";
import { CheckoutInterface } from "../../interfaces/assetsInterface";
import ClipboardCopy from "../checkout/clipboardCopy";
import { useSelector } from "react-redux";
import { addComma } from "../../constants/addCommaFunction";

function CheckoutCheck() {

  const checkoutInfo = useSelector(
    (state: CheckoutInterface) => state.checkoutReducer
  );

  return (
    <div className="checkout-wire">
    <div className="title">
      Your tokens are reserved!
    </div>
    <div className="descr">
      In order to finailze this purchase, you must transfer funds to the following Escrow account:
    </div>
    <div className="fields">
      <div className="field-item">
        <div className="title">MAIL TO:</div>
        <div className="info">
          NORTH CAPITAL PRIVATE SECURITIES623 E FORT UNION BLVD, SUITE 101MIDVALE,UT 84047
          <ClipboardCopy text="NORTH CAPITAL PRIVATE SECURITIES623 E FORT UNION BLVD, SUITE 101MIDVALE,UT 84047"/>
        </div>
      </div>
    </div>
    <div className="title">
      What to expect next:
    </div>
    <div className="descr">
      <ul>
        <li>You will receive an email from SolidBlock confirming the payment.</li>
        <li>Your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} token allocation will appear in your account.</li>
        <li>This allocation will remain in reserved status until the project is funded. </li>
        <li>Once the project has been funded, your {addComma(String(checkoutInfo.tokens))} {checkoutInfo.alias} tokens will officially be distributed to your account.</li>
      </ul>
    </div>
    <div className="descr">
      View your <Link to="/my-assets" className="link">Assets Page</Link> or <Link to="/transaction-history" className="link">Transaction History</Link> to see the status of your order.
    </div>
  </div>
  );
}

export default CheckoutCheck;
