import accreditationReducer from "./accreditation-reducers/accreditationReducer";
import { combineReducers } from "redux";
import mainAssetInfoReducer from "./asset-reducers/main-info";
import registrationReducer from "./registration-reducers/registration";
import forgotReducer from "./registration-reducers/forgotPassword";
import changePasswordReducer from "./profile-reducers/changePassword";
import mainInformationReducer from "./profile-reducers/mainInformation";
import marketplaceReducer from "./marketplace-reducers/marketplace";
import locationReducer from "./asset-reducers/locationIInfo";
import foldersReducer from "./asset-reducers/foldersReducer";
import projectionReducer from "./asset-reducers/projectionReducer";
import highlightsReducer from "./asset-reducers/highlightsInfo";
import KYCReducer from "./KYC-reducers/KYCReducer";

import headerReducer from "./header-reducer/headerReducer";
import loaderReducer from "./loader";

import cryptoReducer from "./payment-reducer/cryptoReducer";
import investmentsReducer from "./my-investments-reducer/myInvestmets";
import referralsReducer from "./profile-reducers/referralsReducer";
import checkoutReducer from "./asset-reducers/checkoutReducer";
import countCreditsReducer from "./asset-reducers/countCredits";
import documentsReducer from "./profile-reducers/documentsReducer";

import exchangeReducer from "./exchange-reducers/exchange-color";
import exchangeItemsReducer from "./exchange-reducers/exchangeItems";
import tempItemsReducer from "./exchange-reducers/tempItems";
import snackbarReducer from "./exchange-reducers/exchangeSnackbar";
import tokensInfoReducer from "./exchange-reducers/assetsTokens";
import achReducer from "./ach-reducers/achReducer";
import createAccountReducer from "./ach-reducers/createAccount";
import contactsReducer from "./contacts-reducer/contactsReducer";
import paymentsReducer from "./asset-reducers/paymentsReducer";
import ATStepsReducer from "./apply-tokenization-reducers/ATStepsReducer";
import newSignUpKYCReducer from "./new-signUp-KYC/newSignUp_KYC_Reducer";
import signInReducer from "./sign-in-reducer/signInReducer";

const rootReducer = combineReducers({
    signInReducer:signInReducer,
    contactsReducer: contactsReducer,
    achReducer: achReducer,
    userdata: registrationReducer,
    createAccountReducer: createAccountReducer,
    loaderReducer: loaderReducer,
    forgotData: forgotReducer,
    changePassword: changePasswordReducer,
    mainInformation: mainInformationReducer,
    marketplace: marketplaceReducer,
    mainAssetInfo: mainAssetInfoReducer,
    locationInfo: locationReducer,
    foldersList: foldersReducer,
    projectionInfo: projectionReducer,
    highlightsInfo: highlightsReducer,
    kycInfo: KYCReducer,
    accreditation: accreditationReducer,
    headerReducer: headerReducer,
    cryptoReducer: cryptoReducer,
    myInvestments: investmentsReducer,
    referralsReducer: referralsReducer,
    checkoutReducer: checkoutReducer,
    countCreditsReducer: countCreditsReducer,
    documentsReducer: documentsReducer,
    exchangeReducer: exchangeReducer,
    exchangeItemsReducer: exchangeItemsReducer,
    tempItemsReducer: tempItemsReducer,
    snackbarReducer: snackbarReducer,
    tokensInfoReducer: tokensInfoReducer,
    paymentsReducer: paymentsReducer,
    atStepsReducer: ATStepsReducer,
    newSignUpKYCReducer:newSignUpKYCReducer,
});

export default rootReducer;
