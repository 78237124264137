import Button from "../../../../reusable/button";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../../interfaces/assetsInterface";
import { THEME_COLOR } from "../../../../../constants/colors";
import { ChatBubble } from "@material-ui/icons";
import { ReactComponent as OpenDeckIcon } from './../../../../../Assets/icons/open-deck.svg'
import GeoIcon from "../../../../../Assets/images/geo.svg";
import { ISO_DATE } from "../../../../../constants/regex";
import moment from "moment";
import { FoldersListInterface } from "../../../../../interfaces/intrfaces";
import { useHistory } from "react-router-dom";

const AssetInfoIpad = ({ buyDigitalButtonHandler, funded }:
    {
        funded: boolean
        handleModal: () => void,
        buyDigitalButtonHandler: () => void
    }) => {
    const history = useHistory();
    const info = useSelector(
        (state: AssetDataInterface) => state.mainAssetInfo
    );
    const foldersList = useSelector((state: FoldersListInterface) => state.foldersList);

    return (
        <div className="asset-info-container-ipad">
            <div className="ipad-inner">
                <div className="higher-info-ipad">
                    <h2 className="asset-title">{info.alias}</h2>
                    <div className="geo">
                        <img src={GeoIcon} alt=" " />
                        <p>{info.location}</p>
                    </div>
                    <p>{info.description} </p>
                    <div className="green-text">
                        <div
                            className="green-label"
                            style={{ color: THEME_COLOR }}
                            onClick={()=>{history.push('/contact')}}
                        >
                            <ChatBubble style={{ fill: THEME_COLOR }} />
                            <p>Questions? Chat with us</p>
                        </div>
                        <a className="green-label"
                            href={foldersList.documents.find((el: any) => el.name === "Open Deck")?.documents[0].link}
                            style={{ color: THEME_COLOR }}
                            download={info.title}
                        >
                            <OpenDeckIcon style={{ fill: THEME_COLOR, width: 22 }} />
                            <p>Download full PPT deck</p>
                        </a>
                    </div>
                </div>
                <div className="line-devider"></div>

                <div className="main-ipad-info">
                    <div>
                        {Object.entries(info.assetFields).map((field, index) => {
                            let fieldValue;
                            ISO_DATE.test(field[1].value) ?
                                fieldValue = moment(field[1].value).format("DD-MMM-YYYY") :
                                fieldValue = field[1].value
                            return (
                                <div
                                    key={index}
                                    className={
                                        index % 2 === 0
                                            ? "optionalField"
                                            : "optionalField2"
                                    }
                                >
                                    <div className="inner-optional">
                                        <p className="field-value">
                                            {fieldValue}
                                        </p>
                                        <p className="field-name">
                                            {" "}
                                            {field[1].customField}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {funded ?
                <Button
                    text={"Funded"}
                    className="buyButton funded"
                    onClick={() => { }}
                    img={null}
                />
                :
                <Button
                    text="Buy Digital Shares"
                    className="buyButton"
                    onClick={buyDigitalButtonHandler}
                    style={{ background: THEME_COLOR }}
                />
            }
        </div>
    );
};

export default AssetInfoIpad;
