import axios from "axios";

const getCryptoPriceApi = () => {
    return axios
        .get(
            `https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,DASH,DAI,USDC&tsyms=USD,GBP`
        )
        .then((data) => {
            return data;
        })
};

export { getCryptoPriceApi };
