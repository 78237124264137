import topVector from './../../../Assets/icons/arrow.svg'

const prepareSlugStories = (arr: any) => {
    const [stories, storyTitleImg] = arr
    return stories.map((story: any, index: any) => {
        story = {
            content: (props: any) => {
                const newStory = stories[index];
                return (
                    <div
                        className="stories-card-modal-active"
                        style={{
                            backgroundImage: `url(${newStory.imagePath})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <div className="stories-checked-active">
                            <img
                                alt=''
                                className="stories-checked-img-active"
                                style={{
                                    backgroundImage: `url(${storyTitleImg})`,
                                }}
                            />
                            <div className="stories-checked-title-active">
                                {newStory.category}
                            </div>
                        </div>
                        <div className="stories-body-active">
                            <div className="stories-main-txt-active">
                                {newStory.category}
                            </div>
                            <div className="stories-sub-txt-active">
                                {newStory.title}
                            </div>
                        </div>
                        {window.innerWidth <= 768 ? (
                            <a
                                className="open-full-article"
                                href={newStory.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="top-arrow-container">
                                    <img
                                        className="top-arrow"
                                        src={topVector}
                                        alt=""
                                    />
                                </div>
                                <div className="open-full-article-txt">
                                    Open full article
                                </div>
                            </a>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        };

        return story;
    });
};

export default prepareSlugStories