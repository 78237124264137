import { useState } from "react";

import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { DESKTOP } from "../../../constants";
import { SignInFormProps } from "../../../interfaces/authInterfaces";
import { OutlinedInput } from "@material-ui/core";

const SignInForm = (props: SignInFormProps) => {

    const { renderNextButton,
        setEmail,
        setPassword,
        errorMessage,
        email,
        password,
        passwordFocusHandler,
        passwordBlurHandler
    } = props;

    const [visiblePassword, setVisiblePassword] = useState(false);

    const handleToggleVisiblePassword = () => {
        setVisiblePassword(!visiblePassword);
    };

    return (
        <div className="signin-page-form">
            <TextField
                onChange={(e) => setEmail(e.target.value.trim())}
                value={email}
                id="auth-email"
                variant="outlined"
                className="get-started__container__form__input"
                margin="normal"
                label="Email"
                InputLabelProps={{
                    style: {
                        fontFamily: "Montserrat",
                    },
                }}
                inputProps={{
                    maxLength: 127
                }}
            />
            <FormControl>
                <InputLabel htmlFor="auth-password">Password</InputLabel>
                <OutlinedInput
                    label="Password"
                    id="auth-password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={passwordBlurHandler}
                    onFocus={passwordFocusHandler}
                    type={visiblePassword ? "text" : "password"}
                    value={password}
                    inputProps={{
                        maxLength: 127
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleToggleVisiblePassword()}
                            >
                                {visiblePassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <p className='input-error'>{errorMessage}</p>
            {renderNextButton(DESKTOP)}
        </div>
    );
};

export default SignInForm;
