import * as type from "../constants/types";
const initialState = {
    loader: false,
    btnLoader: false,
};
const loaderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.TOGGLE_LOADER:
            return {
                ...state,
                loader: !state.loader,
            };
        case type.TOGGLE_BTN_LOADER:
            return {
                ...state,
                btnLoader: !state.btnLoader,
            };

        default:
            return state;
    }
};

export default loaderReducer;
