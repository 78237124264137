import * as types from "../constants/types";
import { NewPassword, UserData } from "./../../interfaces/profileInterface";

export const changePassword = function (object: NewPassword) {
    return {
        type: types.CHANGE_PASSWORD_REQ,
        payload: object,
    };
};

export const cancelSubscription = function (currentSubcription: string) {
    return {
        type: types.CANCELL_SUBSCRIPTION_REQ,
        payload: currentSubcription,
    };
};

export const getUserData = function () {
    return {
        type: types.GET_USER_DATA_REQ,
    };
};

export const changeUserData = function (object: UserData) {
    return {
        type: types.CHANGE_USER_DATA_REQ,
        payload: object,
    };
};

export const getUserImage = function () {
    return {
        type: types.GET_USER_IMAGE_REQ,
    };
};

export const changeUserImage = function (object: FormData) {
    return {
        type: types.CHANGE_USER_IMAGE_REQ,
        payload: object,
    };
};

export const deleteUserImage = function () {
    return {
        type: types.DELETE_USER_IMAGE_REQ,
    };
};

export const getUserReferrals = function () {
    return {
        type: types.GET_USER_REFERRALS_REQ,
    };
};

export const getUserDocuments = function () {
    return {
        type: types.GET_USER_DOCUMENTS_REQ,
    };
};

export const uploadUserDocument = function (object: any) {
    return {
        type: types.UPLOAD_USER_DOC_REQ,
        payload: object,
    };
};

export const clearSecurityMessage = function () {
    return {
        type: types.CLEAR_SECURITY_MESSAGE
    }
}

export const inviteReferral = function(email: string){
    return {
        type: types.INVITE_REFERRAL_REQ,
        payload: email
    }
}