import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import {
  MarketPlaceCard,
  MarketplaceInterface,
} from "../../../../interfaces/marketplaceInterface";
import { getAssetsList } from "../../../../redux/actions/assetsAction";
import Arrow from "./../../../../Assets/new-landing/slider-nav.png";
import { Link, useHistory } from "react-router-dom";
import ViewAll from "./../../../../Assets/new-landing/view-all.svg";
import Aos from "aos";
import LandingCard from "./landingCard";
import { RED_FROG_ID, DETOX_ID, LDB_ID, AIRBC_ID } from "../../../../constants/assetsId";
import { UserImageState } from "../../../../interfaces/headerInterface";
import mountxImg from './../../../../Assets/images/mountx.png'
import ercImg from './../../../../Assets/images/ercInvest.png'
import { checkRole } from "../../../../redux/actions/headerActions";

SwiperCore.use([Navigation]);

function promoImg(site: string, id: string) {
  if (id === RED_FROG_ID && site === 'erc') {
    return <img src={ercImg} alt="erc" className="promo" />
  }
  if (id === RED_FROG_ID && site === 'mountx') {
    return <img src={mountxImg} alt="mountx" className="promo" />
  }
  if (id === LDB_ID && site === 'mountx') {
    return <img src={mountxImg} alt="mountx" className="promo" />
  }
  if (id === DETOX_ID && site === 'mountx') {
    return <img src={mountxImg} alt="mountx" className="promo" />
  }
  return ''
}

function LandingCards() {
  const navigationPrevRef = useRef<HTMLImageElement>(null);
  const navigationNextRef = useRef<HTMLImageElement>(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const marketplace = useSelector(
    (state: MarketplaceInterface) => state.marketplace
  );
  const userData = useSelector((state: UserImageState) => state.headerReducer);

  useEffect(() => {
    dispatch(getAssetsList("landing"));
    dispatch(checkRole())
  }, [dispatch]);

  useEffect(() => {
    Aos.refresh();
  }, [marketplace]);

  const assetLinkHandler = (id: string) => {
    // if (!localStorage.getItem("token")) {
    //   history.push(`/login?redirectTo=${id}`);
    //   return;
    // }
    // if (id === "cd787198-c01f-429b-a60f-627feef04b58") {
    //   history.push("/asset-view/red-frog-beach-portfolio-panama");
    //   return;
    // }
    if (id === RED_FROG_ID) {
      history.push("/asset-view/red-frog-beach-portfolio-panama");
      return;
    }
    if (id === DETOX_ID) {
      history.push("/asset-view/detox-digital-coin");
      return;
    }
    if (id === LDB_ID) {
      history.push("/asset-view/london-digital-bonds");
      return;
    }
    if (id === AIRBC_ID) {
      history.push("/asset-view/air-bc-premium");
      return;
    }
    history.push(`/asset-view/${id}`);
  };

  return (
    <section className="landing-cards">
      <div className="container">
        <h2 className="title" data-aos="fade-up" data-aos-duration="1000">
          Start Growing Your Wealth Today
        </h2>
        <div className="subtitle" data-aos="fade-up" data-aos-duration="1000">
          Current Projects
        </div>
        <div className="slider-container">
          <Swiper
            slidesPerView={3}
            loop
            onSwiper={(swiper) => swiper.slideTo(3, 1, false)}
            breakpoints={{
              0: {
                slidesPerView: 1,
                initialSlide: 3,
              },
              600: {
                slidesPerView: 2,
                initialSlide: 3,
              },
              1200: {
                slidesPerView: 3,
                initialSlide: 3,
              },
            }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            spaceBetween={28}
            className="cards"
            watchSlidesVisibility
            initialSlide={3}
          >
            {marketplace.assetsList.map((asset: MarketPlaceCard, index) => {
              if (index > 5) return null;
              return (
                <SwiperSlide
                  className="card-item"
                  onClick={() => assetLinkHandler(asset.id)}
                  // style={{
                  //   maxHeight:
                  //     asset.tokenSold / asset.maxAmount > 0.2 ? 700 : 630,
                  // }}
                  key={asset.id}
                >
                  {({ isVisible }: { isVisible: boolean }) => (
                    <LandingCard 
                      asset={asset} 
                      isVisible={isVisible} 
                      promo={promoImg(userData.fromSite,asset.id)}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
          <img
            ref={navigationPrevRef}
            src={Arrow}
            alt="arrow left"
            className="swiper-nav nav-left"
          />
          <img
            ref={navigationNextRef}
            src={Arrow}
            alt="arrow right"
            className="swiper-nav nav-right"
          />
        </div>
        <Link className="view-all" to="/marketplace">
          View All Projects <img src={ViewAll} alt="view all project" />
        </Link>
      </div>
    </section>
  );
}

export default LandingCards;
