import { Link, useHistory } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";

import LogOut from "../../../../../Assets/images/log-out.svg";
import Person from "../../../../../Assets/images/person.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutReset } from "../../../../../redux/actions/applyTokenizationActions";
import { UserDataState } from "../../../../../interfaces/headerInterface";
import { entityProgressSteps, individualProgressSteps } from "../../../../../interfaces/authInterfaces";


const AccountNavCollapse = ({ open, setOpen, admin }: {
    open: boolean,
    setOpen: (open: boolean) => void,
    admin: React.ReactElement | null
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const userData = useSelector((state: UserDataState) => state.headerReducer);
    let steps
    const renderBanner = () => {
        // user not entered phone from oauth 
        if(userData.firstTryAfterOauth){
            return <Link to="/sign-up/country-phone" className="menuBanner">verify phone</Link>
        }
        //common cases 
        if (!userData.steps.email) {
            return <Link to="/sign-up/verify-email" className="menuBanner">verify email</Link>
        }
        if (!userData.steps.cell) {
            return <Link to="/sign-up/country-phone" className="menuBanner">verify phone</Link>
        }
        if (userData.isEntity) {
            //entity case
            steps = userData.steps as entityProgressSteps
            if ((!steps.companyInformation)) {
                return <Link to="/sign-up/company-information" className="menuBanner">verify accreditation</Link>
            }
        }
        if (!userData.isEntity) {
            //individual case
            steps = userData.steps as individualProgressSteps
            if (!steps.identity) {
                return <Link to="/sign-up/kyc" className="menuBanner">verify id</Link>
            }
            if ((!steps.accreditation && !steps.accreditationSkipped)) {
                return <Link to="/sign-up/accreditation" className="menuBanner">verify accreditation</Link>
            }
        }
    }

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("expiredTime");
        localStorage.removeItem("refreshToken");
        dispatch(logoutReset());
        window.location.assign('/login')
    };

    return (
        <div onMouseLeave={() => setOpen(!open)} className="nav-collapse-container" >
            <Collapse in={!open} className="nav-collapse">
                <div
                    className="collapse-header-item"
                >
                    <div className="collapse-header-text" onClick={() => history.push("/profile/main-information")}>
                        <img
                            alt="person"
                            src={Person}
                            className="collapse-person"
                        />
                        Profile
                    </div>
                    {renderBanner()}
                </div>
                {admin}
                <div className="collapse-header-item" onClick={() => logout()}>
                    <div className="collapse-header-text">
                        <img
                            alt="logout"
                            src={LogOut}
                            className="collapse-logout"
                        />
                        Log out
                    </div>
                </div>
            </Collapse>
        </div >
    );
};

export default AccountNavCollapse;
