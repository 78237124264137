import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { clearError, } from "../../../redux/actions/registartionAction";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthSocial from "../../../components/auth-components/auth-social/authSocial";
import { validateEmail } from "./../../../constants/regex";
import { MOBILE } from "../../../constants";
import { useSelector } from "react-redux";
import SignInForm from "../../../components/auth-components/signin-form/signinForm";
import c from "../PhoneVerification/style.module.scss"
import Reaptcha from "reaptcha";
import { ENABLED_CAPTCHA } from "../../../constants/enabledCaptcha";
import mautic from 'mautic-tracking';
import TextField from "@mui/material/TextField/TextField";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MobileSvg from "../../../Assets/images/mobile.svg";
import misstakeSvg from "../../../Assets/images/misstake.svg";
import emailSentSvg from "../../../Assets/images/emailSent.svg";
import helpSentSvg from "../../../Assets/images/helpSent.svg";
import { sigInPostCodeAC, signInFirstStepAC, signInResendCodeAC } from "../../../redux/actions/signInActions";
import { SignInStore } from "../../../interfaces/signInInterface";

const LogInPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(!ENABLED_CAPTCHA);
  const signUp = false;

  const signInState = useSelector((state: { signInReducer: SignInStore }) => state.signInReducer);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const code = query.get("code");
  const clientId = query.get("clientId");
  const userEmail = query.get("email");
  const redirectTo = query.get("redirectTo");
  const toCheckout = query.get("toCheckout");
  const toAsset = query.get("toAsset");
  const tokens = query.get("tokens");

  const [emailError,setEmailError] = useState('')
  useEffect(()=>setEmailError(''),[password,email])

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    validateEmail(email) && /^.{8,30}$/gi.test(password) && captchaVerified
      ? setButtonEnabled(true)
      : setButtonEnabled(false);
  }, [email, password, captchaVerified]);

  useEffect(() => {
    mautic.pageView({});
  }, []);

  const postLoginData = useCallback(
    (email: string, password: string, history: any) => {
      const renderLoginData = () => {
        if (code && userEmail && clientId && redirectTo) {
          return {
            logindata: {
              email: email,
              password: password,
              hifromfront: "sd",
            },
            confirmData: {
              email: userEmail,
              code,
              clientId,
            },
            history,
            redirectTo: redirectTo,
            setErrorCB:setEmailError,
          };
        }
        if (code && userEmail && clientId) {
          return {
            logindata: {
              email: email,
              password: password,
              hifromfront: "sd",
            },
            confirmData: {
              email: userEmail,
              code,
              clientId,
            },
            history,
            setErrorCB:setEmailError,
          };
        }
        if (toCheckout && toAsset && tokens) {
          return {
            logindata: {
              email: email,
              password: password,
              hifromfront: "sd",
            },
            history,
            toCheckout: {
              asset: toAsset,
              tokens: tokens,
            },
            setErrorCB:setEmailError,
          };
        }
        if (redirectTo) {
          return {
            logindata: {
              email: email,
              password: password,
              hifromfront: "sd",
            },
            history,
            redirectTo: redirectTo,
            setErrorCB:setEmailError,
          };
        }
        return {
          logindata: {
            email: email,
            password: password,
            hifromfront: "sd",
          },
          history,
          setErrorCB:setEmailError,
        };
      };

      dispatch(signInFirstStepAC(renderLoginData()));
    },
    [
      dispatch,
      clientId,
      code,
      userEmail,
      redirectTo,
      toCheckout,
      toAsset,
      tokens,
    ]
  );

  useEffect(() => {
    const enterListener = (e: any) => {
      if (e.code === "Enter" && buttonEnabled) {
        e.preventDefault();
        postLoginData(email, password, history);
      }
    };

    document.addEventListener("keydown", enterListener);

    return () => {
      document.removeEventListener("keydown", enterListener);
    };
  }, [buttonEnabled, postLoginData, email, password, history]);

  const onVerify = (recaptchaResponse: any) => {
    setCaptchaVerified(true);
  };

  const renderNextButton = (version: any) => {
    return (
      <Button
        onClick={() => postLoginData(email, password, history)}
        style={{
          backgroundColor: signInState.buttonDisabled || !buttonEnabled ? "gray" : `#F64A00`,
        }}
        disabled={!buttonEnabled || signInState.buttonDisabled}
        className={`auth-next-button ${version}`}
      >
        Log in
      </Button>
    );
  };

  // second step logic 
  // main form
  const [isDeviceRemember, setIsDeviceRemember] = useState(false);
  const [tfaCode, setTfaCode] = useState("");

  // main form request
  const [isResponceError, setIsResponceError] = useState(false);
  const [isCodeSubmiting, setIsCodeSubmiting] = useState(false);
  const [isCodeLenghtError, setIsCodeLenghtError] = useState(false);

  const codeFalseCB = () => {
    setIsCodeSubmiting(false);
    setIsResponceError(true);
    dispatch(signInResendCodeAC(sentCodeIndicatorBlink));
  };

  const submitFunction = () => {
    if (tfaCode.length === 6) {
      setIsCodeSubmiting(true);
      dispatch(sigInPostCodeAC(tfaCode, isDeviceRemember, history, codeFalseCB))
      setTfaCode("");
    } else {
      setIsCodeLenghtError(true);
    }
  };

  const renderCodeError = () => {
    if (isResponceError) {
      return "The code is invalid, we already sent you the new one";
    }
    if (isCodeLenghtError) {
      return "Code must be 6 digits long";
    }
    return " ";
  };

  //resend
  const [isSentCodeIndicatorShown, setIsSentCodeIndicatorShown] =
    useState(false);

  let codeTimeout = useRef(setTimeout(() => { }, 0));
  const sentCodeIndicatorBlink = () => {
    setIsSentCodeIndicatorShown(true);
    clearTimeout(codeTimeout.current);
    codeTimeout.current = setTimeout(
      () => setIsSentCodeIndicatorShown(false),
      5000
    );
  };

  //help form
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [isSendShown, setIsSentShown] = useState(false)
  if (signInState.phone) return (
    <div className={c.wrapper}>
      <main className={c.form}>
        <header className={c.page_header}>2-step authentication</header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitFunction();
          }}
          className={c.fields}
        >
          <p className={c.subhead}>
            Enter the 6 digit code we sent to
            <br />
            XXXXXX{signInState.phone}
          </p>
          <br />
          <p className={c.subscription}>
            This extra step shows it&apos;s really you signing in.
          </p>
          <div className={c.input_wrapper}>
            <img src={MobileSvg} alt="Enter 5-digit code" />
            <TextField
              style={{ marginTop: 20 }}
              helperText={renderCodeError()}
              autoComplete="off"
              className={c.input}
              variant="outlined"
              label="2-step authentication code"
              value={tfaCode}
              onChange={(e) => {
                setIsCodeLenghtError(false);
                if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                  setIsResponceError(false);
                  setTfaCode(e.target.value);
                }
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment:
                  isResponceError || isCodeLenghtError ? (
                    <img src={misstakeSvg} alt="error" />
                  ) : (
                    ""
                  ),
              }}
            />
          </div>

          <Button
            type="submit"
            variant="outlined"
            className={c.submit}
            fullWidth
            disabled={isCodeSubmiting}
          >
            {isCodeSubmiting ? "Verifying" : "Verify"}
          </Button>
          <FormControlLabel
            className={c.checkbox}
            label="Remember this computer for 30 days."
            control={
              <Checkbox
                checked={isDeviceRemember}
                onChange={(e) => setIsDeviceRemember(e.target.checked)}
              />
            }
          />
          <div className={c.resend}>
            <Button
              variant="text"
              className={c.resend_button}
              onClick={() => dispatch(signInResendCodeAC(sentCodeIndicatorBlink))}
            >
              Resend the text message
            </Button>
          </div>
        </form>
        <div
          className={`${c.sent_indicator} ${isSentCodeIndicatorShown ? "" : c.hidden
            }`}
        >
          Code sent{" "}
          <img src={emailSentSvg} alt="Code sent" width={19} height={15} />
        </div>
        <Button
          variant="text"
          className={c.help_button}
          onClick={() => {
            setIsHelpFormOpen(!isHelpFormOpen);
          }}
        >
          I need help
        </Button>
        <div
          className={`${c.help_form_container} ${isHelpFormOpen ? "" : c.hidden}`}
        >
          <form className={c.help_form}>
            <h3>Send us a message</h3>
            <TextField
              className={c.input}
              placeholder="Let us know what the problem is, we’ll reply to you via email"
              multiline
              rows={4}
              disabled={!isHelpFormOpen}
              value={helpText}
              onChange={(e) => {
                setHelpText(e.target.value)
                setIsSentShown(false)
              }}
            />
            <Button
              type="submit"
              variant="outlined"
              className={c.submit}
              onClick={(e) => {
                e.preventDefault()
                setIsSentShown(true)
              }}
              fullWidth
              disabled={!isHelpFormOpen || !helpText}
            >
              Send Message
            </Button>
            <div className={`${c.send}  ${isSendShown ? "" : c.hidden}`}>
              <img src={helpSentSvg} alt='Message sent' />
              <p><span>Message sent</span><br />We&apos;ll reply to you via email</p>
            </div>
          </form>
        </div>
      </main>
    </div>
  );

  return (
    <div className="login-page-container">
      <div className="login-page">
        <div
          className="login-page-title"
        >
          Log in to your account
        </div>
        <div className="login-page-content">

          <SignInForm
            errorMessage={emailError}
            renderNextButton={renderNextButton}
            setEmail={setEmail}
            setPassword={setPassword}
            signUp={signUp}
            email={email}
            password={password}
          />
          {renderNextButton(MOBILE)}
          {ENABLED_CAPTCHA ? (
            <Reaptcha
              className="login-page-form"
              sitekey="6LeEa_wcAAAAABG_gf62JQMhHu_xZ7a78S6qYCxj"
              onVerify={onVerify}
            />
          ) : null}
          <AuthSocial />
          <Link
            className="subtitle-GREEN forgotLink"
            to="/forgot-password/email"
            style={{ color: "#0EC22B" }}
          >
            Forgot password?
          </Link>
          <div className="login-page-subtitle">
            Don’t have an account?{" "}
            <Link
              onClick={() => dispatch(clearError())}
              className="subtitle-GREEN"
              to="/sign-up/auth"
              style={{ color: "#0EC22B" }}
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
