import * as types from '../constants/types'
//import * as H from 'history';
import { ATfirstStep, ATfivethStep, ATfourthStep, ATsecondStep, ATthirdStep, DocumentInState, Product } from '../../interfaces/applyTokenizationInterface'

export const sendATDashboard = function (firstStep: ATfirstStep) {
    return {
        type: types.SEND_AT_DASHBOARD_REQ,
        payload: {
            firstStep,
        }
    }
}

export const sendATfirstStep = function (firstStep: ATfirstStep) {
    return {
        type: types.SEND_AT_FIRST_STEP_REQ,
        payload: {
            firstStep,
        }
    }
}

export const sendATsecondStep = function (secondStep: ATsecondStep) {
    return {
        type: types.SEND_AT_SECOND_STEP_REQ,
        payload: {
            secondStep,
        }
    }
}

export const sendATthirdStep = function (thirdStep: ATthirdStep) {
    return {
        type: types.SEND_AT_THIRD_STEP_REQ,
        payload: {
            thirdStep,
        }
    }
}

export const sendATfourthStep = function (fourthStep: ATfourthStep) {
    return {
        type: types.SEND_AT_FOURTH_STEP_REQ,
        payload: {
            fourthStep,
        }
    }
}

export const sendATfivethStep = function (fivethStep: ATfivethStep) {
    return {
        type: types.SEND_AT_FIVETH_STEP_REQ,
        payload: {
            fivethStep,
        }
    }
}

export const getTokenizationByUser = function () {
    return {
        type: types.GET_TOKENIZATION_BY_USER_REQ,
    }
}

export const checkTokenizationName = function (
    name: string,
    cb: (prop: { symbol: string }) => void
) {
    return {
        type: types.CHECK_TOKENIZATION_NAME_REQUEST,
        callBack: cb,
        payload: name,
    }
}

export const checkTokenizationSymbol = (
    symbol: string,
    cb: (prop: boolean) => void
) => ({
    type: types.CHECK_TOKENIZATION_SYMBOL_REQUEST,
    callBack: cb,
    payload: symbol.toUpperCase().trim(),
})

export const getTokenizationFirstStep = function (tokenizationId: string | null) {
    return {
        type: types.GET_AT_FIRST_STEP_REQ,
        payload: {
            tokenizationId,
        }
    }
}

export const getTokenizationSecondStep = function (tokenizationId: string | null) {
    return {
        type: types.GET_AT_SECOND_STEP_REQ,
        payload: {
            tokenizationId,
        }
    }
}

export const getTokenizationThirdStep = function (tokenizationId: string | null) {
    return {
        type: types.GET_AT_THIRD_STEP_REQ,
        payload: {
            tokenizationId,
        }
    }
}

export const getTokenizationFourthStep = function (tokenizationId: string | null) {
    return {
        type: types.GET_AT_FOURTH_STEP_REQ,
        payload: {
            tokenizationId,
        }
    }
}

export const getTokenizationFivethStep = function (tokenizationId: string | null) {
    return {
        type: types.GET_AT_FIVETH_STEP_REQ,
        payload: {
            tokenizationId,
        }
    }
}

export const uploadTokenizationFile = function (
    formData: FormData,
    tokenizationId: string | null,
    stepInfo: ATfivethStep,
    progressCB:(d:any)=>void,
    doneCB:()=>void,
) {
    return {
        type: types.AT_UPLOAD_FILE_REQ,
        payload: {
            formData,
            tokenizationId,
            stepInfo,
            progressCB,
            doneCB,
        }
    }
}

export const addTokenizationFile = function (file: any, tokenizationId: string | null, stepInfo: ATfivethStep) {
    return {
        type: types.AT_ADD_FILE_REQ,
        payload: {
            file,
            tokenizationId,
            stepInfo
        }
    }
}

export const deleteTokenizationFile = function (fileId: string, stepInfo: ATfivethStep) {
    return {
        type: types.AT_DELETE_FILE_REQ,
        payload: {
            fileId,
            stepInfo
        }
    }
}

export const stepChange = function (step: number) {
    return {
        type: types.AT_STEP_CHANGE,
        payload: {
            step
        }
    }
}

export const subscriptionChange = function (name: string, product: string, price: string) {
    return {
        type: types.SET_SUBSCRIPTION,
        payload: {
            product,
            name,
            price
        }
    }
}

export const stripeOnDonePayment = function (sessionId: string) {
    return {
        type: types.STIPRE_DONE_PAYMENT_REQEST,
        payload: {
            sessionId
        },
    }
}

export const getCurrentSubscription = function (tokenizationId: string | null) {
    return {
        type: types.GET_CURRENT_SUBSCRIPTION,
        payload: {
            tokenizationId
        }
    }
}

export const logoutReset = function () {
    return {
        type: types.LOGOUT,
    }
}
export const setNotificationProducts = function (prods: Array<DocumentInState>) {
    return {
        type: types.SET_PRODUCTS_NOTIFICATIION_CART,
        payload: prods.map(product => ({
            name: product.name,
            priceId: product.priceId,
            price: product.price,
        }))
    }
}


export const setTokenizationImage = function (file: File, tokenizationId: string) {
    const formData = new FormData()
    formData.append("file", file);
    return {
        type: types.SET_TOKENIZATION_PICTURE_REQ,
        payload: {
            formData,
            tokenizationId
        }
    }
}

export const getProducts = function(responceCb:(data:Product[])=>void){
    return {
        type:types.GET_ALL_PRODUCTS,
        payload:responceCb
    }
}

export const getTokenizationPaidStatus = function (history: any) {
    return {
        type: types.GET_TOKENIZATION_PAID_STATUS_REQ,
        payload: history,
    };
};

export const getTokenizationPaidStatusWithoutRedirect = function () {
    return {
        type: types.GET_TOKENIZATION_PAID_STATUS_WITHOUT_REDIRECT_REQ,
    };
};

export const setTokenizationPaidStatus = function (formStatus: any) {
    return {
        type: types.SET_TOKENIZATION_PAID_STATUS_REQ,
        payload: formStatus,
    };
};

export const tokenizationStripePurchase = (
    data: {
      "priceId": string,
    },
    callBack:(url:string)=>void,
  ) => ({
    type: types.TOKENIZATION_PURCHASE_REQ,
    payload: {...data,callBack },
  })

