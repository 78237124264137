import { useState } from "react";
import { useSelector } from "react-redux";
import { AssetDataInterface } from "../../../../interfaces/assetsInterface";
import mountxImg from "./../../../../Assets/images/mountx.png";
import AssetCheckout from "./../asset-checkout/assetCheckout";
import AssetTopRight from "./../asset-top-right/assetTopRight";
// import img from "./../../../../Assets/images/mountx1.jpg";
import Image1 from "./../../../../Assets/images/redfrog/detox1.jpg";
import { UserImageState } from "../../../../interfaces/headerInterface";
import ReactPixel from 'react-facebook-pixel';

function AdvanceItemInfo() {
  // const assetInfo = useSelector(
  //   (state: AssetDataInterface) => state.mainAssetInfo
  // );
  const infoAdvanced = {
    title: "Detox Digital Coin",
    location: "United States",
    description: "Detox Digital Coin has identified the first in a series of an additional 20 behavioral health centers (BHCs) for acquisition in prime US locations earmarked for renting to qualified substance use disorder and recovery facilitators.",
    totelRaise: "$2,800,000.00",
    minimumInvestment: "5000",
    tokenName: "DDC",
    videos: [
      'https://www.youtube.com/embed/TlTLfh5-lhA?start=1',
      "https://youtu.be/KRcrv8uG7Y8"
    ],
    highlightItems: [
        {highlight:"Behavioral health centers (BHCs) are forecasted to grow at a compounded annual growth rate (CAGR) of approximately 3.6% over the next seven years."},
        {highlight:"Industry revenues will climb to almost $99.4 billion by 2028."},
        {highlight:"Florida has the highest success rates of drug rehab compared to all other states with 70% completion of treatment programs."},
        {highlight:"The median value of a Florida house was $270,560 in January, an 8.6% increase over the previous year."},
        {highlight:"BHCs are a niche commercial real estate sector that has insulation from the effects of Covid and is considered an “essential business” by US federal and state governments."},
        {highlight:"Facilities identified, renovated and leased prior to acquisition."},
        {highlight:"“Single tenant” credit-worthy operators with NNN leases and a built-in 3% annual raise in rent."},
        {highlight:"Anticipated 5-year Annualized Return: 12%."},
    ],
    investedDetail: [
        {field:"Revenue type", value:"NNN Lease"},
        {field:"Capital Stack", value:"Equity"},
        {field:"Property Type", value:"Medical Facility"},
    ],
    projectionDetail: [
      {field:"Targeted ROI", value:"12%"},
      {field:"Capital Structure", value:"35% Equity; 65% Debt"},
      {field:"Equity Multiple", value:"1.62"},
      {field:"Targeted IRR", value:"15%"},
    ],
    content: {
      offer: `<div>SolidBlock has teamed up with Holdun Family Office to create the Detox Digital Coin, a real estate investment opportunity to grow detox and behavioral health centers across the US.<br/><br/>
      The offering addresses a rapidly expanding market due to the exploding behavioral health crisis which includes drug and alcohol addiction and mental health wellbeing.<br/><br/>
      Detox Digital Coin uses blockchain technology to tokenize and raise capital. With a minimum investment of $250 USD, investors receive tokenized shares in the property, which are represented as Detox Digital Coins. These coins will be deposited into the investor’s account or digital wallet.<br/><br/>
      Each new acquisition will be listed as a separate and distinct investment opportunity. The goal is to acquire up to 20 additional facilities.
      </div>`,
    }
  }
  const info = useSelector((state: AssetDataInterface) => state.mainAssetInfo);

  const [checkout, setCheckout] = useState(false);

  const toggleCheckout = () => {
    ReactPixel.track('AddToCart');
    setCheckout(!checkout);
  };

  const userData = useSelector((state: UserImageState) => state.headerReducer);

  return (
    <div className="asset-info">
      <div className="asset-title">
        <div>
          <div className="title">{infoAdvanced.title} <span>(${info.alias})</span></div>
          <div className="location">{info.location}</div>
          {/* <div className="descr">{infoAdvanced.description}</div> */}
          <div className="descr">
            Detox Digital Coin has identified the first in a series of an additional 20 behavioral health centers (BHCs) 
            for <a style={{ color: "#0EC22B",textDecoration:'underline', }}
            href="#TheFirst" 
            onClick = {(e)=>{
              e.preventDefault()
              document.querySelector('#TheFirst')?.scrollIntoView({block:'center'})
            }}
            >acquisition</a> in
            prime US locations earmarked for renting to qualified substance use disorder and recovery facilitators.</div>
          </div>
          {userData.fromSite ==='mountx'?
          <img src={mountxImg} alt="mountx" className="promo" />
          :
          ''
          }
        </div>
      <div className="asset-info-main">
        <div className="left">
          <div className="asset-image">
            {/* <iframe
              src={infoAdvanced.videos[0]}
              title="Detox Video"
            /> */}
            <img src={Image1} alt="" />
          </div>
        </div>
        <div className="right">
          {/* <Fade in={!checkout}> */}
          {checkout ? null : (
            <AssetTopRight
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          )}
          {checkout ? (
            <AssetCheckout
              toggleCheckout={toggleCheckout}
              checkout={checkout}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdvanceItemInfo;
