const LANDING_CATEGORIES = [
  { title: "ABOUT", path: "#about" },
  { title: "TEAM", path: "#team" },
  { title: "CONTACTS", path: "/contact" },
  { title: "TOKENIZATION", path: "/real-estate-tokenization" },
];
const LANDING_CATEGORIES_AUTHED = [
  { title: "MAIN", path: "/" },
  { title: "MARKETPLACE", path: "/marketplace" },
  { title: "PROFILE", path: "/profile" },
  // { title: "EXCHANGE", path: "/exchange" },
  { title: "CONTACTS", path: "/contact" },
  { title: "MY ASSETS", path: "/my-assets" },
  { title: "TOKENIZATION", path: "/tokenization-form" },
];

const AUTH_MOBILE_CATEGORIES = [
  "/phone-confirmation",
  "/email-confirmation",
  "/contact",
  "/partners",
];

const AUTHED_ROUTES = [
  // "/marketplace",
  // "/asset-view",
  "/profile/main-information",
  "/profile/security",
  "/profile/documents",
  "/profile/referrals",
  "/profile/subscription",
];

const CHECKOUT_PAGES = [
  "/checkout/overview/:id?",
  "/checkout/overview",
  "/checkout/payment",
  "/checkout/confirmation",
]

const ACCREDITATION_PAGES = [
  "/accreditation/first",
  "/accreditation/way",
  "/accreditation/tax-return",
  "/accreditation/account-statement",
  "/accreditation/letter",
  "/kyc/first",
  "/kyc/check",
  "/kyc/id-verification",
  "/issuer/account/1",
  "/issuer/account/2",
  "/issuer/offering/1",
  "/issuer/offering/2",
];

const LANDING_HEADER_PAGES = [
  "/login",
  "/contact",
  "/DAH",
  "/dah",
  "/DAH-thanks",
  "/partners",
  "/about",
  "/course",
  "/new-sign-up",
  '/new-sign-up/auth',
  "/nft-stays",
];

const NON_HEADER_PAGES = [
  "/onboarding",
  "/google-auth-reg",
]

export {
  LANDING_CATEGORIES_AUTHED,
  LANDING_CATEGORIES,
  AUTH_MOBILE_CATEGORIES,
  AUTHED_ROUTES,
  ACCREDITATION_PAGES,
  LANDING_HEADER_PAGES,
  NON_HEADER_PAGES,
  CHECKOUT_PAGES,
};
