import { refreshTokenApi } from "./refreshToken";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

const putTokenApi = async (action: any) => {
  let ourDate: any = localStorage.getItem("expiredTime");
  let final: any = new Date(ourDate * 1000);
  let date: any = new Date();
  if (final - date < 0) {
    await refreshTokenApi();
  }
  return axios
    .put(url + action[0], action[1], {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiredTime");
      }
      throw err
    });
};

export { putTokenApi };
