export const GET_SIGNIN_REQ = "GET_SIGNIN_REQ";
export const GET_USERS_SUC = "GET_USERS_SUC";
export const GET_USERS_FAILED = "GET_USERS_FAIL";
export const PUT_USER_EMAIL_PSW = "PUT_USER_EMAIL_PSW";
export const PUT_USER_OTHER_DATA = "PUT_USER_OTHER_DATA";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const USER_SUGNUP = "USER_SUGNUP";
export const GET_USERS_AUTH = "GET_USERS_AUTH";
export const GET_USERS_AUTH_TOK = "GET_USERS_AUTH_TOK";
export const EMAIL_FOR_FORGOTTEN = "EMAIL_FOR_FORGOTTEN";
export const EMAIL_FOR_FORGOTTEN_FAIL = "EMAIL_FOR_FORGOTTEN_FAIL";
export const FORGOT_PASSWORD_RESEND_EMAIL_REQ = "FORGOT_PASSWORD_RESEND_EMAIL_REQ";
export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const RESEND_CODE_REQ = "RESEND_CODE_REQ";
export const CLEAR_FORGOT_PASSWORD_ERRORS = "CLEAR_FORGOT_PASSWORD_ERRORS";
export const CHECK_IF_EMAIL_EXISTS = "CHECK_IF_EMAIL_EXISTS";
export const CHECK_IF_EMAIL_EXISTS_TOK = "CHECK_IF_EMAIL_EXISTS_TOK";
export const REGISTRATION_FIRST_STEP = "REGISTRATION_FIRST_STEP";

// NEW_SIGN_UP
export const NEW_SIGN_UP_KYC_STOP_LOADING = 'NEW_SIGN_UP_KYC_STOP_LOADING123123';
export const NEW_SIGN_UP_KYC_START_LOADING = 'NEW_SIGN_UP_KYC_START_LOADING';
export const NEW_SIGN_UP_REQUEST = 'NEW_SIGN_UP_REQUEST';
export const NEW_SIGN_UP_KYC_GET_REQ = 'NEW_SIGN_UP_KYC_GET_REQ';
export const NEW_SIGN_UP_KYC_GET_RESP = 'NEW_SIGN_UP_KYC_GET_RESP';
export const NEW_SIGN_UP_KYC_POST = 'NEW_SIGN_UP_KYC_POST';
export const NEW_SIGN_UP_KYC_SET_STEP = 'NEW_SIGN_UP_KYC_SET_STEP';
export const NEW_SIGN_UP_GET_KYC_SECOND_STEP_REQ = 'NEW_SIGN_UP_GET_KYC_SECOND_STEP_REQ'
export const NEW_SIGN_UP_GET_KYC_SECOND_STEP_RESP = 'NEW_SIGN_UP_GET_KYC_SECOND_STEP_RESP'
export const NEW_SIGN_UP_KYC_FILE_UPLOAD_REQ = 'NEW_SIGN_UP_KYC_FILE_UPLOAD_REQ'
export const NEW_SIGN_UP_KYC_FILE_UPLOAD_RESP = 'NEW_SIGN_UP_KYC_FILE_UPLOAD_RESP'
export const NEW_SIGN_UP_KYC_FILE_DELETE_REQ = 'NEW_SIGN_UP_KYC_FILE_DELETE_REQ'
export const NEW_SIGN_UP_KYC_FILE_DELETE_RESP = 'NEW_SIGN_UP_KYC_FILE_DELETE_RESP'
export const NEW_SIGN_UP_POST_KYC_SECOND_STEP_REQ = 'NEW_SIGN_UP_POST_KYC_SECOND_STEP_REQ'

export const NEW_SIGN_UP_ACCREDITATION_SKIP_REQ = 'NEW_SIGN_UP_ACCREDITATION_SKIP_REQ'
export const NEW_SIGN_UP_ACCREDITATION_SKIP_RESP = 'NEW_SIGN_UP_ACCREDITATION_SKIP_RESP'
export const NEW_SIGN_UP_ACCREDITATION_GET_FILES_REQ = 'NEW_SIGN_UP_ACCREDITATION_GET_FILES_REQ'
export const NEW_SIGN_UP_ACCREDITATION_GET_FILES_RESP = 'NEW_SIGN_UP_ACCREDITATION_GET_FILES_RESP'
export const NEW_SIGN_UP_ACCREDITATION_POST_FILES_REQ = 'NEW_SIGN_UP_ACCREDITATION_POST_FILES_REQ'
export const NEW_SIGN_UP_ACCREDITATION_POST_FILES_RESP = 'NEW_SIGN_UP_ACCREDITATION_POST_FILES_RESP'
export const NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_REQ = 'NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_REQ'
export const NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_RESP = 'NEW_SIGN_UP_ACCREDITATION_DELETE_FILE_RESP'
export const NEW_SIGN_UP_ACCREDITATION_SET_DOCUMENT_TYPE = 'NEW_SIGN_UP_ACCREDITATION_SET_DOCUMENT_TYPE'
export const NEW_SIGN_UP_ACCREDITATION_SUBMIT = 'NEW_SIGN_UP_ACCREDITATION_SUBMIT'
export const NEW_SIGN_UP_MOBILE_SET_UP_REQ = 'NEW_SIGN_UP_MOBILE_SET_UP_REQ'
export const NEW_SIGN_UP_MOBILE_SET_UP_OAUTH_REQ = 'NEW_SIGN_UP_MOBILE_SET_UP_OAUTH_REQ'
export const NEW_SIGN_UP_MOBILE_RESEND_CODE_REQ = 'NEW_SIGN_UP_MOBILE_RESEND_CODE_REQ'
export const NEW_SIGN_UP_MOBILE_CONFIRM_UP_REQ = 'NEW_SIGN_UP_MOBILE_CONFIRM_UP_REQ'
export const NEW_SIGN_UP_MOBILE_CONFIRM_UP_RESP = 'NEW_SIGN_UP_MOBILE_CONFIRM_UP_RESP'

export const NEW_SIGN_UP_COMPANY_INFO_SUBMIT_REQ = 'NEW_SIGN_UP_COMPANY_INFO_SUBMIT_REQ'
export const NEW_SIGN_UP_COMPANY_INFO_SUBMIT_RESP = 'NEW_SIGN_UP_COMPANY_INFO_SUBMIT_RESP'

// sign in 
export const SIGN_IN_FIRST_STEP_REQ = 'SIGN_IN_FIRST_STEP_REQ'
export const SIGN_IN_FIRST_STEP_TFA_RESP = 'SIGN_IN_FIRST_STEP_TFA_RESP'
export const SIGN_IN_POST_TFA_CODE_REQ = 'SIGN_IN_POST_TFA_CODE_REQ'
export const SIGN_IN_ENABLE_BUTTON_SUBMIT = 'SIGN_IN_ENABLE_BUTTON_SUBMIT'
export const SIGN_IN_RESEND_TFA_REQ = 'SIGN_IN_RESEND_TFA_REQ'
export const SIGN_IN_RESEND_TFA_RESP = 'SIGN_IN_RESEND_TFA_RESP'

export const CONFIRM_EMAIL_REQ = "CONFIRM_EMAIL_REQ";
export const CONFIRM_EMAIL_REQ_TOK = "CONFIRM_EMAIL_REQ_TOK";
export const CONFIRM_EMAIL_SUC = "CONFIRM_EMAIL_SUC";
export const CONFIRM_EMAIL_FAIL = "CONFIRM_EMAIL_FAIL";
export const RESEND_PHONE_CODE_REQ = "RESEND_PHONE_CODE_REQ";
export const CONFIRM_PHONE_REQ = "CONFIRM_PHONE_REQ";
export const CONFIRM_PHONE_SUC = "CONFIRM_PHONE_SUC";
export const CONFIRM_PHONE_FAIL = "CONFIRM_PHONE_REQ_FAIL";

export const OPEN_VERIFY_MODAL = "OPEN_VERIFY_MODAL";
export const CLOSE_VERIFY_MODAL = "CLOSE_VERIFY_MODAL";

export const CHANGE_PASSWORD_REQ = "CHANGE_PASSWORD_REQ";
export const CANCELL_SUBSCRIPTION_REQ = "CANCELL_SUBSCRIPTION_REQ";
export const CANCELL_SUBSCRIPTION_SUC = "CANCELL_SUBSCRIPTION_SUC";
export const CHANGE_PASSWORD_SUC = "CHANGE_PASSWORD_SUC";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const GET_USER_DATA_REQ = "GET_USER_DATA_REQ";
export const GET_USER_DATA_SUC = "GET_USER_DATA_SUC";
export const SET_USER_DATA = "SET_USER_DATA";
export const GET_USER_DATA_FAIL = "GET_USER_DATA_FAIL";
export const GET_USER_DOCUMENTS_REQ = "GET_USER_DOCUMENTS_REQ";
export const GET_USER_DOCUMENTS_SUC = "GET_USER_DOCUMENTS_SUC";
export const GET_USER_DOCUMENTS_FAIL = "GET_USER_DOCUMENTS_FAIL";
export const UPLOAD_USER_DOC_REQ = "UPLOAD_USER_DOC_REQ";
export const UPLOAD_USER_DOC_FAIL = "UPLOAD_USER_DOC_FAIL";
export const UPLOAD_USER_DOC_SUC = "UPLOAD_USER_DOC_SUC";

export const GET_USER_REFERRALS_REQ = "GET_USER_REFERRALS_REQ";
export const GET_USER_REFERRALS_SUC = "GET_USER_REFERRALS_SUC";
export const GET_USER_REFERRALS_FAIL = "GET_USER_REFERRALS_FAIL";

export const GET_USER_REGISTRATION_FAILED = "GET_USER_REGISTRATION_FAILED";
export const CHANGE_USER_DATA_REQ = "CHANGE_USER_DATA_REQ";
export const CHANGE_USER_DATA_SUC = "CHANGE_USER_DATA_SUC";
export const CHANGE_USER_DATA_FAIL = "CHANGE_USER_DATA_FAIL";
export const CLEAR_SECURITY_MESSAGE = "CLEAR_SECURITY_MESSAGE";

export const GET_USER_IMAGE_REQ = "GET_USER_IMAGE_REQ";
export const GET_USER_IMAGE_SUC = "GET_USER_IMAGE_SUC";
export const GET_USER_IMAGE_FAIL = "GET_USER_IMAGE_FAIL";
export const PUT_HEADER_IMAGE = "PUT_HEADER_IMAGE";
export const CHECK_IS_ADMIN_REQ = "CHECK_IS_ADMIN_REQ";
export const CHECK_IS_ADMIN_SUC = "CHECK_IS_ADMIN_SUC";
export const CHECK_IS_ADMIN_FAIL = "CHECK_IS_ADMIN_FAIL";

export const CHANGE_USER_IMAGE_REQ = "CHANGE_USER_IMAGE_REQ";
export const CHANGE_USER_IMAGE_SUC = "CHANGE_USER_IMAGE_SUC";
export const CHANGE_USER_IMAGE_FAIL = "CHANGE_USER_IMAGE_FAIL";
export const DELETE_USER_IMAGE_REQ = "DELETE_USER_IMAGE_REQ";
export const DELETE_USER_IMAGE_SUC = "DELETE_USER_IMAGE_SUC";
export const FORGOT_PASSWORD_REDIRECT = "FORGOT_PASSWORD_REDIRECT";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_ASSETS_LIST_REQ = "GET_ASSETS_LIST_REQ";
export const GET_ASSETS_LIST_SUC = "GET_ASSETS_LIST_SUC";
export const GET_ASSETS_LIST_FAIL = "GET_ASSETS_LIST_FAIL";
export const GET_STORIES_LIST_REQ = "GET_STORIES_LIST_REQ";
export const GET_STORIES_LIST_SUC = "GET_STORIES_LIST_SUC";
export const GET_STORIES_LIST_FAIL = "GET_STORIES_LIST_FAIL";
export const GET_SLUG_STORIES_LIST_REQ = "GET_SLUG_STORIES_LIST_REQ";
export const GET_SLUG_STORIES_LIST_SUC = "GET_SLUG_STORIES_LIST_SUC";
export const GET_SLUG_STORIES_LIST_FAIL = "GET_SLUG_STORIES_LIST_FAIL";
export const OPEN_STORIES_REQ = "OPEN_STORIES_REQ";
export const OPEN_STORIES_SUC = "OPEN_STORIES_SUC";
export const OPEN_STORIES_FAIL = "OPEN_STORIES_FAIL";
export const CLOSE_STORIES = "CLOSE_STORIES";
export const NEXT_STORIE_REQ = "NEXT_STORIE_REQ";
export const NEXT_STORIE_SUC = "NEXT_STORIE_SUC";
export const NEXT_STORIE_FAIL = "NEXT_STORIE_FAIL";
export const GET_ASSET_MAIN_INFO = "GET_ASSET_MAIN_INFO";
export const GET_ASSET_INFO_REQ = "GET_ASSET_INFO_REQ";
export const GET_ASSET_LOCATION_REQ = "GET_ASSET_LOCATION_REQ";
export const GET_LOCATION_INFO = "GET_LOCATION_INFO";
export const GET_FOLDERS = "GET_FOLDERS";
export const GET_PROJECTION_INFO = "GET_PROJECTION_INFO";
export const GET_HIGHLIGHTS_INFO = "GET_HIGHLIGHTS_INFO";
export const SEND_KYC_INFORMATION_REQ = "SEND_KYC_INFORMATION_REQ";
export const SEND_KYC_INFORMATION_SUC = "SEND_KYC_INFORMATION_SUC";
export const SEND_KYC_INFORMATION_FAIL = "SEND_KYC_INFORMATION_FAIL";
export const SEND_KYC_QUESTIONS_REQ = "SEND_KYC_QUESTIONS_REQ";
export const SEND_KYC_QUESTIONS_SUC = "SEND_KYC_QUESTIONS_SUC";
export const SEND_KYC_QUESTIONS_FAIL = "SEND_KYC_QUESTIONS_FAIL";
export const SEND_ID_VERIFICATION_REQ = "SEND_ID_VERIFICATION_REQ";
export const SEND_ID_VERIFICATION_SUC = "SEND_ID_VERIFICATION_SUC";
export const SEND_ID_VERIFICATION_FAIL = "SEND_ID_VERIFICATION_FAIL";
export const GET_START_KYC_REQ = "GET_START_KYC_REQ";
export const GET_START_KYC_SUC = "GET_START_KYC_SUC";
export const GET_START_KYC_FAIL = "GET_START_KYC_FAIL";
export const SEND_KYC_INFORMATION_STATUS_FALSE =
  "SEND_KYC_INFORMATION_STATUS_FALSE";
export const APPLY_DISCOUNT_REQ = "APPLY_DISCOUNT_REQ";
export const APPLY_DISCOUNT_SUC = "APPLY_DISCOUNT_SUC";
export const APPLY_DISCOUNT_FAIL = "APPLY_DISCOUNT_FAIL";
export const SPENT_CREDITS_COUNTING = "SPENT_CREDITS_COUNTING";
export const CALLBACK_ACTION = "CALLBACK_ACTION";
export const SET_INPUT_VAL = "SET_INPUT_VAL";
export const CHANGE_DISCOUNT = "CHANGE_DISCOUNT";
export const CHANGE_CODE = "CHACNGE_CODE";

export const GET_ACCREDITATION_INFO_REQ = "GET_ACCREDITATION_INFO_REQ";
export const GET_ACCREDITATION_INFO_SUC = "GET_ACCREDITATION_INFO_SUC";
export const GET_DOMESTIC_SUC = "GET_DOMESTIC_SUC";
export const GET_DOMESTIC_FAIL = "GET_DOMESTIC_FAIL";
export const GET_ACCOUNT_TYPE_SUC = "GET_ACCOUNT_TYPE_SUC";
export const GET_ACCOUNT_TYPE_FAIL = "GET_ACCOUNT_TYPE_FAIL";
export const SEND_ACCREDITATION_REQ = "SEND_ACCREDITATION_REQ";
export const SEND_ACCREDITATION_SUC = "SEND_ACCREDITATION_SUC";
export const SEND_ACCREDITATION_FAIL = "SEND_ACCREDITATION_FAIL";
export const SET_ACCREDITATION_STEP1 = "SET_ACCREDITATION_STEP1";
export const SET_ACCREDITATION_STEP2 = "SET_ACCREDITATION_STEP2";
export const SET_ACCREDITATION_STEP3 = "SET_ACCREDITATION_STEP3";
export const CLOSE_ACCREDITATION_MODAL = "CLOSE_ACCREDITATION_MODAL";
export const CLOSE_KYC_MODAL = "CLOSE_KYC_MODAL";
export const GET_CHECKOUT_INFO_REQ = "GET_CHECKOUT_INFO_REQ";
export const GET_CHECKOUT_INFO_SUC = "GET_CHECKOUT_INFO_SUC";
export const GET_CHECKOUT_INFO_FAIL = "GET_CHECKOUT_INFO_FAIL";

export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const TOGGLE_BTN_LOADER = "TOGGLE_BTN_LOADER";

export const GET_IS_ACCREDITED_REQ = "GET_IS_ACCREDITED_REQ";
export const GET_IS_ACCREDITED_SUC = "GET_IS_ACCREDITED_SUC";
export const GET_IS_KYC_REQ = "GET_IS_KYC_REQ";
export const GET_IS_KYC_SUC = "GET_IS_KYC_SUC";
export const GET_KYC_STATES_SUC = "GET_KYC_STATES_SUC";

export const SLIDER_VALUE = "SLIDER_VALUE";
export const CURRENCY_CONVERTING = "CURRENCY_CONVERTING";
export const SET_CONVERTED_VALUE = "SET_CONVERTED_VALUE";
export const CURRENCY_CRYPTO_PRICE = "CURRENCY_CRYPTO_PRICE";
export const SET_CONVERTED_PRICES = "SET_CONVERTED_PRICES";
export const CONVERT_CURRENCY = "CONVERT_CURRENCY";

export const GET_MY_INVESTMENTS_REQ = "GET_MY_INVESTMENTS_REQ";
export const GET_MY_INVESTMENTS = "GET_MY_INVESTMENTS";
export const GET_MY_HISTORY_REQ = "GET_MY_HISTORY_REQ";
export const GET_MY_HISTORY = "GET_MY_HISTORY";
export const POST_MY_INVESTMENTS_REQ = "POST_MY_INVESTMENTS_REQ";
export const TOGGLE_HISTORY = "TOGGLE_HISTORY";

export const ECHANGE_COLOR = "EXCHANGE-COLOR";
export const GET_ALL_EXCHANGE_OFFERS = "GET_ALL_EXCHANGE_OFFERS";
export const ALL_EXCAHNGE_OFFERS = "ALL_EXCHANGE_OFFERS";
export const GET_TEMP_ITEMS = "GET_TEMP_ITEMS";
export const TEMP_ITEMS = "TEMP_ITEMS";
export const GET_TEMP_TOKENS = "GET_TEMP_TOKENS";
export const TEMP_TOKENS = "TEMP_TOKENS";
export const MAIN_EXCHANGE_ITEMS = "MAIN_EXCHANGE_ITEMS";
export const POST_TRADE_OFFER = "POST_TRADE_OFFER";
export const BUY_EXCHANGED_OFFER = "BUY_EXCHANGED_OFFER";
export const CANCEL_OFFER = "CANCEL_OFFER";
export const FILTER_OFFERS = "FILTER_OFFERS";
export const SNACKBAR_RESPONSE = "SNACKBAR_RESPONSE";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const GET_USER_ASSETS_TOKENS = "GET_USER_ASSETS_TOKENS";
export const GET_TOKENS_INFO = "GET_TOKENS_INFO";
export const CHECK_USER_TOKENS = "CHECK_USER_TOKENS";

export const SEND_CONTACTS_DATA = "SEND_CONTACTS_DATA";
export const SET_PARTNERS_CONTACTS = "SET_PARTNERS_CONTACTS";
export const FIRST_STEP_INFO = "FIRST_STEP_INFO";
export const SECOND_STEP_INFO = "SECOND_STEP_INFO";
export const SEND_ONBOARDING_DATA = "SEND_ONBOARDING_DATA";

export const UPDATE_USER_PHONE = "UPDATE_USER_PHONE";

export const SUBSCRIBE_UPCOMING_REQ = "SUBSCRIBE_UPCOMING_REQ";
export const SUBSCRIBE_UPCOMING_SUC = "SUBSCRIBE_UPCOMING_SUC";
export const SUBSCRIBE_UPCOMING_FAIL = "SUBSCRIBE_UPCOMING_FAIL";
export const CHECK_EXTERNAL_ACCOUNT = "CHECK_EXTERNAL_ACCOUNT";
export const ACH_STATUS = "ACH_STATUS";
export const GET_WIDGET_LINK = "GET_WIDGET_LINK";
export const USE_WIDGET_LINK = "USE_WIDGET_LINK";
export const CREATE_EXTERNAL_ACCOUNT = "CREATE_EXTERNAL_ACCOUNT";
export const GET_EXTERNAL_ACCOUNT_INFO = "GET_EXTERNAL_ACCOUNT_INFO";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const CLEAR_DISCOUNT = "CLEAR_DISCOUNT";

export const INVITE_REFERRAL_REQ = "INVITE_REFERRAL_REQ";
export const INVITE_REFERRAL_SUC = "INVITE_REFERRAL_SUC";
export const INVITE_REFERRAL_FAIL = "INVITE_REFERRAL_FAIL";

export const GET_PRESENTATION_REQ = "GET_PRESENTATION_REQ"
export const GET_PRESENTATION_FAIL = "GET_PRESENTATION_FAIL"
export const GET_PRESENTATION_SUC = "GET_PRESENTATION_SUC"

export const SET_CHECKOUT_TOTAL = "SET_CHECKOUT_TOTAL";
export const GET_ASSET_PAYMENTS_REQ = "GET_ASSET_PAYMENTS_REQ"
export const GET_ASSET_PAYMENTS_SUC = "GET_ASSET_PAYMENTS_SUC"
export const GET_ASSET_PAYMENTS_FAIL = "GET_ASSET_PAYMENTS_FAIL"
export const CHECKOUT_PRODUCTS = 'CHECKOUT_PRODUCTS'
export const CHECKOUT_PURCHASE = 'CHECKOUT_PURCHASE'

export const GET_TOKENIZATION_BY_USER_REQ = "GET_TOKENIZATION_BY_USER_REQ";
export const GET_TOKENIZATION_BY_USER_SUC = "GET_TOKENIZATION_BY_USER_SUC";
export const GET_TOKENIZATION_BY_USER_FAIL = "GET_TOKENIZATION_BY_USER_FAIL";

export const SEND_AT_DASHBOARD_REQ = "SEND_AT_DASHBOARD_REQ"

export const SEND_AT_FIRST_STEP_REQ = "SEND_AT_FIRST_STEP_REQ"
export const SEND_AT_FIRST_STEP_SUC = "SEND_AT_FIRST_STEP_SUC"

export const SEND_AT_STEP_FAIL = "SEND_AT_STEP_FAIL"

export const SEND_AT_SECOND_STEP_REQ = "SEND_AT_SECOND_STEP_REQ"
export const SEND_AT_SECOND_STEP_SUC = "SEND_AT_SECOND_STEP_SUC"

export const SEND_AT_THIRD_STEP_REQ = "SEND_AT_THIRD_STEP_REQ"
export const SEND_AT_THIRD_STEP_SUC = "SEND_AT_THIRD_STEP_SUC"

export const SEND_AT_FOURTH_STEP_REQ = "SEND_AT_FOURTH_STEP_REQ"
export const SEND_AT_FOURTH_STEP_SUC = "SEND_AT_FOURTH_STEP_SUC"

export const SEND_AT_FIVETH_STEP_REQ = "SEND_AT_FIVETH_STEP_REQ"
export const SEND_AT_FIVETH_STEP_SUC = "SEND_AT_FIVETH_STEP_SUC"

export const GET_AT_FIRST_STEP_REQ = "GET_AT_FIRST_STEP_REQ"
export const GET_AT_FIRST_STEP_SUC = "GET_AT_FIRST_STEP_SUC"
export const GET_AT_STEP_FAIL = "GET_AT_STEP_FAIL"

export const GET_AT_SECOND_STEP_REQ = "GET_AT_SECOND_STEP_REQ"
export const GET_AT_SECOND_STEP_SUC = "GET_AT_SECOND_STEP_SUC"

export const GET_AT_THIRD_STEP_REQ = "GET_AT_THIRD_STEP_REQ"
export const GET_AT_THIRD_STEP_SUC = "GET_AT_THIRD_STEP_SUC"

export const GET_AT_FOURTH_STEP_REQ = "GET_AT_FOURTH_STEP_REQ"
export const GET_AT_FOURTH_STEP_SUC = "GET_AT_FOURTH_STEP_SUC"

export const GET_AT_FIVETH_STEP_REQ = "GET_AT_FIVETH_STEP_REQ"
export const GET_AT_FIVETH_STEP_SUC = "GET_AT_FIVETH_STEP_SUC"

export const AT_STEP_CHANGE = "AT_STEP_CHANGE"

export const AT_ADD_FILE_REQ = "AT_ADD_FILE_REQ"
export const AT_UPLOAD_FILE_REQ = "AT_UPLOAD_FILE_REQ"
export const AT_ADD_FILE_SUC = "AT_ADD_FILE_SUC"
export const AT_DELETE_FILE_REQ = "AT_DELETE_FILE"
export const AT_DELETE_FILE_SUC = "AT_DELETE_FILE_SUC"

export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION"
export const GET_CURRENT_SUBSCRIPTION = "GET_CURRENT_SUBSCRIPTION"
export const GET_CURRENT_SUBSCRIPTION_SUC = "GET_CURRENT_SUBSCRIPTION_SUC"
export const CHECK_TOKENIZATION_NAME_REQUEST = "CHECK_TOKENIZATION_NAME_REQUEST"
export const CHECK_TOKENIZATION_SYMBOL_REQUEST = "CHECK_TOKENIZATION_SYMBOL_REQUEST"
export const LOGOUT = "LOGOUT"
export const STIPRE_DONE_PAYMENT_REQEST = 'STIPRE_DONE_PAYMENT_REQEST'

// notification products logic
export const SET_PRODUCTS_NOTIFICATIION_CART = 'SET_PRODUCTS_NOTIFICATIION_CART'

export const SET_TOKENIZATION_PICTURE_REQ = 'SET_TOKENIZATION_PICTURE_REQ'
export const SET_TOKENIZATION_PICTURE_RESP = 'SET_TOKENIZATION_PICTURE_RESP'
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_TOKENIZATION_PAID_STATUS_REQ = 'GET_TOKENIZATION_PAID_STATUS_REQ'
export const GET_TOKENIZATION_PAID_STATUS_WITHOUT_REDIRECT_REQ = 'GET_TOKENIZATION_PAID_STATUS_WITHOUT_REDIRECT_REQ'
export const GET_TOKENIZATION_PAID_STATUS_SUC = 'GET_TOKENIZATION_PAID_STATUS_SUC'
export const SET_TOKENIZATION_PAID_STATUS_REQ = 'SET_TOKENIZATION_PAID_STATUS_REQ'
export const TOKENIZATION_PURCHASE_REQ = 'TOKENIZATION_PURCHASE_REQ'

export const GET_WIRE_PDF = "GET_WIRE_PDF";