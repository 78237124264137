import * as type from "../../constants/types";

const initialState = {
  alias: "",
  assetFields: [],
  imagePath: "",
  footerFields: [],
  description: "",
  title: "",
  currency: "",
  location: "",
  summary: "",
  id: "",
  minAmount: "",
  maxAmount: "",
  realMaxAmount: "",
  status: "",
  images: [],
  unitPrice: 0,
  newPrice: 0,
  endDate: "",
  allowPresentation: false,
  isLoading: false,
};
const mainInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GET_ASSET_MAIN_INFO:
      return {
        ...state,
        alias: action.assetData.alias,
        assetFields: action.assetData.assetFields,
        imagePath: action.assetData.imagePath,
        description: action.assetData.description,
        title: action.assetData.title,
        status: action.assetData.status,
        images: action.assetData.images,
        currency: action.assetData.currency,
        location: action.assetData.location,
        summary: action.assetData.summary,
        id: action.id,
        minAmount: action.assetData.minAmount,
        maxAmount: action.assetData.maxAmount,
        realMaxAmount: action.assetData.realMaxAmount,
        unitPrice: action.assetData.unitPrice,
        newPrice: action.assetData.unitPrice,
        footerFields: action.assetData.footerFields,
        endDate: action.assetData.endDate,
        isLoading: false,
      };
    // case type.APPLY_DISCOUNT:
    //     let newPrice = state.unitPrice * 0.8
    //     return {
    //         ...state,
    //         newPrice: newPrice
    //     }
    case type.GET_ASSET_INFO_REQ:
      return {
        ...state,
        isLoading: true,
      };

    case type.GET_PRESENTATION_SUC:
      return {
        ...state,
        allowPresentation: true,
      };
    default:
      return state;
  }
};

export default mainInfoReducer;
