// import { DiscFull } from "@material-ui/icons";
import Perks from "./../../../../Assets/images/perks.png";
import React from "react";

function AdvanceInvestorPerks() {
    return (
        <div className="investor-perks">
        <img src={Perks} alt="" className="icon" />
        <div className="top">
            <div className="title">Investor Perks</div>
            <div className="descr">
                You can receive perks for investing in the Digital Detox Coin through Holt Digital Banking Platform such as an additional discount on your DDC tokens.
            </div>
        </div>
        <div className="field-item">
            <div className="bonus" style={{fontSize:"15px", lineHeight:"24px"}}>
                By signing up with Holt Digital Banking Platform you will be eligible to receive a 5% discount on your Digital Detox Coin investment. 
                Holt Digital is a banking and investment platform that is supported by Holdun for investing in Digital Detox Coin. To apply for the promotion you will need to <a
                href="https://joinholt.com/apply-now/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#0EC22B" }}
                >
                open an account with Holt
                </a>{" "}.*<br/><br/>
                Investors who already have a Holt Digital Banking Platform account and want to invest in Digital Detox Coin through the Holt portal 
                will be directed to Holt’s landing page with information about the fund. You’ll then check out using SolidBlock’s payment system.<br/><br/>
            </div>
        </div>
        <div className="field-item">
            <div className="bonus">
                <span className="smaller">*Must keep the account open for a minimum of 1 year to exercise this option.</span>
            </div>
        </div>
    </div>
  );  
} 

export default AdvanceInvestorPerks;
