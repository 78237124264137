import * as types from './../../constants/types'

const initialState = {
    kycInfo: {
        firstName: null,
        lastName: null,
        dob: null,
        primCoutry: null,
        primAddress1: null,
        primCity: null,
        primState: null,
        primZip: null,
        occupation: null,
        isUSCitizen: null,
    },
    questions: [],
    idNumber: '',
    partyId: '',
    kycStatus: null,
    questionsStatus: null,
    error: false,
    kycMessage: null,
    idVerificationMessage: '',
    modalOpen: false,
    idVerificationStep: false,
    kycLoading: false,
    kycCheckLoading: false,
    idVerificationLoading: false,
    isKYC: true,
    fileError: false,
    states: []
}

const KYCReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.SEND_KYC_INFORMATION_REQ:
            return {
                ...state,
                kycLoading: true
            }
        case types.SEND_KYC_INFORMATION_SUC:
            return {
                ...state,
                kycLoading: false,
                questions: action.payload.questions.question,
                error: false,
                partyId: action.payload.partyId,
                idNumber: action.payload.idNumber,
            }
        case types.SEND_KYC_INFORMATION_STATUS_FALSE:
            return {
                ...state,
                kycLoading: false,
                kycStatus: false,
                partyId: action.payload.partyId
            }
        case types.SEND_KYC_INFORMATION_FAIL:
            return {
                ...state,
                kycLoading: false,
                error: true
            }
        case types.SEND_KYC_QUESTIONS_REQ:
            return {
                ...state,
                kycCheckLoading: true
            }
        case types.SEND_KYC_QUESTIONS_SUC:
            return {
                ...state,
                kycCheckLoading: false,
                error: false,
                questionsStatus: action.payload.status,
                message: action.payload.message,
                modalOpen: true,
                idVerificationStep: true
            }
        case types.SEND_KYC_QUESTIONS_FAIL:
            return {
                ...state,
                kycCheckLoading: false,
                error: true
            }
        case types.SEND_ID_VERIFICATION_REQ:
            return {
                ...state,
                idVerificationLoading: true,
                idVerificationStep: false
            }
        case types.SEND_ID_VERIFICATION_SUC:
            return {
                ...state,
                idVerificationLoading: false,
                idVerificationMessage: action.payload,
                modalOpen: true
            }
        case types.SEND_ID_VERIFICATION_FAIL:
            return {
                ...state,
                idVerificationLoading: false,
                fileError: true
            }
        case types.GET_START_KYC_REQ:
            return {
                ...state,
                kycLoading: true
            }
        case types.GET_START_KYC_SUC:
            return {
                ...state,
                kycInfo: {
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    dob: null,
                    primCountry: action.payload.country,
                    primAddress1: action.payload.address,
                    primCity: action.payload.city,
                    primState: action.payload.state,
                    primZip: action.payload.zipCode,
                    occupation: null,
                    isUSCitizen: action.payload.isUSCitizen,
                },
                kycLoading: false
            }
        case types.GET_KYC_STATES_SUC:
            return {
                ...state,
                states: action.payload.states
            }
        case types.GET_START_KYC_FAIL:
            return {
                ...state,
                kycLoading: false,
                error: true
            }
        case types.CLOSE_KYC_MODAL:
            return {
                ...state,
                modalOpen: false
            }
        case types.GET_IS_KYC_REQ:
            return {
                ...state
            }
        case types.GET_IS_KYC_SUC:
            return {
                ...state,
                isKYC: action.payload.isKYC,
                partyId: action.payload.partyId

            }
        default: return state
    }
}

export default KYCReducer;