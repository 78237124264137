import { call, put, select, takeEvery } from "redux-saga/effects";
import { postApi } from "../shared/postApi";
import { TypedIterableIterator } from "../../../interfaces/interfaces";
import * as type from "../../constants/types";
import { emailForgotten, forgotPasswordResendEmailCodeAC, sendVerificationCode, showWarningSnackbar } from "../../actions/registartionAction";
import { ForgotPasswordInterface } from "../../../interfaces/authInterfaces";

function* handleEmail(action: ReturnType<typeof emailForgotten>): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "auth/forgotPassword";
        const data = yield call(postApi, [{ email: action.payload.email }, endpoint]);
        if (data.status >= 200 && data.status < 300) {
            yield put({
                type: type.SEND_EMAIL,
                userdata: data,
                email: action.payload.email,
            });
            action.payload.history.push('/forgot-password/new-password')
        } else throw data

    } catch (e: any) {
        if (typeof e?.response?.data?.error === 'string') {
            action.payload.errorMessageCB(e?.response?.data?.error)
        } else {
            yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, `${e?.response?.data}`));
            action.payload.errorMessageCB('Server error')
        }
    }
}

function* forgotPasswordResendEmailCodeSaga(action: ReturnType<typeof forgotPasswordResendEmailCodeAC>): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "auth/forgotPassword";
        const forgotData = yield select((state: { forgotData: ForgotPasswordInterface }) => state.forgotData)
        if (forgotData.email) {
            const data = yield call(postApi, [{ email: forgotData.email }, endpoint]);
            if (data.status >= 200 && data.status < 300) {
                action.payload.donecb()
            } else throw data
        } else window.location.assign('/login')

    } catch (e: any) {
        yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, `${e?.response?.data}`));
    }
}

function* reqCodeSaga(action: ReturnType<typeof sendVerificationCode>): TypedIterableIterator<any, any, number> {
    try {
        const endpoint = "auth/confirmPassword";
        const data = yield call(postApi, [action.payload, endpoint]);
        if (data.status >= 200 && data.status < 300) {
            action.payload.doneCb()
        } else throw data
    } catch (e: any) {
        if (typeof e?.response?.data?.error === 'string') {
            action.payload.failCb(e?.response?.data?.error)
        } else {
            yield put(showWarningSnackbar(`Server Error ${e?.response?.status}`, `${e?.response?.data}`));
            action.payload.failCb('Server error')
        }
    }
}

function* watchReqEmailSaga() {
    yield takeEvery(type.EMAIL_FOR_FORGOTTEN, handleEmail);
    yield takeEvery(type.SEND_VERIFICATION_CODE, reqCodeSaga);
    yield takeEvery(type.FORGOT_PASSWORD_RESEND_EMAIL_REQ, forgotPasswordResendEmailCodeSaga);
}

export default watchReqEmailSaga;
