import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { useSelector, useDispatch } from "react-redux";
import { InvestmentsInterface } from '../../interfaces/myInvestymentsInterface'
import { showWarningSnackbar } from "../../redux/actions/registartionAction";
import Snackbar from '../../components/exchange-componnets/snakBar'
import Company1 from "../../Assets/images/Rec1.png";
import Company3 from "../../Assets/images/Rec3.png";
import Company4 from "../../Assets/images/Rec4.png";
import Company5 from "../../Assets/images/Rec5.png";
import Company6 from "../../Assets/images/Rec6.png";
import Company7 from "../../Assets/images/Rec7.png";
import { THEME_COLOR } from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: 0,
            marginTop: 20,
            width: "100%",
        },
    })
);

const SendExchangeSection = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [asset, setAsset] = React.useState("");
    const [tradeAmount, setTradeAmount] = React.useState("");
    const investmentsData = useSelector((store: InvestmentsInterface) => store.myInvestments.investmentsList);
    const handleAssetChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setAsset(event.target.value as string);
    };
    const handleTradeAmount = (name: string) => {
        setTradeAmount(String(investmentsData.find((asset) => asset.name === name)?.totalAmount))
    }


    return (
        <>
            <div className="trade-modal-section">
                <Snackbar />
                <FormControl className={classes.formControl}>
                    <InputLabel
                        shrink
                        id="trade-exchange-asset-label-label"
                        className="trade-input-label"
                    >
                        Select asset
                    </InputLabel>
                    <Select
                        labelId="trade-exchange-asset-label-label"
                        id="trade-exchange-asset-label"
                        value={asset}
                        onChange={handleAssetChange}
                        displayEmpty
                        className="trade-input"
                    >
                        {
                            investmentsData.map((asset) => {
                                return (<MenuItem onClick={() => handleTradeAmount(asset.name)} key={asset.name + asset.id} value={asset.name}>
                                    {asset.name}
                                </MenuItem>)
                            })
                        }

                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel
                        htmlFor="trade-exchange-amount"
                        className="trade-input-label"
                        shrink={true}
                    >
                        Amount
                    </InputLabel>
                    <Input
                        id="trade-exchange-amount"
                        value={tradeAmount}

                        className="trade-input"
                        placeholder=""
                    />
                </FormControl>
            </div>
            <div className="trade-companies-container">
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company1} alt="" />
                </button>
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company3} alt="" />
                </button>
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company4} alt="" />
                </button>
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company5} alt="" />
                </button>
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company6} alt="" />
                </button>
                <button className="trade-company-btn">
                    <img className="trade-company-img" src={Company7} alt="" />
                </button>
            </div>
            <button onClick={() => dispatch(showWarningSnackbar('Currently unavailable'))} className="close-btn" style={{ backgroundColor: THEME_COLOR }}>
                Send to Exchange
            </button>
        </>
    );
};

export default SendExchangeSection;
