import moment from "moment";
import { THEME_COLOR } from "../../../../constants/colors";
import { CURRENCY_SYMBOLS } from "../../../../constants/myInvestmentsConstants";
import { ISO_DATE } from "../../../../constants/regex";
import {
  iAssetField,
  MarketPlaceCard,
} from "../../../../interfaces/marketplaceInterface";
import AssetField from "./assetField";
import { addComma } from "../../../../constants/addCommaFunction";
import FundedIcon from "./../../../../Assets/icons/funded.svg";
import { RED_FROG_ID, DETOX_ID } from "../../../../constants/assetsId"; 
import { ReactChild } from "react";

export default function LandingCard(props: {
  asset: MarketPlaceCard;
  isVisible?: boolean;
  promo?:ReactChild
}) {
  const {
    asset,
    promo,
    //  isVisible
  } = props;
  

  // const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   if (isVisible) {
  //     if (asset.status === "FUNDED") {
  //       setProgress(100);
  //       return;
  //     }
  //     setProgress((asset.tokenSold / asset.maxAmount) * 100);
  //   }
  //   return () => setProgress(0);
  // }, [isVisible, asset.tokenSold, asset.maxAmount, asset.status]);

  const timerField = (endDate: string, status: string) => {
    const endTime = Date.parse(endDate);
    const diff = +endTime - +new Date();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (status === "FUNDED" || days < 0) {
      return 0;
    }
    return days;
  };

  const labelColors = (status: string) => {
    switch (status) {
      case "UPCOMING":
        return "#8A00F6";
      default:
        return THEME_COLOR;
    }
  };

  const nFormatter = (num: number, digits: number) => {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };

  const daysLeft = timerField(asset.endDate, asset.status)

  const renderLabel = (
    <>
      {asset.status !== "ACTIVE" ? (
        <div
          className="label"
          style={{ backgroundColor: labelColors(asset.status) }}
        >
          {asset.status === "FUNDED" ? <img src={FundedIcon} alt="" /> : null}
          {asset.status === "UPCOMING" ? "COMING SOON" : asset.status}
        </div>
      ) : null}
    </>
  );

  const _isRedFrog = asset.id === RED_FROG_ID;
  const _isDetox = asset.id === DETOX_ID;

  
  return (
    <>
      <div className="card-img">
        {promo||null}
        {renderLabel}
        <img src={asset.imagePath} alt="Best Online Marketplace Platform" loading="lazy"/>
      </div>
      <div className="card-content">
        <div className="card-title">{asset.title} <span>(${asset.alias})</span></div>
        <div className="card-location">{asset.location}</div>
        <div className="card-descr">{asset.description}</div>
        <div className="card-timer">
          {/* <div className="amount">
            {asset.status === "FUNDED" ? asset.maxAmount : asset.tokenSold} of {asset.maxAmount} {asset.alias} tokens
          </div>
          <div className="line">
            <div
              className="raised"
              style={{
                width: `${progress > 5 ? progress : 5}%`,
              }}
            ></div>
          </div> */}
          <div className="investors">
            {/* <div className="left">
              <span className="green">{`${
                CURRENCY_SYMBOLS[asset.currency]
              }${nFormatter(
                (asset.status === "FUNDED"
                  ? asset.maxAmount
                  : asset.tokenSold) * asset.unitPrice,
                1
              )}`}</span>
              raised
            </div> */}
            {asset.status === "FUNDED" ? null : (
              <div className="right">
                <span className="green">
                  {_isRedFrog ? "$6M" : _isDetox ? "$2.45M": `${
                  CURRENCY_SYMBOLS[asset.currency]
                }${nFormatter(asset.maxAmount * asset.unitPrice, 4)}`}
                </span>
                First closing
              </div>
            )}
          </div>
        </div>

        <div className="card-fields">
          {/* <AssetField
            title="First closing"
            value={`${CURRENCY_SYMBOLS[asset.currency]}${addComma(
              asset.maxAmount.toString()
            )}`}
            currency={asset.currency}
          /> */}
          <AssetField
            title="Minimum Investment"
            value={`${CURRENCY_SYMBOLS[asset.currency]}${addComma(
              asset.minAmount.toString()
            )}`}
            currency={asset.currency}
          />
          {asset.assetFields.map((field: iAssetField) => {
            let fieldValue;
            ISO_DATE.test(field.value as string)
              ? (fieldValue = moment(field.value).format("DD-MMM-YYYY"))
              : (fieldValue = field.value);
            return (
              <AssetField
                title={field.customField}
                value={fieldValue}
                currency={asset.currency}
                key={field.customField}
              />
            );
          })}
          {/* {timerField(asset.endDate) < 365 &&
          timerField(asset.endDate) > 0 &&
          asset.status === "ACTIVE" ? ( */}
          {daysLeft?
          <AssetField
          title="Days Left to Invest in Current Round"
          value={daysLeft}
          currency={asset.currency}
          isEndtime
          key="endtime"
        />:null}
          

          {/* : null} */}
        </div>
        <div className="get-access">
          {localStorage.getItem("token") ? "Explore Asset" : "Get Full Access"}
        </div>
      </div>
    </>
  );
}
