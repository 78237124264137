import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeVerifyModal } from './../../../redux/actions/headerActions';
import { UserImageState } from '../../../interfaces/headerInterface';

function VerifyModal() {

    const history = useHistory();

    const dispatch = useDispatch();

    const userData = useSelector((state: UserImageState) => state.headerReducer);

    return (
        <Dialog
            className="verify-modal"
            open={userData.verifyOpen}
            onClose={() => dispatch(closeVerifyModal())}>
            <span className="title">
                Please verify your credentials
            </span>
            <span className="descr">
                Your email still requires verification.
            </span>
            <Button
                variant="contained"
                color="primary"
                className="verify"
                onClick={() => {
                    history.push('/sign-up/verify-email')
                    dispatch(closeVerifyModal())
                }}>
                Verify
            </Button>
        </Dialog>
    )
}

export default VerifyModal;