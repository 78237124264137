import { refreshTokenApi } from "./refreshToken";
const axios = require("axios");
const url = process.env.REACT_APP_API_KEY;

const postImageTokenApi = async (action: any) => {
    let ourDate: any = localStorage.getItem("expiredTime");
    let final: any = new Date(ourDate * 1000);
    let date: any = new Date();
    if (final - date < 0) {
        await refreshTokenApi();
    }
    return axios
        .post(url + action[1], action[0], {
            headers: {
                Authorization: localStorage.getItem("token"),
                "Content-type": "multipart/form-data",
            },
        })
        .then((res: any) => res)
};

export { postImageTokenApi };
